import customerImportUsageDataTemplate from './../../components/popup-view/customer-import-usage-data-information.html';
export default function(app) {
  app.factory('dataServices', dataServices);

  function dataServices($http, $state, $rootScope, Upload, apiVersions, serverUrl, localStorageService, spinnerLoader, messages, bobjMap, dateFactory, emailThrottle, $mdDialog) {

    "ngInject";

    return {
      get: get,
      post: post,
      put: put,
      patch: patch,
      delete: del,
      upload: upload,
      toSave: toSave,
      confirmPopup: confirmPopup
    };

    // Initialise spinner
    function initSpinner(xhrInfo) {
      if (xhrInfo.spinner !== false) {
        spinnerLoader.start(xhrInfo.spinnerName);
      }
    }

    function addAccessData(xhrInfo, headers) {
      if (xhrInfo.addAccessData === undefined) {
        if ($rootScope.accessData && $rootScope.accessData.access_token) {
          headers.Authorization = 'Bearer ' + $rootScope.accessData.access_token;
        } else {
          console.error('No Access Token!');
          messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
          spinnerLoader.stop(xhrInfo.spinnerName);
          return;
        }
      }
    }

    // Initialise API Version
    function configureAPICall(xhrInfo) {

      /**
       *  Escape # from url by replacing it with %23
       *
       *  The hashmark (#) has a special meaning in URLs. It has meaning to the client but not to the server, so the server strips it off and ignores it.
       *
       *  If the hash mark is part of your data, it should be sanitized in the same way you sanitize any data sent as part of a query string. In this case, you should replace "#" with "%23" in your query string.
       */
      xhrInfo.url = xhrInfo.url.replace(/#/g, '%23');

      let headers = {
        'product': 'jacktrade_1'
      };

      // Set Accept Header with API Version
      if (!xhrInfo.auc) {
        // Extract API Name
        let temp = xhrInfo.url.split("Api")[0];
        let apiName = temp.substr(temp.lastIndexOf("/") + 1);

        if (apiVersions[apiName]) {
          headers.Accept = 'application/jacktrade.v' + apiVersions[apiName] + '+json';
          headers['time-offset'] = dateFactory.getTimeOffset();
          addAccessData(xhrInfo, headers);
        } else {
          // TODO: Handle missing api version meaningfully
          console.error('API version missing!');
          messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
          spinnerLoader.stop(xhrInfo.spinnerName);
          return;
        }
      } else if (xhrInfo.addAccessDataForAuc) {
        addAccessData(xhrInfo, headers);
      }

      // $http.defaults.headers.common = headers;
      xhrInfo.headers = headers;
    }

    function get(xhrInfo) {

      initSpinner(xhrInfo);
      configureAPICall(xhrInfo);

      if (xhrInfo.addAccessData === undefined && !xhrInfo.noVerData) {
        if (!$rootScope.bObj || !$rootScope.rObj) {
          console.error('No Versions!');
          messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
          spinnerLoader.stop(xhrInfo.spinnerName);
          return;
        }
        xhrInfo.url += "bzobjver=" + $rootScope.bObj.ver + "&usrObjVer=" + $rootScope.usrObj.ver + "&reasonObjVer=" + $rootScope.rObj.ver;
      }

      return $http({
        method: 'GET',
        url: xhrInfo.url,
        headers: xhrInfo.headers
      }).then(function(response) {
        return responseDataValidation(xhrInfo, response);
      }, function(error) {
        return errorHandler(error, xhrInfo);
      });
    }

    function addVersionData(xhrInfo) {
      if (xhrInfo.addAccessData === undefined) {

        if (!xhrInfo.data) {
          xhrInfo.data = {};
        }

        if (!xhrInfo.noVerData) {
          if (!$rootScope.bObj || !$rootScope.rObj) {
            console.error('No Versions!');
            messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
            spinnerLoader.stop(xhrInfo.spinnerName);
            return;
          }
          xhrInfo.data.bzobjver = $rootScope.bObj.ver;
          xhrInfo.data.usrObjVer = $rootScope.usrObj.ver;
          xhrInfo.data.reasonObjVer = $rootScope.rObj.ver;
        }
      }
    }

    // , isValidateResponse = true
    function post(xhrInfo) {

      initSpinner(xhrInfo);
      configureAPICall(xhrInfo);
      addVersionData(xhrInfo);

      xhrInfo.headers['Content-Type'] = 'application/x-www-form-urlencoded';

      return $http({
        url: xhrInfo.url,
        method: xhrInfo.method || 'POST',
        headers: xhrInfo.headers,
        transformRequest: function(obj) {
          let str = [];
          for (let p in obj) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
          return str.join("&");
        },
        data: xhrInfo.data
      }).then(function(response) {
        return responseDataValidation(xhrInfo, response);
      }, function(error) {
        return errorHandler(error, xhrInfo);
      });
    }

    /*Upload File Function*/
    function upload(xhrInfo, returnOnlyPromise, id) {

      let promise;

      initSpinner(xhrInfo);
      configureAPICall(xhrInfo);
      addVersionData(xhrInfo);

      promise = Upload.upload({
        url: xhrInfo.url,
        headers: xhrInfo.headers,
        data: xhrInfo.data
      });

      // returned promise object only to handle abort functionality
      if (returnOnlyPromise) {
        return promise;
      } else {
        return promise.then(function(response) {
          return responseDataValidation(xhrInfo, response);
        }, function(error) {
          return errorHandler(error, xhrInfo);
        }, function(evt) {
          if (id) {
            $rootScope.progressPercentage = $rootScope.progressPercentage || {};
            $rootScope.progressPercentage[id] = parseInt(100.0 * evt.loaded / evt.total);
          }
        });
      }
    }

    function put(xhrInfo) {
      xhrInfo.method = 'PUT';
      return post(xhrInfo);
    }

    function patch(xhrInfo) {
      xhrInfo.method = 'PATCH';
      return post(xhrInfo);
    }

    function del(xhrInfo) {
      xhrInfo.method = 'DELETE';
      return post(xhrInfo);
    }

    function stopDataServiceSpinner(xhrInfo) {
      if (xhrInfo.spinner !== false) {
        spinnerLoader.stop(xhrInfo.spinnerName);
      }
    }

    function errorHandler(error, xhrInfo) {
      // Stop spinner in case of error
      console.log(error, xhrInfo);

      spinnerLoader.hardStop();
      spinnerLoader.hardStop($rootScope.customSpinnerName);

      if (!xhrInfo.isRequiredApi && xhrInfo.isShowError !== false) {
        if (error.data && error.data.error) {
          if (error.status == '504' && xhrInfo.url.includes('customerBulkImportApi/customerImport/move')) {
            confirmPopup();
          } else {
            messages.simpleToast(error.data.error, 'danger');
          }
        } else {
          messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
        }
      }
      return error;
    }

    function confirmPopup() {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: confirmDialogController,
        controllerAs: 'cd',
        templateUrl: customerImportUsageDataTemplate,
        parent: angular.element(document.body),
        locals: {}
      });
    }

    function confirmDialogController($scope, $state, $mdDialog, spinnerLoader) {
      var cd = this;
      cd.cancel = function() {
        $mdDialog.cancel();
      };
    }

    function handleObjVersions(data) {
      if (angular.isObject(data.bzobj) && data.bzobj.hasOwnProperty('ver') && $rootScope.bObj.ver != data.bzobj.ver) {
        $rootScope.bObj = data.bzobj;
        bobjMap.mapBusinessData();
        // TODO: do not save business object in local storage for security concerns
        localStorageService.set('bObj', data.bzobj);
      }

      if (angular.isObject(data.reasonObj) && data.reasonObj.hasOwnProperty('ver') && $rootScope.rObj.ver != data.reasonObj.ver) {
        bobjMap.mapReasonData(data.reasonObj);
      }

      if (angular.isObject(data.usrObj) && data.usrObj.hasOwnProperty('ver') && $rootScope.usrObj.ver != data.usrObj.ver) {
        $rootScope.usrObj = data.usrObj;
        bobjMap.mapUserData();
        localStorageService.set('usrObj', data.usrObj);
      }

      if (data.ndsObj && data.ndsObj.length) {
        emailThrottle.init(data.ndsObj[0]);
        // emailThrottle.init({bounce_rate: 0.4, total_bounce: 20, total: 575});
      }
    }

    function responseDataValidation(xhrInfo, response) {

      let data = response.data;
      stopDataServiceSpinner(xhrInfo);

      if (data && data.error && xhrInfo.isShowError !== false) {
        messages.simpleToast(data.error, 'warning');
      } else if (data && data.result && (data.response_code == 200 || data.response_code == 201)) {
        handleObjVersions(data)
      }

      return response;
    }

    // Function to filter objects before sending data to api
    // Params: dataObj -> object to be filtered, fieldsToSave -> array of keys to retain
    function toSave(dataObj, fieldsToSave) {
      let newObj = {};
      angular.forEach(fieldsToSave, function(field) {
        newObj[field] = dataObj[field];
      });
      return angular.copy(newObj);
    }

  }
}