
export default function OpenAIController($scope, id, isCustomer, ListService, $mdDialog, spinnerLoader, messages, OpenAIService, maxlength, errorMessage, $rootScope, vm, ActionService, $state, dataServices,
  serverUrl,
  $timeout,
  $mdSidenav,) {

  'ngInject';

  let cd = this;
  angular.extend(cd, vm);
  cd.aiPopup = 'Action Item'
  cd.parentVm = vm;
  cd.maxlength = maxlength;
  cd.errorMessages = errorMessage;
  cd.gpt = false;
  cd.step1 = true;
  cd.step2 = false;
  cd.index = 0;
  cd.gptData = [];
  cd.actionItems = [];
  cd.getResourceList = () => {
    ActionService.getResourceList(cd);
  };
  // cd.switchList = ListService.switchList;
  // cd.openSidenavResource = ActionService.openSidenavResource;

  // cd.actionConstant = {
  //   modelName: 40,
  //   modelId: $rootScope.usrObj.userId,
  //   scopeParentVm: $scope.$parent.vm,
  //   isWorkboard: false,
  //   isHiddenFolder: false
  // };

  // // Suggestion and search history api keys required for search functionality in right sidenavs
  // cd.suggestionApi = {
  //   resourcesF: resourceHeader.ACTION.suggestionApi.resourcesF,
  // };

  // cd.searchHistoryApiKey = {
  //   resourcesF: resourceHeader.ACTION.searchHistoryApiKey.resourcesF,
  // };

  cd.toggleSearch = ListService.toggleSearch;
  cd.searchOnEnter = ListService.searchOnEnter;
  cd.getSearchKeys = ListService.getSearchKeys(cd);

  cd.getList = (type = 'new', retainLimit, switchPageType, callback) => {
    let url, filterCriteria = ListService.setPrimaryDataCopy(cd, type, retainLimit, switchPageType),
      spinnerName;
    console.log(cd.listName);
    switch (cd.listName) {
      case 'resourcesF':
        if(id && !isCustomer) {
          url = serverUrl.main + "resourceApi/resources/rnp/20/" + id + "?filterCriteria=" + filterCriteria + '&' + (cd.searchText.resourcesF ? ('search=' + cd.searchText.resourcesF + '&') : "");
        } else {
          url = serverUrl.main + 'resourceApi/resources?filterCriteria=' + filterCriteria + '&' + (cd.searchText.resourcesF ? ('search=' + cd.searchText.resourcesF + '&') : '');
        }
        spinnerName = 'sidebar-spinner';
        break;
        
      case 'actionActive':
        if(id) {
          url = serverUrl.main + "actionsApi/function/action?filterCriteria=" + filterCriteria + "&" + (cd.parentVm.searchText ? "search=" + cd.parentVm.searchText + "&" : "");
          // if(isCustomer) {
          //   url = serverUrl.main + "actionsApi/function/action?filterCriteria=" + filterCriteria + "&" + (cd.parentVm.searchText ? "search=" + cd.parentVm.searchText + "&" : "");
          // } else {

          // }
        } else {
          url = serverUrl.main + 'actionsApi/action/list/details?filterCriteria=' + filterCriteria + (cd.parentVm.currentFolderId != 'all' ? '&folder=' + cd.parentVm.currentFolderId + '&' : '&') + (cd.parentVm.searchText[cd.parentVm.listName] ? ('search=' + cd.parentVm.searchText[cd.parentVm.listName] + '&') : '');
          callback = () => {
            ActionService.afterActionList(cd.parentVm)
          };
        }
        
        spinnerName = $rootScope.customSpinnerName;
        break;
    }

    ListService.postList(cd, url, cd.enableList, spinnerName, type == 'add', callback);
  }
  ListService.assignedDataCopy(cd, 'RESOURCE_LIST', [], 'resourcesF', 'id');
  ListService.assignedDataCopy(cd, 'ACTION_LIST', [], 'actionActive', 'id', false);

  cd.setFilters = (type, retainLimit = false, switchPageType = false) => {
    cd.filters[cd.listName] = angular.copy(cd.defaultFc[cd.listName].filters);

    if (cd.listName == 'resourcesF') {
      setResourcesFilters();
    }

    cd.getList(type, retainLimit, switchPageType);
  }

  function setResourcesFilters() {
    if (cd.filter.resourcesF.selectedLocation == 'None') {
      delete cd.filter.resourcesF.selectedLocation;
    }
    if (cd.filter.resourcesF.orgId) {
      ListService.addFilter(cd, 'orgId', cd.filter.resourcesF.orgId, '=');
    } else {
      ListService.removeFilter(cd, 'orgId');
    }
    if (cd.filter.resourcesF.selectedLocation) {
      ListService.addFilter(cd, 'businessAddress', parseInt(cd.filter.resourcesF.selectedLocation), '=');
    } else {
      ListService.removeFilter(cd, 'businessAddress');
    }
  }

  cd.resourcesSideNav = () => {
    $mdSidenav('resourcesF-Ai').toggle();
  };

  cd.closeResourceSidenav = () => {
    cd.selectResNew = angular.copy(cd.selectResNewBackUp);
    $mdSidenav('resourcesF-Ai').close();
  };

  cd.saveResources = () => {
    cd.selectedResources = angular.copy(cd.selectResNew);
    
    cd.saveAttachedResources(cd);
    $mdSidenav('resourcesF-Ai').close();
  };

  ActionService.init(cd);
  cd.confirm = () => {
    cd.parentVm.priority = cd.priority;
    cd.parentVm.isFavourite = cd.isFavourite;
    cd.parentVm.selectedResToAdd = cd.selectedResToAdd;
    cd.parentVm.addEndDate = cd.addEndDate;
    cd.parentVm.labelsToAdd = cd.labelsToAdd;
    cd.multipleActionItems = true;
    ActionService.newAction(cd);
    cd.cancel();
  }

  cd.messages = [{
    role: "system",
    // content: 'Extract scope of work info from the text. Avoid duplicate scope of work. Present the available data in the following JSON format { "scopes": [{"name": "VALUE", "description": "VALUE"}, {"name": "VALUE", "description": "VALUE"}], "quantityAlias": "VALUE", "pricing":{"LA":[{"pricing":{"rate":10},"type":"flat_per_task"},{"pricing":{"rate":20},"type":"flat"},{"pricing":{"rate":15},"type":"hour"},{"pricing":{"rate":2,"initialRate":1,"initialDuration":12}]}}'
    content: 'Extract action list from the text. Avoid duplicate action list. Present the available data in the following JSON format { "action": ["Value", "Value"], dueDate: "Value", focus: value should be true or false}'
  }];

  cd.nextStep = () => {
    if (cd.field == 1) {
      cd.field = 2;
    }
  }

  cd.backStep = () => {
    if (cd.field == 2) {
      cd.field = 1;
    }
  }

  cd.sendPromptToGPT = () => {
    // cd.step1 = false;
    // cd.step2 = true;
    // cd.field = 2;
    // return;
    if (!cd.gptInput) {
      messages.simpleToast("Enter data", "danger");
      return;
    }
    spinnerLoader.start($rootScope.customSpinnerName);

    let prompt = cd.gptInput;
    cd.messages.push({
      role: "user",
      content: prompt
    });

    cd.gptData.push({
      prompt
    });

    OpenAIService.createChatCompletion(cd.messages).then(response => {
      spinnerLoader.stop($rootScope.customSpinnerName);
      let data = response.replace(/^```json\n|\n```$/g, '');
      try {
        data = JSON.parse(data);
        
        cd.actionItems = angular.copy(data.action);
        cd.step1 = false;
        cd.step2 = true;
        cd.field = 1;
        cd.totalActionsExtracted = cd.actionItems.length;
        $timeout(function () {
          let input = document.getElementById("gpt-input");
          input.focus();
        });
      } catch (error) {
        
        messages.simpleToast("Data you entered could not be converted. Please reevaluate.", "danger");
        return;
      }
    });
  };

  cd.search = search;

  function search(isSidenav) {
    if (!isSidenav) {
      cd.searchText[cd.listName] = $scope.$parent.cd.searchText[cd.listName];
    }
    console.log(cd.listName);
    cd.getList('new', false, false, function() {
      if (cd.searchText && cd.searchText[cd.listName]) {
        ListService.toggleSearch(isSidenav);
      }
    });
  }

  cd.goToNextStep = () => {
    cd.step2 = !cd.step2;
    cd.step1 = !cd.step1;
  }

  cd.cancel = function () {
    $mdDialog.cancel();
  };
}