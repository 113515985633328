import packageTriggerTemplate from './../../components/popup-view/purchaseTriggerTemplate.html';

export default function(app) {
  app.factory('PackageTriggerService', PackageTriggerService);

  function PackageTriggerService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage, $rootScope, $mdSidenav) {
    "ngInject";

    return {
      getPackageInformation: getPackageInformation,
      packageInfoPopup: packageInfoPopup
    }

    function getPackageInformation(vm, module = 'customers', activeCount) {
      // TODO: remove return later
      return;
      if (process.env.NODE_ENV !== 'production') {
        dataServices.get({
          url: serverUrl.auc + 'client/business-trigger-values',
          auc: true,
          noVerData: true,
          addAccessDataForAuc: true,
          spinner: false
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            vm.packageInformation = response.data.result.trigger_values[module][1];
            console.log(vm.packageInformation);
            if (module == 'dashboard') {
              dataServices.get({
                url: serverUrl.main + 'dashboardApi/dashboards/count?',
                spinner: false
              }).then(function(response) {
                if (response && response.data && response.data.result) {
                  customerUsages(vm, response.data.result);
                }
              });
            } else {
              customerUsages(vm, activeCount);
            }
          }
        });
      }
    }

    function customerUsages(vm, activeCount) {
      let usagesPercent = (activeCount / vm.packageInformation) * 100;
      console.log('Usages Percent : ', usagesPercent);
      if (usagesPercent > 24) {
        packageInfoPopup(vm, activeCount);
      }
    }

    function packageInfoPopup(vm, activeCount) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: PackageInfoController,
        controllerAs: 'cd',
        templateUrl: packageTriggerTemplate,
        parent: angular.element(document.body),
        // targetEvent: ev,
        locals: {
          parentVm: vm,
          activeCount: activeCount
        }
      });
    }

    function PackageInfoController($scope, $mdDialog, $rootScope, $timeout, parentVm, activeCount) {

      "ngInject";

      let cd = this;
      cd.packageInformation = parentVm['packageInformation'];
      cd.activeCount = activeCount;

      console.log('Active Count:', cd.activeCount);
      console.log('Package Information:', cd.activeCount);

      cd.cancel = cancel;

      function cancel() {
        $mdDialog.cancel();
      }
    }

  }
}