export default function(app) {
  app.factory('GlobalMethodsService', GlobalMethodsService);

  function GlobalMethodsService($rootScope, dataServices, serverUrl, sharedDataService) {
    "ngInject";

    return {
      init: init
    };

    function startLocationTracking() {
      console.log('startLocationTracking from global-------');
      if (sharedDataService.get('isFromAndroidApp')) {
        AndroidInterface.startLocationTracking();
      } else if (sharedDataService.get('isFromIOSApp')) {
        sendIOSMessage({
          action: 'startLocationTracking'
        });
      }
    }

    function stopLocationTracking() {
      console.log('stopLocationTracking from global-------');
      if (sharedDataService.get('isFromAndroidApp')) {
        AndroidInterface.stopLocationTracking();
      } else if (sharedDataService.get('isFromIOSApp')) {
        sendIOSMessage({
          action: 'stopLocationTracking'
        });
      }
    }

    function init() {

      let clockinDetail = $rootScope.usrObj ? $rootScope.usrObj.ClockStatus : {};
      console.log('clockinDetail----', clockinDetail);
      if (clockinDetail.status == 'clock_in') {
        startLocationTracking();
      } else {
        stopLocationTracking();
      }

      window.depositLocation = (address = {}) => {
        console.log('depositLocation global', address);

        const data = {
          deviceType: 'app',
          taskId: clockinDetail.taskId,
          quoteId: clockinDetail.quoteId,
          address
        };

        dataServices.post({
            url: serverUrl.main + 'locationTrackingApi/location/add',
            data: {
              data: angular.toJson(data)
            },
            spinner: false,
            isShowError: false
          })
          .then(function(response) {
            console.log(response);
            let data = response.data;
            if (data && data.response_code == 200) {

            }
          });
      };
    }

  }
}