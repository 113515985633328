import findIndex from 'lodash/findIndex';

import alertDialogTemplate from './../../components/popup-view/alert-dialog.html';
import fileDownloadDialogTemplate from './../../components/popup-view/file-download.html';

export default function(app) {
  app.factory('FFFileUploadService', fileUploadService);

  let errors = [],
    rightSidebarName;

  function fileUploadService($rootScope, $http, $mdDialog, $mdSidenav, commonService, sharedDataService, messages, dataServices, serverUrl, FILE_SIZES) {

    "ngInject";

    return {
      init: init,
      onPicked: onPicked,
      initFiles: initFiles,
      deleteFile: deleteFile,
      buildToggler: buildToggler,
      validateFiles: validateFiles,
      uploadFilesAndSave: uploadFilesAndSave
    };

    // Initiate variables required for file upload
    function init(vm, _rightSidebarName = 'fileUploadSidebar') {
      rightSidebarName = _rightSidebarName;
      vm.customFormFiles = vm.customFormFiles || {};

      vm.validateFiles = validateFiles;
      vm.deleteFile = deleteFile;
      vm.onPicked = onPicked;

      vm.setMultiFileUpload = function() {
        if (sharedDataService.get('isFromAndroidApp')) {
          AndroidInterface.setMultipleFileUploadFlag('YES');
        } else if (sharedDataService.get('isFromIOSApp')) {
          if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOSMessager) {
            window.webkit.messageHandlers.iOSMessager.postMessage({
              "message": "hi from web!!!"
            });
          }
        }
      };
    }

    function initFiles(vm, formTarget, formIndex, index) {
      vm.customFormFiles[formTarget] = vm.customFormFiles[formTarget] || {};
      if (vm.freedomForms[formIndex].customFields[index].attachments) {
        vm.customFormFiles[formTarget][index] = angular.copy(vm.freedomForms[formIndex].customFields[index].attachments) || [];
      } else {
        vm.customFormFiles[formTarget][index] = vm.customFormFiles[formTarget][index] || [];
      }
    }

    function validateFiles(vm, files, fileError) {
      onPicked(vm, files, 'local');
    }

    function alertDialogController($scope, $mdDialog, errors) {

      'ngInject';

      let ad = this;

      ad.confirmText = 'Okay';

      ad.title = 'File Upload Errors';

      ad.description = '<ol class="text-left">';
      angular.forEach(errors, function(error) {
        ad.description += '<li>' + error + '</li>';
      });
      ad.description += '</ol>';

      ad.confirm = function() {
        $mdDialog.cancel();
      };

    }

    function showErrorInfo() {
      if (!errors.length) {
        return;
      }

      $mdDialog.show({
        locals: {
          errors: errors
        },
        controller: alertDialogController,
        templateUrl: alertDialogTemplate,
        parent: angular.element(document.body),
        controllerAs: 'ad',
        multiple: true,
        clickOutsideToClose: true
      });
    }

    function nonLocalFileUpload(vm, file, type, errFileCount, errors) {
      let promise;

      if (type == 'dropbox') {
        promise = $http({
          method: 'GET',
          url: file.link,
          responseType: 'arraybuffer'
        });
      } else if (type == 'googleDrive') {
        promise = $http({
          method: 'GET',
          url: "https://www.googleapis.com/drive/v3/files/" + file.id + '?alt=media',
          headers: {
            'Authorization': 'Bearer ' + $rootScope.gapiAccessToken
          },
          responseType: 'arraybuffer'
        });
      }

      if (promise) {
        file.isDownloaded = false;
        file.statusInText = 'Downloading';
        promise.then(function(response) {
          if (response.status == 200 && response.data) {
            let mimeType = commonService.getFileMimeType(file);
            if (mimeType) {
              file.isDownloaded = true;
              file.statusInText = 'Downloaded';
              let downloadedFile = new File([response.data], file.name, {
                type: mimeType,
              });
              commonService.compressImageOrPushFile(downloadedFile, vm.customFormFiles[vm.ffTarget][vm.fIndex], () => vm.autoSaveFF(vm.ffTarget));
            } else {
              // File extension validation
              errFileCount++;
              errors.push(file.name + ' has invalid extension!');
              file.isDownloaded = false;
              file.statusInText = 'Invalid Extension';
              return;
            }
          } else {
            file.isDownloaded = false;
            file.statusInText = 'Technical Error';
            // messages.simpleToast('Technical Error! File cannot be processed!');
          }
        }, function(error) {
          file.isDownloaded = false;
          file.statusInText = 'Downloading Failed';
          // messages.simpleToast('Technical Error! File downloading failed!');
        });
      }
    }

    function fileDownloadDialogController($scope, $mdDialog, messages, files) {

      'ngInject';

      let fd = this;
      fd.files = files;
      const filesCount = files.length;

      fd.confirm = function() {
        let downloadingCount = 0;
        for (let index in fd.files) {
          if (fd.files[index].statusInText == 'Downloading') {
            downloadingCount++;
          } else {
            break;
          }
        }

        if (downloadingCount) {
          messages.simpleToast('Please wait till all the attachments are downloaded!');
        } else {
          $mdDialog.cancel();
        }
      };

    }

    // Validate files and prepare to upload
    function onPicked(vm, files, type) {

      vm.myForm.$setDirty();

      let errFileCount = 0;
      errors = [];

      angular.forEach(files, function(file) {

        let mimeType = commonService.getFileMimeType(file);
        if (mimeType) {
          // File size validation - Max size FILE_SIZES.maxMB
          // File Size Variable => sizeBytes - googleDrive, bytes - Dropbox, size - local
          let fileSize = (file.sizeBytes || file.bytes || file.size);
          if (fileSize > FILE_SIZES.max) {
            errFileCount++;
            errors.push(`${file.name} exceeds ${FILE_SIZES.maxMB} (${Math.round(fileSize / 1024 / 1024)}MB)!`);
            file.statusInText = `Exceeds ${FILE_SIZES.maxMB}`;
            return;
          }
          if (type == 'dropbox' || type == 'googleDrive') {
            nonLocalFileUpload(vm, file, type, errFileCount, errors);
          } else {
            commonService.compressImageOrPushFile(file, vm.customFormFiles[vm.ffTarget][vm.fIndex], () => vm.autoSaveFF(vm.ffTarget));
          }
        } else {
          // File extension validation
          errFileCount++;
          errors.push(file.name + ' - File type is not supported. The supported file types are zip, xls, xlsx, txt, pdf, doc, docx, csv, jpeg, jpg, png, gif');
          file.statusInText = 'Invalid Extension';
          return;
        }

      });

      if (type == 'dropbox' || type == 'googleDrive') {
        $mdDialog.show({
          locals: {
            files: files
          },
          controller: fileDownloadDialogController,
          templateUrl: fileDownloadDialogTemplate,
          parent: angular.element(document.body),
          controllerAs: 'fd',
          multiple: true
        });
      } else if (errFileCount && type == 'local') {
        messages.showActionToast(errFileCount + ' out of ' + files.length + ' files ha' + (errFileCount > 1 ? 've' : 's') + ' errors!', 'More Info', 'default', 6000, showErrorInfo);
      }

      $mdSidenav(rightSidebarName).close()
    }

    // Delete attachment - remove it's extension from vm.exts and card from UI
    // Add to removeAttachments variable if it was already uploaded
    function deleteFile(ev, vm, formTarget, fieldIndex, index) {
      messages.mdconfirm(ev, "DO_YOU_WANT_TO_DELETE_THIS_ATTACHMENT")
        .then(function(flag) {
          if (flag) {
            vm.myForm.$setDirty();
            let formIndex;
            if (vm.customFormFiles[formTarget][fieldIndex][index].id) {
              formIndex = findIndex(vm.freedomForms, function(form) {
                return form.formTarget == formTarget;
              });
              vm.freedomForms[formIndex].customFields[fieldIndex].removeAttachments = vm.freedomForms[formIndex].customFields[fieldIndex].removeAttachments || [];
              vm.freedomForms[formIndex].customFields[fieldIndex].removeAttachments.push(vm.customFormFiles[formTarget][fieldIndex][index].id);
            }
            vm.customFormFiles[formTarget][fieldIndex].splice(index, 1);
            vm.autoSaveFF(formTarget);
            // vm.saveFreedomForm();
          }
        });
    }

    function buildToggler(vm, navID) {
      return function() {
        vm.setMultiFileUpload();
        $mdSidenav(navID).toggle();
      };
    }

    function uploadFilesAndSave(vm, callback) {
      let uploadedFormCount = 0,
        formCount = Object.keys(vm.customFormFiles).length;

      if (!formCount) {
        callback();
        return;
      }

      angular.forEach(vm.customFormFiles, (fields, formTarget) => {

        let uploadedFieldCount = 0;
        let fieldCount = Object.keys(fields).length;
        if (!fieldCount) {
          callback();
          return;
        }

        let formIndex = findIndex(vm.freedomForms, function(form) {
          return form.formTarget == formTarget;
        });

        angular.forEach(fields, (filesArr, fieldIndex) => {

          let files = filesArr.filter(function(file) {
            return !file.hasOwnProperty('id') && !file.hasOwnProperty('fileId');
          });

          if (files.length) {
            dataServices.upload({
                url: serverUrl.main + 'mediaApi/upload/file',
                data: {
                  file: files
                },
                isShowError: false
              })
              .then(function(response) {
                uploadedFieldCount++;

                let data = response.data;
                if (response.status == '406') {
                  messages.simpleToast('File couldn\'t be uploaded: System has detected the file as unsafe.', 'danger');
                } else if (data && data.response_code == 200) {

                  let filesArr = vm.customFormFiles[formTarget][fieldIndex].filter(file => file.hasOwnProperty('id') || file.hasOwnProperty('fileId'));
                  vm.customFormFiles[formTarget][fieldIndex] = angular.copy([...filesArr, ...data.result.success]);
                  let fileId = data.result.success.map(function(file) {
                    return file.fileId;
                  })

                  vm.freedomForms[formIndex].customFields[fieldIndex].attachments = fileId;
                  if (fieldCount === uploadedFieldCount) {
                    uploadedFormCount++;
                    if (formCount === uploadedFormCount) {
                      callback();
                    }
                  }
                }
              });
          } else {
            uploadedFieldCount++;
            vm.freedomForms[formIndex].customFields[fieldIndex].attachments = [];
            if (fieldCount === uploadedFieldCount) {
              uploadedFormCount++;
              if (formCount === uploadedFormCount) {
                callback();
              }
            }
          }
        });
      });

    }
  }
}