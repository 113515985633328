import forEach from "lodash/forEach";
import findIndex from "lodash/findIndex";

import folderDeleteTemplate from "../../pages/resources/resource/popup/folderDeleteTemplate.html";
import addNewFolderTemplate from "../../pages/resources/resource/popup/addNewFolder.html";
import folderSequenceTemplate from "../../pages/resources/resource/popup/folderMovement.html";
import saveFilterTemplate from "../../pages/resources/resource/popup/save-filter.html";
import saveFilterListTemplate from "../../pages/resources/resource/popup/save-filter-list.html";
import confirmDialogTemplate from "./../../components/popup-view/confirm-dialog.html";
// import datePickerPopup from "./../../components/popup-view/dueDate-popup.html";
import datePickerPopup from "../../pages/quotes/quote-cycle/action/dueDate-popup.html";
import actionFullscreenPopup from "../../pages/quotes/quote-cycle/action/action-fullscreen.html";
import dashboardService from "./dashboard.service";
import AITemplate from './../../components/popup-view/ai-import-template.html';
import OpenAIController from './../../components/actionItem/actionListOpenAI.controller';

export default function(app) {
  app.factory("ActionService", ActionService);

  let actionRightSidenavName = 'right';

  function ActionService(
    $state,
    dataServices,
    serverUrl,
    $timeout,
    messages,
    maxlength,
    $mdDialog,
    $filter,
    DashboardService,
    validatorService,
    TimezoneService,
    FileUploadService,
    errorMessage,
    timeList,
    $rootScope,
    ListService,
    imageService,
    $mdSidenav,
    sharedDataService
  ) {

    "ngInject";

    const sortTypeWithIndex = [
      "MOST_RECENT_FIRST",
      "LAST_UPDATED",
      "PRIORITY_HIGHEST_FIRST",
      "DUE_DATE",
      "OLDEST_FIRST",
    ];
    const sortTypeParamValue = [
      "createdOn",
      "lastUpdated",
      "priority",
      "setReminder",
      "createdOnFirst",
    ];

    return {
      init: init,
      newAction: newAction,
      editAction: editAction,
      attachFunctionData: attachFunctionData,
      setUnsetFavourite: setUnsetFavourite,
      setUnsetPriority: setUnsetPriority,
      setDueDate: setDueDate,
      updateActionTitle: updateActionTitle,
      updateAction: updateAction,
      showResources: showResources,
      goToState: goToState,
      uploadFilesAndSaveAction: uploadFilesAndSaveAction,

      selectResource: selectResource,
      getResourceList: getResourceList,

      clearSelectedResources: clearSelectedResources,
      clearSelectedFunctions: clearSelectedFunctions,
      enableEditScreen: enableEditScreen,
      attachResource: attachResource,
      attachFunction: attachFunction,
      saveAttachedResources: saveAttachedResources,
      openAddResource: openAddResource,
      selectUnselectFunction: selectUnselectFunction,
      getFunctionsList: getFunctionsList,
      actionCompleteAndActive: actionCompleteAndActive,
      completeAndActive: completeAndActive,
      checkActionItemTime: checkActionItemTime,
      bulkOperation: bulkOperation,
      clearOperation: clearOperation,
      actionDeletionBar: actionDeletionBar,
      bulkSelection: bulkSelection,
      bulkOperationBar: bulkOperationBar,
      openBulkOperationSidebar: openBulkOperationSidebar,
      cloneAction: cloneAction,
      clearDate: clearDate,
      deleteActionItem: deleteActionItem,
      objLength: objLength,
      folderDelete: folderDelete,
      actionFolderCreateAndUpdate: actionFolderCreateAndUpdate,
      getActionListCount: getActionListCount,
      transformChip: transformChip,
      getFolderList: getFolderList,
      moveAction: moveAction,
      folderSequenceMove: folderSequenceMove,
      actionSortBy: actionSortBy,
      afterActionList: afterActionList,
      buildToggler: buildToggler,
      close: close,
      setActionType: setActionType,
      functionClickable: functionClickable,
      setLocalTimezoneBulk: setLocalTimezoneBulk,
      createCustomFilter: createCustomFilter,
      customFilterList: customFilterList,
      completeAllActionPopup: completeAllActionPopup,
      showDatePickerPopup: showDatePickerPopup,
      openFullScreen: openFullScreen,
      onPaste: onPaste,
      createMultipleActionItems: createMultipleActionItems,
      openSidenavResource: openSidenavResource,
      editActionInPopup: editActionInPopup,
      editActionInSidenav: editActionInSidenav,
      closeEditActionSidenav: closeEditActionSidenav,
      getOpportunitiesBoard: getOpportunitiesBoard,
      closeActionRightSidenav: closeActionRightSidenav,
      useGpt: useGpt
    };

    function closeActionRightSidenav(vm) {
      if (vm.addFunctionFlag) {
        vm.clearSelectedFunctions(vm);
      } else if (vm.editActionFlag) {
        vm.toggleRight();
      }

      if (vm.addResourceFlag && vm.showOnly) {
        vm.close(vm);
      } else {
        vm.clearSelectedResources(vm);
      }
    }

    function closeEditActionSidenav(vm) {
      vm.isSideAction = false;
      $mdSidenav('right').close();
      $mdSidenav('action-edit').toggle();
    }

    function editActionInSidenav(vm, index, action) {
      vm.isSideAction = true;
      vm.editAction(vm, vm.actionActive[index], index, false, false);
      $mdSidenav('action-edit').open()
      vm.openAccord = [];
      for (let i = 0; i < 3; i++) {
        vm.openAccord[i] = true;
      }
      let endTime = action.endTime;
      let endTimeDate = new Date(endTime * 1000);
      let endTimeAMPM = endTimeDate.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
      });
      vm.dueTime = endTimeAMPM;
      vm.dueDateVal = action.endTime;
      vm.selectResNew = {};
      
      if (action.resources && action.resources.length) {
        for (let i = 0; i < action.resources.length; i++) {
          vm.selectResNew[action.resources[i].resourceId] =
            action.resources[i];
        }
      }
      vm.selectResShow = angular.copy(vm.selectResNew);
    }

    function editActionInPopup(ev, vm, index) {
      
      vm.editAction(vm, vm.actionActive[index], index, false, false);
      openFullScreen(ev, vm, vm.actionActive[index].modelId);
      ev.stopPropagation();
    }

    function openFullScreen(ev, vm, modelId) {
      $mdDialog.show({
        locals: {
          vm,
          modelId,
          modelName: vm.actionConstant.modelName,
          workBoard: vm.actionConstant.isWorkboard,
          hiddenFolder: vm.actionConstant.isHiddenFolder
        },
        controller: ActionFullscreenPopupController,
        templateUrl: actionFullscreenPopup,
        parent: angular.element(document.body),
        closeOnNavigation: true,
        clickOutsideToClose: true,
        targetEvent: ev,
        multiple: true,
        controllerAs: 'fp',
        fullscreen: true
      }).then((result) => {
        
        if (!vm.actionConstant.isSidenavOnly) {
          getActionListCount(vm);
        }
      });
    };

    function ActionFullscreenPopupController($mdSidenav, sharedDataService, ActionService, timeList, FileUploadService, imageService, errorMessage, vm, modelId, modelName, workBoard, hiddenFolder) {

      'ngInject';

      let fp = this;
      const resourceSidenavName = vm.isFrame ? 'resources-frame-popup' : 'resourcesF';

      fp.sTimeList = timeList;
      fp.modelName = modelName;
      fp.parentVm = vm;
      fp.errorMessages = errorMessage;

      angular.extend(fp, vm);

      FileUploadService.init(fp, "actionEdit", 'fileUploadSidebar' + (vm.isFrame ? '-frame' : ''));

      fp.getOrganizations = function() {
        const filterCriteria = angular.toJson({
          sort: [{
            field: 'orgName',
            order: 1
          }]
        });

        return fp.organizationsList || dataServices.get({
            url: serverUrl.main + 'resourceApi/organizations?filterCriteria=' + filterCriteria + '&',
            spinner: false
          })
          .then(function(response) {
            if (response.data && response.data.result) {
              fp.organizationsList = response.data.result;
            }
          });
      };

      if (!fp.isAddingAction) {
        fp.getComments(fp);
      }
      // fp.actionEdit = vm.actionEdit;
      // 
      // ActionService.init(fp);
      // fp.search = search;

      fp.defaultVal = sharedDataService.get('functional_attach') || 10;
      fp.boardId = vm.boardId;
      vm.functionVal = fp.defaultVal;
      fp.selectedBusinessId = sharedDataService.get('functional_attach_location') || $rootScope.usrObj.primaryBzLocation;
      vm.selectedBusinessId = fp.selectedBusinessId;

      fp.assignFunctionVal = () => {
        vm.functionVal = fp.functionVal;
        vm.selectedBusinessId = fp.selectedBusinessId;
        fp.attachFunctionData(fp);
        vm.selectedFunctionNEW[vm.functionVal] = {};
        // sharedDataService.set('functional_attach_location', fp.selectedBusinessId);
        // sharedDataService.set('functional_attach', fp.functionVal);
      };

      // called on board selection
      fp.getOpportunityList = () => {
        vm.boardId = fp.boardId;
        sharedDataService.set('functional_board', fp.boardId);
        vm.selectedFunctionNEW[vm.functionVal] = {};
        if (fp.isPanel) {
          fp.infiniteCards.init();
        } else {
          fp.getOpportunities();
        }
      };

      fp.viewOnly = (fp.actionEdit.status == 5);

      let endTime = fp.actionEdit.endTime;
      let endTimeDate = new Date(`1970-01-01T${endTime}:00`);
      let endTimeAMPM = endTimeDate.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
      });

      fp.dueTime = endTimeAMPM;
      fp.hasWriteAccess = vm.hasWriteAccess;
      fp.selectedResources = angular.copy(fp.selectResNew);
      fp.fiveDaysAgoTS = moment()
        .subtract(5, "day")
        .endOf("day")
        .unix();

      fp.fiveDaysAgoTS = moment()
        .subtract(5, "day")
        .endOf("day")
        .unix();

      fp.initImageUpload = imageService.initImageUpload;
      fp.actionConstant = {
        modelName: modelName,
        modelId: modelId,
        scopeParentVm: vm,
        isWorkboard: workBoard,
        isHiddenFolder: hiddenFolder
      };

      switch (fp.actionConstant.modelName) {
        case 21:
          fp.isJob = true;
          break;
        case 20:
          fp.isJob = false;
          break;
        case 10:
        case 45:
        case 69:
          fp.isCustomerAction = true;
          break;
        case 40:
          fp.isResourceAction = true;
          break;
      }

      fp.status = [];
      for (let i = 0; i < 3; i++) {
        fp.status[i] = true;
      }

      fp.expandAll = () => {
        fp.status[0] = true;
        fp.status[1] = true;
        fp.status[2] = true;
      };

      fp.closeAll = () => {
        fp.status[0] = false;
        fp.status[1] = false;
        fp.status[2] = false;
      };

      fp.navigateTo = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView();
      };

      fp.toggleFileUploadSidebar = FileUploadService.buildToggler(fp, 'fileUploadSidebar-fullScreen');

      fp.resourceSideNav = () => {
        $mdSidenav(resourceSidenavName).toggle();
      };

      fp.closeResourceSidenav = () => {
        fp.selectResNew = angular.copy(fp.selectResNewBackUp);
        $mdSidenav(resourceSidenavName).close();
      };

      fp.saveResources = () => {
        fp.selectedResources = angular.copy(fp.selectResNew);
        fp.saveAttachedResources(fp);
        $mdSidenav(resourceSidenavName).close();
      };

      fp.attachFunctionSideNav = () => {
        const sidenavId = 'attachFunction' + (vm.isPanel ? '-frame-popup' : '');
        $mdSidenav(sidenavId).open();
      };

      fp.attachFunctionClose = () => {
        console.log(fp.functionVal, fp.boardId)
        if(fp.functionVal == 45 && !fp.boardId) {
          return;
        }
        fp.closeAttachFunction();
      }

      fp.closeAttachFunction = () => {
        const sidenavId = 'attachFunction' + (vm.isPanel ? '-frame-popup' : '');
        $mdSidenav(sidenavId).toggle();
      };

      fp.getDefaultLocation = () => {
        if (fp.actionEdit.addressId) {
          fp.selectedBusinessId = vm.selectedBusinessId = sharedDataService.get('functional_attach_location');
          // fp.boardId = vm.boardId = sharedDataService.get('functional_board');
        }
      };

      const LIMIT = 25;

      fp.infiniteTags = {
        numLoaded_: 0,
        toLoad_: 0,
        items: [],
        isLoading: false,
        isListLoaded: false,
        disablePagination: false,

        // Required.
        getItemAtIndex: function(index) {
          if (index > this.numLoaded_) {
            this.fetchMoreItems_(index);
            return null;
          }

          return this.items[index];
        },

        // Required.
        // For infinite scroll behavior, we always return a slightly higher
        // number than the previously loaded items.
        getLength: function() {
          return this.numLoaded_ + (this.disablePagination ? 0 : LIMIT);
        },

        fetchMoreItems_: function(index) {
          // For demo purposes, we simulate loading more items with a timed
          // promise. In real code, this function would likely contain an
          // $http request.
          if (this.toLoad_ < index && !this.isLoading && !this.disablePagination) {
            this.toLoad_ += LIMIT;

            const filterCriteria = {
              filters: [{
                field: 'isArchived',
                operator: '=',
                value: false
              }, {
                field: 'category',
                operator: '=',
                value: 1
              }, {
                field: 'mainCategory',
                operator: '!=',
                value: 'System Tags'
              }],
              sort: [{
                field: 'name',
                order: 1
              }],
              skip: 0,
              limit: 15
            };

            this.isLoading = true;
            this.isListLoaded = false;
            dataServices.get({
                url: serverUrl.main + 'tagApi/tags?filterCriteria=' + angular.toJson(filterCriteria) + '&',
                spinner: false
              })
              .then(response => {
                this.isLoading = false;
                this.isListLoaded = true;

                let data = response.data;
                if (data && data.response_code == 200) {
                  this.items = this.items.concat(data.result);
                  this.disablePagination = data.result.length < LIMIT;
                  this.numLoaded_ = this.disablePagination ? this.items.length : this.toLoad_;
                }
              }, error => {
                this.isLoading = false;
                this.isListLoaded = true;
              });
          }
        }
      };

      fp.openTags = function() {
        fp.selectedTagsBackup = angular.copy(fp.selectedTags);

        if (vm.isFrame || vm.isPanel) {
          fp.toggleSidebar('tags-frame-popup');
        } else {
          ListService.openSidenav(fp, vm, 'tags', '.top-section', false);
          fp.setFilters();
        }
      };

      fp.toggleSidebar = (navId) => {
        $mdSidenav(navId).toggle();
      };

      fp.validateFiles = FileUploadService.validateFiles;
      

      fp.closeFullscreen = () => {
        // prepareTimeData(vm)
        $mdDialog.cancel();
      };

      fp.completed = fp.viewOnly;

      fp.completeAction = (id, $event) => {
        fp.trueArchiveCheckBox[id] = !fp.trueArchiveCheckBox[id];

        let element = angular.element($event.currentTarget);
        if (fp.trueArchiveCheckBox[fp.actionEdit.id]) {
          element.addClass('click-effect');
        } else {
          element.addClass('click-effect');
        }

        $timeout(() => {
          fp.closeFullscreen();
        }, 500);

        fp.completed = !fp.completed;
        if (fp.completed) {
          $mdSidenav(actionRightSidenavName).close();
        } else if (!fp.completed) {
          $mdSidenav("viewOnly").close();
        }
      }
    }

    // START : It is used to complete the action and move it to completed.
    function completeAllActionPopup(vm, ev) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: completeAllActionController,
        controllerAs: "cd",
        templateUrl: confirmDialogTemplate,
        targetEvent: ev,
        parent: angular.element(document.body),
        locals: {
          vm: vm,
        },
      });
    }

    function completeAllActionController($scope, $mdDialog, spinnerLoader, vm) {

      'ngInject';

      const cd = this;

      cd.confirm = confirm;
      cd.description =
        "This will complete the action items for other resources with whom the action items are shared.";
      cd.question =
        "Are you sure you want to mark all the action items 'Complete' in this folder?";

      cd.cancel = function() {
        $mdDialog.cancel();
      };

      function confirm() {
        let data = {};
        data[vm.currentFolder == "Inbox" ? "isDefault" : "id"] =
          vm.currentFolder == "Inbox" ? true : vm.currentFolderId;

        dataServices
          .put({
            url: serverUrl.main +
              "actionFolderApi/folder/complete/" +
              $rootScope.usrObj.userId,
            data: {
              data: angular.toJson(data),
            },
          })
          .then(function(response) {
            if (
              response &&
              response.data.result &&
              response.data.response_code == 200
            ) {
              messages.simpleToast(
                "All action items are successfully completed!"
              );
              cd.cancel();
              getActionListCount(vm);
            }
          });
      }
    }
    // END : It is used to complete the action and move it to completed.

    //due date popup
    function showDatePickerPopup(vm, action, index, isAdd, isFullScreen, ev) {
      if (isAdd) {
        if (!vm.setReminder || !vm.actionEdit.setReminder) {
          prepareTimeData(vm)
        }
        if (vm.setReminder) {
          vm.actionEdit.setReminder = vm.setReminder;
          // vm.actionEdit.endDate = vm.addEndDate;
          // vm.actionEdit.date = vm.addStartDate;
          // vm.actionEdit.time = vm.addTime;
          // vm.actionEdit.endTime = vm.addEndTime;
        }
        // if(vm.setReminder) {
        // }
        // vm.actionEdit = action;
      }
      if (!isAdd && !isFullScreen) {
        prepareTimeData(vm)
        
        vm.editAction(vm, action, false, false, false);
        
      }
      // if(isFullScreen){
      //   vm.actionEdit = action;
      //   prepareTimeData(vm);
      // }
      $mdDialog.show({
          locals: {
            vm: vm,
            isAdd,
            isFullScreen
          },
          controller: DatePickerController,
          templateUrl: datePickerPopup,
          parent: angular.element(document.body),
          targetEvent: ev,
          multiple: true,
          controllerAs: 'dp',
        })
        .then((result) => {
          
          if (result && result.action == 'done') {
            vm.dueTime = result.endTime;
            if (!isAdd) {
              setDueDate(vm, action, index, isFullScreen);
            } else {
              vm.addDue = true;
              vm.setReminder = vm.actionEdit.setReminder;
              vm.addEndDate = vm.actionEdit.endDate;
              // vm.addStartDate = vm.actionEdit.date;
              // vm.addEndTime = vm.actionEdit.time;
              // vm.addTime = vm.actionEdit.endTime;
            }
            if(!vm.actionEdit.setReminder && !isAdd) {
              
              prepareTimeData(vm)
            }
          }
        }).catch((error) => {
          console.error('Error occurred:', error);
        });
    };

    function DatePickerController($mdDialog, isFullScreen, TimezoneService, timeList, vm, isAdd) {
      const dp = this;

      angular.extend(dp, vm);

      dp.sTimeList = timeList;
      // if (!isFullScreen) {
      //   prepareTimeData(dp);
      // }
      TimezoneService.loadTimezones(dp);
      
      dp.actionEdit.endDate = vm.actionEdit.endDate ? new Date(vm.actionEdit.endDate) : new Date();
      dp.actionEdit.endTime = vm.actionEdit.endTime;

      dp.dateOptions = [{
          key: 'today',
          label: 'Today',
          display: ''
        },
        {
          key: 'later',
          label: 'Later',
          display: ''
        },
        {
          key: 'tomorrow',
          label: 'Tomorrow',
          display: ''
        },
        {
          key: 'thisWeekend',
          label: 'This Weekend',
          display: ''
        },
        {
          key: 'nextWeek',
          label: 'Next Week',
          display: ''
        },
        {
          key: 'nextWeekend',
          label: 'Next Weekend',
          display: ''
        },
        {
          key: '2Weeks',
          label: '2 Weeks',
          display: ''
        },
        {
          key: '4Weeks',
          label: '4 Weeks',
          display: ''
        }
      ];

      // if(dp.actionEdit.date){
      //   dp.actionEdit.endDate = dp.actionEdit.date;
      // }
      function updateDisplay() {
        dp.dateOptions.forEach(option => {
          let now = new Date();
          switch (option.key) {
            case 'today':
              option.display = now.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            case 'later':
              var laterDate = new Date();
              let roundedLaterDate;
              laterDate.setHours(laterDate.getHours() + 2);
              roundedLaterDate = roundToNearestInterval(laterDate, 30);
              option.display = roundedLaterDate.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              });
              break;
            case 'tomorrow':
              var tomorrowDate = new Date();
              tomorrowDate.setDate(tomorrowDate.getDate() + 1);
              option.display = tomorrowDate.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            case 'thisWeekend':
              var weekendDate = new Date();
              weekendDate.setDate(weekendDate.getDate() + (6 - weekendDate.getDay()));
              option.display = weekendDate.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            case 'nextWeek':
              var nextWeekDate = new Date();
              nextWeekDate.setDate(nextWeekDate.getDate() + 7);
              option.display = nextWeekDate.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            case 'nextWeekend':
              var nextWeekendDate = new Date();
              nextWeekendDate.setDate(nextWeekendDate.getDate() + (13 - nextWeekendDate.getDay()));
              option.display = nextWeekendDate.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            case '2Weeks':
              var twoWeeksDate = new Date();
              twoWeeksDate.setDate(twoWeeksDate.getDate() + 14);
              option.display = twoWeeksDate.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            case '4Weeks':
              var fourWeeksDate = new Date();
              fourWeeksDate.setDate(fourWeeksDate.getDate() + 28);
              option.display = fourWeeksDate.toLocaleDateString('en-US', {
                weekday: 'short'
              });
              break;
            default:
              option.display = '';
              break;
          }
        });
      }
      updateDisplay();

      function roundToNearestInterval(date, intervalMinutes) {
        const roundedDate = new Date(date);
        const minutes = Math.round(roundedDate.getMinutes() / intervalMinutes) * intervalMinutes;
        roundedDate.setMinutes(minutes);
        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);

        return roundedDate;
      }
      dp.openRightSidenav = () => {
        $mdSidenav("timeZone").toggle()
        dp.showTimezone = true;
        dp.loadTimezones();
      }

      dp.closeRightSidenav = () => {
        dp.showTimezone = false
        $mdSidenav("timeZone").close();
      }

      dp.validateStartEndTime = () => {
        if (!dp.actionEdit.endDate) {
          messages.simpleToast("Please select end date")
          return false;
        } else if (!dp.actionEdit.endTime) {
          messages.simpleToast("Please select end time")
          return false;
        }
        if (dp.actionEdit.time && dp.actionEdit.endTime && dp.actionEdit.date && dp.actionEdit.endDate) {

          let startDateTime = new Date(dp.actionEdit.date.toDateString() + ' ' + dp.actionEdit.time);
          let endDateTime = new Date(dp.actionEdit.endDate.toDateString() + ' ' + dp.actionEdit.endTime);

          if (dp.actionEdit.endDate.getTime() === dp.actionEdit.date.getTime()) {

            if (dp.actionEdit.endTime <= dp.actionEdit.time) {
              dp.actionEdit.endDate = '';
              messages.simpleToast("End time cannot be earlier than or equal to start time on the same day.");
              return false;
            }
          }
          if (startDateTime >= endDateTime) {
            dp.actionEdit.endDate = '';
            messages.simpleToast("Start time must be earlier than end time.");
            return false;
          }
        }
        return true;
      };

      dp.onReminderChange = () => {
        if(!dp.actionEdit.setReminder) {
          vm.dueTime = undefined;
          
        }
      }

      dp.selectOption = function(optionKey) {
        let now = new Date();

        function roundToNearestInterval(date, interval) {
          let minutes = Math.round(date.getMinutes() / interval) * interval;
          date.setMinutes(minutes);
          return date;
        }

        switch (optionKey) {
          case 'today':
            dp.actionEdit.date = dp.actionEdit.endDate = now;
            break;
          case 'later':
            let laterDate = new Date();
            laterDate.setHours(laterDate.getHours() + 2);
            laterDate = roundToNearestInterval(laterDate, 30);
            dp.actionEdit.date = dp.actionEdit.endDate = laterDate;
            let endTime = laterDate.toLocaleTimeString('en-US', {
              hour12: false
            });
            dp.actionEdit.endTime = endTime.substring(0, 5);
            break;
          case 'tomorrow':
            let tomorrowDate = new Date();
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            dp.actionEdit.date = dp.actionEdit.endDate = tomorrowDate;
            break;
          case 'thisWeekend':
            let weekendDate = new Date();
            weekendDate.setDate(weekendDate.getDate() + (6 - weekendDate.getDay()));
            dp.actionEdit.date = dp.actionEdit.endDate = weekendDate;
            break;
          case 'nextWeek':
            let nextWeekDate = new Date();
            nextWeekDate.setDate(nextWeekDate.getDate() + 7);
            dp.actionEdit.date = dp.actionEdit.endDate = nextWeekDate;
            break;
          case 'nextWeekend':
            let nextWeekendDate = new Date();
            nextWeekendDate.setDate(nextWeekendDate.getDate() + (13 - nextWeekendDate.getDay()));
            dp.actionEdit.date = dp.actionEdit.endDate = nextWeekendDate;
            break;
          case '2Weeks':
            let twoWeeksDate = new Date();
            twoWeeksDate.setDate(twoWeeksDate.getDate() + 14);
            dp.actionEdit.date = dp.actionEdit.endDate = twoWeeksDate;
            break;
          case '4Weeks':
            let fourWeeksDate = new Date();
            fourWeeksDate.setDate(fourWeeksDate.getDate() + 28);
            dp.actionEdit.date = dp.actionEdit.endDate = fourWeeksDate;
            break;
          default:
            dp.actionEdit.date = dp.actionEdit.endDate = now;
            break;
        }

      };
      dp.cancel = () => {
        if (dp.actionEdit.setReminder && !dp.validateStartEndTime()) {
          return;
        }
        if (!dp.actionEdit.setReminder || (!dp.actionEdit.setReminder && !dp.validateStartEndTime())) {
          vm.setReminder = false;
          prepareTimeData(dp);
        }
        let endTime = dp.actionEdit.endTime;
        let endTimeDate = new Date(`1970-01-01T${endTime}:00`);
        let endTimeAMPM = endTimeDate.toLocaleTimeString('en-US', {
          hour12: true,
          hour: '2-digit',
          minute: '2-digit'
        });
        vm.dueDateVal = dp.actionEdit.endDate;
        $mdDialog.hide({
          endTime: endTimeAMPM,
          action: 'done'
        });
      };

      dp.close = () => {
        $mdDialog.cancel();
      }
    }

    // START : This function is called whenever user creating new Action.
    function newAction(vm) {
      let data = {
        action: "unarchive",
        ...(!vm.multipleActionItems && {
          description: vm.description
        }),
        ...(vm.multipleActionItems && {
          actions: vm.actionItems
        }),
        fav: vm.isFavourite,
        modelName: parseInt(vm.actionConstant.modelName),
        modelId: vm.actionConstant.modelId,
        resources: vm.resources,
        tags: [],
        labels: [],
        setReminder: vm.setReminder,
        priority: (vm.priority != undefined ? vm.priority : 2),
        isSystem: false
      };
      
      // if it is creating from resource
      if (vm.actionConstant.modelName == 40 && vm.currentFolderId != "all") {
        data.destinationFolderId = vm.currentFolderId;
      }

      if (vm.actionConstant.modelName == 10) {
        data.addressId = parseInt($rootScope.usrObj.primaryBzLocation);
      }

      if (angular.isObject(vm.labelsToAdd) && Object.keys(vm.labelsToAdd).length > 0) {
        data.labels = Object.keys(vm.labelsToAdd);
      }

      if (vm.addDue && vm.setReminder && !vm.isAttachedResource) {
        vm.prepareLocalEndTimeData();
        vm.prepareLocalStartTimeData();
        
        vm.actionEdit.date = new Date(
          vm.actionEdit.date.getFullYear(),
          vm.actionEdit.date.getMonth(),
          vm.actionEdit.date.getDate(),
          vm.actionEdit.time.split(":")[0],
          vm.actionEdit.time.split(":")[1]
        );

        vm.actionEdit.endDate = new Date(
          vm.actionEdit.endDate.getFullYear(),
          vm.actionEdit.endDate.getMonth(),
          vm.actionEdit.endDate.getDate(),
          vm.actionEdit.endTime.split(":")[0],
          vm.actionEdit.endTime.split(":")[1]
        );

        if (vm.actionEdit.endDate <= vm.actionEdit.date) {
          messages.simpleToast("Start date Should be less than End Date.");
          return;
        }

        if (vm.timezoneInfo) {
          data.startTime = vm.timezoneInfo.startTime;
          data.endTime = vm.timezoneInfo.endTime;
        }
      }
      // console.log(data);
      // return
      dataServices
        .post({
          url: serverUrl.main + (vm.multipleActionItems ? "actionsApi/action/multiple" : "actionsApi/action"),
          data: {
            data: angular.toJson(data),
          },
        })
        .then(function(response) {
          if (response && response.data && response.data.result) {
            vm.description = undefined;
            vm.actionItems = [];
            vm.resources = [];
            vm.multipleActionItems = undefined;
            vm.labelsToAdd = {};

            if (vm.activeMenu != "starred-item") {
              vm.isFavourite = false;
              vm.priority = 2;
              vm.selectRes = {};
              vm.selectedResToAdd = {};
              vm.setReminder = false;
              prepareTimeData(vm);
            }

            vm.switchList(vm, vm.actionConstant.scopeParentVm, "actionActive", function() {
              getActionListCount(vm);
            });

            if (vm.actionConstant.modelName == 40) {
              getFolderList(vm);
            }

            messages.simpleToast("New Action Added successfully");
            vm.focusInput(vm);
          }
        });
    }
    // END : This function is called whenever user creating new Action.

    function prepareTimeData(vm) {
      vm.selectedTimezone = vm.searchTextZone = null;
      if (angular.isFunction(vm.prepareTimezoneInfo)) {
        vm.prepareTimezoneInfo(vm.actionEdit);
      }

      if (vm.actionEdit.startTime && vm.actionEdit.endTime) {
        vm.actionEdit.date = new Date(vm.actionEdit.startTime * 1000);
        vm.actionEdit.endDate = new Date(vm.actionEdit.endTime * 1000);

        const startTime = Math.trunc(
          vm.actionEdit.date.setMinutes(
            vm.actionEdit.date.getMinutes() + vm.timezoneInfo.timezoneOffset
          ) / 1000
        );

        const endTime = Math.trunc(
          vm.actionEdit.endDate.setMinutes(
            vm.actionEdit.endDate.getMinutes() + vm.timezoneInfo.timezoneOffset
          ) / 1000
        );

        vm.actionEdit.time = $filter("time24Hrs")(startTime);
        vm.actionEdit.endTime = $filter("time24Hrs")(endTime);
      } else {
        vm.actionEdit.setReminder = false;

        vm.actionEdit.date = new Date();
        vm.actionEdit.endDate = new Date();

        let incrementHour = 0,
          incrementHourEnd = 0,
          hours = vm.actionEdit.date.getHours(),
          endHours = vm.actionEdit.endDate.getHours();
        if (vm.actionEdit.date.getMinutes() > 30) {
          incrementHour = 1;
          hours += 1;
        }

        if (vm.actionEdit.endDate.getMinutes() > 30 || incrementHour == 0) {
          endHours += 1;
        }

        vm.actionEdit.time =
          (hours < 10 ? "0" + hours : hours) +
          ":" +
          (incrementHour == 0 ? "30" : "00");
        vm.actionEdit.endTime =
          (endHours < 10 ? "0" + endHours : endHours) +
          ":" +
          (incrementHour == 1 ? "30" : "00");
      }

      if (angular.isFunction(vm.prepareLocalStartTimeData) && angular.isFunction(vm.prepareLocalEndTimeData)) {
        vm.prepareLocalStartTimeData();
        vm.prepareLocalEndTimeData();
      }
    }

    // START : This function will prepare data for updating or showing data in edit sidebar.
    function editAction(vm, action, index, isCallFunction = vm.toggleRight, isEditOnSidebar = true) {
      if (angular.isDefined(vm.hasWriteAccess) && !vm.hasWriteAccess) {
        return;
      }

      imageService.initImageUpload(vm, "actionEdit");

      if (!vm.isAddingAction) {
        vm.actionEdit = angular.copy(action || vm.actionActive[index]);
        vm.visited = index;
      }

      vm.selectedLabels = {};
      if (vm.actionEdit.labelInfo && vm.actionEdit.labelInfo.length > 0) {
        angular.forEach(vm.actionEdit.labelInfo, (label) => {
          if (label != null) {
            vm.selectedLabels[label.id] = label;
          }
        });
      }
      vm.selectedLabelsCopy = angular.copy(vm.selectedLabels);

      vm.isAttachedResource = false;
      vm.editActionFlag = true;
      vm.addResourceFlag = false;
      vm.selectResNew = {};
      vm.totalFileUpload = [];
      vm.filesInServerLen = 0;

      if (vm.actionEdit.attachments) {
        vm.filesInServerLen = vm.actionEdit.attachments.length;
        angular.forEach(vm.actionEdit.attachments, function(file) {
          vm.totalFileUpload.push({
            name: file.fileOriginalName,
            id: file.id,
            url: file.thumbnailUrl,
            webUrl: file.webUrl,
          });
        });
      }
      // TimezoneService.setLocalTimezone(vm, vm.actionEdit.addressId);

      // vm.actionEdit.attachments = [];
      vm.actionEdit.images = vm.actionEdit.images || [];
      vm.initImg = angular.copy(vm.actionEdit.images);

      if (vm.actionEdit.addressId) {
        vm.selectedBusinessId = vm.actionEdit.addressId.toString();
      }
      
      vm.actionEdit.tags = !vm.actionEdit.tags ? [] : vm.actionEdit.tags;

      if (vm.actionEdit.resources && vm.actionEdit.resources.length) {
        for (let i = 0; i < vm.actionEdit.resources.length; i++) {
          vm.selectResNew[vm.actionEdit.resources[i].resourceId] =
            vm.actionEdit.resources[i];
        }
      }

      vm.selectedFunctionNEW = {
        20: {},
        21: {},
        10: {},
        45: {},
        69: {},
      };

      // START : modelName is not equal to 40.It means action is having attach function.
      if (
        vm.actionConstant.modelName == 40 &&
        vm.actionEdit.modelName &&
        vm.actionEdit.modelName != 40 && !vm.isAddingAction
      ) {
        vm.functionVal = vm.actionEdit.modelName;
        vm.modelDataBackup = angular.copy(vm.actionEdit.modelData);
        if (vm.functionVal == 45 && vm.boardId) {
          getOpportunitiesBoard(vm);
        }

        if (vm.actionEdit.modelName == 20 || vm.actionEdit.modelName == 21) {
          // Quote & Jobs
          vm.selectedFunctionNEW[vm.actionEdit.modelName][vm.actionEdit.modelId] = {
            contact: vm.actionEdit.modelData,
          };
        } else if (vm.actionEdit.modelName == 10) {
          // Customer
          vm.selectedFunctionNEW["10"][vm.actionEdit.modelId] =
            vm.actionEdit.modelData;
        } else if (vm.actionEdit.modelName == 69) {
          // Campaign
          vm.selectedFunctionNEW[vm.actionEdit.modelName][vm.actionEdit.modelId] = vm.actionEdit.modelData;
        } else if (vm.actionEdit.modelName == 45) {
          // Opportunity
          vm.boardId = angular.copy(vm.actionEdit.opportunityBoardId);
          if (isEditOnSidebar) {
            vm.getOpportunities();
          }
          vm.selectedFunctionNEW["45"][vm.actionEdit.modelId] = vm.actionEdit.modelData;
        }
      }
      // END : modelName is not equal to 40.It means action is having attach function.

      prepareTimeData(vm);
      if (isCallFunction) {
        isCallFunction();
      }
    }

    // END : This function will prepare data for updating or showing data in edit sidebar.

    function openSidenavResource(vm, parentVm, navID, sectionId) {
      
      vm.switchList(vm, parentVm, navID, vm.setFilters);
      
      $('#scroll-top').addClass('sidenav');
      if ($(sectionId + '[scroll-id=' + navID + ']').scrollTop() > 300) {
        $('#scroll-top').addClass('active');
      }

      $mdSidenav(actionRightSidenavName).onClose(function() {
        if (vm.showOnly) {
          vm.showOnly = false;
          if (vm.setResource) {
            vm.switchList(vm, $scope.$parent.vm, 'actionActive');
          } else {
            vm.selectResNew = angular.copy(vm.selectResNewBackUp);
            vm.addResourceFlag = false;
            vm.editActionFlag = true;
          }
        } else {
          vm.isAddingAction = vm.addFunctionFlag = false;
          if (!vm.isSideAction) {
            vm.editFunctionFlag = false;
          }
          vm.switchList(vm, parentVm);
          vm.selectResNew = {};
          vm.selectRes = {};
        }

        $('#scroll-top').removeClass('sidenav');
        $('#scroll-top').removeClass('active');
      });

      $mdSidenav('viewOnly').onClose(function() {
        vm.openSidenavResource(vm, parentVm, 'actionActive', '.task-wrapper');
      });
    }

    function attachFunctionData(vm, from) {
      

      sharedDataService.set('functional_attach', vm.functionVal);
      sharedDataService.set('functional_attach_location', vm.selectedBusinessId);

      vm.modelDataBackup = angular.copy(vm.actionEdit.modelData);
      if (vm.functionVal == 45) {
        vm.boardId = undefined;
        vm.opportunityBoard = [];
        vm.cards = [];
        // getOpportunitiesBoard(vm);

        if (vm.isPanel) {
          vm.infiniteCards.init();
        } else {
          vm.getOpportunities();
        }
      } else {
        if (vm.isPanel) {
          vm.infiniteAttachFunctions.init();
        } else {
          vm.openSidenavResource(vm, vm.actionConstant.scopeParentVm, "attachFunctionValue", ".task-wrapper");
        }
      }
    }

    // START : It is fetching the opportunity board.
    function getOpportunitiesBoard(vm) {
      let filterCriteria = angular.toJson({
        filters: vm.selectedBusinessId && vm.selectedBusinessId != 'All' ? [{
          field: "location",
          operator: "=",
          value: vm.selectedBusinessId.toString(),
        }] : []
      });

      return vm.opportunityBoard && vm.opportunityBoard.length ? vm.opportunityBoard : dataServices
        .get({
          url: serverUrl.main +
            "opportunityApi/opportunity/boards?filterCriteria=" +
            filterCriteria +
            "&",
          spinner: false
        })
        .then(function(response) {
          if (response && response.data && response.data.result) {
            vm.opportunityBoard = response.data.result;
          }
        });
    }
    // END : It is fetching the opportunity board.

    // START : This function is called whenever user want to set or unset action to favorite.
    function setUnsetFavourite(vm, action, index, nav = actionRightSidenavName, isSwitchList = true) {
      editAction(vm, action, index, false, false);
      vm.actionEdit.fav = !vm.actionEdit.fav;
      updateAction(vm, nav, isSwitchList);
    }
    // END : This function is called whenever user want to set or unset action to favorite.

    // START : This function is called whenever user want to set action priority.
    function setUnsetPriority(vm, action, index, priorityType, nav = actionRightSidenavName, isSwitchList = true) {
      editAction(vm, action, index, false, false);
      vm.actionEdit.priority = priorityType;
      updateAction(vm, nav, isSwitchList);
    }
    // END : This function is called whenever user want to set action priority.

    function setDueDate(vm, action, index, isFullScreen) {
      if (vm.actionEdit.setReminder) {
        vm.prepareLocalEndTimeData();
        vm.prepareLocalStartTimeData();
        angular.forEach(action.labelInfo, (label) => {
          if (label != null) {
            vm.selectedLabelsCopy[label.id] = label;
          }
        });
        if (isFullScreen && !vm.isSideAction && !vm.actionEdit.setReminder) {
          prepareTimeData(vm)
        }
        if (!isFullScreen) {
          updateAction(vm);
        }
      }
    }

    function updateActionTitle(vm, action, index) {
      editAction(vm, action, index, false, false);
      updateAction(vm, false, false);
    }

    // START : This function is called whenever user Update action.
    function updateAction(vm, nav = actionRightSidenavName, isSwitchList = true) {

      // let comments = angular.copy(vm.comments) || {
      //   add: [],
      //   update: [],
      //   delete: []
      // };

      // comments.add.forEach(comment => {
      //   delete comment.uniqueId;
      // });

      let data = {
        description: vm.actionEdit.description,
        resources: vm.actionEdit.resources,
        shortDescription: vm.actionEdit.shortDescription,
        tags: vm.actionEdit.tags,
        modelId: vm.actionEdit.modelId,
        modelName: parseInt(vm.actionEdit.modelName),
        setReminder: vm.actionEdit.setReminder,
        fav: vm.actionEdit.fav,
        priority: parseInt(vm.actionEdit.priority),
        attachments: (vm.actionEdit.attachments || []).filter(file => angular.isString(file)),
        removeAttachments: vm.actionEdit.removeAttachments,
        images: [],
        removeImages: [],
        isSystem: vm.actionEdit.isSystem,
        winChances: vm.actionEdit.winChances || 0,
        // comments
      };
      
      if (angular.isObject(vm.selectedLabelsCopy)) {
        if (Object.keys(vm.selectedLabelsCopy).length === 0) {
          // if (Array.isArray(vm.actionEdit.labelInfo)) {
          //   data.labels = vm.actionEdit.labelInfo
          //     .filter(label => label && label.id)
          //     .map(label => label.id);
          // } else {
          data.labels = [];
          // }
        } else {
          data.labels = Object.keys(vm.selectedLabelsCopy);
          vm.selectedLabels = angular.copy(vm.selectedLabelsCopy);
        }
      }

      data.addressId = vm.actionEdit.addressId ? parseInt(vm.actionEdit.addressId) : parseInt($rootScope.usrObj.primaryBzLocation);

      angular.forEach(vm.actionEdit.images, function(img, index) {
        if (vm.removedImage && vm.removedImage[index]) {
          data.removeImages.push(vm.initImg[index].id);
        }

        if (vm.croppedImg && vm.croppedImg[index]) {
          data.images.push(vm.actionEdit.images[index]);
        }
      });

      // if (vm.actionEdit.modelName == 10) {
      //   data.addressId = parseInt(vm.actionEdit.addressId);
      // }
      if (vm.actionEdit.modelName == 45) {
        data.opportunityBoardId = vm.actionEdit.opportunityBoardId;
        
        if (vm.actionEdit.addressId) {
          data.addressId = parseInt(vm.selectedBusinessId);
        }
      }

      if (vm.actionEdit.setReminder && !vm.isAttachedResource) {
        
        vm.actionEdit.date = new Date(
          vm.actionEdit.date.getFullYear(),
          vm.actionEdit.date.getMonth(),
          vm.actionEdit.date.getDate(),
          vm.actionEdit.time.split(":")[0],
          vm.actionEdit.time.split(":")[1]
        );

        vm.actionEdit.endDate = new Date(
          vm.actionEdit.endDate.getFullYear(),
          vm.actionEdit.endDate.getMonth(),
          vm.actionEdit.endDate.getDate(),
          vm.actionEdit.endTime.split(":")[0],
          vm.actionEdit.endTime.split(":")[1]
        );

        if (vm.actionEdit.endDate <= vm.actionEdit.date) {
          messages.simpleToast("Start date Should be less than End Date.");
          return;
        }

        if (vm.timezoneInfo) {
          data.startTime = vm.timezoneInfo.startTime;
          data.endTime = vm.timezoneInfo.endTime;
        }
      }

      // save timezone for next edit
      data.timeZone = vm.selectedTimezone ?
        dataServices.toSave(vm.selectedTimezone, [
          "gmt",
          "offset",
          "region",
          "timezoneId",
          "timezone",
        ]) :
        (vm.actionEdit.modelName == 40 ?
          $rootScope.usrObj.timeZone :
          null);

      
      // return;

      let url = serverUrl.main + "actionsApi/action/" + vm.actionEdit.id + "/update/" + vm.actionConstant.modelName;
      if (vm.isAddingAction) {
        url = serverUrl.main + "actionsApi/action";
      }

      if (vm.isAddingAction) {
        vm.isAddedAction = true; // for pipeline socket
        // if it is creating from resource
        if (vm.actionConstant.modelName == 40 && vm.currentFolderId != "all") {
          data.destinationFolderId = vm.currentFolderId;
        }
      } else {
        vm.isUpdatedCard = true;
      }

      // 
      // return;

      dataServices
        .post({
          url: url,
          data: {
            data: angular.toJson(data),
          },
          method: vm.isAddingAction ? '' : 'PUT',
          spinnerName: "sidebar-spinner",
        })
        .then(function(response) {
          if (response && response.data && response.data.result) {
            vm.selectResNew = {};
            vm.setReminder = false;
            prepareTimeData(vm);
            // vm.filter.resourcesF.orgId = undefined;
            if(vm.reportAction && !vm.isAddingAction) {
              DashboardService.fetchAssignedDashboard(vm, vm.currentModuleName);
            } else if(vm.isListPage) {
              vm.updateQuoteList();
            }
            
            // vm.selectedLabels = angular.copy(vm.actionEdit.labelInfo);
            if (vm.isAddingAction && !vm.actionEdit.id) {
              messages.simpleToast("New Action Added successfully");
              // added action from right panel
              if (vm.isPanel) {
                if (['main.resources.action', 'main.resources.actionPipeline', 'main.resources.actionWorkboard'].includes($rootScope.currentStateName) && angular.isFunction($rootScope.reloadActionItems)) {
                  $rootScope.reloadActionItems();
                }
                return;
              }

              vm.toggleRight();
              // vm.selectedLabels = {};
              if (vm.isFrame) {
                vm.infiniteActions.init();
                vm.quote.numberOfReminder++;
              } else {
                vm.switchList(vm, vm.actionConstant.scopeParentVm, "actionActive", function() {
                  vm.setFilters("new", true);
                });
              }
            } else {
              messages.simpleToast("Action updated successfully");
              
              if (!vm.actionConstant.isWorkboard) {
                vm.switchList(vm, vm.actionConstant.scopeParentVm, "actionActive", function() {
                  getActionListCount(vm);
                });
              }

              if (isSwitchList) {
                // if (vm.isFrame) {
                //   vm.infiniteActions.init();
                // } else {
                //   vm.switchList(vm, vm.actionConstant.scopeParentVm, "actionActive", function() {
                //     vm.setFilters("new", true);
                //   });
                // }
                if (vm.isFrame) {
                  vm.infiniteActions.items[vm.visited] = response.data.result;
                } else {
                  vm.actionActive[vm.visited] = response.data.result;
                }
              }

              // START : if you complete and active it from workboard then you need to get updated statistics and priority distribution.
              if (vm.actionConstant.isWorkboard) {
                
                vm.switchList(vm, vm.actionConstant.scopeParentVm, "actionActive", function() {
                  vm.setFilters("new", true);
                });
                vm.getStatistic();
                vm.getPriorityDistribution();
              }

              if (nav) {
                vm.close(vm, nav);
              }
            }
          }
        });
    }
    // END : This function is called whenever user Update action.

    // START : This function is called when user want to see all attached resource to the action.
    function showResources(vm, index) {
      vm.editActionFlag = false;
      vm.addResourceFlag = true;
      vm.showOnly = true;
      // START : vm.setResource is true it means user clicked (2 more ) to show all resource attached to an action.
      vm.setResource = true;
      vm.selectResNew = {};
      vm.selectRes = {};
      vm.actionEdit = angular.copy(vm.actionActive[index]);

      if (vm.actionEdit.resources && vm.actionEdit.resources.length) {
        for (let i = 0; i < vm.actionEdit.resources.length; i++) {
          vm.selectResNew[vm.actionEdit.resources[i].resourceId] =
            vm.actionEdit.resources[i];
        }
      }

      vm.openSidenavResource(vm, vm.actionConstant.scopeParentVm, "resourcesF");
      vm.toggleRight();
    }
    // END : This function is called when user want to see all attached resource to the action.

    function goToState(modelId, modelName, boardId, locationId) {
      if (modelName == 20) {
        // quotes
        $state.go("main.quotes.guider", {
          quoteId: modelId,
        });
      } else if (modelName == 45) {
        // opportunity
        $state.go("main.customers.opportunitiesDetail", {
          cardId: modelId,
          boardId: boardId,
          locationId: locationId,
        });
      } else if (modelName == 21) {
        // Job
        $state.go("main.jobs.guider", {
          quoteId: modelId,
        });
      } else if (modelName == 10) {
        // Customer
        $state.go("main.customers.customersDetail", {
          customerId: modelId,
        });
      } else if (modelName == 24) {
        // Product
        $state.go("main.business.inventoryProductsDetail", {
          id: modelId,
        });
      } else if (modelName == 27) {
        // Promotion
        $state.go("main.settings.automationPromotionsView", {
          id: modelId,
          lastScreen: "",
        });
      } else if (modelName == 69) {
        // Promotion
        $state.go("main.customers.campaignsDetail", {
          id: modelId,
        });
      } else if (modelName == 40) {
        // resource
        $state.go("main.resources.resourcesDetails", {
          id: modelId,
        });
      }
    }

    // START: It is used if you are attching the file and update the action item. If it does not have any new file to upload it will call the updated without file upload api.
    function uploadFilesAndSaveAction(vm, nav = actionRightSidenavName) {

      if (vm.isAttachedResource || vm.editFunctionFlag) {
        updateAction(vm, nav);
      } else {
        validatorService.validateForm(
          vm,
          function() {
            if (vm.totalFileUpload.length <= vm.filesInServerLen) {
              vm.actionEdit.attachments = [];
              updateAction(vm, nav);
            } else {
              let files = vm.totalFileUpload.filter(function(file) {
                return !file.hasOwnProperty("id");
              });
              // 
              // return
              dataServices
                .upload({
                  url: serverUrl.main + "mediaApi/upload/file",
                  data: {
                    file: files,
                  },
                  spinnerName: "sidebar-spinner",
                })
                .then(function(response) {
                  let data = response.data;
                  if (data && data.response_code == 200) {
                    vm.actionEdit.attachments = data.result.success.map(function(
                      file
                    ) {
                      return file.fileId;
                    });
                    updateAction(vm, nav);
                  }
                });
            }
          },
          "actionEditForm"
        )();
      }
    }
    // END: It is used if you are attching the file and update the action item. If it does not have any new file to upload it will call the updated without file upload api.

    // START: It is used to select resource for attaching with the action item.
    function selectResource(vm, index, bool) {
      
      let resource = vm.isFrame ? vm.infiniteResources.items[index] : vm.resourcesF[index];
      if (bool) {
        if (Object.keys(vm.selectResNew).indexOf(resource.id) == -1) {
          if (Object.keys(vm.selectResNew).length < 5) {
            vm.selectResNew[resource.id] = resource;
          } else {
            messages.simpleToast(
              "More than 5 Resources are not allowed to attach."
            );
          }
        } else {
          delete vm.selectResNew[resource.id];
        }
      }
      if (!bool) {
        if (Object.keys(vm.selectRes).indexOf(resource.id) == -1) {
          if (Object.keys(vm.selectRes).length < 5) {
            vm.selectRes[resource.id] = resource;
          } else {
            messages.simpleToast(
              "More than 5 Resources are not allowed to attach."
            );
          }
        } else {
          delete vm.selectRes[resource.id];
        }
      }
    }
    // END: It is used to select resource for attaching with the action item.

    // START : This function is calling when you want to attach resource to the action.
    function getResourceList(vm) {
      vm.addResourceFlag = true;
      vm.editActionFlag = vm.isSidePanel ? true : false;
      vm.setResource = false;
      vm.showOnly = false;
      vm.selectResNewBackUp = angular.copy(vm.selectResNew);
      if (!vm.isFrame && !vm.isPanel) {
        vm.openSidenavResource(vm, vm.actionConstant.scopeParentVm, "resourcesF", ".task-wrapper");
      }
      // else {
      //   vm.infiniteResources.init();
      // }
    }
    // END : This function is calling when you want to attach resource to the action.

    // START : Clear selected Resources or closing sidebar.
    function clearSelectedResources(vm) {
      vm.showOnly = false;

      if (vm.setResource || vm.isAttachedResource) {
        if (!vm.isFrame) {
          vm.switchList(vm, vm.actionConstant.scopeParentVm, "actionActive");
        }
        $mdSidenav(actionRightSidenavName).close();
        vm.selectResNew = {};
      } else {
        vm.selectResNew = angular.copy(vm.selectResNewBackUp);
        vm.addResourceFlag = false;
        vm.editActionFlag = true;
      }
    }
    // END : Clear selected Resources  or closing sidebar.

    // START : Clear selected function or closing sidebar.
    function clearSelectedFunctions(vm) {
      vm.actionEdit.modelData = angular.copy(vm.modelDataBackup);

      if (vm.editFunctionFlag) {
        vm.toggleRight();
      } else {
        vm.enableEditScreen(vm);
      }
    }
    // END : Clear selected function or closing sidebar.

    function enableEditScreen(vm) {
      vm.addResourceFlag = vm.addFunctionFlag = false;
      vm.editActionFlag = true;
    }

    function getResourceObj(resource, id) {
      let obj = {
        resourceId: id,
        firstName: resource.firstName,
        lastName: resource.lastName,
        businessAddress: resource.businessAddress,
      };
      if (resource.middleName) {
        obj.middleName = resource.middleName;
      }

      return obj;
    }

    // START : This function is called when you attaching resource to action.
    function attachResource(vm) {
      vm.resources = [];
      vm.actionEdit.resources = [];
      angular.forEach(vm.selectResNew, function(resource, id) {
        let obj = getResourceObj(resource, id);
        if (vm.actionEdit.resources.indexOf(obj) == -1) {
          vm.actionEdit.resources.push(obj);
        }
      });

      angular.forEach(vm.selectRes, function(resource, id) {
        let obj = getResourceObj(resource, id);
        if (vm.resources.indexOf(obj) == -1) {
          vm.resources.push(obj);
        }
      });

      vm.selectedResToAdd = angular.copy(vm.selectRes);

      if (!vm.isAttachedResource) {
        enableEditScreen(vm);
      }
    }

    // END : This function is called when you attaching resource to action.

    // Start: This function is used attach or upload resource assignment

    function saveAttachedResources(vm) {
      attachResource(vm);
      vm.selectResShow = angular.copy(vm.selectResNew);

      if (!vm.isSideAction && vm.isAttachedResource) {
        uploadFilesAndSaveAction(vm);
      }

      if (vm.isAttachedResource) {
        $mdSidenav(actionRightSidenavName).close();
      }
    }

    // End: This function is used attach or upload resource assignment

    // Start: This function is used to open resource assignment sidenav directly

    function openAddResource(vm, action) {
      vm.editActionFlag = false;
      vm.addResourceFlag = true;
      vm.isAddingAction = undefined;
      vm.isAttachedResource = true;
      vm.setResource = false;
      vm.showOnly = false;

      vm.actionEdit = angular.copy(action);
      if (!vm.selectResNew) {
        vm.selectResNew = {};
      }
      vm.selectResNewBackUp = angular.copy(vm.selectResNew);
      if (vm.actionEdit.resources && vm.actionEdit.resources.length) {
        for (let i = 0; i < vm.actionEdit.resources.length; i++) {
          vm.selectResNew[vm.actionEdit.resources[i].resourceId] =
            vm.actionEdit.resources[i];
        }
      }

      vm.openSidenavResource(vm, vm.actionConstant.scopeParentVm, "resourcesF", ".task-wrapper");
      vm.toggleRight();
    }

    // End: This function is used to open resource assignment sidenav directly

    // START : It is used to attach the function like what type function action is attached to.
    function attachFunction(vm) {
      vm.actionEdit.modelId = vm.actionConstant.modelId;
      vm.actionEdit.modelName = 40;
      vm.actionEdit.modelData = undefined;
      // ATTACH_FUNCTION : Quote and Jobs
      console.log(vm.boardId)
      if(vm.functionVal == 45 && !vm.boardId) {
        messages.simpleToast("Please select board!");
        return;
      }
      if (vm.functionVal == 20 || vm.functionVal == 21) {
        forEach(vm.selectedFunctionNEW[vm.functionVal], function(val, key) {
          vm.actionEdit.modelId = key;
          vm.actionEdit.modelName = vm.functionVal;
          vm.actionEdit.modelData = angular.copy(val.contact);
        });
      }

      // ATTACH_FUNCTION : Customer
      if (vm.functionVal == 10) {
        forEach(vm.selectedFunctionNEW[vm.functionVal], function(val, key) {
          vm.actionEdit.modelId = key;
          vm.actionEdit.modelName = 10;
          vm.actionEdit.modelData = val;
        });
      }

      // ATTACH_FUNCTION : Opportunity
      if (vm.functionVal == 45) {
        forEach(vm.selectedFunctionNEW[vm.functionVal], function(val, key) {
          vm.actionEdit.modelId = key;
          vm.actionEdit.modelName = 45;
          vm.actionEdit.modelData = {
            firstName: val.contact.firstName,
            middleName: val.contact.middleName,
            lastName: val.contact.lastName,
            cardId: val.cardId,
          };
          vm.actionEdit.opportunityBoardId = val.boardId;
          vm.actionEdit.boardName = vm.selectedFunctionBoardName;
        });

      }

      // ATTACH_FUNCTION : Campaign
      if (vm.functionVal == 69) {
        forEach(vm.selectedFunctionNEW[vm.functionVal], function(val, key) {
          vm.actionEdit.modelId = key;
          vm.actionEdit.modelName = vm.functionVal;
          vm.actionEdit.modelData = angular.copy(val);
        });
      }

      // ATTACH_FUNCTION : Product
      // ATTACH_FUNCTION : Promotion

      if (vm.editFunctionFlag && !vm.isSideAction) {
        uploadFilesAndSaveAction(vm);
      } else if (vm.editFunctionFlag) {
        $mdSidenav(actionRightSidenavName).close();
      } else {
        enableEditScreen(vm);
      }
    }
    // END : It is used to attach the function like what type function action is attached to.

    function selectUnselectFunctionCommon(vm, item, key) {
      if (Object.keys(vm.selectedFunctionNEW[vm.functionVal]).indexOf(item[key].toString()) == -1) {
        vm.selectedFunctionNEW[vm.functionVal] = {};
        vm.selectedFunctionNEW[vm.functionVal][item[key]] = item;
      } else {
        vm.selectedFunctionNEW[vm.functionVal] = {};
      }
    }

    // START : This function is called whenever you select or unselect function.
    function selectUnselectFunction(vm, index) {
      const keyMap = {
        20: 'quoteId',
        21: 'quoteId',
        10: 'customerId',
        45: 'id',
        69: 'id'
      };

      // for (const funcVal of Object.keys(keyMap)) {
      //   vm.selectedFunctionNEW[funcVal] = {};
      // }

      let item = vm.isPanel ? vm.infiniteAttachFunctions.items[index] : vm.attachFunctionValue[index];

      if (vm.functionVal == 45) {
        item = vm.isPanel ? vm.infiniteCards.items[index] : vm.cards[index];
      }

      selectUnselectFunctionCommon(vm, item, keyMap[vm.functionVal]);
      // vm.selectedFunctionNEW[vm.functionVal] = {};
    }
    // END : This function is called whenever you select or unselect function.

    // START : This function is calling when you want to attach function to the action.
    function getFunctionsList(vm, action, isOpenRight) {
      if (isOpenRight) {
        vm.editFunctionFlag = true;
        editAction(vm, action);
      }

      vm.addFunctionFlag = true;
      vm.addResourceFlag = false;
      vm.editActionFlag = false;
      vm.setResource = false;
      vm.functionVal = vm.actionEdit.modelName ? vm.actionEdit.modelName : 10;
      if (vm.actionEdit.addressId) {
        vm.selectedBusinessId = vm.actionEdit.addressId.toString();
      }

      vm.selectDefaultFunction(vm);
      if (!vm.isPanel) {
        vm.openSidenavResource(vm, vm.actionConstant.scopeParentVm, vm.functionVal == 45 ? 'cards' : 'attachFunctionValue', '.task-wrapper');
      }
    }
    // END : This function is calling when you want to attach function to the action.

    // START : This function is called when user is marking action complete or Active.
    function completeAndActive(vm, id, isComplete) {
      vm.trueArchiveCheckBox[id] = !vm.trueArchiveCheckBox[id];
      actionCompleteAndActive(vm, id, isComplete);
    }

    function actionCompleteAndActive(vm, id, isComplete) {
      vm.isUpdatedCard = true;
      dataServices
        .put({
          url: serverUrl.main +
            "actionsApi/action/" +
            (isComplete ? "complete" : "active") +
            "/" +
            id,
          spinner: false,
        })
        .then(function(response) {
          if (response && response.data && response.data.result) {
            if (vm.isSideAction) {
              closeEditActionSidenav(vm);
            }
            if (
              vm.actionConstant.modelName == 40 &&
              !vm.actionConstant.isWorkboard
            ) {
              if (!vm.actionConstant.isPipeline) {
                getActionListCount(vm);
              }
              getFolderList(vm);
            } else {
              // START : if you complete and active it from workboard then you need to get updated statistics and priority distribution. We are refreshing the list because we are not updating count on workboard bcoz Workboard is not based on folder.
              if (vm.actionConstant.isWorkboard) {
                vm.setFilters();
                vm.getStatistic();
                vm.getPriorityDistribution();
              } else if (!vm.actionConstant.isSidenavOnly) {
                getActionListCount(vm);
              }
            }
          }
        });
    }
    // END : This function is called when user is marking action complete or Active.

    // START: It is used to check the action item time. If It is new then we need to show new label icon.
    function checkActionItemTime(dateTime, isCheckForNewAction) {
      let currentDate = new Date() / 1000;
      let dateBeforeFiveDay =
        new Date().setDate(new Date().getDate() - 5) / 1000;

      if (isCheckForNewAction) {
        if (currentDate > dateTime && dateTime > dateBeforeFiveDay) {
          return "new";
        }
      } else {
        if (dateTime > currentDate) {
          return "orange";
        } else if (dateTime < currentDate) {
          return "red";
        } else {}
      }
    }
    // END: It is used to check the action item time. If It is new then we need to show new label icon.

    // START : It is used to perform action to multiple action.
    function bulkOperation(vm) {
      let ids = [];
      let filteredIds = [];
      // if (vm.selectAll) {
      //   ids = vm.actionActive.map((item) => {
      //     return item.id;
      //   });
      // } else {

      angular.forEach(vm.selectIds, function(isSelect, id) {
        if (isSelect) {
          ids.push(id);
          filteredIds.push(id);
        }
      });
      let active = vm.actionActive.filter(function(action) {
        return filteredIds.includes(action.id);
      });

      if (!ids.length) {
        messages.simpleToast("Please select atleast one action.");
        return;
      }

      if (vm.operation.startDate && !vm.operation.time) {
        messages.simpleToast("Please select time to set reminder.");
        return;
      }

      if (!vm.operation.startDate && vm.operation.time) {
        messages.simpleToast("Please select reminder date to set reminder.");
        return;
      }

      let data = {
        actions: ids,
        mdl: 40,
        // tags: []
      };

      if (vm.operation.priority) {
        data.priority = parseInt(vm.operation.priority);
      }

      if (!vm.operation.startDate && !vm.operation.time) {
        data.startTime = null;
        data.endTime = null;
      }

      if (vm.operation.startDate && vm.timezoneInfo) {
        data.startTime = vm.timezoneInfoBulk.startTime;
        data.endTime = vm.timezoneInfoBulk.startTime + 1800;
      }

      data.timeZone = vm.selectedTimezoneBulk ?
        dataServices.toSave(vm.selectedTimezoneBulk, [
          "gmt",
          "offset",
          "region",
          "timezoneId",
          "timezone",
        ]) :
        vm.actionConstant.modelName == 40 ?
        $rootScope.usrObj.timeZone :
        null;

      if (vm.operation.assignTo && vm.operation.assignTo.length) {
        data.resources = [];
        for (let i = 0; i < vm.operation.assignTo.length; i++) {
          data.resources.push({
            resourceId: vm.operation.assignTo[i],
          });
        }
      }

      if (vm.actionConstant.modelName == 40 && vm.operation.folderId) {
        data.sourceFolder = vm.currentFolderId;
        data.destnFolder = vm.operation.folderId;
      }

      if (vm.operation.tags && vm.operation.tags.length) {
        data.tags = vm.operation.tags;
      }

      function extractResourceIds(activeItem) {
        return activeItem.resources.map(function(resource) {

          return resource.resourceId;
        });
      }

      if (data['resources'] == null && data['startTime'] == null && data['tags'] == null && data['priority'] != null) {

        let allPrioritiesMatch = true;
        for (let i = 0; i < active.length; i++) {
          let activePriority = active[i].priority;
          if (activePriority != data['priority']) {
            allPrioritiesMatch = false;
            break;
          }
        }
        if (allPrioritiesMatch == true) {
          messages.simpleToast("Please select different priorities.");
          return;
        }

      }
      if (data['resources'] != null && data['startTime'] == null && data['tags'] == null && data['priority'] == null) {

        let dataResourceIds = data['resources'].map(function(resource) {
          return resource.resourceId;
        });

        let areListsEqual = active.every(function(activeItem) {
          let activeResourceIds = extractResourceIds(activeItem);

          return JSON.stringify(activeResourceIds.sort()) === JSON.stringify(dataResourceIds.sort());
        });

        if (areListsEqual == true) {
          messages.simpleToast("Please select different resources.");
          return;
        }
      }

      if (data['resources'] != null && data['startTime'] == null && data['tags'] == null && data['priority'] != null) {
        let allPrioritiesMatch = true;
        for (let i = 0; i < active.length; i++) {
          let activePriority = active[i].priority;
          if (activePriority != data['priority']) {
            allPrioritiesMatch = false;
            break;
          }
        }
        let dataResourceIds = data['resources'].map(function(resource) {
          return resource.resourceId;
        });

        let areListsEqual = active.every(function(activeItem) {
          let activeResourceIds = extractResourceIds(activeItem);

          return JSON.stringify(activeResourceIds.sort()) === JSON.stringify(dataResourceIds.sort());
        });

        if (areListsEqual == true && allPrioritiesMatch == true) {
          messages.simpleToast("Please select different resources and priorities.");
          return;

        }

      }

      dataServices
        .put({
          url: serverUrl.main + "actionsApi/action/bulk/assign",
          data: {
            data: angular.toJson(data),
          },
        })
        .then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast("Actions updated successfully.");
            vm.selectAll = false;
            vm.selectIds = {};

            if (vm.actionConstant.modelName == 40 && vm.operation.folderId) {
              getActionListCount(vm);
              getFolderList(vm);
            } else {
              vm.setFilters('new', true);
            }

            vm.clearOperation(vm);

            $mdSidenav('bulk-ops').close();
          }
        });
    }
    // END : It is used to perform action to multiple action.

    // START : It is just resetting the variables.
    function clearOperation(vm) {
      vm.operation = {
        tags: [],
        assignTo: [],
        startDate: undefined,
        time: undefined
      };
    }
    // END : It is just resetting the variables.

    // START : It unable the delete action bar for multiple action to select.
    function actionDeletionBar(vm) {
      vm.selectIds = {};
      vm.isBulkOperationOn = vm.selectAll = vm.isActionDeletionOn = false;
    }
    // END : It unable the delete action bar for multiple action to select.

    function bulkSelection(vm) {
      if (Object.keys(vm.operation).length > 2) {
        return true;
      }
      if (
        Object.keys(vm.operation).length == 2 &&
        !vm.operation.assignTo.length &&
        !vm.operation.tags.length
      ) {
        return false;
      }
      return true;
    }

    // START : It unable the action bar for multiple action to select for the operation.
    function bulkOperationBar(vm) {
      vm.minimize = vm.isActionDeletionOn = false;
      vm.isBulkOperationOn = !vm.isBulkOperationOn;

      vm.operation.startDate = new Date();
      let incrementHour = 0,
        hours = vm.operation.startDate.getHours();
      if (vm.operation.startDate.getMinutes() > 30) {
        incrementHour = 1;
        hours += 1;
      }
      vm.operation.time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (incrementHour == 0 ? "30" : "00");

      vm.setDefaultTimezoneBulk();
    }

    function openBulkOperationSidebar(vm) {
      vm.operation.startDate = new Date();
      let incrementHour = 0,
        hours = vm.operation.startDate.getHours();
      if (vm.operation.startDate.getMinutes() > 30) {
        incrementHour = 1;
        hours += 1;
      }
      vm.operation.time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (incrementHour == 0 ? "30" : "00");

      vm.setDefaultTimezoneBulk();
      clearDate(vm);
      $mdSidenav('bulk-ops').open().then(() => {
        $mdSidenav('bulk-ops').onClose(function() {
          vm.isBulkOperationOn = false;
        });
      });
    }
    // END : It unable the action bar for multiple action to select for the operation.

    // START: It is used to clone the action.
    function cloneAction(vm, ev, id) {
      messages
        .mdconfirm(
          ev,
          "Attachments will not be cloned. Are you sure to clone this action ?"
        )
        .then(function(flag) {
          if (flag) {
            dataServices
              .post({
                url: serverUrl.main + "actionsApi/action/clone/" + id,
                spinner: false,
              })
              .then(function(response) {
                if (response && response.data && response.data.result) {
                  messages.simpleToast("Action cloned successfully.");
                  getActionListCount(vm);

                  if (
                    vm.actionConstant.modelName == 40 &&
                    !vm.actionConstant.isWorkboard
                  ) {
                    getFolderList(vm);
                  } else {
                    vm.setFilters();
                  }
                }
              });
          }
        });
    }
    // END: It is used to clone the action.

    // START: Used to clear the date.
    function clearDate(vm) {
      vm.operation.startDate = undefined;
      vm.operation.time = undefined;
    }
    // END: Used to clear the date.

    // START : It is called to hit delete api for single / multiple action to be deleted.
    function deleteActionItem(vm, id) {
      if (id) {
        vm.selectIds = {};
        vm.selectIds[id] = true;
      }
      let ids = [];
      angular.forEach(vm.selectIds, function(isSelect, id) {
        if (isSelect) {
          ids.push(id);
        }
      });

      if (!ids.length) {
        messages.simpleToast("Please select atleast one action.");
        return;
      }

      messages
        .mdconfirm(false, "Are you sure you want to delete selected actions ?")
        .then(function(flag) {
          if (flag) {
            dataServices
              .put({
                url: serverUrl.main + "actionsApi/action/bulk/delete",
                data: {
                  data: angular.toJson({
                    actions: ids,
                  }),
                },
              })
              .then(function(response) {
                if (response && response.data && response.data.result) {
                  messages.simpleToast("Action deleted successfully.");

                  getActionListCount(vm);
                  actionDeletionBar(vm);

                  if (
                    vm.actionConstant.modelName == 40 &&
                    !vm.actionConstant.isWorkboard
                  ) {
                    getFolderList(vm, vm.filterActionsByFolder);
                  }

                  if (id) {
                    vm.close(vm);
                  }
                }
              });
          }
        });
    }
    // END : It is called to hit delete api for single / multiple action to be deleted.

    function objLength(obj) {
      return obj ? Object.keys(obj).length : 0;
    }

    // START : It is used to delete the folder of action item and try to move all action to different folder.
    function folderDelete(vm, ev, action) {
      $mdDialog.show({
        locals: {
          vm: vm,
          action: action,
        },
        controller: FolderDeleteController,
        templateUrl: folderDeleteTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: "cd",
        clickOutsideToClose: true,
      });
    }

    function FolderDeleteController($scope, $mdDialog, messages, vm, action) {
      let cd = this;
      cd.action = action;
      cd.parentVm = vm;
      cd.selection = 1;
      cd.selectionTypeDelete = {
        1: "Delete folder and all it’s action items.",
        2: "Move all action items to another folder, and delete the folder.",
      };
      cd.selectionTypeHide = {
        1: "Keep all action items in the same folder.",
        2: "Move all action items to another folder.",
      };

      cd.cancel = cancel;
      cd.confirm = cd.action == "delete" ? deleteFolder : hideFolder;

      function cancel() {
        $mdDialog.cancel();
      }

      function deleteFolder() {
        let data = {};

        if (cd.selection == 1) {
          data.action = "delete";
        }

        if (cd.selection == 2 && cd.folderId) {
          data.destinationFolderId = cd.folderId;
        }

        cd.folderIndex = findIndex(cd.parentVm.allFolder, function(val) {
          return val.id == cd.folderId;
        });

        dataServices
          .delete({
            url: serverUrl.main +
              "actionFolderApi/actionFolder/delete/" +
              cd.parentVm.currentFolderId,
            data: data,
            spinnerName: "folder-delete",
          })
          .then(function(response) {
            if (response && response.data && response.data.result) {
              messages.simpleToast(
                cd.selection == 1 ?
                "Action folder deleted successfully!" :
                "Action folder deleted and action move to " +
                cd.parentVm.allFolder[cd.folderIndex].name +
                " folder"
              );

              if (vm.actionConstant.isHiddenFolder) {
                $state.go("main.resources.actionInactiveFolder");
              } else {
                cd.parentVm.currentFolder =
                  cd.selection == 1 ||
                  cd.parentVm.allFolder[cd.folderIndex].hide ?
                  "Inbox" :
                  cd.parentVm.allFolder[cd.folderIndex].name;
                getFolderList(cd.parentVm, cd.parentVm.filterActionsByFolder);
              }
              cd.cancel();
            }
          });
      }

      function hideFolder() {
        let data = {};

        if (cd.selection == 1) {
          data["action"] = "hide";
        }

        if (cd.selection == 2 && cd.folderId) {
          data.destinationFolderId = cd.folderId;
        }

        cd.folderIndex = findIndex(cd.parentVm.allFolder, function(val) {
          return val.id == cd.folderId;
        });

        dataServices
          .put({
            url: serverUrl.main +
              "actionFolderApi/actionFolder/hide/" +
              cd.parentVm.currentFolderId,
            data: data,
            spinnerName: "folder-delete",
          })
          .then(function(response) {
            if (response && response.data && response.data.result) {
              messages.simpleToast(
                cd.selection == 1 ?
                "Action folder hide successfully!" :
                "Action folder hide and action move to " +
                cd.parentVm.allFolder[cd.folderIndex].name +
                " folder"
              );

              cd.parentVm.currentFolder =
                cd.selection == 1 || cd.parentVm.allFolder[cd.folderIndex].hide ?
                "Inbox" :
                cd.parentVm.allFolder[cd.folderIndex].name;
              let index = findIndex(
                cd.parentVm.allFolder,
                function(val, index) {
                  return val.name == cd.parentVm.currentFolder;
                }
              );
              cd.parentVm.currentFolderId = cd.parentVm.allFolder[index].id;
              getFolderList(cd.parentVm, cd.parentVm.filterActionsByFolder);
              cd.cancel();
            }
          });
      }
    }
    // END : It is used to delete the folder of action item and try to move all action to different folder.

    // START : Save Filter for Quickly filter the action.
    function createCustomFilter(vm, ev) {
      $mdDialog.show({
        controller: CustomFilterController,
        templateUrl: saveFilterTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: "cd",
        clickOutsideToClose: true,
        locals: {
          parentVm: vm,
        },
      });
    }

    function CustomFilterController(
      $scope,
      $mdDialog,
      messages,
      validatorService,
      errorMessage,
      parentVm
    ) {
      let cd = this;

      cd.errorMessage = errorMessage;
      cd.parentVm = parentVm;
      cd.confirm = validatorService.validateForm(cd, confirm);
      cd.cancel = cancel;

      function confirm() {
        let filter = [];
        if (cd.parentVm.filter.actionActive.priority) {
          filter.push({
            field: "priority",
            value: parseInt(cd.parentVm.filter.actionActive.priority),
            operator: "=",
          });
        }

        if (cd.parentVm.filter.actionActive.assignTo) {
          filter.push({
            field: "resources.resourceId",
            value: cd.parentVm.filter.actionActive.assignTo,
            operator: "=",
          });
        }

        if (cd.parentVm.filter.actionActive.modelName) {
          filter.push({
            field: "mdl",
            value: parseInt(cd.parentVm.filter.actionActive.modelName),
            operator: "=",
          });
        }

        if (
          cd.parentVm.filter.actionActive.startDate &&
          cd.parentVm.filter.actionActive.endDate
        ) {
          let dRange = [],
            startDate,
            endDate;

          // START : This date is for validating dates.
          startDate = Math.floor(
            new Date(
              new Date(cd.parentVm.filter.actionActive.startDate).setHours(
                0,
                0,
                0,
                1
              )
            ).getTime() / 1000
          );
          endDate = Math.floor(
            new Date(
              new Date(cd.parentVm.filter.actionActive.endDate).setHours(
                23,
                59,
                59,
                999
              )
            ).getTime() / 1000
          );
          // END : This date is for validating dates.

          if (startDate > endDate) {
            messages.simpleToast("Start Date Should be lesser then end date!");
            return false;
          }

          dRange.push(startDate);
          dRange.push(endDate);

          filter.push({
            field: "setReminder",
            value: true,
            operator: "=",
          });

          filter.push({
            field: "startTime",
            value: dRange,
            operator: "dtrange",
            type: "date",
          });
        }

        let data = {
          name: cd.filterName,
          filter: filter,
          category: parseInt(cd.parentVm.actionConstant.modelName),
        };

        dataServices
          .post({
            url: serverUrl.main + "filterSettingsApi/filter/settings",
            data: {
              data: angular.toJson(data),
            },
          })
          .then(function(response) {
            if (response && response.data.result) {
              messages.simpleToast("Custom filter saved successfully");
              cd.cancel();
              getAllCustomFilter(cd.parentVm);
            }
          });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }
    // END : Save Filter for Quickly filter the action.

    // START : Get all Custom Filter.
    function customFilterList(vm) {
      $mdDialog.show({
        controller: CustomFilterListController,
        templateUrl: saveFilterListTemplate,
        parent: angular.element(document.body),
        // targetEvent: ev,
        controllerAs: "cd",
        clickOutsideToClose: true,
        locals: {
          parentVm: vm,
        },
      });
    }

    function CustomFilterListController(
      $scope,
      $mdDialog,
      messages,
      validatorService,
      errorMessage,
      parentVm
    ) {
      let cd = this;

      cd.errorMessage = errorMessage;
      cd.parentVm = parentVm;
      cd.confirm = validatorService.validateForm(cd, confirm);
      cd.cancel = cancel;
      cd.customFilterList = angular.copy(cd.parentVm.customFilterList);
      cd.deleteCustomFilter = deleteCustomFilter;
      cd.updateCustomFilter = updateCustomFilter;

      function updateCustomFilter(index) {
        if (
          cd.customFilterList[index].name ==
          cd.parentVm.customFilterList[index].name
        ) {
          messages.simpleToast("Name is not changed");
          return;
        }

        dataServices
          .put({
            url: serverUrl.main +
              "filterSettingsApi/filter/settings/" +
              cd.customFilterList[index].id,
            data: {
              data: angular.toJson({
                name: cd.customFilterList[index].name,
              }),
            },
          })
          .then(function(response) {
            if (response && response.data.result) {
              messages.simpleToast("Custom filter updated successfully !");
              cd.parentVm.customFilterList[index].name = angular.copy(
                cd.customFilterList[index].name
              );
            }
          });
      }

      function deleteCustomFilter(ev, index) {
        messages
          .mdconfirm(ev, "Are you sure you want to delete this ?")
          .then(function(flag) {
            if (flag) {
              dataServices
                .delete({
                  url: serverUrl.main +
                    "filterSettingsApi/filter/settings/" +
                    cd.customFilterList[index].id,
                })
                .then(function(response) {
                  if (response && response.data.result) {
                    messages.simpleToast(
                      "Custom filter deleted successfully !"
                    );
                    cd.customFilterList.splice(index, 1);
                    cd.parentVm.customFilterList.splice(index, 1);
                    getAllCustomFilter(cd.parentVm);

                    if (!cd.customFilterList.length) {
                      cd.cancel();
                    }
                  }
                });
            }
          });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }
    // END : Get all Custom Filter.

    function getAllCustomFilter(vm) {
      dataServices
        .get({
          url: serverUrl.main +
            "filterSettingsApi/filter/settings?filterCriteria=" +
            angular.toJson({
              filters: [{
                field: "category",
                operator: "=",
                value: vm.actionConstant.modelName,
              }, ],
            }),
        })
        .then(function(response) {
          if (response && response.data.result) {
            vm.customFilterList = response.data.result;
          }
        });
    }

    // START : Folder Creating and Update Folder Name.
    function actionFolderCreateAndUpdate(
      vm,
      ev,
      folderName = false,
      color = false
    ) {
      $mdDialog.show({
        controller: AddNewFolderController,
        templateUrl: addNewFolderTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: "cd",
        clickOutsideToClose: true,
        locals: {
          parentVm: vm,
          folderName: folderName,
          colorCode: color,
        },
      });
    }

    function AddNewFolderController(
      $scope,
      $mdDialog,
      messages,
      validatorService,
      errorMessage,
      folderName,
      colorCode,
      parentVm
    ) {
      let cd = this;

      cd.resourceSelect = {};
      cd.errorMessage = errorMessage;
      cd.type = folderName ? "Update" : "Create";
      cd.folderName = folderName ? angular.copy(folderName) : undefined;
      cd.colorCode = colorCode ? angular.copy(colorCode) : '#ecb700';
      cd.parentVm = parentVm;
      cd.confirm = validatorService.validateForm(cd, confirm);
      cd.cancel = cancel;

      // Nephtalem changed the colorBoxColor according to provided colors
      cd.colorBoxColor = [
        "#d04d9d",
        "#ecb700",
        "#5cc5c3",
        "#ee3c3c",
        "#a6c221",
        "#f7a538",
        "#ba64f7",
        "#fa8700",
        "#08abff",
      ];
      cd.focus = () => {
        $timeout(function() {
          $("#new-folder").focus();
        }, 500);
      }

      function confirm() {
        if (
          folderName &&
          cd.folderName == folderName &&
          colorCode &&
          cd.colorCode == colorCode
        ) {
          messages.simpleToast("Folder name & color are not change.");
          return;
        }

        dataServices
          .post({
            url: serverUrl.main +
              "actionFolderApi/actionFolder" +
              (folderName ? "/" + cd.parentVm.currentFolderId : ""),
            data: {
              data: angular.toJson({
                name: cd.folderName,
                color: cd.colorCode,
              }),
            },
            method: folderName ? "PUT" : "",
            spinnerName: "new-folder-create",
          })
          .then(function(status) {
            if (status.data && status.data.response_code == 200) {
              cd.parentVm.currentFolder = cd.folderName;
              messages.simpleToast(
                folderName ?
                "Folder Update successfully" :
                "Folder Created successfully"
              );
              cd.cancel();
              getFolderList(cd.parentVm, cd.parentVm.filterActionsByFolder);
            }
          });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }
    // END : Folder Creating and Update Folder Name.

    // function getPositions(vm) {
    //   let filterCriteria = {
    //     filters: [{
    //       field: 'isArchived',
    //       operator: '=',
    //       value: false
    //     }, {
    //       field: 'resource_id',
    //       value: $rootScope.usrObj.resourceId,
    //       operator: '='
    //     }, {
    //       field: 'folder_id',
    //       value: vm.currentFolderId,
    //       operator: '='
    //     }],
    //     sort: [{
    //       field: 'lastUpdated',
    //       order: -1
    //     }]
    //   };
    //
    //   let url = serverUrl.main + 'actionItemPositionsApi/actionPosition?filterCriteria=' + angular.toJson(filterCriteria) + '&';
    //
    //   dataServices
    //     .get({
    //       url: url,
    //       spinner: false
    //     })
    //     .then(function(response) {
    //       if (response.data && response.data.result) {
    //         vm.positions = response.data.result;
    //       }
    //     });
    // }

    // START : Get action count of current selected Folder.
    function getActionListCount(vm, isCallback = true) {
      let filterCriteria = {
        filters: [],
      };

      if (!vm.actionConstant.isWorkboard) {
        filterCriteria.filters.push({
          field: "modelId",
          value: vm.actionConstant.modelId,
          operator: "=",
        });
      }

      if (vm.actionConstant.modelName == 45 || vm.actionConstant.modelName == 10) {
        filterCriteria.filters.push({
          field: "modelName",
          value: vm.actionConstant.modelName,
          operator: "=",
        });
      }

      let url =
        serverUrl.main +
        "actionsApi/action/folder/overall/stat?" + (vm.actionConstant.modelName == 40 && !vm.actionConstant.isWorkboard ? (vm.currentFolderId != "all" ? "folder=" + vm.currentFolderId + "&" : "") : "filterCriteria=" + angular.toJson(filterCriteria) + "&");

      dataServices
        .get({
          url: url,
          spinner: false,
        })
        .then(function(response) {
          if (response.data && response.data.result) {
            vm.actionListCount = response.data.result;
          }
        });

      if (isCallback) {
        vm.setFilters();
        // TODO: uncomment to do drag and drop
        // getPositions(vm);
      }
    }
    // END : Get action count of current selected Folder.

    function transformChip(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise, create a new one
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(chip) ? {
          email: chip,
          type: "external",
        } :
        null;
    }

    // START : This function is called whenever you want to get all folder.
    function getFolderList(vm, callback) {
      dataServices
        .get({
          url: serverUrl.main +
            "actionFolderApi/actionFolder?filterCriteria=" +
            angular.toJson({
              sort: [{
                field: "seqId",
                order: 1,
              }, ],
            }) +
            "&",
          spinner: false,
        })
        .then(function(response) {
          if (response.data && response.data.response_code == 200) {
            vm.folderCount = response.data.result.length;
            vm.allFolder = response.data.result;

            vm.hiddenFolder = [];
            vm.activeFolder = [];
            for (let i = 0; i < vm.allFolder.length; i++) {
              if (vm.allFolder[i].hide) {
                vm.hiddenFolder.push(vm.allFolder[i]);
              } else {
                vm.activeFolder.push(vm.allFolder[i]);
              }
            }
            vm.dropdownMenu.items["Organize Projects"].disabled =
              vm.allFolder.length == 1 ? true : false;

            vm.dynamicMenu.ACTION.items = {};
            // vm.currentFolder = vm.currentFolder || "Inbox";
            vm.currentFolder = vm.currentFolder;

            angular.forEach(vm.allFolder, function(folder) {
              if (!folder.hide) {
                if (folder.name == vm.currentFolder) {
                  vm.currentFolderId = folder.id;
                  vm.currentFolderColor = folder.color;
                }

                vm.dynamicMenu.ACTION.items[folder.name] = {
                  id: folder.id,
                  click: vm.filterActionsByFolder,
                  icon: "ico-nav-summary",
                  count: folder.count.active,
                  name: folder.name,
                  color: folder.color,
                };
              }
            });

            vm.dynamicMenu.ACTION.activeMenu = vm.currentFolder;

            if (callback) {
              callback();
            }
          }
        });
    }
    // END : This function is called whenever you want to get all folder.

    // START : Move Action to different Directory
    function moveAction(
      vm,
      actionId,
      folderId,
      destinationFolderName,
      sourceFolderId,
      isWorkboard
    ) {
      dataServices
        .put({
          url: serverUrl.main + "actionsApi/action/move",
          data: {
            data: angular.toJson({
              id: actionId,
              destinationFolderId: folderId,
              sourceFolderId: sourceFolderId || vm.currentFolderId,
            }),
          },
        })
        .then(function(response) {
          if (response.data && response.data.response_code == 200) {
            messages.simpleToast(
              "Action Item successfully moved to " +
              destinationFolderName +
              " Folder."
            );
            if (isWorkboard) {
              vm.setFilters("new", true);
            } else {
              getActionListCount(vm);
              getFolderList(vm);
            }
          }
        });
    }
    // END : Move Action to different Directory

    // START: It is used for re-arranging the folder Sequence.
    function folderSequenceMove(vm) {
      if (vm.allFolder.length == 1) {
        messages.simpleToast("No project is available for organize.");
        return;
      }

      $mdDialog.show({
        controller: FolderSequenceMoveController,
        templateUrl: folderSequenceTemplate,
        parent: angular.element(document.body),
        // targetEvent: ev,
        controllerAs: "cd",
        clickOutsideToClose: true,
        openFrom: {
          top: -50,
          width: 30,
          height: 80,
        },
        closeTo: {
          left: 1500,
        },
        locals: {
          vm: vm,
        },
      });
    }

    function FolderSequenceMoveController(
      $scope,
      $rootScope,
      $mdDialog,
      messages,
      vm
    ) {
      let cd = this;

      cd.cancel = cancel;
      cd.confirm = confirm;

      function getActiveFolder() {
        dataServices
          .get({
            url: serverUrl.main +
              "actionFolderApi/actionFolder?filterCriteria=" +
              angular.toJson({
                filters: [{
                  field: "hide",
                  operator: "=",
                  value: false,
                }, ],
                sort: [{
                  field: "seqId",
                  order: 1,
                }, ],
              }) +
              "&",
            spinnerName: "folder-list-spinner",
          })
          .then(function(response) {
            if (response && response.data && response.data.result) {
              cd.allFolder = response.data.result;
              cd.allFolder.splice(0, 1);
              cd.allFolderBackup = angular.copy(cd.allFolder);
            }
          });
      }

      $timeout(function() {
        getActiveFolder();
      }, 0);

      function confirm() {
        let ids = [];
        // inbox folder id
        ids.push(cd.allFolderBackup[0].id);

        for (let i = 0; i < cd.allFolder.length; i++) {
          ids.push(cd.allFolder[i].id);
        }

        dataServices
          .put({
            url: serverUrl.main +
              "actionFolderApi/folder/position/" +
              $rootScope.usrObj.resourceId,
            spinnerName: "folder-list-spinner",
            data: {
              data: angular.toJson({
                position: ids,
              }),
            },
          })
          .then(function(response) {
            if (response && response.data && response.data.result) {
              messages.simpleToast("Folder Sequence changed successfully");
              cd.cancel();
              vm.getFolderList(vm);
            }
          });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }
    // END: It is used for re-arranging the folder Sequence.

    function registerTimezoneEvents(vm) {
      vm.prepareLocalStartTimeData = function() {

        let startDate = new Date(
          vm.actionEdit.date.toDateString() +
          (vm.actionEdit.time ? " " + vm.actionEdit.time : "")
        );
        // vm.actionEdit.endDate = vm.actionEdit.date;
        let endDate = new Date(
          vm.actionEdit.endDate.toDateString() +
          (vm.actionEdit.endTime ? " " + vm.actionEdit.endTime : "")
        );

        if (startDate > endDate) {
          vm.actionEdit.endDate = undefined;
        }

        vm.timezoneInfo.startTime = Math.trunc(
          startDate.setMinutes(
            startDate.getMinutes() - vm.timezoneInfo.timezoneOffset
          ) / 1000
        );
      };

      vm.prepareLocalEndTimeData = function() {
        if (vm.actionEdit.endDate) {
          let endDate = new Date(
            vm.actionEdit.endDate.toDateString() +
            (vm.actionEdit.endTime ? " " + vm.actionEdit.endTime : "")
          );
          vm.timezoneInfo.endTime = Math.trunc(
            endDate.setMinutes(
              endDate.getMinutes() - vm.timezoneInfo.timezoneOffset
            ) / 1000
          );
        }
      };

      vm.selectTimezone = function(loc) {
        if (loc) {
          vm.timezoneInfo.destTimeZone = loc.gmt + " (" + loc.region + ")";
          vm.timezoneInfo.timezoneOffset =
            new Date().getTimezoneOffset() + loc.offset / 60;
          vm.prepareLocalStartTimeData();
          vm.prepareLocalEndTimeData();
        }
        $timeout(function() {
          $mdSidenav("timeZone").close();
        }, 500);
      };

      vm.searchTextChanged = function() {
        if (vm.searchTextZone === "") {
          vm.setDefaultTimezone();
        }
      };

      vm.prepareTimezoneInfo = function(action) {
        if (action && action.timeZone) {
          vm.selectedTimezone = action.timeZone;
          vm.timezoneInfo.destTimeZone =
            action.timeZone.gmt + " (" + action.timeZone.region + ")";
          vm.timezoneInfo.timezoneOffset =
            new Date().getTimezoneOffset() + action.timeZone.offset / 60;
        } else if (!vm.selectedTimezone) {
          vm.setDefaultTimezoneInfo();
        }
      };

      vm.setDefaultTimezone = function() {
        vm.selectedTimezone = vm.searchTextZone = null;
        vm.setDefaultTimezoneInfo();
        vm.prepareLocalStartTimeData();
        vm.prepareLocalEndTimeData();
      };
    }

    function setLocalTimezoneBulk(vm, addressId) {
      let localTimezone = new Date().toString().split("GMT")[1];
      vm.timezoneInfoBulk = {
        localTimeZone: localTimezone.substr(0, 3) + ":" + localTimezone.substr(3),
      };

      vm.setDefaultTimezoneInfoBulk = function() {
        let timezone = $rootScope.bObj.bAddsMapped[addressId].timeZone;
        if (timezone) {
          vm.timezoneInfoBulk.timezoneOffset =
            new Date().getTimezoneOffset() + timezone.offset / 60;
          vm.timezoneInfoBulk.destTimeZone =
            timezone.gmt + " (" + timezone.region + ")";
        }
      };
    }

    function registerTimezoneEventsBulk(vm) {
      vm.prepareLocalStartTimeDataBulk = function() {
        let startDate = new Date(
          vm.operation.startDate.toDateString() +
          (vm.operation.time ? " " + vm.operation.time : "")
        );
        vm.timezoneInfoBulk.startTime = Math.trunc(
          startDate.setMinutes(
            startDate.getMinutes() - vm.timezoneInfoBulk.timezoneOffset
          ) / 1000
        );
      };

      vm.selectTimezoneBulk = function(loc) {
        if (loc) {
          vm.timezoneInfoBulk.destTimeZone = loc.gmt + " (" + loc.region + ")";
          vm.timezoneInfoBulk.timezoneOffset =
            new Date().getTimezoneOffset() + loc.offset / 60;
          vm.prepareLocalStartTimeDataBulk();
        }
      };

      vm.searchTextChangedBulk = function() {
        if (vm.searchTextZoneBulk === "") {
          vm.setDefaultTimezoneBulk();
        }
      };

      vm.setDefaultTimezoneBulk = function() {
        vm.selectedTimezoneBulk = vm.searchTextZoneBulk = null;
        vm.setDefaultTimezoneInfoBulk();
        vm.prepareLocalStartTimeDataBulk();
      };
    }

    // START : It is used to initialize same default variable and function.
    function init(vm) {
      actionRightSidenavName = vm.isFrame ? 'right-frame' : 'right';
      let commentsSidenavName = vm.isFrame ? 'comments-frame' : 'comments';

      // vm.activeMenu = vm.actionConstant.isWorkboard ? 'all' : 'active-item';
      vm.isFavourite = false;
      vm.selectIds = {};
      vm.selectResNew = {};
      vm.selectRes = {};
      vm.setReminder = false;
      vm.trueArchiveCheckBox = {};
      // vm.selectedIndex = 0;
      vm.actionPriority = {
        5: "critical",
        4: "high",
        3: "medium",
        2: "normal",
        1: "low",
        0: "backlog",
      };

      vm.operation = {
        tags: [],
        assignTo: [],
      };
      if (!vm.isAddingAction) {
        vm.filter = {
          ...vm.filter,
          actionActive: {
            status: 1,
          },
          resourcesF: {}
        };
      }
      vm.focusInput = (vm) => {
        $timeout(function() {
          var input = document.getElementById("action-item-description");
          input.focus();
        });
      }

      vm.timeZone = TimezoneService.getTimeZoneShortName();
      TimezoneService.loadTimezones(vm);
      registerTimezoneEvents(vm);
      registerTimezoneEventsBulk(vm);

      vm.selectAndUnselectAll = selectAndUnselectAll;

      function selectAndUnselectAll() {
        vm.selectAll = !vm.selectAll;
        if (vm.selectAll) {
          for (var i = 0; i < vm.actionActive.length; i++) {
            vm.selectIds[vm.actionActive[i].id] = true;
          }
        } else {
          vm.selectIds = {};
        }
      }

      vm.isIndeterminate = () => {
        vm.selectedCount = Object.keys(vm.selectIds).length;
        return vm.selectedCount !== 0 && vm.selectedCount !== vm.actionActive.length;
      };

      vm.showHiddenIcons = {};

      vm.toggleHiddenIcons = function(vm, index) {
        vm.showHiddenIcons[index] = true;
      };
      vm.hideHiddenIcons = function(vm, index) {
        vm.showHiddenIcons[index] = false;
      };

      vm.toggleSelection = function(actionId) {
        if (vm.selectIds[actionId]) {
          delete vm.selectIds[actionId];
        } else {
          vm.selectIds[actionId] = true;
        }
      };

      vm.clearOption = (vm) => {
        vm.setReminder = false;
        vm.selectedResToAdd = {};
        vm.selectRes = {};
        vm.labelsToAdd = {};
        vm.priority = 2;
        vm.isFavourite = false;
        vm.isCleared = true;
      }
      vm.getActionState = sharedDataService.get("actionExpandedState");
      if(vm.getActionState) {
        vm.showDetails = vm.getActionState;
      }
      vm.saveDisplayState = (vm) => {
        vm.showDetails = ! vm.showDetails;
        sharedDataService.set("actionExpandedState", vm.showDetails);
      }
      vm.selectDefaultFunction = (vm) => {
        vm.defaultVal = sharedDataService.get('functional_attach') || 10;
        vm.selectedBusinessId = sharedDataService.get('functional_attach_location') || $rootScope.usrObj.primaryBzLocation;
        vm.functionVal = vm.defaultVal;
        // attachFunctionData(vm, 'selectDefaultFunction');
      };
      // vm.completeActions = (id, $event) => {
      //   vm.trueArchiveCheckBox[id] = !vm.trueArchiveCheckBox[id];
      // }
      vm.isMobileScreen = () => {
        return window.innerWidth <= 600;
      }
      vm.convertToRgba = function(hex, opacity) {
        var r = parseInt(hex.slice(1, 3), 16);
        var g = parseInt(hex.slice(3, 5), 16);
        var b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      };

      vm.labels = [];
      vm.selectedLabels = {};
      vm.selectedLabelsCopy = {};
      vm.currentSelectedLabels = {};
      vm.isLoadingLabels = false;

      vm.openLabelsSidenav = (vm, action, id) => {
        vm.editLabels = true;
        vm.editAction(vm, action, false, false, false);
        vm.openAssignLabels(vm, true, id);
      };

      vm.openAssignLabels = (vm, isEditLabelOnly, id) => {
        vm.isEditLabelOnly = isEditLabelOnly;
        if (isEditLabelOnly) {
          vm.selectedLabelsCopy = angular.copy(vm.selectedLabels);
          vm.currentSelectedLabels = {};
        } else {

          vm.selectedLabelsCopy = {};
          vm.currentSelectedLabels = angular.copy(vm.labelsToAdd) || {};
          // (vm.labelsToAdd || {});
          // vm.currentSelectedLabels = {};
          // vm.selectedLabels = {};
        }

        $mdSidenav(id).open().then(() => {
          vm.isEditLabelOnly = isEditLabelOnly;
          $mdSidenav(id).onClose(function() {
            vm.isEditLabelOnly = vm.editLabels = false;
          });
        });

        if (!vm.labels.length) {
          getAssignedLabels(vm);
        }
      };

      function getAssignedLabels(vm) {
        vm.isLoadingLabels = true;
        dataServices.get({
          url: serverUrl.main + 'actionItemLabelApi/action/item?',
          method: 'GET',
          spinnerName: 'notes-labels-sidebar-spinner'
        }).then(response => {
          vm.isLoadingLabels = false;
          if (response && response.data) {
            vm.labels = response.data.result;
          }
        }, error => {
          vm.isLoadingLabels = false;
          console.error('Error fetching labels:', error);
        });
      }

      vm.updateSelectedLabels = (vm, label) => {
        if (vm.isEditLabelOnly) {
          if (vm.selectedLabelsCopy[label.id]) {
            delete vm.selectedLabelsCopy[label.id];
          } else {
            if (Object.keys(vm.selectedLabelsCopy).length >= 3) {
              messages.simpleToast('YOU_CAN_SELECT_MAX_THREE_LABELS.');
              return;
            }
            vm.selectedLabelsCopy[label.id] = label;
          }
        } else {
          if (vm.currentSelectedLabels[label.id]) {
            delete vm.currentSelectedLabels[label.id];
          } else {
            if (Object.keys(vm.currentSelectedLabels).length >= 3) {
              messages.simpleToast('YOU_CAN_SELECT_MAX_THREE_LABELS.');
              return;
            }
            vm.currentSelectedLabels[label.id] = label;
          }
        }
      };

      vm.savePostLabels = (vm, id) => {
        if (vm.isEditLabelOnly) {
          vm.actionEdit.labels = Object.keys(vm.selectedLabelsCopy);
          vm.selectedLabels = angular.copy(vm.selectedLabelsCopy);
          // vm.selectedLabelsCopy = {};
        } else {
          vm.actionEdit.labels = Object.keys(vm.currentSelectedLabels);
          vm.labelsToAdd = angular.copy(vm.currentSelectedLabels);
          vm.currentSelectedLabels = {};
        }

        if (vm.editLabels) {
          updateAction(vm)
        }
        vm.closeAssignLabels(vm, id);
      };

      vm.cancelLabels = (vm, id) => {
        vm.closeAssignLabels(vm, id);
      };

      vm.closeAssignLabels = (vm, id) => {
        $mdSidenav(id).close();
      };

      //comment sidebar
      vm.openCommentsSidebar = (vm) => {
        if (!vm.isAddingAction) {
          vm.getComments(vm);
        }
        $mdSidenav(commentsSidenavName).open();
        vm.comments = {
          add: [],
          update: [],
          delete: []
        };
        vm.commentDetails = vm.commentDetails || [];
        vm.activeIndex = -1;
      };

      vm.deleteComment = (vm, ev, index) => {
        messages.mdconfirm(ev, "Are you sure to remove the comment?", 'YES', 'NO')
          .then(function(result) {
            if (result) {
              if (vm.actionEdit.actionId) {
                let comment = vm.commentDetails[index];
                if (comment) {
                  dataServices.delete({
                      url: serverUrl.main + "actionItemCommentApi/action/item/comment/" + vm.commentDetails[index].id,
                    })
                    .then(function(response) {
                      if (response && response.data && response.data.result) {
                        vm.getComments(vm);
                        vm.commentDetails.splice(index, 1);
                        messages.simpleToast("Comment deleted!");
                      }
                    });
                } else {
                  vm.commentDetails.splice(index, 1);
                  messages.simpleToast("Comment deleted!");
                }
              }
            }
          });
      };

      vm.stripPre = function(content) {
        return content.replace(/<pre[^>]*>/g, "").replace(/<\/pre>/g, "");
      };

      vm.addComment = (vm) => {
        if (vm.actionEdit.commentEditor && vm.actionEdit.commentEditor !== '') {
          if (vm.actionEdit.commentEditor && vm.actionEdit.commentEditor.includes(".")) {
            const urlPattern = vm.actionEdit.commentEditor.includes("http://") || vm.actionEdit.commentEditor.includes("https://");
            if (!urlPattern) {
                messages.simpleToast("Invalid URL detected. Please enter a complete URL");
                return;
            }
          }
          let comment = {
            content: angular.copy(vm.actionEdit.commentEditor),
          };

          if (!Array.isArray(vm.actionEdit.commentDetails)) {
            vm.commentDetails = [];
          }

          vm.commentDetails.unshift(comment);

          if (!vm.comments) {
            vm.comments = {
              add: [],
              update: [],
              delete: []
            };
          }

          if (!Array.isArray(vm.comments.add)) {
            vm.comments.add = [];
          }

          comment.uniqueId = Math.random().toString(36) + Date.now();
          vm.comments.add.push(comment);
          vm.actionEdit.commentEditor = '';

          let data = {};
          data.id = vm.actionEdit.id;
          let comments = angular.copy(vm.comments);

          comments.add.forEach(comment => {
            delete comment.uniqueId;
          });
          data.comments = comments
          dataServices
            .post({
              url: serverUrl.main + "actionItemCommentApi/action/item/comment",
              data: {
                data: angular.toJson(data),
              },
            })
            .then(function(response) {
              if (response && response.data && response.data.result) {
                messages.simpleToast("Comment added successfully")
                vm.getComments(vm)
                vm.comments = {
                  add: [],
                  update: [],
                  delete: []
                };
              }
            });
        } else {
          messages.simpleToast('PLEASE_ENTER_VALID_COMMENT.');
        }
      };

      vm.editComment = (vm, index) => {
        if (vm.activeIndex >= 0) {
          vm.commentDetails[vm.activeIndex] = vm.editingComment;
        }
        vm.commentEdits = {};
        vm.commentEdits[index] = true;
        vm.editingComment = angular.copy(vm.commentDetails[index]);
        vm.activeIndex = index;
      };

      vm.cancelEdit = (vm, index) => {
        vm.activeIndex = -1;
        vm.commentDetails[index].content = vm.editingComment.content;
        vm.commentEdits = {};
      };

      vm.saveComment = (vm, index) => {
        // vm.activeIndex = -1;
        // vm.commentEdits = {};
        // if (vm.actionEdit.actionId && vm.actionEdit.commentDetails[index].id) {
        //   vm.actionEdit.commentDetails[index].lastUpdated = new Date();
        if (!vm.comments) {
          vm.comments = {
            add: [],
            update: [],
            delete: []
          };
        }
        if (!Array.isArray(vm.comments.update)) {
          vm.comments.update = [];
        }
        vm.comments.update.push(dataServices.toSave(vm.commentDetails[index], ['content', 'id']));
        let data = {};
        data.id = vm.commentDetails[index].id;
        let comments = angular.copy(vm.comments);

        // comments.add.forEach(comment => {
        //   delete comment.uniqueId;
        // });
        if (comments.add) {
          comments.add = [];
        }
        data.comments = comments

        dataServices
          .put({
            url: serverUrl.main + "actionItemCommentApi/action/item/comment/feedback/" + vm.commentDetails[index].id,
            data: {
              data: angular.toJson(data),
            },
          })
          .then(function(response) {
            if (response && response.data && response.data.result) {
              messages.simpleToast("Comment updated successfully")
              vm.getComments(vm)
              vm.comments = {
                add: [],
                update: [],
                delete: []
              };
            }
            vm.cancelEdit(vm, index);
          });
      };

      vm.toggleComments = (vm) => {
        $mdSidenav(commentsSidenavName).close()
      };

      vm.getComments = (vm) => {
        let filterCriteria = {
          filters: [{
            field: 'isArchived',
            operator: '=',
            value: false
          }, {
            field: 'modelId',
            value: vm.actionEdit.id,
            operator: '='
          }]
        };

        let url = serverUrl.main + 'actionItemCommentApi/action/items/comment?filterCriteria=' + angular.toJson(filterCriteria) + '&';

        dataServices
          .get({
            url: url,
            spinner: 'opportunity-sidebar-spinner'
          })
          .then(function(response) {
            if (response.data && response.data.result) {
              vm.commentDetails = response.data.result;
              vm.actionEdit.commentCount = vm.commentDetails.length;
            }
          });
      }

      //download attachments

      vm.downloadAllImages = (vm) => {
        let filesToDownload = [];

        let attachments = vm.actionEdit.attachments || [];
        let images = vm.actionEdit.images || [];

        let allFiles = attachments.concat(images);
        angular.forEach(allFiles, function(file) {
          filesToDownload.push({
            name: file.fileOriginalName,
            id: file.id,
            src: file.src || file.thumbnailUrl,
            webUrl: file.webUrl
          });
        });

        if (vm.totalFileUpload && vm.totalFileUpload.length > 0) {
          angular.forEach(vm.totalFileUpload, function(file) {
            filesToDownload.push({
              name: file.name,
              src: file.src || file.thumbnailUrl,
            });
          });
        }


        if (filesToDownload.length > 0) {
          filesToDownload.forEach(function(attach) {
            

            let downloadUrl = attach.src || attach.webUrl;

            if (downloadUrl) {
              fetch(downloadUrl)
                .then(response => response.blob())
                .then(blob => {
                  const link = document.createElement('a');
                  const url = URL.createObjectURL(blob);
                  link.href = url;
                  link.download = attach.name;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(url);
                })
                .catch(error => {
                  console.error('Error downloading image:', error);
                });
            }
          });
        }

      };

      //tags
      vm.selectedTags = {};
      vm.selectTag = function(vm, tag) {
        if (vm.selectedTags.hasOwnProperty(tag.id)) {
          delete vm.selectedTags[tag.id];
        } else {
          vm.selectedTags[tag.id] = dataServices.toSave(tag, ['id', 'name', 'category', 'mainCategory', 'isSystem']);
        }
      };

      vm.attachTags = function(vm, id) {
        vm.actionEdit.tags = !vm.actionEdit.tags ? [] : vm.actionEdit.tags;
        angular.forEach(vm.selectedTags, function(tag, id) {
          if (vm.actionEdit.tags.indexOf(tag.name) == -1) {
            vm.actionEdit.tags.push(tag.name);
          }
        });
        vm.selectedTags = {};
        vm.selectedTagsBackup = {};
        vm.closeRightTags(vm, id);
      };

      vm.closeRightTags = (vm, id) => {
        $mdSidenav(id).close();
      };
    }

    // END : It is used to initialize same default variable and function.

    // START : It is used to sort the action.
    function actionSortBy(vm, field, index) {
      return function() {
        vm.sort[vm.listName] = [{
          field: field == "createdOnFirst" ?
            "createdOn" : field == "setReminder" ?
            "startTime" : field,
          order: field == "createdOnFirst" ? 1 : -1,
        }, ];

        angular.forEach(sortTypeWithIndex, function(sortType) {
          vm.dropdownMenu.items[sortType].class = "";
        });

        // active current sort
        vm.dropdownMenu.items[sortTypeWithIndex[index]].class = "active-menu";

        vm.setFilters();
      };
    }
    // END : It is used to sort the action.

    function afterActionList(vm) {
      vm.trueArchiveCheckBox = {};
      vm.isFavourite = false;

      if (
        vm.actionConstant.modelName == 40 &&
        vm.activeMenu != "archived-item"
      ) {
        if (vm.activeMenu == "starred-item" || vm.filter.actionActive.fav) {
          vm.isFavourite = true;
          vm.actionConstant.scopeParentVm.screenData =
            (vm.actionConstant.isHiddenFolder ?
              "Hidden Folder" :
              $filter("getFullName")($rootScope.usrObj)) +
            " - Favorite - " +
            (vm.actionListCount.favorite || 0);
        } else if (
          vm.activeMenu == "active-item" ||
          vm.filter.actionActive.status == 1
        ) {
          vm.actionConstant.scopeParentVm.screenData =
            (vm.actionConstant.isHiddenFolder ?
              "Hidden Folder" :
              $filter("getFullName")($rootScope.usrObj)) +
            " - Active - " +
            (vm.actionListCount.active || 0);
        } else if (
          vm.activeMenu == "system-item" ||
          vm.filter.actionActive.is_system
        ) {
          vm.actionConstant.scopeParentVm.screenData =
            (vm.actionConstant.isHiddenFolder ?
              "Hidden Folder" :
              $filter("getFullName")($rootScope.usrObj)) +
            " - System - " +
            (vm.actionListCount.system || 0);
        } else {}
      } else {
        if (vm.activeMenu == "starred-item" || vm.filter.actionActive.fav) {
          vm.isFavourite = true;
        } else if (
          vm.activeMenu == "archived-item" ||
          vm.filter.actionActive.status == 5
        ) {
          for (let i = 0; i < vm.actionActive.length; i++) {
            vm.trueArchiveCheckBox[vm.actionActive[i].id] = true;
          }

          if (vm.actionConstant.modelName == 40) {
            vm.actionConstant.scopeParentVm.screenData =
              (vm.actionConstant.isHiddenFolder ?
                "Hidden Folder" :
                $filter("getFullName")($rootScope.usrObj)) +
              " - Complete - " +
              (vm.actionListCount.completed || 0);
          }
        }
      }

      // if (vm.dropdownMenu && !vm.actionConstant.isWorkboard) {
      //   vm.dropdownMenu.items["Bulk Operation"] = {
      //     action: vm.bulkOperationBar,
      //     disabled: !vm.actionActive.length
      //   };
      //   vm.dropdownMenu.items["Delete Action"] = {
      //     action: vm.actionDeletionBar,
      //     disabled: !vm.actionActive.length
      //   };
      // }
    }

    function buildToggler(navID) {
      return function() {
        

        $mdSidenav(navID).toggle();
      };
    }

    function close(vm, nav = actionRightSidenavName) {
      
      $mdSidenav(nav).close();
      if (nav == actionRightSidenavName) {
        vm.visited = undefined;
      }
    }

    function setActionTypeValues(vm, is_system, status, fav) {
      vm.filter.actionActive.is_system = is_system;
      vm.filter.actionActive.status = status;
      vm.filter.actionActive.fav = fav;
    }

    function setActionType(vm) {
      vm.visited = undefined;

      switch (vm.activeMenu) {
        case "all":
          delete vm.filter.actionActive.is_system;
          vm.filter.actionActive.status = 1;
          vm.filter.actionActive.fav = false;
          break;

        case "system-item":
          setActionTypeValues(vm, true, 1, false);
          break;

        case "active-item":
          setActionTypeValues(vm, false, 1, false);
          break;

        case "starred-item":
          setActionTypeValues(vm, false, 1, true);
          break;

        case "archived-item":
          setActionTypeValues(vm, false, 5, false);
          break;
      }
    }

    function functionClickable(modelId, modelName, boardId, locationId) {
      let url;

      if (modelName == 20) {
        // quotes
        url = $state.href("main.quotes.guider", {
          quoteId: modelId,
        });
      } else if (modelName == 45) {
        // opportunity
        url = $state.href("main.customers.opportunitiesDetail", {
          cardId: modelId,
          boardId: boardId,
          locationId: locationId,
        });
      } else if (modelName == 21) {
        // Job
        url = $state.href("main.jobs.guider", {
          quoteId: modelId,
        });
      } else if (modelName == 10) {
        // Customer
        url = $state.href("main.customers.customersDetail", {
          customerId: modelId,
        });
      } else if (modelName == 24) {
        // Product
        url = $state.href("main.business.inventoryProductsDetail", {
          id: modelId,
        });
      } else if (modelName == 27) {
        // Promotion
        url = $state.href("main.settings.automationPromotionsView", {
          id: modelId,
          lastScreen: "",
        });
      } else if (modelName == 69) {
        // Campaign
        url = $state.href("main.customers.campaignsDetail", {
          id: modelId,
        });
      } else {
        url = $state.href("main.customers.customers");
      }

      window.open(url, '_blank');
    }

    // START: Multiple action items creation
    function onPaste(vm, event) {
      
      let data = event.clipboardData;
      let text = data.getData('text');
      
      // addMultipleActionItems(vm, pastedText);
      vm.actionItems = [];
      vm.actionItems = text.split('\n').map(item => item.replace('\r', ''));
      vm.actionItems = vm.actionItems.filter((x) => {
        return x.trim().length > 0;
      });
      
    }

    function createMultipleActionItems(vm) {
      
      vm.multipleActionItems = true;
      newAction(vm);
    }
    // END: Multiple action items creation

    function useGpt(vm, ev, id, isCustomer) {
      $mdDialog.show({
        controller: OpenAIController,
        templateUrl: AITemplate,
        locals: {
          vm: vm,
          id: id,
          isCustomer: isCustomer
        },
        fullscreen: true,
        multiple: true,
        closeOnNavigation: true,
        clickOutsideToClose: false,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'cd'
      });
    };

    
  }

}