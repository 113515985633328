import NotesSidenavTemplate from './notes-sidenavs.html';

export default function(app) {
  app.directive('notesSidenav', ['$document', notesSidenav]); 

  function notesSidenav() {
    return {
      restrict: 'E',
      templateUrl: NotesSidenavTemplate,
    };
  }
}
