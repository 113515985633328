function updateWorkorderController($scope, $rootScope, $state, $stateParams, $window, errorMessage, validatorService, statusIcons, unsavedChangesService, imageService, AttachDocumentService, FreedomFormService, dataServices, serverUrl, NavigationService, messages) {

  "ngInject";

  const vm = this;

  vm.errorMessages = errorMessage;
  $scope.$parent.vm.isRequiredApiFailed = vm.isRequiredApiFailed = {};
  vm.isValidForm = validatorService.isValidForm;
  vm.signaturePanel = signaturePanel;

  vm.id = $stateParams.id;
  vm.quoteId = $stateParams.quoteId;
  vm.serviceId = $stateParams.serviceId;
  vm.statusIcons = statusIcons.services;

  $scope.$parent.vm.isUnsavedChanges = unsavedChangesService.isFormChanged(vm);

  FreedomFormService.init(vm);
  AttachDocumentService.init(vm, $scope);
  imageService.initImageUpload(vm, 'service');
  vm.deleteDocument = AttachDocumentService.deleteDocument;
  vm.showImageMarkerPopUp = imageService.showImageMarkerPopUp;
  vm.showMultipleImagePopUp = imageService.showMultipleImagePopUp;

  vm.goBack = () => {
    NavigationService.back();
  };

  let markerArea, imgDataUrl;
  vm.showMarkerArea = (index, obj, img) => {
    let target = document.getElementById("image-" + index);

    const markerArea = new markerjs2.MarkerArea(target);
    markerArea.settings.displayMode = 'popup';

    markerArea.addEventListener("render", (event) => {
      imgDataUrl = target.src = event.dataUrl;

      dataServices.upload({
          url: serverUrl.main + 'mediaApi/upload/base64',
          data: {
            data: angular.toJson([{
              type: 'png',
              data: imgDataUrl
            }])
          }
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm[obj][img][index] = data.result.success[0].fileId;
            // vm.croppedImg[index] = imgDataUrl;
            vm.croppedImg[index] = data.result.success[0].webUrl;

            if (vm.initImg && vm.initImg[index] && vm.initImg[index].webUrl) {
              vm.removedImage[index] = true;
            }

            messages.simpleToast("Image markings saved.");
          }
        });
    });

    markerArea.addEventListener("close", (event) => {
      imgDataUrl = undefined;
    });

    markerArea.show();
  };

  function sectionIndexing(formArray) {
    vm.formSections = {};
    vm.isOpened = {};
    vm.fieldHasValue = {}
    let i = 0;
    angular.forEach(formArray, (form) => {
      let index = 0;
      let sectionIndex = 0;
      let mandatoryField = 0;
      let dataFilled = 0;
      vm.formSections[i] = {};
      vm.isOpened[i] = {};
      vm.fieldHasValue[i] = {};

      vm.fieldHasValue[i][sectionIndex] = vm.fieldHasValue[i][sectionIndex] || {};
      angular.forEach(form.customFields, (x) => {
        if (x.fieldType != 'Section Header') {
          index++;
          x.index = index;
          x.sectionIndex = sectionIndex;
          if (x.fieldValue) {
            if (x.fieldType != "Checkboxes") {
              dataFilled++;
              vm.fieldHasValue[i][sectionIndex][index] = true;
            } else if (x.fieldType == "Checkboxes" && x.fieldValue.length) {
              dataFilled++;
              vm.fieldHasValue[i][sectionIndex][index] = true;
            }
          }

          if (x.fieldType == 'Upload Files / Images') {
            dataFilled++;
            vm.fieldHasValue[i][sectionIndex][index] = true;
          }

          if (x.responseType == "mandatory") {
            mandatoryField++;
          }
        } else {
          sectionIndex++;
          x.remainingFields = index - dataFilled;
          index = 0;
          mandatoryField = 0;
          dataFilled = 0;
          vm.isOpened[i][sectionIndex] = false;
          vm.fieldHasValue[i][sectionIndex] = {};
          x.sectionIndex = sectionIndex;
        }
        vm.formSections[i][sectionIndex] = {
          totalField: index,
          mandatoryFields: mandatoryField,
          dataFilled: dataFilled,
          remainingFields: index - dataFilled
        };
      });
      i++;
    });
  }

  vm.trackInput = (focus, fieldValue, accIndex, sectionIndex) => {
    if (focus) {
      vm.fieldValue = fieldValue;
      vm.hasValue = Boolean(fieldValue);
    } else {
      if (fieldValue) {
        if (vm.fieldValue != fieldValue && !vm.hasValue) {
          vm.formSections[accIndex][sectionIndex].dataFilled = parseInt(vm.formSections[accIndex][sectionIndex].dataFilled) + 1;
          vm.formSections[accIndex][sectionIndex].remainingFields = parseInt(vm.formSections[accIndex][sectionIndex].remainingFields) - 1;
        }
      } else {
        if (!fieldValue && vm.hasValue) {
          vm.formSections[accIndex][sectionIndex].dataFilled = parseInt(vm.formSections[accIndex][sectionIndex].dataFilled) - 1;
          vm.formSections[accIndex][sectionIndex].remainingFields = parseInt(vm.formSections[accIndex][sectionIndex].remainingFields) + 1;
        }
      }
      validatorService.validateForm(vm, saveFreedomForm)();
    }
  }

  let isValidated = false;

  vm.trackInputForOtherFields = (field, accIndex, sectionIndex, fieldValue) => {
    if (fieldValue && !vm.fieldHasValue[accIndex][sectionIndex][field]) {
      vm.fieldHasValue[accIndex][sectionIndex][field] = true;
      vm.formSections[accIndex][sectionIndex].dataFilled = parseInt(vm.formSections[accIndex][sectionIndex].dataFilled) + 1;
      vm.formSections[accIndex][sectionIndex].remainingFields = parseInt(vm.formSections[accIndex][sectionIndex].remainingFields) - 1;
    }
    if (!fieldValue || !fieldValue.length) {
      vm.fieldHasValue[accIndex][sectionIndex][field] = false;
      vm.formSections[accIndex][sectionIndex].dataFilled = parseInt(vm.formSections[accIndex][sectionIndex].dataFilled) - 1;
      vm.formSections[accIndex][sectionIndex].remainingFields = parseInt(vm.formSections[accIndex][sectionIndex].remainingFields) + 1;
    }

    if (!isValidated) {
      isValidated = true;
    } else {
      validatorService.validateForm(vm, saveFreedomForm)();
    }
  }

  vm.trackAttachments = (fieldValue, accIndex, sectionIndex, field) => {
    if (fieldValue.length && !vm.fieldHasValue[accIndex][sectionIndex][field]) {
      vm.fieldHasValue[accIndex][sectionIndex][field] = true;
      vm.formSections[accIndex][sectionIndex].dataFilled = parseInt(vm.formSections[accIndex][sectionIndex].dataFilled) + 1;
      vm.formSections[accIndex][sectionIndex].remainingFields = parseInt(vm.formSections[accIndex][sectionIndex].remainingFields) - 1;
    }
  }

  imageService.initImageUpload(vm, 'form');

  vm.navigateToSection = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView();
  };

  function initFF() {
    vm.selectedFF = vm.selectedFF || {};
    vm.workOrderFreedomForm = vm.workOrderFreedomForm || [];
    angular.forEach(vm.workOrderFreedomForm, (form, i) => {
      vm.selectedFF[form.formTarget] = form;
    });

    sectionIndexing(vm.workOrderFreedomForm);
    FreedomFormService.initForUpdate(vm, vm.workOrderFreedomForm);
  }

  // get service detail
  vm.getForm = function() {
    dataServices.get({
        url: `${serverUrl.main}taskApi/survey/${vm.serviceId}/quotes/${vm.quoteId}/form/${vm.id}?`,
        // url: `${serverUrl.main}public/webSurveyApi/survey/${vm.serviceId}/quotes/${vm.quoteId}/form/${vm.id}?businessId=${$rootScope.accessData.client_id}`,
        addAccessData: false,
        noVerData: false
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          vm.workOrderFreedomForm = data.result;
          initFF();
        }
      });
  };
  vm.getForm();

  function signaturePanel(ev, signFor) {
    if (!$rootScope.hasWriteAccess) {
      return;
    }

    const type = signFor == 1 ? 'primary' : 'secondary';

    $mdDialog.show({
      templateUrl: signatureTemplate,
      controller: SignatureController,
      controllerAs: 'cd',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: false,
      locals: {
        vm: vm,
        type: type,
        imageData: vm.service.workOrderSign && vm.service.workOrderSign[type] || undefined
      }
    });
  }

  function SignatureController($scope, serverUrl, messages, Upload, $mdDialog, dataServices, vm, type, imageData) {

    "ngInject";

    let cd = this;

    cd.cancel = cancel;
    cd.save = save;
    cd.reset = reset;
    cd.parentVm = vm;

    function assignedData() {
      if (imageData && imageData.webUrl) {
        Upload.urlToBlob(imageData.webUrl).then(function(blob) {
          Upload.base64DataUrl(blob).then(function(base64) {
            cd.dataUrl = $scope.dataUrl = base64;
          });
        });
      }
    }

    assignedData();

    function save() {
      let signature = $scope.accept();

      if (!cd.dataUrl && signature.isEmpty) {
        messages.simpleToast('Signature is not available for update.');
        return;
      }

      dataServices.upload({
          url: serverUrl.main + 'mediaApi/upload/base64',
          data: {
            data: angular.toJson([{
              type: 'png',
              data: $scope.dataUrl
            }])
          }
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            let dataToSend = {
              serviceId: vm.serviceId,
              taskScheduleType: vm.service.taskScheduleType
            };

            if (signature.isEmpty) {
              dataToSend['remove'] = [type];
            } else {
              dataToSend[type] = data.result.success[0].fileId;
            }

            dataServices.patch({
              url: serverUrl.main + 'taskApi/service/quotes/' + vm.quoteId + '/workOrder/sign',
              data: {
                data: angular.toJson(dataToSend)
              }
            }).then(function(result) {
              vm.getForm();
              cd.cancel();
              messages.simpleToast('Signature Updated Successfully!');
            });
          }
        });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function reset() {
      $scope.dataUrl = angular.copy(cd.dataUrl);
    }
  }

  vm.downloadFF = (id) => {
    dataServices.get({
        url: serverUrl.main + 'taskApi/services/' + vm.quoteId + '/' + vm.serviceId + '/' + id + '/download?'
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          if (data.result && data.result.web_url) {
            $window.open(data.result.web_url, '_blank');
          } else {
            messages.simpleToast('Download failed!', 'danger');
          }
        }
      });
  };

  vm.saveFreedomForm = () => {
    vm.saveForm = true;
    saveFreedomForm();
  };

  vm.autoSaveFF = () => {
    saveFreedomForm(vm.getForm);
  };

  function saveFreedomForm(callback) {
    const data = [];

    FreedomFormService.beforeSave(vm, data).then(function(_data) {
      if (!_data) {
        return;
      }

      dataServices.post({
          url: `${serverUrl.main}taskApi/survey/${vm.serviceId}/quotes/${vm.quoteId}/form/${vm.id}?`,
          data: {
            data: angular.toJson(_data[0])
          },
          addAccessData: false,
          noVerData: false,
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200 && vm.saveForm) {
            vm.saveForm = false;
            messages.simpleToast('Updated Successfully.');
            vm.myForm.$setPristine();
            vm.getForm();
            $scope.$parent.vm.save = vm.save = undefined;
            // vm.isDisabledFF = true;
          }

          if (angular.isFunction(callback)) {
            callback();
          }
        });
    }, function(error) {
      console.log(error);
    });

  }

  vm.updateFormStatus = (index, status) => {
    vm.freedomForms[index].formStatus = status;
    vm.inputTracking = false;
    saveFreedomForm();
  }

  // Image preview on upload form screen
  vm.showImagePopUp = imageService.showImagePopUp;

  vm.totalFileUpload = [];
  let imgExtensions = {
    'jpeg': true,
    'jpg': true,
    'png': true,
    'gif': true
  };

  vm.isImage = function(file, key) {
    let fileName = file.fileOriginalName || file.fileName || file.name;
    let extension = fileName.slice(fileName.lastIndexOf('.') + 1);
    if (!extension) {
      return;
    } else {
      extension = extension.toLowerCase();
    }

    if (file.type && imgExtensions[extension]) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        file.src = reader.result;
      };
    }

    return Boolean(imgExtensions[extension]);
  };

  function urlToPromise(url) {
    return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  vm.downloadZip = (images) => {

    let zip = new JSZip();

    angular.forEach(images, function(value, key) {
      zip.file(value.fileOriginalName, urlToPromise(value.webUrl), {
        binary: true
      });
    });

    zip.generateAsync({
      type: "blob"
    }).then(function(content) {
      saveAs(content, "media.zip");
    });
  };
}

export default updateWorkorderController;