export const DOCUMENT_LIST_WO = {
  url: 'assocDocApi/assoc/docs/list',
  name: 'documentList',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 10,
      operator: "="
    }]
  },
  filterValue: ['serviceId'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const SERVICE_DETAIL_WO = {
  url: 'taskApi/services/:serviceId/quotes/:quoteId/calendar?type=detail&',
  method: 'GET',
  name: 'service',
  // data: {
  //   type: "detail"
  // },
  params: ['quoteId', 'serviceId']
};

export const documentDetail = {
  url: 'assocDocApi/assoc/doc/:docId',
  name: 'documentDetail',
  method: 'GET',
  params: ['docId']
};
