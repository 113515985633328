import ServiceListTemplate from '_quotes/quote-cycle/service/service/service-list.html';
import ServiceDetailTemplate from '_quotes/quote-cycle/service/service/service-detail.html';
import EditServiceTemplate from '_quotes/quote-cycle/service/service/editServices.html';
import estimateTemplate from '_quotes/quote-cycle/service/estimate/servicesEstimateDetail.html';
import updateEstimateTemplate from '_quotes/quote-cycle/service/estimate/updateEstimate.html';
// import estimateEditTemplate from '_quotes/quote-cycle/service/estimate/editEstimate.html';
import viewOrAddSOWTemplate from '_quotes/quote-cycle/service/estimate/view-add-sow/view-add-sow.html';
import EditRecurringServiceTemplate from '_quotes/quote-cycle/service/service/editRecurringService.html';
import ProductsTemplate from '_quotes/quote-cycle/service/products/product-list/products.html';
import SavePromotion from '_quotes/quote-cycle/service/service/saving/save-edit.html';
import ProductsDetailTemplate from '_quotes/quote-cycle/service/products/product-list/regular-product-details/regular-product-detail.html';
import AddBundleTemplate from '_quotes/quote-cycle/service/products/product-list/add-bundle/add-bundle.html';
import AddPackageTemplate from '_quotes/quote-cycle/service/products/product-list/add-package/add-package.html';
import PackageProductCustomizeTemplate from '_quotes/quote-cycle/service/products/product-list/add-package/package-product-customize/customize-product.html';

import CartDashboardTemplates from './products/JobOrderingCart.html';
import ProductFulfillmentTemplate from './products/ProductFulfillment/regularFulfillment/regularFulfillment.html';
import BundleFulfillmentTemplate from './products/ProductFulfillment/bundleFulfillment/bundleFulfillment.html';
import BundleProductFulfillmentTemplate from './products/ProductFulfillment/bundleProductFulfillment/bundleProductFulfillment.html';
import PackageFulfillmentTemplate from './products/ProductFulfillment/packageFulfillment/packageFulfillment.html';
import BundleDetailAllFulfillmentTemplate from './products/ProductFulfillment/bundle-detail-all-fulfill/bundleDetailAllFulfill.html';
import PackageDetailAllFulfillmentTemplate from './products/ProductFulfillment/package-detail-all-fulfill/packageDetailAllFulfill.html';
import CartDashboardTemplate from '_quotes/quote-cycle/service/products/cartDashboard.html';

import updateWorkorderCommonTemplate from '../../../../components/update-workorder/update-workorder-common.html';
import updateDocumentTemplate from '../../../../components/associate-documents/updateDocumentTemplate.html';
import documentDetailTemplate from '../../../../components/associate-documents/documentDetailTemplate.html';

import {
  SERVICE_LIST,
  SERVICE_DETAIL,
  ESTIMATE_DETAIL,
  ScopeOfWorks,
  LIST_SERVICE_RATES,
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_CART,
  BUNDLE_DETAIL,
  BUNDLE_DETAIL_CART,
  PACKAGE_DETAIL,
  PACKAGE_DETAIL_CART,
  PACKAGE_PRODUCT_DETAIL,
  REGULAR_LIST,
  suppliersList,
  policiesList,
  fetchAllPromotion,
  CART_LIST,
  CART_TOTAL,
  CART_LIST_MATERIAL,
  CART_TOTAL_MATERIAL,
  REGULAR_LIST_MATERIAL,
  PACKAGE_DETAIL_MATERIAL,
  PACKAGE_DETAIL_CART_MATERIAL,
  PACKAGE_PRODUCT_DETAIL_MATERIAL,
  PRODUCT_DETAIL_MATERIAL,
  PRODUCT_DETAIL_CART_MATERIAL,
  BUNDLE_DETAIL_MATERIAL,
  BUNDLE_DETAIL_CART_MATERIAL,
  RECURRING_PLANS,
  SAVING_SUMMARY,
  JOBS_PRODUCT_VIEW,
  JOBS_PRODUCT_CART_VIEW,
  JOBS_BUNDLE_PRODUCT_CART_VIEW,
  JOBS_BUNDLE_MATERIAL_CART_VIEW,
  JOBS_MATERIAL_VIEW,
  PACKAGE_PRODUCT_SELECTED_SKU_DETAIL,
  STOCK_VIEW_DATA_PRODUCT,
  STOCK_VIEW_DATA_MATERIAL,
  DELIVERY_USAGE_PRODUCT,
  DELIVERY_USAGE_MATERIAL,
  FULFILLMENT_TABS_COUNT_PRODUCT,
  FULFILLMENT_TABS_COUNT_MATERIAL,
  JOBS_PACKAGE_PRODUCT_CART_VIEW,
  JOBS_PACKAGE_MATERIAL_CART_VIEW,
} from '_quotes/quote-cycle/service/service.resolver.obj';

import {
  QUOTE_DETAIL,
  RECURRING_SERVICE_LIST,
  PREP_CYCLE_STATUS,
} from '_quotes/quote.resolve.obj';

import {
  DOCUMENT_LIST_WO,
  SERVICE_DETAIL_WO,
  documentDetail,
} from '../../../../components/update-workorder/wo.resolve.obj';

export default function loginRoute($urlRouterProvider, $stateProvider, quoteResolverProvider, resolverProvider, jobsServiceResolverProvider) {

  'ngInject';

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.jobs.services', resolver('jobs-service-list/:quoteId', ServiceListTemplate, 'ServiceListController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [SERVICE_LIST, PREP_CYCLE_STATUS, QUOTE_DETAIL]
    }, {
      cbac: 'job_services'
    }))

    .state('main.jobs.servicesDetail', resolver('jobs-service-detail/:quoteId/:serviceId', ServiceDetailTemplate, 'ServiceDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [SERVICE_DETAIL, QUOTE_DETAIL, RECURRING_PLANS]
    }, {
      cbac: 'job_services'
    }))

    .state('main.jobs.editServices', resolver('jobs-edit-service/:quoteId/:serviceId', EditServiceTemplate, 'EditServiceController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [SERVICE_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'job_services',
      isWrite: true
    }))

    .state('main.jobs.addServices', resolver('jobs-add-service/:quoteId', EditServiceTemplate, 'EditServiceController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, SERVICE_DETAIL.name]
    }, {
      cbac: 'job_services',
      isWrite: true
    }))

    .state('main.jobs.servicesEstimateDetail', resolver('jobs-estimate-detail/:quoteId/:serviceId', estimateTemplate, 'EstimateDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [ESTIMATE_DETAIL, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_services'
    }))

    .state('main.jobs.updateServicesEstimates', resolver('job-update-estimate/:quoteId/:serviceId?list', updateEstimateTemplate, 'UpdateEstimateController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [ESTIMATE_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'job_services',
      isWrite: true
    }))

    .state('main.jobs.updateServicesEstimatesSow', resolver('job-update-estimate-sow/:quoteId/:serviceId/:regionId/:locationId', viewOrAddSOWTemplate, 'ViewOrAddSOWController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [ScopeOfWorks, ESTIMATE_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'job_services',
      isWrite: true
    }))

    .state('main.jobs.addRecurringServices', resolver('jobs-add-rec-service/:quoteId', EditRecurringServiceTemplate, 'EditRecurringService', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, SERVICE_DETAIL.name]
    }, {
      cbac: 'job_services',
      isWrite: true
    }))

    .state('main.jobs.editRecurringServices', resolver('jobs-edit-rec-service/:quoteId/:serviceId', EditRecurringServiceTemplate, 'EditRecurringService', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_services',
      isWrite: true
    }))

    .state('main.jobs.servicesProductsAdd', resolver('jobs-products/:quoteId/:locationId/:serviceId/', ProductsTemplate, 'ProductsController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [QUOTE_DETAIL, REGULAR_LIST, suppliersList, policiesList, fetchAllPromotion, CART_TOTAL, CART_LIST, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addProductDetail', resolver('jobs-products-add/:quoteId/:serviceId/:locationId/:productId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule, ],
      preData: [PRODUCT_DETAIL, SERVICE_DETAIL, QUOTE_DETAIL, PRODUCT_DETAIL_CART.name],
      loadOnDemand: [resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie, ]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addProductDetailCart', resolver('jobs-products-detail-cart/:quoteId/:serviceId/:locationId/:productId/:lineItemId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL, SERVICE_DETAIL, QUOTE_DETAIL, PRODUCT_DETAIL_CART]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addBundleDetail', resolver('jobs-bundle-detail/:quoteId/:serviceId/:locationId/:catalogId/:bundleId/?childCatalogId', AddBundleTemplate, 'AddBundleController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL, BUNDLE_DETAIL_CART.name, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addBundleProductDetailCart', resolver('jobs-bundle-detail-cart/:quoteId/:serviceId/:locationId/:bundleId/:lineItemId/?simpleFulfillment', BundleFulfillmentTemplate, 'BundleFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL, BUNDLE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    // START : Package route
    .state('main.jobs.addPackageDetail', resolver('jobs-package-detail/:quoteId/:serviceId/:locationId/:packageId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART.name, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addPackageDetailCart', resolver('jobs-package-detail-cart/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addPackageProductCustomize', resolver('jobs-package-product-detail/:quoteId/:serviceId/:locationId/:packageId/:productId', PackageProductCustomizeTemplate, 'PackageProductCustomizeController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_PRODUCT_DETAIL, QUOTE_DETAIL, PACKAGE_PRODUCT_SELECTED_SKU_DETAIL.name]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.addPackageProductSelectedCustomize', resolver('jobs-package-product-selected-sku-detail/:quoteId/:serviceId/:locationId/:packageId/:productId/:catalogId/:lineItemId', PackageProductCustomizeTemplate, 'PackageProductCustomizeController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_PRODUCT_DETAIL, QUOTE_DETAIL, PACKAGE_PRODUCT_SELECTED_SKU_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))
    // END : Package route

    .state('main.jobs.editServicesPromotion', resolver('jobs-save-promotion/:quoteId/:serviceId', SavePromotion, 'SavePromotionController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [SERVICE_DETAIL, ESTIMATE_DETAIL, SAVING_SUMMARY, QUOTE_DETAIL]
    }, {
      cbac: 'job_service_promotions'
    }))

    .state('main.jobs.servicesWorkorder', resolver('work-order/:quoteId/:serviceId?tabIndex', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadJsZip, resolverProvider.loadJsZipUtil, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'job_service_work_order'
    }))

    .state('main.jobs.servicesWorkorderDocumentDetail', resolver('wo-document/:quoteId/:serviceId/detail/:docId', documentDetailTemplate, 'WoDocumentDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [documentDetail]
    }, {
      cbac: 'job_service_work_order_documents'
    }))

    .state('main.jobs.addWoDocument', resolver('wo-document/:quoteId/:serviceId/add-document', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'job_service_work_order_documents',
      isWrite: true
    }))

    .state('main.jobs.updateWoDocument', resolver('wo-document/:quoteId/:serviceId/update-document/:docId', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [documentDetail]
    }, {
      cbac: 'job_service_work_order_documents',
      isWrite: true
    }))

    .state('main.jobs.servicesMaterialsAdd', resolver('material/:quoteId/:locationId/:serviceId/', ProductsTemplate, 'ProductsController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [QUOTE_DETAIL, REGULAR_LIST_MATERIAL, suppliersList, policiesList, fetchAllPromotion, CART_TOTAL_MATERIAL, CART_LIST_MATERIAL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addPackageDetailMaterial', resolver('package-detail-material/:quoteId/:serviceId/:locationId/:packageId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL_MATERIAL, PACKAGE_DETAIL_CART_MATERIAL.name, QUOTE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addPackageDetailCartMaterial', resolver('package-detail-cart-material/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL_MATERIAL, PACKAGE_DETAIL_CART_MATERIAL, QUOTE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addPackageProductCustomizeMaterial', resolver('package-material-detail/:quoteId/:serviceId/:locationId/:packageId/:productId/:catalogId/:lineItemId', PackageProductCustomizeTemplate, 'PackageProductCustomizeController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_PRODUCT_DETAIL_MATERIAL, QUOTE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addProductDetailMaterial', resolver('material/:quoteId/:serviceId/:locationId/:productId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL_MATERIAL, QUOTE_DETAIL, PRODUCT_DETAIL_CART.name, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addProductDetailCartMaterial', resolver('material-detail-cart/:quoteId/:serviceId/:locationId/:productId/:lineItemId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL_MATERIAL, QUOTE_DETAIL, PRODUCT_DETAIL_CART_MATERIAL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addBundleDetailMaterial', resolver('bundle-material-detail/:quoteId/:serviceId/:locationId/:catalogId/:bundleId/?childCatalogId', AddBundleTemplate, 'AddBundleController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL_MATERIAL, BUNDLE_DETAIL_CART_MATERIAL.name, QUOTE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.addBundleProductDetailCartMaterial', resolver('bundle-detail-cart/:quoteId/:serviceId/:locationId/:bundleId/:lineItemId', BundleFulfillmentTemplate, 'BundleFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL_MATERIAL, BUNDLE_DETAIL_CART_MATERIAL, QUOTE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.servicesProductDashboard', resolver('job-cart-product-dashboard/:quoteId/:locationId/:serviceId/', CartDashboardTemplates, 'JobOrderingCartController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_PRODUCT_VIEW, CART_TOTAL, STOCK_VIEW_DATA_PRODUCT, DELIVERY_USAGE_PRODUCT, QUOTE_DETAIL, FULFILLMENT_TABS_COUNT_PRODUCT, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products'
    }))

    .state('main.jobs.servicesMaterialDashboard', resolver('job-cart-material-dashboard/:quoteId/:locationId/:serviceId/', CartDashboardTemplates, 'JobOrderingCartController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_MATERIAL_VIEW, CART_TOTAL_MATERIAL, STOCK_VIEW_DATA_MATERIAL, DELIVERY_USAGE_MATERIAL, FULFILLMENT_TABS_COUNT_MATERIAL, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials'
    }))

    .state('main.jobs.servicesProductDashboardTagging', resolver('job-cart-dashboard/:quoteId/:locationId/:serviceId/', CartDashboardTemplate, 'CartDashboardController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [CART_LIST, CART_TOTAL, QUOTE_DETAIL, STOCK_VIEW_DATA_PRODUCT]
    }, {
      cbac: 'job_service_products'
    }))

    .state('main.jobs.servicesMaterialDashboardTagging', resolver('job-cart-dashboard-material/:quoteId/:locationId/:serviceId/', CartDashboardTemplate, 'CartDashboardController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [CART_LIST_MATERIAL, CART_TOTAL_MATERIAL, QUOTE_DETAIL, STOCK_VIEW_DATA_MATERIAL]
    }, {
      cbac: 'job_service_materials'
    }))

    .state('main.jobs.servicesProductOrderingCartAdd', resolver('product-ordering-cart/:quoteId/:serviceId/:productId/:itemId/:lineItemId', ProductFulfillmentTemplate, 'RegularFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.servicesMaterialOrderingCartAdd', resolver('material-ordering-cart/:quoteId/:serviceId/:productId/:itemId/:lineItemId', ProductFulfillmentTemplate, 'RegularFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.servicesProductBundleOrderingCartAdd', resolver('product-bundle-ordering-cart/:quoteId/:serviceId/:slugId/:productId/:itemId/:lineItemId', BundleProductFulfillmentTemplate, 'BundleProductFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_BUNDLE_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.servicesMaterialBundleOrderingCart', resolver('material-bundle-ordering-cart/:quoteId/:serviceId/:slugId/:productId/:itemId/:lineItemId', BundleProductFulfillmentTemplate, 'BundleProductFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_BUNDLE_MATERIAL_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.servicesProductPackageOrderingCart', resolver('product-package-ordering-cart/:quoteId/:serviceId/:slugId/:catalogId/:productId/:itemId/:lineItemId', PackageFulfillmentTemplate, 'PackageFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_PACKAGE_PRODUCT_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.servicesMaterialPackageOrderingCart', resolver('material-package-ordering-cart/:quoteId/:serviceId/:slugId/:catalogId/:productId/:itemId/:lineItemId', PackageFulfillmentTemplate, 'PackageFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [JOBS_PACKAGE_MATERIAL_CART_VIEW, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.servicesProductBundleDetailAllFulfill', resolver('jobs-product-bundle-detail-all-fulfill/:quoteId/:serviceId/:locationId/:bundleId/:lineItemId', BundleDetailAllFulfillmentTemplate, 'BundleDetailAllFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL, BUNDLE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }))

    .state('main.jobs.servicesMaterialBundleDetailAllFulfill', resolver('jobs-material-bundle-detail-all-fulfill/:quoteId/:serviceId/:locationId/:bundleId/:lineItemId', BundleDetailAllFulfillmentTemplate, 'BundleDetailAllFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL, BUNDLE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.servicesMaterialPackageDetailAllFulfill', resolver('jobs-material-package-detail-all-fulfill/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', PackageDetailAllFulfillmentTemplate, 'PackageDetailAllFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_materials',
      isWrite: true
    }))

    .state('main.jobs.servicesProductPackageDetailAllFulfill', resolver('jobs-product-package-detail-all-fulfill/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', PackageDetailAllFulfillmentTemplate, 'PackageDetailAllFulfillmentController', {
      lazyModule: [jobsServiceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'job_service_products',
      isWrite: true
    }));

}