import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';

import addDashboardTemplate from '../../components/popup-view/add-dashboard.html';
import confirmDialogTemplate from '../../components/popup-view/confirm-dialog.html';
import updateKBISettingTemplate from '../../components/popup-view/KBIBlockSetting.html';
import addKBIBlockTemplate from '../../components/popup-view/addKBIBlockTemplate.html';
import addReportBlockTemplate from '../../components/popup-view/addReportBlockTemplate.html';
export default function(app) {
  app.factory('DashboardService', dashboardService);

  function dashboardService($mdSidenav, $sce, sharedDataService, $state, dataServices, serverUrl, messages, dateFactory, $mdDialog, GraphService, $filter) {

    "ngInject";

    return {
      init: init,
      initReportBlock: initReportBlock,
      addKBI: addKBI,
      addKBIBlock: addKBIBlock,
      addReportBlock: addReportBlock,
      createTimeReference: createTimeReference,
      deleteKBIBlock: deleteKBIBlock,
      updateKBISetting: updateKBISetting,
      fetchAssignedDashboard: fetchAssignedDashboard,
      loadKBIValue: loadKBIValue,
      positionUpdateConfirmation: positionUpdateConfirmation,
      fetchDashboardList: fetchDashboardList,
      addDashboard: addDashboard,
      deleteDashboard: deleteDashboard,
      dashboardSequenceChange: dashboardSequenceChange,
      blockByCatagory: blockByCatagory,
      addReportBlockToDashboard: addReportBlockToDashboard,
      blockByCatagoryIndicator: blockByCatagoryIndicator,
      isKBIBlockExist: isKBIBlockExist,
      addKBIBlockToDashboard: addKBIBlockToDashboard,
      fetchBlockDetail: fetchBlockDetail,
      KBIBlockCount: KBIBlockCount,
      reportBlockCount: reportBlockCount,
      shareDataStore: shareDataStore,
      sumValue: sumValue,
      filter: filter,
      objLengthValueExist: objLengthValueExist,
      getStringLength: getStringLength,
      handleMobileEvents: handleMobileEvents,
      confirmKBISettings: confirmKBISettings,
      formatDate: formatDate,
      openUpdateKbI: openUpdateKbI,
      fetchListData: fetchListData
      
    };

    function getStringLength(text) {
      return $filter('removeTags')(text).length;
    }

    function objLengthValueExist(obj) {
      let exist = false;
      forEach(obj, function(val, key) {
        if (val.length) {
          exist = true;
        }
      });
      return exist;
    }

    function filter(vm, val) {
      // console.log($filter(vm.statusType[val.key])(val[val.key]));
      if (!val.category) {
        return $filter(vm.statusType[val.key])(val[val.key]);
      }
      return $filter(vm.statusType[val.key])(val[val.key], val.category);
    }

    function sumValue(arr) {
      // console.log(arr);
      let val = 0;
      for (let i = 0; i < arr.length; i++) {
        val += arr[i].hover;
      }
      return val;
    }

    function initReportBlock(vm) {
      vm.reportCategory = [{
          name: 'Interaction',
          category: 7,
          icon: 'cat-intraction'
        },
        {
          name: 'Productivity',
          category: 8,
          icon: 'cat-productivity'
        },
        {
          name: 'Progress',
          category: 6,
          icon: 'cat-progress'
        },
        {
          name: 'Regional',
          category: 5,
          icon: 'cat-regional'
        },
        {
          name: 'Relationship',
          category: 4,
          icon: 'cat-relationship'
        },
        {
          name: 'Revenue',
          category: 2,
          icon: 'cat-revenue'
        },
        {
          name: 'Sales',
          category: 1,
          icon: 'cat-sales'
        },
        {
          name: 'Services',
          category: 3,
          icon: 'cat-service'
        },
        {
          name: 'Support',
          category: 9,
          icon: 'cat-support'
        }
      ];

      vm.allChartType = {
        "Bar Chart": 3,
        "List": 2,
        "Pie Chart": 1,
        "Stack Bar Chart": 6,
        "Trend Chart": 4,
        "Twin Pie Chart": 7,
        "Grouped Multi Bar Chart": 8
      };

      vm.selectedCategory = vm.reportCategory[0].category.toString();

      vm.isBlockAssigned = function(vm, bId) {
        if(vm.blockIdsString) {
          return vm.blockIdsString.includes(bId.toString());
        }
      };
    }

    function handleMobileEvents(scope) {
      // START : For hiding tooltip on mobile devices
      $('md-content').on('scroll', () => {
        d3.selectAll('.nvtooltip').style('opacity', '0');
      });

      d3.select(window).on('mouseout', () => {
        d3.selectAll('.nvtooltip').style('opacity', '0');
      });

      scope.$on('$destroy', function() {
        $('md-content').off('scroll');
        d3.selectAll('.nvtooltip').remove();
      });
      // END : For hiding tooltip on mobile devices
    }

    //

    function reInitiateSeeding(vm) {
      vm.isSeedingFailed = true;
      vm.seedingProcessStmt = undefined;
    }

    function saveDashboardSeedSuccessFlag(vm, callback) {
      dataServices.put({
          url: serverUrl.main + 'dashboardApi/dashboards/seed',
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.isSeeded = true;
            // show dashboard
            if (angular.isFunction(callback)) {
              callback();
            }
          } else {
            messages.simpleToast('Dashboard initiation failied. Contact administrator!', 'danger');
          }
        }, function(error) {
          messages.simpleToast('Dashboard initiation failied. Contact administrator!', 'danger');
        });
    }

    // init dashboards of all modules
    async function seedDashBoards(vm, callback) {
      let modules = [{
        name: 'Quotes',
        val: 'quote'
      }, {
        name: 'Jobs',
        val: 'job'
      }, {
        name: 'Opportunities',
        val: 'opportunity'
      }, {
        name: 'Customers',
        val: 'customer'
      }, {
        name: 'Main',
        val: 'main_dashboard'
      }];

      let count = 0,
        len = modules.length;

      for (let module of modules) {
        vm.seedingProcessStmt = `Initiating ${module.name} Dashboard...`;

        await dataServices.post({
            url: serverUrl.main + 'dashboardApi/dashboard/default/' + module.val,
            noVerData: true,
            spinner: false
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              count++;
              if (count === len) {
                saveDashboardSeedSuccessFlag(vm, callback);
              }
            } else {
              // clear modules array to stop for of
              console.log('dashboard seed failed');
              modules = [];
              reInitiateSeeding(vm);
            }
          }, function(error) {
            // clear modules array to stop for of
            console.log('dashboard seed failed');
            modules = [];
            reInitiateSeeding(vm);
          });
      }
    }

    function initiateDashboards(vm, callback) {
      // clear dashboard before creating
      vm.seedingProcessStmt = 'Initiating Dashboards...';
      dataServices.delete({
          url: serverUrl.main + 'dashboardApi/dashboards/clear',
          noVerData: true,
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            seedDashBoards(vm, callback);
          } else {
            console.log('dashboard clear failed');
            reInitiateSeeding(vm);
          }
        }, function(error) {
          console.log('dashboard clear failed');
          reInitiateSeeding(vm);
        });
    }

    //

    function initData(vm, scope) {
      GraphService.initD3();
      vm.backgroundColor = {
        20: '#f2eff6',
        28: '#fde8e7',
        10: '#eef7ed',
        45: '#eef7ed',
        99: '#eef7ed'
        // 99: '#fff9e6'
      };
      vm.allModuleName = {
        10: 'customer',
        45: 'opportunity',
        20: 'quotes',
        28: 'jobs'
      };

      vm.statusType = {
        'status': 'cardStatus',
        'qstatus': 'quotesStatus',
        'jstatus': 'quotesStatus',
        'leadSource': 'sourceOfLeadFilter',
        'reason': 'reasonName',
        'serviceType': 'serviceTypeValue',
        'serviceStatus': 'serviceStatus'
      };

      vm.isKBIValueLoaded = {};
      vm.isLoaded = {};
      vm.list = {};
      vm.loader = {};
      vm.reportIcon = {
        1: 'cat-sales',
        2: 'cat-revenue',
        3: 'cat-service',
        4: 'cat-relationship',
        5: 'cat-regional',
        6: 'cat-progress',
        7: 'cat-intraction',
        8: 'cat-productivity',
        9: 'cat-support'
      };

      const daysInYear = 365;

      vm.returnDay = {
        1: 7,
        2: 14,
        3: 30,
        4: 60,
        5: 90,
        6: 180,
        7: 270,
        8: daysInYear,
        10: daysInYear * 2,
        11: daysInYear * 5,
        12: daysInYear * 10,
        13: daysInYear * 15,
        14: daysInYear * 20
      };

      vm.timeRef = {
        1: 'One Week',
        2: 'Two Week',
        3: 'One Month',
        4: 'Two Month',
        5: 'Three Month',
        6: 'Six Month',
        7: 'Nine Month',
        8: 'One Year',
        9: 'Custom Time',
        10: 'Two Year',
        11: 'Five Year',
        12: 'Ten Year',
        13: 'Fifteen Year',
        14: 'Twenty Year'
      };

      vm.chartType = {
        1: GraphService.renderPieChart,
        3: GraphService.renderBarChart,
        4: GraphService.renderLineChart,
        6: GraphService.renderStackBarChart,
        7: GraphService.renderPieChart,
        8: GraphService.renderMultiBarChart
      };

      handleMobileEvents(scope);
    }

    function init(vm, scope, callback) {
      dataServices.get({
          url: serverUrl.main + 'dashboardApi/dashboards/seed?',
          spinner: false
        })
        .then(function(response) {
          vm.isSeedLoaded = true;

          let data = response.data;
          if (data && data.response_code == 200) {
            vm.isSeeded = data.result;
            initData(vm, scope, callback);
            if (vm.isSeeded && angular.isFunction(callback)) {
              callback();
            } else {
              vm.initiateDashboards = () => initiateDashboards(vm, callback);
            }
          } else {
            messages.simpleToast('Dashboard initiation failied. Contact administrator!', 'danger');
          }
        }, function(error) {
          vm.isSeedLoaded = true;
          messages.simpleToast('Dashboard initiation failied. Contact administrator!', 'danger');
        });
    }

    function fetchAssignedDashboard(vm, moduleName) {

      dataServices.get({
        url: serverUrl.main + 'dashboardApi/dashboard/resource/list?moduleName=' + moduleName + '&',
        spinner: false
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.assignedDashboard = response.data.result;
          vm.assignedDashboardBackUp = angular.copy(vm.assignedDashboard);
          vm.currentDashboardIndex = sharedDataService.get('currentDashboardIndex') || 0;
          console.log("called")

          console.log(sharedDataService.get('currentDashboardIndex'), sharedDataService.get('dashboardId'))
          if (vm.assignedDashboard && vm.assignedDashboard[vm.currentDashboardIndex].id != sharedDataService.get('dashboardId')) {
            // START : we are reseting currentDashboardIndex to zero because this dashboard is deleted successfully.
            vm.currentDashboardIndex = 0;
            // END : we are reseting currentDashboardIndex to zero because this dashboard is deleted successfully.
          }
          console.log(sharedDataService.get('dashboardId'))
          if (moduleName == 45) {
            vm.boardId = sharedDataService.get('cardBoardId');
          } else {
            vm.boardId = undefined;
          }

          vm.salesAgentId = sharedDataService.get('salesAgentId');
          vm.locationId = sharedDataService.get('locationId');
          console.log(vm.locationId);
          // sharedDataService.delete('currentDashboardIndex');
          // sharedDataService.delete('dashboardId');
          // sharedDataService.delete('cardBoardId');
          // sharedDataService.delete('salesAgentId');
          // sharedDataService.delete('locationId');
          // return
          if (moduleName == 45) {
            vm.oppBoardListByLocation();
          } else {
            vm.assignedData();
          }
        }
      });
    }

    function shareDataStore(vm) {
      sharedDataService.set('currentDashboardIndex', vm.currentDashboardIndex);
      sharedDataService.set('dashboardId', vm.assignedDashboard[vm.currentDashboardIndex].id);
      sharedDataService.set('cardBoardId', vm.boardId);
      sharedDataService.set('salesAgentId', vm.salesAgentId);
      sharedDataService.set('locationId', vm.locationId);
    }

    function addKBI(vm, id) {

      shareDataStore(vm);
      sharedDataService.set('dashboardName', vm.assignedDashboard[vm.currentDashboardIndex].name);

      $state.go(vm.stateRedirect, {
        dashboardId: vm.assignedDashboard[vm.currentDashboardIndex].id,
        KBIBlockId: id
      });
    }

    function addKBIBlock(vm, moduleName, dashboardId = false, isDashboardList = false) {
      $mdDialog.show({
        controller: addKBIBlockController,
        controllerAs: 'cd',
        templateUrl: addKBIBlockTemplate,
        parent: angular.element(document.body),
        locals: {
          vm: vm,
          moduleName: moduleName,
          dashboardId: dashboardId,
          isDashboardList: isDashboardList
        }
      });
    }

    function addKBIBlockController($scope, $mdDialog, serverUrl, dataServices, messages, vm, moduleName, dashboardId, isDashboardList) {

      'ngInject';

      let cd = this;

      cd.cancel = cancel;
      cd.confirm = confirm;

      function cancel() {
        $mdDialog.cancel();
      }

      function confirm() {
        let data = {
          moduleName: moduleName,
          category: 10,
          chartType: 5,
          blockName: "New KBI",
          alias: "New KBI",
          blockId: 1,
          description: "TO BE GIVEN",
          reports: []
        };

        if (vm.showForState != 'customer') {
          data['settings'] = {
            isArchived: false,
            void: false,
            cancel: false,
            timeRef: 8
          }
        } else {
          data['settings'] = {
            status: [1, 2, 66, 44, 5],
            timeRef: 8
          }
        }

        dataServices.post({
          url: serverUrl.main + 'blocksApi/blocks/add/report/' + (dashboardId ? dashboardId : vm.assignedDashboard[vm.currentDashboardIndex].id),
          data: {
            data: angular.toJson(data)
          }
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            cd.cancel();

            if (!isDashboardList) {
              // $('.auto-scroll').animate({
              //   scrollTop : ($('.auto-scroll').get(0).scrollHeight + 309)
              // }, 'fast');
              shareDataStore(vm);
              fetchAssignedDashboard(vm, vm.moduleName);
              messages.simpleToast('New KBI Block added scroll down to see it !');
            } else {
              vm.fetchDashboardList(vm);
            }
          }
        });
      }

    }

    function addReportBlock(vm, dashboard = false) {
      console.log(vm)
      $mdDialog.show({
        controller: addReportBlockController,
        controllerAs: 'cd',
        templateUrl: addReportBlockTemplate,
        parent: angular.element(document.body),
        locals: {
          vm: vm,
          dashboard: dashboard
        }
      });
    }

    function addReportBlockController($scope, $mdDialog, $state, vm, dashboard, sharedDataService) {

      'ngInject';

      let cd = this;

      cd.cancel = cancel;
      cd.confirm = confirm;

      function cancel() {
        $mdDialog.cancel();
      }
      console.log(vm)
      let concatenatedApiIds = vm.assignedDashboard[vm.currentDashboardIndex].blocks
        .map(block =>
          block.reports.map(report => report.apiId)
        )
        .flat()
        .join(',');

      if (concatenatedApiIds.length) {
        console.log('Concatenated API IDs:', concatenatedApiIds);
      }

      console.log(concatenatedApiIds)

      function confirm() {
        cd.cancel();
        if (!dashboard) {
          shareDataStore(vm);
        }

        sharedDataService.set('dashboardName', (dashboard ? dashboard.name : vm.assignedDashboard[vm.currentDashboardIndex].name));

        $state.go(vm.reportBlockState, {
          dashboardId: (dashboard ? dashboard.id : vm.assignedDashboard[vm.currentDashboardIndex].id),
        });
      }
    }
    function createSingleTimeReference(vm, timeRef, dateRangeType) {
      let date = [],
        toDate, fromDate;

      console.log(timeRef);

      if (dateRangeType == "past") {
        fromDate = new Date(new Date().setDate(new Date().getDate() - vm.returnDay[timeRef])).setHours(0, 0, 0, 0);
        toDate = new Date().setHours(23, 59, 59, 999);
      }
      // else if (dateRangeType === 'future') {
      //   fromDate = new Date().setHours(0, 0, 0, 0);
      //   toDate = new Date(new Date().setDate(new Date().getDate() + vm.returnDay[timeRef])).setHours(23, 59, 59, 999);
      // }
      else if (dateRangeType == "future") {
        fromDate = new Date().setHours(0, 0, 0, 0);
        toDate = new Date(new Date().setDate(new Date().getDate() + vm.returnDay[timeRef])).setHours(23, 59, 59, 999);
      }

      date.push(dateFactory.toUTCTimeZone(new Date(fromDate)));
      date.push(dateFactory.toUTCTimeZone(new Date(toDate)));
      return date;
    }

    function createTimeReference(vm, timeRef, dateRangeType) {
      let date = [],
        toDate, fromDate;

      console.log(timeRef);

      if (dateRangeType === 'past') {
        fromDate = new Date(new Date().setDate(new Date().getDate() - vm.returnDay[timeRef])).setHours(0, 0, 0, 0);
        toDate = new Date().setHours(23, 59, 59, 999);
      }
      else if (dateRangeType === 'future') {
        fromDate = new Date().setHours(0, 0, 0, 0);
        toDate = new Date(new Date().setDate(new Date().getDate() + vm.returnDay[timeRef])).setHours(23, 59, 59, 999);
      }
      else if (dateRangeType === 'lifetime') {
        fromDate = new Date(new Date().setDate(new Date().getDate() - vm.returnDay[timeRef])).setHours(0, 0, 0, 0);
        toDate = new Date(new Date().setDate(new Date().getDate() + vm.returnDay[timeRef])).setHours(23, 59, 59, 999);
      }

      date.push(dateFactory.toUTCTimeZone(new Date(fromDate)));
      date.push(dateFactory.toUTCTimeZone(new Date(toDate)));
      return date;
    }

    function deleteKBIBlock(vm, ev, id) {

      shareDataStore(vm);
      $mdDialog.show({
        controller: deleteKBIBlockController,
        controllerAs: 'cd',
        templateUrl: confirmDialogTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          id: id,
          parentVm: vm,
          parentScopeVm: vm.parentVm
        }
      });
    }

    function deleteKBIBlockController($scope, $mdDialog, serverUrl, dataServices, id, parentVm, parentScopeVm) {

      'ngInject';

      let cd = this;

      cd.dashboardId = parentVm.assignedDashboard[parentVm.currentDashboardIndex].id;
      cd.question = 'Are you sure you want to delete Block ?';
      // cd.description = ;
      cd.confirm = confirm;
      cd.cancel = cancel;

      function confirm() {
        dataServices.delete({
          url: serverUrl.main + 'blocksApi/blocks/delete/' + cd.dashboardId + '/' + id + '?'
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            cd.cancel();
            parentScopeVm.draggableOff();

            fetchAssignedDashboard(parentVm, parentVm.moduleName);
            messages.simpleToast("Block deleted successfully ! ");
          }
        });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }

    function openUpdateKbI(vm, block) {
      vm.blockBackup = angular.copy(block);
      vm.blockBackup.noteIds.length ? vm.noteId = vm.blockBackup.noteIds[0] : vm.noteId = null;
      vm.showCost = (((vm.state == 'jobs' || vm.state == 'quotes') || (vm.allModuleName[block.moduleName] == 'jobs' || vm.allModuleName[block.moduleName] == 'quotes')) && (vm.blockBackup.listType == 1 || vm.blockBackup.listType == 2 || vm.blockBackup.listType == 3 || vm.blockBackup.listType == 6 || vm.blockBackup.listType == 7 || vm.blockBackup.listType == 8 || vm.blockBackup.listType == 9 || vm.blockBackup.listType == 5 || vm.blockBackup.listType == 11)) || ((vm.state == 'jobs' || vm.allModuleName[vm.blockBackup.moduleName] == 'jobs') &&  vm.blockBackup.listType == 10)
      vm.notes = {};
      shareDataStore(vm);
      console.log(block)
      vm.allChartName = {
        3: "Bar Chart",
        2: "List",
        1: "Pie Chart",
        6: "Stack Bar Chart",
        4: "Trend Chart",
        7:"Twin Pie Chart",
        8:"Grouped Multi Bar Chart",
        5: "KBI"
      };

      vm.reportType = {
        1: 'sales',
        2: 'revenue',
        3: 'services',
        4: 'relationship',
        5: 'regional',
        6: 'progress',
        7: 'interaction',
        8: 'productivity',
        9: 'support',
      }
      if(block.noteIds.length) {
        getNotesDetails();
      } 
      $mdSidenav("updateKBI").open();
      delete vm.blockBackup.ver;
      delete vm.blockBackup.updatedBy;
      vm.confirm = confirm;
      vm.allListLimit = ['10', '15', '20'];
      vm.dashboardId = vm.assignedDashboard[vm.currentDashboardIndex].id;
      vm.settings = angular.copy(vm.blockBackup.settings);
      if (vm.settings.timeRef == 9) {
        vm.visibleFrom = new Date(vm.settings.fromDate * 1000);
        vm.visibleTo = new Date(vm.settings.toDate * 1000);
      }
      // if (cd.parentVm.moduleName == 28 || cd.parentVm.moduleName == 20) {
      //   if(cd.settings.futureDated == null) {
      //     cd.settings.futureDated = true;
      //   }
      // }

      //labels code start
      vm.selectedPostLabels = angular.copy(vm.blockBackup.labelInfo);
      vm.selectedLabels = {};
      vm.selectedLabelsCopy = {};
      vm.labels = [];
      // vm.blockBackup.labels = [];
     vm.openAssignLabels = () => {
      if (vm.blockBackup) {
        vm.editingCon = vm.blockBackup;
        vm.editingLabelsCon = vm.editingCon;
        vm.selectedLabelsCopy = {};
        console.log(vm.selectedPostLabels)
        angular.forEach(vm.blockBackup.labelInfo, (label, i) => {
          vm.selectedLabelsCopy[label.id] = label;
        });
      } 
      getAssignedLabels();
      $mdSidenav("assignBoardLabels").open();
     }

     vm.closeAssignLabels = () => {
      $mdSidenav('assignBoardLabels').close();
    };

    vm.updateSelectedLabels = (item) => {
      if (vm.selectedLabelsCopy[item.id]) {
        delete vm.selectedLabelsCopy[item.id];
      } else {
        if (Object.keys(vm.selectedLabelsCopy).length >= 3) {
          messages.simpleToast('YOU_CAN_SELECT_MAX_THREE_LABELS.');
          return;
        }
        vm.selectedLabelsCopy[item.id] = item;
      }
    }

     vm.getAssignedLabels = getAssignedLabels;
    
     function getAssignedLabels() {
       vm.isLoadingLabels = true;
       dataServices.get({
         url: serverUrl.main + 'reportLabelsApi/report/label?',
         method: 'GET',
         spinnerName: 'notes-labels-sidebar-spinner'
       }).then(response => {
         vm.isLoadingLabels = false;
         if (response && response.data) {
           vm.labels = response.data.result;
         }
       }, error => {
         vm.isLoadingLabels = false;
       });
     }

    //  vm.saveLabels = saveLabels;

    //  function saveLabels() {
    //    const conversation = dataServices.toSave(vm.editingCon, ['labels']);
    //    saveConversationFinal(vm.editingCon, conversation);
    //    vm.closeAssignLabels();
    //  }
   
     vm.savePostLabels = () => {
       vm.selectedPostLabelsCopy = vm.selectedPostLabels = angular.copy(vm.selectedLabelsCopy);
       vm.blockBackup.labels = Object.keys(vm.selectedLabelsCopy);
       if (vm.blockBackup.labels.length != 0) {
         vm.selectedPostLabel = vm.selectedPostLabelsCopy[vm.blockBackup.labels[0]];
         // vm.labelsIndex++;
       }
       vm.closeAssignLabels();
     }
   
     vm.cancelLabels = cancelLabels;
   
     function cancelLabels() {
       vm.closeAssignLabels();
     }
   

      //labels end

      //create labels code start
      vm.manageBoardLabels = manageBoardLabels;

      function manageBoardLabels() {
        $mdSidenav('boardLabels').toggle();
        getLabels();
      }
      vm.closeBoardLabelsSidebar = () => {
        $mdSidenav('boardLabels').close();
      };
      
      function getLabels() {
        dataServices.get({
          url: serverUrl.main + 'reportLabelsApi/report/label?',
          method: 'GET',
          spinnerName: 'dashboard-labels-sidebar-spinner'
        }).then(response => {
          if (response && response.data) {
            console.log(response);
            prepareLabels(response.data.result);
          }
        });
      }
      
      function prepareLabels(labels) {
        vm.labels = labels;
        vm.noOfLabels = labels.length;
        angular.forEach(labels, function(value) {
          if (!vm.boardLabels[value.id]) {
            vm.boardLabels[value.id] = value;
            // vm.colorClass[value.color] = false;
          }
          vm.boardLabels[value.id].edit = false;
        });
        console.log(vm.boardLabels)
      }
      
      vm.saveUpdatedLabels = (label = null) => {
        let data = label ? updateData() : dataServices.toSave(vm.label, ['color', 'name', 'status']);
        data.status = 2;
      
        function updateData() {
          return {
            'color': label.color,
            'name': label.name,
            'status': label.status
          }
        }
      
        dataServices.post({
          url: serverUrl.main + 'reportLabelsApi/report/label' + (label ? '/' + label.id : ''),
          method: (label ? 'PUT' : 'POST'),
          spinnerName: 'notes-labels-sidebar-spinner',
          data: {
            data: angular.toJson(data)
          }
        }).then(response => {
          if (response && response.data) {
            console.log(response.data);
            getLabels();
          }
        });
        vm.resetSelectedLabel();
        vm.createLabelForm.$setPristine();
        vm.createLabelForm.$setUntouched();
      };
      
      vm.resetSelectedLabel = () => {
        vm.label = {
          name: '',
          color: '',
          status: 2
        };
      };
      
      vm.deleteLabel = (label) => {
        dataServices.delete({
          url: serverUrl.main + 'reportLabelsApi/report/label/' + label.id,
          spinnerName: 'notes-labels-sidebar-spinner'
        }).then(response => {
          if (response && response.data) {
            delete vm.boardLabels[label.id];
            getLabels();
            messages.simpleToast("Deleted Successfully");
          }
        })
      };
      vm.colorClass = {
        '#741b47': true,
        '#351c75': true,
        '#0b5394': true,
        '#1155cc': true,
        '#134f5c': true,
        '#38761d': true,
        '#bf9000': true,
        '#b45f06': true,
        '#990000': true,
        '#434343': true,
        '#5b0f00': true,
      };
      vm.focus = focus;
      vm.labelCopy = {};
      vm.boardLabels = {};
      vm.labelCopy = [];
      
      function focus(label, index) {
        vm.labelCopy[label.id] = angular.copy(label);
        label.edit = true;
      
        $timeout(function() {
          $('#label' + index).focus().select();
        }, 0);
      };
      
      vm.cancelEditLabel = function(id) {
        vm.boardLabels[id] = angular.copy(vm.labelCopy[id]);
        vm.boardLabels[id].edit = false;
      };
      
      //create label end

      vm.openEditNotes = () => {
        $mdSidenav("updateNotes").open();
      }

      vm.closeBlockSettings = () => {
        $mdSidenav('updateKBI').close();
      }
  
      vm.toggleNotesEditor = function () {
      //  vm.notes.description = '';
       $mdSidenav("updateNotes").close();
      };

      vm.toTrusted = function(html_code) {
        return $sce.trustAsHtml(html_code);
      };

      function getNotesDetails() {
        // vm.blockBackup.noteIds.length ? notesId = vm.blockBackup.noteIds[0] : notesId = vm.postResponse.id;
        if(vm.noteId) {
          dataServices.get({
            url: serverUrl.main + 'reportBlockNotesApi/report/notes/' + vm.noteId + '?',
          }).then(function(response) {
            if (response && response.data && response.data.result) {
              vm.notes = response.data.result;
              vm.noteId = vm.notes.id;
              // messages.simpleToast('Notes addeed successfully !');
            } else {
              vm.notes = response;
            }
          });
        }
      }
      vm.saveNotes = () => {
        vm.stripHtml = (html) => {
          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = html;
          return tempDiv.textContent || tempDiv.innerText || "";
      }
        let data = {
          'description': vm.stripHtml(vm.notes.description),
          'modelId': parseInt(vm.blockBackup.moduleName) ,
          'blockId': vm.blockBackup.id,
          'dashboardId': vm.blockBackup.dashboardId,
          'chartType': vm.blockBackup.chartType
        }
        console.log(data);
        // return
        dataServices.post({
          url: serverUrl.main + 'reportBlockNotesApi/report/notes' + (vm.noteId ? ('/' + vm.noteId ) : '') + '?',
          data: {
            data: angular.toJson(data)
          },
          method: vm.noteId ? 'PUT' : 'POST'
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            // vm.postResponse = response.data.result;
            if(response.data.result.id) {
              vm.noteId = response.data.result.id;
            }
            console.log(vm.noteId)
            getNotesDetails();
            messages.simpleToast('Notes ' + (vm.blockBackup.noteIds.length ? 'updated' : 'added') + ' successfully !');
            $mdSidenav("updateNotes").close();
          }
        });
      }

      vm.deleteNotes = () => {
        dataServices.delete({
          url: serverUrl.main + 'reportBlockNotesApi/report/notes/' + vm.noteId + '?',
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            vm.notes.description = ''
            vm.noteId = null;
            getNotesDetails();
            messages.simpleToast('Notes deleted successfully !');
          }
        });
      }

      if (vm.showForState == 'customer') {
        vm.customerStatus = {
          new: 1,
          active: 2,
          buying: 66,
          lead: 44,
          buyer: 5,
          inactive: 3
        };

        forEach(vm.customerStatus, function(val, key) {
          if (vm.settings.status.indexOf(val) != -1) {
            vm.settings[key] = true;
          }
        });
      }

      vm.changeCancelStatus = () => {
        if (vm.cancelStatus) {
          vm.settings.cancel = 28;
        } else {
          vm.settings.cancel = false;
        }
      };

      vm.changeVoidStatus = () => {
        if (vm.voidStatus) {
          vm.settings.void = 25;
        } else {
          vm.settings.void = false;
        }
      };

      if (vm.settings.listLimit) {
        vm.listTypeLimit = vm.settings.listLimit.toString();
      }

      // vm.timeRef = angular.copy(vm.timeRef);

      function confirm() {
        vm.settings.timeRef = parseInt(vm.settings.timeRef);
        vm.settings.listLimit = parseInt(vm.listTypeLimit);
        
        if (vm.blockBackup.alias == '') {
          vm.blockBackup.alias = angular.copy(vm.blockBackup.blockName);
        }
        // if(vm.blockBackup.reports.length) {
          // vm.blockBackup.reports = vm.blockBackup.reports.map(function(obj) {
          //   return obj.apiId;
          // });
          console.log(vm.blockBackup.reports)
          if (Array.isArray(vm.blockBackup.reports)) {
            if (vm.blockBackup.reports.length > 0 && typeof vm.blockBackup.reports[0] === "object") {
                vm.blockBackup.reports = vm.blockBackup.reports.map(obj => obj.apiId);
            }
        }
        
          console.log(vm.blockBackup.reports)
        // }
        
        if (vm.showForState != 'customer') {
          vm.blockBackup.settings = vm.settings;
          console.log(vm.settings);

        } else {
          // START : customer dashboard block setting is different then other dasboard. we are sending it in STATUS array.
          vm.blockBackup.settings.timeRef = vm.settings.timeRef;

          vm.blockBackup.settings['status'] = [];
          forEach(vm.customerStatus, function(val, key) {
            if (vm.settings[key]) {
              vm.blockBackup.settings.status.push(val);
            }
          });
          // END : customer dashboard block setting is different then other dasboard. we are sending it in STATUS array.
        }

        if (vm.settings.timeRef == 9) {
          vm.blockBackup.settings.fromDate = (vm.visibleFrom.setHours(0, 0, 0, 0) / 1000);
          vm.blockBackup.settings.toDate = (vm.visibleTo.setHours(23, 59, 59, 999) / 1000);
        }

        
        let apiData = angular.copy(vm.blockBackup);
        delete apiData.labelInfo;
        delete apiData.id;
        if(!vm.blockBackup.labels) {
          apiData.labels = [];
        }
        // return;
        dataServices.put({
          url: serverUrl.main + 'blocksApi/blocks/update/' + vm.dashboardId + '/' + vm.blockBackup.id + '?',
          data: {
            data: angular.toJson(apiData)
          }
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            $mdSidenav("updateKBI").close();
            // vm.draggableOff();
            console.log(vm);
            fetchAssignedDashboard(vm, vm.moduleName);
            messages.simpleToast('Block setting updated successfully !');
          }
        });
      }
    }

   
    function updateKBISetting(vm, ev, block) {
      shareDataStore(vm);
      $mdDialog.show({
        controller: UpdateKBISettingController,
        controllerAs: 'cd',
        templateUrl: updateKBISettingTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          block: block,
          parentVm: vm,
          parentScopeVm: vm.parentVm
        }
      });
    }

    function UpdateKBISettingController($scope, $mdDialog, serverUrl, dataServices, block, parentVm, parentScopeVm) {

      'ngInject';

      let cd = this;

      cd.blockBackup = angular.copy(block);
      // console.log(cd.blockBackup);
      delete cd.blockBackup.ver;
      delete cd.blockBackup.updatedBy;
      cd.parentVm = parentVm;
      cd.cancel = cancel;
      cd.confirm = confirm;
      cd.allListLimit = ['10', '15', '20'];
      cd.dashboardId = parentVm.assignedDashboard[parentVm.currentDashboardIndex].id;
      cd.settings = angular.copy(cd.blockBackup.settings);

      if (cd.settings.timeRef == 9) {
        cd.visibleFrom = new Date(cd.settings.fromDate * 1000);
        cd.visibleTo = new Date(cd.settings.toDate * 1000);
      }
      // if (cd.parentVm.moduleName == 28 || cd.parentVm.moduleName == 20) {
      //   if(cd.settings.futureDated == null) {
      //     cd.settings.futureDated = true;
      //   }
      // }
      if (cd.parentVm.showForState == 'customer') {
        cd.customerStatus = {
          new: 1,
          active: 2,
          buying: 66,
          lead: 44,
          buyer: 5,
          inactive: 3
        };

        forEach(cd.customerStatus, function(val, key) {
          if (cd.settings.status.indexOf(val) != -1) {
            cd.settings[key] = true;
          }
        });
      }

      cd.changeCancelStatus = () => {
        if (cd.cancelStatus) {
          cd.settings.cancel = 28;
        } else {
          cd.settings.cancel = false;
        }
      };

      cd.changeVoidStatus = () => {
        if (cd.voidStatus) {
          cd.settings.void = 25;
        } else {
          cd.settings.void = false;
        }
      };

      if (cd.settings.listLimit) {
        cd.listTypeLimit = cd.settings.listLimit.toString();
      }

      cd.timeRef = angular.copy(parentVm.timeRef);

      function confirm() {
        cd.settings.timeRef = parseInt(cd.settings.timeRef);
        cd.settings.listLimit = parseInt(cd.listTypeLimit);
        
        if (cd.blockBackup.alias == '') {
          cd.blockBackup.alias = angular.copy(cd.blockBackup.blockName);
        }

        cd.blockBackup.reports = cd.blockBackup.reports.map(function(obj) {
          return obj.apiId;
        });
        if (cd.parentVm.showForState != 'customer') {
          cd.blockBackup.settings = cd.settings;
          console.log(cd.settings);

        } else {
          // START : customer dashboard block setting is different then other dasboard. we are sending it in STATUS array.
          cd.blockBackup.settings.timeRef = cd.settings.timeRef;

          cd.blockBackup.settings['status'] = [];
          forEach(cd.customerStatus, function(val, key) {
            if (cd.settings[key]) {
              cd.blockBackup.settings.status.push(val);
            }
          });
          // END : customer dashboard block setting is different then other dasboard. we are sending it in STATUS array.
        }

        if (cd.settings.timeRef == 9) {
          cd.blockBackup.settings.fromDate = (cd.visibleFrom.setHours(0, 0, 0, 0) / 1000);
          cd.blockBackup.settings.toDate = (cd.visibleTo.setHours(23, 59, 59, 999) / 1000);
        }

        console.log(cd.blockBackup);
        // return;
        dataServices.put({
          url: serverUrl.main + 'blocksApi/blocks/update/' + cd.dashboardId + '/' + cd.blockBackup.id + '?',
          data: {
            data: angular.toJson(cd.blockBackup)
          }
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            cd.cancel();
            parentScopeVm.draggableOff();
            console.log(parentVm);
            fetchAssignedDashboard(parentVm, parentVm.moduleName);
            messages.simpleToast('Block setting updated successfully !');
          }
        });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }

    function loadKBIValue(vm, block, index, isTwinChart) {
      if (!index) {
        index = 0;
      }
      // START : We need to send setting filter to all block with its own filter to fetch block detail.
      let filterCriteria = {
        filters: []
      };
      if (vm.locationId && vm.moduleName != 45) {
        filterCriteria.filters.push({
          field: 'addressId',
          operator: '=',
          value: parseInt(vm.locationId)
        });
      }

      // console.log('b', vm.boardId);
      if (vm.boardId) {
        filterCriteria.filters.push({
          field: 'boardId',
          operator: '=',
          value: vm.boardId
        });
      }

      if (vm.salesAgentId != undefined && vm.salesAgentId != 'all') {
        filterCriteria.filters.push({
          field: 'salesAgentId',
          operator: '=',
          value: vm.salesAgentId
        });
      }
      // if (block.settings.futureDated != null) {
      //   filterCriteria.filters.push({
      //     field: 'futureDated',
      //     operator: '=',
      //     value: block.settings.futureDated
      //   });
      // }
      // console.log(vm.settings.future)
      // block.reports[index].model != 66
      
      if (block.settings && block.settings.listLimit) {
        filterCriteria['limit'] = block.settings.listLimit;
      }
      if (block.moduleName !== 20 && block.moduleName !== 28) {
        if (block.settings.status && block.settings.status.length) {
          filterCriteria.filters.push({
            field: 'status',
            operator: 'in',
            value: angular.copy(block.settings.status)
          });
        }

        if (block.settings.cancel) {
          filterCriteria.filters.push({
            field: 'cancel',
            operator: '=',
            value: true
          });
        }
        if (block.settings.void) {
          let searchIndex = findIndex(filterCriteria.filters, function(obj) {
            return obj.field == 'void';
          });
          if (searchIndex == -1) {
            filterCriteria.filters.push({
              field: 'void',
              operator: '=',
              value: true
            });
          } else {
            filterCriteria.filters[searchIndex].value.push(24);
          }
        }
        
        if(block.moduleName == 45 && (block.chartType == 2 || block.chartType == 1)) {
          if(!block.settings.void && !block.settings.cancel) {
            filterCriteria.filters.push({
              field: 'isArchived',
              operator: '=',
              value: block.settings.isArchived || false
            });
          }
        } else {
          filterCriteria.filters.push({
            field: 'isArchived',
            operator: '=',
            value: block.settings.isArchived || false
          });
        }
        // && block.blockName == 'New KBI'
      } else if ((block.moduleName == 20 || block.moduleName == 28) ) {
        if (block.reports[index].model != 66 && block.reports[index].model != 22 && vm.currentModel != undefined) {
          let statusArray = [];
          if(block.reports[index].model == 20) {
            statusArray = [10,11,20,21,22,24,26,27,30,31,34,35,40]
          }
          if(block.reports[index].model == 23) {
            statusArray = [10,11,20,25,30,42,48,49,50,55,75]
          }
          const statusMappings = {
            "20": {
              cancel: 28,
              void: 25
            },
            // 22: { cancel: 23, void: 24 },
            "23": {
              cancel: 40,
              void: 45
            },
          };
          console.log(statusMappings)
          const cancelStatus = statusMappings[vm.currentModel].cancel;
          const voidStatus = statusMappings[vm.currentModel].void;
          if (block.settings.cancel && !block.settings.void) {
            filterCriteria.filters.push({
              field: 'status',
              operator: 'in',
              value: [...statusArray, cancelStatus]
            });
          }
          if (block.settings.void && !block.settings.cancel) {
            filterCriteria.filters.push({
              field: 'status',
              operator: 'in',
              value: [...statusArray, voidStatus]
            });
          }
          if (block.settings.void && block.settings.cancel) {
            filterCriteria.filters.push({
              field: 'status',
              operator: 'in',
              value: [...statusArray,voidStatus, cancelStatus]
            });
          }
          if (!block.settings.void && !block.settings.cancel && !block.settings.isArchived) {
            filterCriteria.filters.push({
              field: 'status',
              operator: 'in',
              value: statusArray
            });
          }
          if (block.settings.isArchived) {
            filterCriteria.filters.push({
              field: 'isArchived',
              operator: '=',
              value: true
            });
          }
          if (!block.settings.isArchived && block.reports[index].model != 23) {
            filterCriteria.filters.push({
              field: 'isArchived',
              operator: '=',
              value: false
            });
          }
        }
        if(block.chartType == 2 || block.chartType == 1) {
          if (block.settings.status && block.settings.status.length) {
            filterCriteria.filters.push({
              field: 'status',
              operator: 'in',
              value: angular.copy(block.settings.status)
            });
          }
  
          if (block.settings.cancel) {
            filterCriteria.filters.push({
              field: 'cancel',
              operator: '=',
              value: true
            });
          }
          if (block.settings.void) {
            let searchIndex = findIndex(filterCriteria.filters, function(obj) {
              return obj.field == 'void';
            });
            if (searchIndex == -1) {
              filterCriteria.filters.push({
                field: 'void',
                operator: '=',
                value: true
              });
            } else {
              filterCriteria.filters[searchIndex].value.push(24);
            }
          }
          if (!block.settings.void && !block.settings.cancel) {
          filterCriteria.filters.push({
            field: 'isArchived',
            operator: '=',
            value: block.settings.isArchived || false
          });
          }
        }
          // if (block.settings.status && block.settings.status.length) {
          //   filterCriteria.filters.push({
          //     field: 'status',
          //     operator: 'in',
          //     value: angular.copy(block.settings.status)
          //   });
          // }
  
          // if (block.settings.cancel) {
          //   filterCriteria.filters.push({
          //     field: 'cancel',
          //     operator: '=',
          //     value: true
          //   });
          // }
          // if (block.settings.void) {
          //   let searchIndex = findIndex(filterCriteria.filters, function(obj) {
          //     return obj.field == 'void';
          //   });
          //   if (searchIndex == -1) {
          //     filterCriteria.filters.push({
          //       field: 'void',
          //       operator: '=',
          //       value: true
          //     });
          //   } else {
          //     filterCriteria.filters[searchIndex].value.push(24);
          //   }
          // }
          // // if (block.settings.isArchived) {
          // filterCriteria.filters.push({
          //   field: 'isArchived',
          //   operator: '=',
          //   value: block.settings.isArchived || false
          // });
        // }
      } 
      // else if ((block.moduleName == 45) && block.blockName != 'New KBI') {
      //   if(vm.moduleName == 45 && vm.locationId) {
      //     filterCriteria.filters.push({
      //       field: 'addressId',
      //       operator: '=',
      //       value: parseInt(vm.locationId)
      //     });
      //   }
      //   if (block.settings.cancel && !block.settings.void && !block.settings.isArchived) {
      //     filterCriteria.filters.push({
      //       field: 'status',
      //       operator: 'in',
      //       value: block.moduleName == 45 ? ["", 20] : ["", "cancel"]
      //     });
      //   }
      //   if (block.settings.void && !block.settings.cancel && !block.settings.isArchived) {
      //     filterCriteria.filters.push({
      //       field: 'status',
      //       operator: 'in',
      //       value:  block.moduleName == 45 ? ["", 24] : ["", "void"]
      //     });
      //   }
      //   if (!block.settings.void && !block.settings.cancel && !block.settings.isArchived) {
      //     filterCriteria.filters.push({
      //       field: 'status',
      //       operator: '=',
      //       value: ""
      //     });
      //   }
      //   if (vm.salesAgentId == 'all' && block.moduleName != 45) {
      //     filterCriteria.filters.push({
      //       field: 'salesAgentId',
      //       operator: '=',
      //       value: false
      //     });
      //   }

      //   if (block.settings.void && block.settings.cancel && !block.settings.isArchived) {
      //     filterCriteria.filters.push({
      //       field: 'status',
      //       operator: 'in',
      //       value: block.moduleName == 45 ? ["", 20, 24] : ["", "void", "cancel"]
      //     });
      //   }
      //   if (block.settings.isArchived && !block.settings.void && !block.settings.cancel && block.moduleName != 45) {
      //     filterCriteria.filters.push({
      //       field: 'status',
      //       operator: '=',
      //       value: 'isArchivedTrue'
      //     });
      //   }
      //   if (block.settings.isArchived && !block.settings.void && !block.settings.cancel && block.moduleName == 45) {
      //     filterCriteria.filters.push({
      //       field: 'status',
      //       operator: 'in',
      //       value: [20, 24]
      //     });
      //   }
      // }
      if(block.chartType == 2) {

      }

      if (block.settings.timeRef == 9) {
        console.log(block.settings.fromDate, block.settings.toDate);
        let val = [];
        val.push(block.settings.fromDate);
        val.push(block.settings.toDate);
        filterCriteria.filters.push({
          field: block.reports[index].filterKey,
          value: val,
          operator: "dtrange"
        });
      } else {
        filterCriteria.filters.push({
          field: block.reports[index].filterKey,
          value: createTimeReference(vm, block.settings.timeRef, block.reports[index].date_range_type),
          operator: "dtrange"
        });
      }
      // console.log(angular.copy(filterCriteria));
      // console.log("index", index);
      // console.log("filter", block.reports);

      filterCriteria.filters = filterCriteria.filters.concat(block.reports[index].filter.filters);
      if (block.reports[index].filter && block.reports[index].filter.sort) {
        filterCriteria['sort'] = block.reports[index].filter.sort;
      }

      // console.log(block.blockName);
      // console.log(filterCriteria);
      // console.log(block.reports[index].url);
      // return;

      dataServices.get({
        url: serverUrl.main + block.reports[index].url + '?filterCriteria=' + angular.toJson(filterCriteria) + '&',
        spinner: false
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.isKBIValueLoaded[block.reports[index].id] = vm.isKBIValueLoaded[block.reports[index].id] || {};
          vm.isKBIValueLoaded[block.reports[index].id][index] = response.data.result;
          // console.log(vm.chartType[block.chartType]);
          vm.loader[block.id] = true;

          if (!isTwinChart && block.chartType != 5 && block.chartType != 2 && vm.chartType[block.chartType]) {
            if (response.data.result.data.length) {
              vm.list[block.id] = angular.copy(response.data.result.data);
              vm.chartType[block.chartType](response.data.result, block.id);
            } else {
              vm.list[block.id] = [];
            }

          } else if (isTwinChart) {
            // START : if isTwinChart is true (having a id ) means we have to show two pie chart in one block.
            // console.log("isTwinChart", isTwinChart);
            vm.listTwinChart = vm.listTwinChart || {};
            if (!vm.listTwinChart[block.id]) {
              vm.listTwinChart[block.id] = {};
            }
            if (response.data.result.data && response.data.result.data.length) {
              vm.listTwinChart[block.id][isTwinChart] = response.data.result.data;

              // vm.list[block.id] = angular.copy(response.data.result.data);
              vm.chartType[block.chartType](response.data.result, block.id, isTwinChart);
            } else {
              // vm.list[block.id] = [];
            }
            // START : if isTwinChart is true (having a id ) means we have to show two pie chart in one block.
          } else {
            if (block.chartType == 2) {
              vm.list[block.id] = response.data.result.data;
            } else {
              // LOAD KBI
            }
          }
        } else {
          // console.log(serverUrl.main + block.reports[index].url + '?filterCriteria=' + angular.toJson(filterCriteria) + '&');
        }
      });
    }

    function positionUpdateConfirmation(vm, scope) {
      shareDataStore(vm);

      $mdDialog.show({
        clickOutsideToClose: true,
        controller: confirmDialogController,
        controllerAs: 'cd',
        templateUrl: confirmDialogTemplate,
        parent: angular.element(document.body),
        locals: {
          parentVm: vm,
          parentScopeVm: scope
        }
      });
    }

    function confirmDialogController($scope, $mdDialog, parentVm, parentScopeVm) {
      "ngInject";
      var cd = this;

      cd.parentVm = parentVm;
      cd.question = "Are you sure you want to update block sequence ?";
      cd.confirm = confirm;
      cd.cancel = cancel;

      function cancel() {
        parentScopeVm.draggableOff();
        // cd.parentVm.assignedDashboardBackUp = angular.copy(cd.parentVm.assignedDashboard);
        fetchAssignedDashboard(cd.parentVm, cd.parentVm.moduleName);
        $mdDialog.cancel();
      }

      function confirm() {
        let ids = [],
          dashboardBlocks = cd.parentVm.assignedDashboard[cd.parentVm.currentDashboardIndex].blocks;

        for (let i = 0; i < dashboardBlocks.length; i++) {
          ids.push(dashboardBlocks[i].id);
        }

        dataServices.put({
          url: serverUrl.main + 'blockPositionApi/block/position/' + cd.parentVm.assignedDashboard[cd.parentVm.currentDashboardIndex].id,
          data: {
            data: angular.toJson({
              position: ids
            })
          }
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast("Blocks Position Changed !");
            // console.log(parentScopeVm);
            parentScopeVm.draggableOff();
            // cd.parentVm.assignedDashboardBackUp = angular.copy(cd.parentVm.assignedDashboard);
            fetchAssignedDashboard(cd.parentVm, cd.parentVm.moduleName);
            $mdDialog.cancel();
          }
        });
      }
    }

    // START : Dashboard List for particular module like Quote Job and Opportunity by passing moduleName.
    function fetchDashboardList(vm) {
      let filterCriteria = {
        filters: [{
          field: "moduleName",
          value: vm.moduleName,
          operator: "=",
        }]
      }
      dataServices.get({
        url: serverUrl.main + 'dashboardApi/dashboard?filterCriteria=' + angular.toJson(filterCriteria) + '&'
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.dashboardList = response.data.result;
          vm.dashboardListBackup = angular.copy(vm.dashboardList);
          vm.parentVm.screenData = vm.dashboardName[vm.moduleName] + "- " + vm.dashboardList.length + " Dashboards";

          // if secondary data is showing using frame then.
          if (vm.moduleName == 99) {
            vm.scopeParentVm.screenData = vm.dashboardName[vm.moduleName] + "- " + vm.dashboardList.length + " Dashboards";
          }

        }
      });
    }
    // START : Dashboard List for particular module like Quote Job and Opportunity by passing moduleName.

    function addDashboard(vm, data, clone, customerHeader) {
      $mdDialog.show({
        controller: addDashboardController,
        controllerAs: 'cd',
        templateUrl: addDashboardTemplate,
        parent: angular.element(document.body),
        locals: {
          data: angular.copy(data),
          parentVm: vm,
          isClone: clone,
          customerHeader: customerHeader
        }
      });
    }

    function addDashboardController(ListService, errorMessage, $scope, $mdDialog, $mdSidenav, serverUrl, dataServices, data, parentVm, isClone, validatorService, customerHeader) {
      'ngInject';

      let cd = this;
      cd.errorMessages = errorMessage;
      cd.isClone = isClone;
      cd.confirm = validatorService.validateForm(cd, cd.isClone ? cloneDashboard : confirm);
      cd.cancel = cancel;
      cd.clearSelectedResources = clearSelectedResources;
      cd.attachSalesAgent = attachSalesAgent;
      cd.updateDB = data;
      cd.resourceSelect = {};
      if (data && data.id) {
        cd.id = data.id;
        data.name += cd.isClone ? ' Clone' : '';

        for (let i = 0; i < data.resourceId.length; i++) {
          cd.resourceSelect[data.resourceId[i].id] = angular.copy(data.resourceId[i]);
        }

        cd.resourceBackup = angular.copy(cd.resourceSelect);

        cd.dashboard = {
          name: data.name,
          resourceId: data.resourceId,
          moduleName: data.moduleName,
          description: data.description
        }
      } else {
        cd.resourceBackup = angular.copy(cd.resourceSelect);
        cd.dashboard = {
          resourceId: [],
          moduleName: parentVm.moduleName
        };
      }

      function getOrganization() {
        dataServices.get({
            url: serverUrl.main + "resourceApi/organizations?",
            spinner: false
          })
          .then(function(response) {
            if (response.data && response.data.result) {
              cd.organizationsList = response.data.result;
            }
          });
      }
      getOrganization();

      function confirm() {
        let resourceId = [];
        for (let i = 0; i < cd.dashboard.resourceId.length; i++) {
          resourceId.push(cd.dashboard.resourceId[i].id);
        }
        cd.dashboard.resourceId = angular.copy(resourceId);
        dataServices.post({
          url: serverUrl.main + 'dashboardApi/dashboard/' + (cd.id ? cd.id : 'add'),
          data: {
            data: angular.toJson(cd.dashboard)
          },
          method: cd.id ? 'PUT' : ''
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast('Dashboard ' + (cd.id ? 'Updated ' : 'added ') + 'Successfully !');
            fetchDashboardList(parentVm);
            cd.cancel();
          }
        });
      }

      function clearSelectedResources() {
        cd.resourceSelect = angular.copy(cd.resourceBackup);
      }

      cd.selectSalesAgent = selectSalesAgent;

      function selectSalesAgent(rList) {
        if (cd.resourceSelect[rList.id]) {
          cd.resourceSelect[rList.id] = false
        } else {
          cd.resourceSelect[rList.id] = rList;
        }
      }

      function attachSalesAgent() {
        cd.dashboard.resourceId = [];
        angular.forEach(cd.resourceSelect, function(val, key) {
          if (val) {
            cd.dashboard.resourceId.push(val);
          }
        });
        cd.resourceBackup = angular.copy(cd.resourceSelect)

        angular.copy(cd.resourceSelect)
        cd.closeSidenav('resources');
      }

      function cancel() {
        $mdDialog.cancel();
      }

      function cloneDashboard() {
        let resourceId = [];
        for (let i = 0; i < cd.dashboard.resourceId.length; i++) {
          resourceId.push(cd.dashboard.resourceId[i].id);
        }
        cd.dashboard.resourceId = angular.copy(resourceId);

        dataServices.post({
          url: serverUrl.main + 'dashboardApi/dashboard/clone/' + cd.id,
          data: {
            data: angular.toJson(cd.dashboard)
          }
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast('Dashboard Clone Successfully !');
            fetchDashboardList(parentVm);
            cd.cancel();
          }
        });
      }
      // START : LIST PAGINATION

      cd.filter = {};
      cd.setFilters = setFilters;
      // Lists for the screen
      cd.lists = cd.lists = ['resources'];
      cd.listName = cd.lists[0];
      cd.search = search;
      cd.toggleSidenav = toggleSidenav
      cd.closeSidenav = closeSidenav;
      cd.getList = getList;
      // console.log(cd.filter);
      // Search functions references for right sidenavs
      cd.toggleSearch = ListService.toggleSearch;
      cd.searchOnEnter = ListService.searchOnEnter;
      cd.getSearchKeys = ListService.getSearchKeys(cd);
      cd.isRequiredApiFailed = {};
      // Common functionality for opening and closing sidenavs

      cd.defaultFc = {
        'resources': {
          filters: [{
              field: 'isArchived',
              operator: '=',
              value: false
            },
            {
              field: 'status',
              operator: '=',
              value: 2
            }
          ],
          project: ['images', 'avatar', 'first_name', 'middle_name', 'last_name', 'org_details.organizations.primary', 'org_details.organizations.secondary', 'assignedDashboards', 'totalDashboards'],
          defaultSort: [{
            field: "firstName",
            order: 1
          }]
        }
      };

      // Suggestion and search history api keys required for search functionality in right sidenavs
      cd.suggestionApi = {
        resources: customerHeader.DASHBOARD_LIST.suggestionApi.resourcesF
      };

      cd.searchHistoryApiKey = {
        resources: customerHeader.DASHBOARD_LIST.searchHistoryApiKey.resourcesF
      };

      ListService.assignedDataCopy(cd, 'RESOURCE_LIST', [], 'resources', 'id', 'noSpinner');

      // On refresh, assign search text to search toolbar if any previous search text exists
      // Search: set typed text to local controller variable and call list api
      function search(isSidenav) {
        getList('new', false, false, function() {
          if (cd.searchText && cd.searchText.resources) {
            ListService.toggleSearch(isSidenav);
          }
        });
      }

      function getList(type = 'new', retainLimit, switchPageType) {

        let filterCriteria = ListService.setPrimaryDataCopy(cd, type, retainLimit, switchPageType);
        // view model, url, data, what list name you want
        let url = serverUrl.main + 'resourceApi/resources?filterCriteria=' + filterCriteria + '&' + (cd.searchText.resources ? ('search=' + cd.searchText.resources + '&') : '');
        ListService.postList(cd, url, cd.enableList, 'noSpinner', type == 'add');
      }

      function setFilters(type, retainLimit = false, switchPageType = false) {
        cd.filters.resources = angular.copy(cd.defaultFc.resources.filters);

        if (cd.filter.resources.selectedLocation) {
          ListService.addFilter(cd, 'businessAddress', parseInt(cd.filter.resources.selectedLocation), '=');
        } else {
          ListService.removeFilter(cd, 'businessAddress');
        }

        if (cd.filter.resources.orgId) {
          ListService.addFilter(cd, 'orgId', cd.filter.resources.orgId, '=');
        } else {
          ListService.removeFilter(cd, 'orgId');
        }

        cd.getList(type, retainLimit, switchPageType);
      }

      function toggleSidenav(menuId) {
        $mdSidenav(menuId).toggle();
        setFilters();
      }

      function closeSidenav(navId) {
        $mdSidenav(navId).close();
        cd.resources = [];
      }
    }

    function deleteDashboard(vm, ev, id, moduleName) {
      shareDataStore(vm);
      console.log(sharedDataService.get('currentDashboardIndex'), sharedDataService.get('dashboardId'))
      $mdDialog.show({
        controller: deleteDashboardController,
        controllerAs: 'cd',
        templateUrl: confirmDialogTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          id: id,
          parentVm: vm,
          moduleName: moduleName
        }
      });
    }

    function deleteDashboardController($scope, $mdDialog, $mdSidenav, serverUrl, dataServices, id, parentVm, moduleName, ListService) {
      'ngInject';

      let cd = this;
      // cd.parentScope = parentVmScope;
      cd.parentVm = parentVm;
      cd.question = 'Are you sure you want to delete dashboard ?';
      // cd.description = ;
      cd.confirm = confirm;
      cd.cancel = cancel;

      function confirm() {
        dataServices.delete({
          url: serverUrl.main + 'dashboardApi/dashboard/delete/' + moduleName + '/' + id,
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast('Dashboard Deleted Successfully !');
            console.log(sharedDataService.get('currentDashboardIndex'), sharedDataService.get('dashboardId'))
            // sharedDataService.set('currentDashboardIndex', 0);
            fetchDashboardList(cd.parentVm);
            cd.cancel();
          }
        });
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }

    function dashboardSequenceChange(vm) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: dashboardSequenceChangeController,
        controllerAs: 'cd',
        templateUrl: confirmDialogTemplate,
        parent: angular.element(document.body),
        locals: {
          parentVm: vm
        }
      });
    }

    function dashboardSequenceChangeController($scope, $mdDialog, parentVm) {
      "ngInject";
      var cd = this;

      cd.parentVm = parentVm;
      cd.question = "Are you sure you want to update dashboard sequence ?";
      cd.confirm = confirm;
      cd.cancel = cancel;

      function cancel() {
        cd.parentVm.dashboardList = angular.copy(cd.parentVm.dashboardListBackup);
        $mdDialog.cancel();
      }

      function confirm() {
        let ids = [];

        // console.log(cd.parentVm.dashboardList);

        for (let i = 0; i < cd.parentVm.dashboardList.length; i++) {
          ids.push(cd.parentVm.dashboardList[i].id);
        }
        // console.log(ids);
        dataServices.put({
          url: serverUrl.main + 'dashboardPositionApi/dashboard/position/' + cd.parentVm.moduleName,
          data: {
            data: angular.toJson({
              position: ids
            })
          }
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast("Dashboard Position Changed !");
            cd.parentVm.dashboardListBackup = angular.copy(cd.parentVm.dashboardList);
            $mdDialog.cancel();
          }
        });
      }
    }

    function fetchListData(vm) {
      dataServices.get({
        url: serverUrl.main + 'dashboardApi/dashboard/resource/list?moduleName=' + vm.moduleName + '&',
        spinner: false
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.assignedDashboard = response.data.result;
          vm.assignedDashboardBackUp = angular.copy(vm.assignedDashboard);
          vm.currentDashboardIndex = sharedDataService.get('currentDashboardIndex') || 0;
          console.log(vm.currentDashboardIndex, sharedDataService.get('dashboardId'))
          console.log("called1")

          if (vm.assignedDashboard && vm.assignedDashboard[vm.currentDashboardIndex].id != sharedDataService.get('dashboardId')) {
            // START : we are reseting currentDashboardIndex to zero because this dashboard is deleted successfully.
            vm.currentDashboardIndex = 0;
            // END : we are reseting currentDashboardIndex to zero because this dashboard is deleted successfully.
          }

          vm.blockIdsArray = vm.assignedDashboard[vm.currentDashboardIndex].blocks
            .flatMap(block => block.reports.map(report => report.apiId));

          vm.blockIdsString = vm.blockIdsArray.join(',');

          console.log(vm.blockIdsString);
        }
      });
    }

    function blockByCatagory(vm) {
      fetchListData(vm);
      let filterCriteria = {
        filters: [{
          field: 'category',
          operator: '=',
          value: parseInt(vm.selectedCategory)
        }, {
          field: 'moduleName',
          operator: '=',
          value: vm.moduleName
        }]
      };

      if (vm.chartType && vm.chartType != "None") {
        filterCriteria.filters.push({
          field: 'chartType',
          operator: '=',
          value: parseInt(vm.chartType)
        });
      }

      dataServices.get({
        url: serverUrl.main + 'blocksApi/category/blocks?filterCriteria=' + angular.toJson(filterCriteria) + '&'
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.allReportBlock = response.data.result;
        }
      });

    }

    function addReportBlockToDashboard(vm, block) {
      let field = [
        "moduleName",
        "category",
        "chartType",
        "blockName",
        "alias",
        "blockId",
        "description",
        "settings",
        "reports",
        "listType"
      ];

      let data = dataServices.toSave(block, field);

      dataServices.post({
        url: serverUrl.main + 'blocksApi/blocks/add/report/' + vm.dashboardId,
        data: {
          data: angular.toJson(data)
        }
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          // $state.go(vm.stateRedirect);
          blockByCatagory(vm);
          messages.simpleToast('Block Added successfully !');
        }
      });
    }

    function blockByCatagoryIndicator(vm) {

      let filterCriteria = angular.toJson({
        filters: [{
          field: 'chartType',
          operator: '=',
          value: 5
        }, {
          field: 'category',
          operator: '=',
          value: parseInt(vm.selectedCategory)
        }, {
          field: 'moduleName',
          operator: '=',
          value: vm.moduleName
        }]
      });
      dataServices.get({
        url: serverUrl.main + 'blocksApi/category/charts?filterCriteria=' + filterCriteria + '&'
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.allKBIBlock = response.data.result;
        }
      });
    }

    function isKBIBlockExist(vm, apiId) {
      return vm.blockDetail.reports.indexOf(apiId) == -1 ? false : true;
    }

    function addKBIBlockToDashboard(vm, scopeParentVm, apiId, isAdd) {

      let field = [
        "moduleName",
        "category",
        "chartType",
        "blockName",
        "alias",
        "blockId",
        "description",
        "settings",
        "reports"
      ];

      let data = dataServices.toSave(vm.blockDetail, field);
      if (isAdd) {
        data.reports.push(apiId)

      } else {
        data.reports.splice(data.reports.indexOf(apiId), 1);
      }

      // console.log(data);
      // return;

      dataServices.put({
        url: serverUrl.main + 'blocksApi/blocks/update/' + vm.dashboardId + '/' + vm.KBIBlockId + '?',
        data: {
          data: angular.toJson(data)
        }
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          messages.simpleToast('Indicator ' + (isAdd ? 'added to' : 'removed from') + ' your block !');
          fetchBlockDetail(vm);

          vm.blockByCatagory(vm);
        }
      });
    }

    function fetchBlockDetail(vm) {
      dataServices.get({
        url: serverUrl.main + 'blocksApi/block/' + vm.KBIBlockId + '?'
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.blockDetail = response.data.result;
        }
      });
    }

    function KBIBlockCount(vm) {
      let filterCriteria = {
        filters: [{
          field: 'moduleName',
          operator: '=',
          value: vm.moduleName
        }, {
          field: 'chartType',
          operator: '=',
          value: 5
        }]
      };
      dataServices.get({
        url: serverUrl.main + 'blocksApi/chart/category/count?filterCriteria=' + angular.toJson(filterCriteria) + '&'
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.blockCount = response.data.result;
        }
      });
    }

    function reportBlockCount(vm) {
      let filterCriteria = {
        filters: [{
          field: 'moduleName',
          operator: '=',
          value: vm.moduleName
        }]
      };
      dataServices.get({
        url: serverUrl.main + 'blocksApi/block/category/count?filterCriteria=' + angular.toJson(filterCriteria) + '&'
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.blockCount = response.data.result;
        }
      });
    }

    function confirmKBISettings(block, vm) {
      shareDataStore(vm);
      let dashboardId = vm.assignedDashboard[vm.currentDashboardIndex].id;
      block.settings.timeRef = parseInt(block.settings.timeRef);
      // block.reports = block.reports.map(function(obj) {
      //   return obj.apiId;
      // });
      if (Array.isArray(block.reports)) {
        if (block.reports.length > 0 && typeof block.reports[0] === "object") {
            block.reports = block.reports.map(obj => obj.apiId);
        }
    }
    
      vm.settings = angular.copy(block.settings);

      if (block.ver) {
        delete block.ver;
      }

      if (block.updatedBy) {
        delete block.updatedBy;
      }

      if (vm.showForState != 'customer') {
        block.settings = vm.settings;
      } else {
        // START : customer dashboard block setting is different then other dasboard. we are sending it in STATUS array.
        vm.customerStatus = {
          new: 1,
          active: 2,
          buying: 66,
          lead: 44,
          buyer: 5,
          inactive: 3
        };
        angular.forEach(vm.customerStatus, function(val, key) {
          if (vm.settings.status.indexOf(val) != -1) {
            vm.settings[key] = true;
          }
        });

        block.settings['status'] = [];
        angular.forEach(vm.customerStatus, function(val, key) {
          if (vm.settings[key]) {
            block.settings.status.push(val);
          }
        });
        // END : customer dashboard block setting is different then other dasboard. we are sending it in STATUS array.
      }

      if (vm.settings.timeRef == 9) {
        block.settings.fromDate = (block.settings.fromDate.setHours(0, 0, 0, 0) / 1000);
        block.settings.toDate = (block.settings.toDate.setHours(23, 59, 59, 999) / 1000);
      }
      let apiData = angular.copy(block);
      delete apiData.labelInfo;
      delete apiData.id;
      if(!block.labels) {
        apiData.labels = [];
      }
      console.log(block);
      // return;
      dataServices.put({
        url: serverUrl.main + 'blocksApi/blocks/update/' + dashboardId + '/' + block.id + '?',
        data: {
          data: angular.toJson(apiData)
        }
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          vm.parentVm.draggableOff();
          // console.log(vm);
          fetchAssignedDashboard(vm, vm.moduleName);
          messages.simpleToast('Block setting updated successfully !');
        }
      });
    }

    function formatDate(block, vm) {
      if (block.settings.fromDate && block.settings.toDate) {
        block.settings.fromDate = new Date(block.settings.fromDate * 1000);
        block.settings.toDate = new Date(block.settings.toDate * 1000);
      }
      vm.dateFormated[block.id] = true;
      console.log(block);
      console.log(vm.dateFormated);
    }

  }
}