export default function (app) {
  app.factory('GraphService', graphService);

  function graphService($filter) {

    "ngInject";

    var myColors = ["#FAB963", "#5CC5C3", "#0894B5", "#ABD036", "#d6e1e5", "#b9eaa7", "#FF8162", "#424853", "#39A82D", "#F45151", "#D84E99", "#66BC7E", "#6C9AFF", "#FCB24F", "#AA79DB"];

    return {
      initD3: initD3,
      renderPieChart: renderPieChart,
      renderBarChart: renderBarChart,
      renderStackBarChart: renderStackBarChart,
      renderLineChart: renderLineChart,
      renderMultiBarChart: renderMultiBarChart
    };

    function initD3() {
      d3.scale.myColors = function () {
        return d3.scale.ordinal().range(myColors);
      };
    }

    function renderPieChart(result, id, isTwinChart) {
      // console.log("here", id, result);
      var width = 300;
      var height = 300;
      if (id == 'priorityDistribution' || id == "1") {
        result.data = result;
      }

      nv.addGraph(function () {
        var chart = nv.models.pieChart()
          .x(function (d) {
            if (result.data.length > 5) {
              return;
            }
            if (d.key == "custStatus") {
              return $filter('customerStatus')(d[d.key]);
            } else if (d.key == "status") {
              return $filter('cardStatus')(d[d.key]);
            } else if (d.key == "qstatus" || d.key == "jstatus") {
              return $filter('quotesStatus')(d[d.key]);
            } else if (d.key == "leadSource") {
              return $filter('reasonName')(d[d.key], 'sourceOfLead');
            } else if (d.key == 'reason') {
              return $filter('reasonName')(d[d.key], d.category);
            } else if (d.key == 'serviceType') {
              // console.log($filter('serviceTypeValue')(d[d.key]));
              return $filter('serviceTypeValue')(d[d.key]);
            } else if (d.key == 'serviceStatus' || d.key == 'recStatus' || d.key == 'regStatus') {
              return $filter('serviceStatus')(d[d.key]);
            } else if (d.key == 'taskScheduleType') {
              return $filter('taskScheduleType')(d[d.key]);
            } else {
              if (d.key == 'rating' && ((typeof d[d.key]) == 'number')) {
                return d[d.key] + ' Rating';
              } else {
                if (((typeof d[d.key]) == 'number')) {
                  return d[d.key];
                }
                return $filter('camelcase')(d[d.key]);
              }
              // console.log($filter('camelcase')(d[d.key]));
            }
          })
          .y(function (d) {
            return d.hover;
          })
          // .yAxis.tickFormat(function() {
          //   return '$' + d3.format('.02f')(d.hover);
          // })
          .width(width)
          .height(height)
          .labelType('percent')
          // .growOnHover(false)
          .showTooltipPercent(true)
          .donut(true)
          .showLabels(true).color(d3.scale.myColors().range());

        let actionPriority = {
          5: 'critical',
          4: 'high',
          3: 'medium',
          2: 'normal',
          1: 'low',
          0: 'backlog'
        }

        chart.tooltip.contentGenerator(function (e) {
          // console.log(e);
          if (e.data.key == 'custStatus') {
            // for quote leadSource
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('customerStatus')(e.data[e.data.key]) + '</div>';

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>'
            }

            if (e.data.count) {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.count + '</div>';
            }
            if (e.data.percentage) {
              data += '<div style="display:inline-block;margin-right:5px;">(' + $filter('number')(e.data.percentage, 2) + ')%</div>'
            }
            return data;

          } else if (e.data.key == 'status') {
            // for quote leadSource
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('cardStatus')(e.data[e.data.key]) + '</div>';

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>'
            }

            if (e.data.count) {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.count + '</div>';
            }
            if (e.data.percentage) {
              data += '<div style="display:inline-block;margin-right:5px;">(' + $filter('number')(e.data.percentage, 2) + ')%</div>'
            }
            return data;

          } else if (e.data.key == 'leadSource') {
            // for quote leadSource
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('reasonName')(e.data[e.data.key], 'sourceOfLead') + '</div>';

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>';

            } else {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.hover + '</div>';
            }
            if (e.data.percentage) {
              data += '<div style="display:inline-block;margin-right:5px;">(' + $filter('number')(e.data.percentage, 2) + ')%</div>'
            }
            return data;

          } else if (e.data.key == 'qstatus' || e.data.key == 'jstatus') {
            // for quote Status
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('quoteStatus')(e.data[e.data.key]) + '</div>'

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>';
            } else {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.hover + '</div>';
            }

            return data;

          } else if (e.data.key == 'name' && e.data && !e.data.currencySymbol) {
            // If currency symbol is not available
            return '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + e.data[e.data.key] + '</div><div style="display:inline-block;margin-right:5px;">' + e.data.count + '</div>';

          } else if (e.data.key == 'name' && e.data && e.data.currencySymbol) {
            // If currency symbol is not available
            // return '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + e.data[e.data.key] + '</div><div style="display:inline-block;margin-right:5px;">' + e.data.count + '</div>';
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + e.data[e.data.key] + '</div>'

            data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>';

            return data;

          } else if (e.data.key == 'rating') {
            const hoverValue = e.data.hover || '';
            const currencySymbol = e.data.currencySymbol || '';
            return `
              <div style="width: 10px; height: 10px; display: inline-block; background-color: ${e.color}; margin-right: 7px;"></div>
              <div style="display: inline-block; margin-right: 5px;">${e.data[e.data.key]} Star -</div>
              <div style="display: inline-block; margin-right: 5px;">${currencySymbol}${hoverValue}</div>
            `;
          } else if (e.data.key == 'reason') {
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('reasonName')(e.data[e.data.key], e.data.category) + '</div>';

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>';
            } else {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.hover, +'</div>';
            }

            return data;

          } else if (e.data.key == 'serviceType') {
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('serviceTypeValue')(e.data[e.data.key]) + '</div>';

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>';
            } else {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.hover, +'</div>';
            }

            return data;

          } else if (e.data.key == 'serviceStatus' || e.data.key == 'recStatus' || e.data.key == 'regStatus') {
            console.log(e.data, e.data.key, e.data[e.data.key], $filter('serviceStatus')(e.data[e.data.key]))
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('serviceStatus')(e.data[e.data.key]) + '</div>';

            if (e.data.currencySymbol) {
              data += '<div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.hover, e.data.currencySymbol) + '</div>';
            } else {
              data += '<div style="display:inline-block;margin-right:5px;">' + e.data.hover, +'</div>';
            }

            return data;

          } else if (e.data.key == 'type') {

            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + e.data[e.data.key] + '</div> <div style="display:inline-block;margin-right:5px;">' + e.data.hover + '</div>';

            return data;

          } else if (e.data.key == 'priority') {
            // $filter('camelcase')
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('camelcase')(actionPriority[e.data.id]) + '</div> <div style="display:inline-block;margin-right:5px;">' + e.data.total + '</div>';

            return data;

          } else if (e.data.key == 'paymentModes') {
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('camelcase')(e.data[e.data.key]) + ' - </div><div style="display:inline-block;margin-right:5px;">' + e.data.hover + ' - </div><div style="display:inline-block;margin-right:5px;">' + $filter('currencyForm')(e.data.amount, e.data.currencySymbol) + '</div>';
            return data;
          } else if (e.data.key == 'taskScheduleType') {
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('taskScheduleType')(e.data[e.data.key]) + '</div> <div style="display:inline-block;margin-right:5px;">' + (e.data.currencySymbol ? $filter('currencyForm')(e.data.hover, e.data.currencySymbol) : e.data.hover) + '</div>';
            return data;
          } else {
            // if currencySymbol or amount are available.
            let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + $filter('camelcase')(e.data[e.data.key]) + '</div> <div style="display:inline-block;margin-right:5px;">' + (e.data.currencySymbol ? $filter('currencyForm')(e.data.hover, e.data.currencySymbol) : e.data.hover) + '</div>';

            if (e.data.percentage) {
              data += '<div style="display:inline-block;margin-right:5px;">(' + $filter('number')(e.data.percentage, 2) + ')%</div>'
            }
            return data;
          }

        });

        let pieChartId = '#chart' + id;

        // START : if isTwinChart is true (having a id ) means we have to show two pie chart in one block.
        if (isTwinChart) {
          pieChartId += isTwinChart;
        }
        // START : if isTwinChart is true (having a id ) means we have to show two pie chart in one block.
        // console.log(pieChartId);

        d3.select(pieChartId)
          .datum(result.data)
          .transition().duration(1200)
          .attr('width', width)
          .attr('height', height)
          .call(chart);

        nv.utils.windowResize(chart.update);

        return chart;
      });
      // pie chart ended
    }

    function renderBarChart(result, id, xKey = 'customerName', yKey = 'potentialAmount') {
      console.log(result)
      // If xKey or yKey is not provided, try to infer keys from the data
      let historicalBarChart = [{
        key: "Cumulative Return",
        values: result.length ? result : result.data
      }];

      nv.addGraph(function () {
        var chart = nv.models.discreteBarChart()
          .x(function (d) {
            return d[xKey];
          })
          .y(function (d) {
            return d[yKey];
          })
          .staggerLabels(true)
          // .wrapLabels(true)
          .rotateLabels(45)
          //.staggerLabels(historicalBarChart[0].values.length > 8)
          .showValues(true)
          .duration(250)
          .color(d3.scale.myColors().range());

        chart.tooltip.contentGenerator(function (e) {
          // console.log(e);
          // Math.round(num * 100) / 100
          // for quote leadSource
          let data = '<div style="width: 10px;height: 10px;display: inline-block;background-color: ' + e.color + ';margin-right: 7px;"></div><div style="display:inline-block;margin-right:5px;">' + e.data[xKey] + '</div><div style="display:inline-block;margin-right:5px;">' + e.data[yKey] + '</div>';

          return data;
        });

        // console.log("bar" + id + " svg");
        d3.select("#bar" + id + " svg")
          .datum(historicalBarChart)
          .call(chart);

        nv.utils.windowResize(chart.update);
        return chart;
      });
    }

    // function renderStackBarChart(result, id) {
    //   let historicalBarChart = [{
    //     key: "Cumulative Return",
    //     values: result.data
    //   }];

    //   nv.addGraph(function() {
    //     let chart = nv.models.discreteBarChart()
    //       .x(function(d) {
    //         return d.customerName + '(' + d.cardId + ')';
    //       })
    //       .y(function(d) {
    //         // return d.potentialOpportunity
    //         return d.value / 1000;
    //       })
    //       // .color(['yellow'])
    //       .staggerLabels(true)
    //       .rotateLabels(45)
    //       // .staggerLabels(historicalBarChart[0].values.length > 8)
    //       .showValues(false)
    //       .duration(250)
    //       .color(d3.scale.myColors().range());

    //     d3.select('#stack' + id + ' svg')
    //       .datum(historicalBarChart)
    //       .call(chart);

    //     nv.utils.windowResize(chart.update);
    //     return chart;
    //   });
    // }

    function renderStackBarChart(result, id) {
      if (result.chartType == 'Stack Bar Chart') {
        let historicalBarChart = [{
          key: "Cumulative Return",
          values: result.data
        }];

        nv.addGraph(function () {
          let chart = nv.models.discreteBarChart()
            .x(function (d) {
              return d.customerName + '(' + d.cardId + ')';
            })
            .y(function (d) {
              // return d.potentialOpportunity
              return d.value / 1000;
            })
            // .color(['yellow'])
            .staggerLabels(true)
            .rotateLabels(45)

            // .staggerLabels(historicalBarChart[0].values.length > 8)
            .showValues(false)
            .duration(250)
            .color(d3.scale.myColors().range());

          d3.select('#stack' + id + ' svg')
            .datum(historicalBarChart)
            .call(chart);

          nv.utils.windowResize(chart.update);
          return chart;
        });
      } else {
        nv.addGraph(function () {
          let chart = nv.models.multiBarChart()
            .duration(350)
            .reduceXTicks(true) // Reduces x-axis ticks if true
            // .rotateLabels() // Angle for rotating x-axis labels
            .showControls(false) // Disable toggle for Grouped/Stacked mode
            .groupSpacing(0.1) // Distance between grouped bars
            .stacked(true) // Enables the stacked mode
            .margin({ bottom: 70, right: 20 });

          // Conditionally rotate x-axis labels based on result.xAxisLabel
          if (result.xAxisLabel === "Customer Name") {
            chart.xAxis.rotateLabels(45); // Rotate labels by 45 degrees if "Customer Name"
          } else {
            chart.xAxis.rotateLabels(0);  // Keep labels horizontal if not "Customer Name"
          }

          // Configure x-axis
          chart.xAxis
            .axisLabel(result.xAxisLabel)
            .tickPadding(3)
            .tickFormat(function (data) {
              if (result.xAxisDateFormat) {
                let d = new Date(data);
                return d.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                });
              }

              if (data == null) {
                return "N/A";
              }

              return data;
            });

          // Configure y-axis
          chart.yAxis
            .axisLabel(result.yAxisLabel)
            .tickFormat(function (d) {
              if (d == null) {
                return "N/A";
              }
              return d;
            });

          // Apply chart data
          d3.select("#stack" + id + " svg")
            .datum(result.data)
            .call(chart);

          // Resize chart when window changes
          nv.utils.windowResize(chart.update);

          return chart;
        });

      }

    }

    function renderMultiBarChart(result, id) {
      nv.addGraph(function () {
        let chart = nv.models.multiBarChart()
          .duration(350)
          .reduceXTicks(true)
          .rotateLabels(0)
          .showControls(result.data.length > 1 && (result.data.length > 2 || !(result.data[0].key === 'Count' && result.data[1].key === 'Amount')))
          .groupSpacing(0.1)
          .margin({ bottom: 70, right: 20 });
    
        chart.xAxis
          .axisLabel(result.xAxisLabel)
          .tickPadding(3)
          .tickFormat(function (data) {
            if (result.xAxisDateFormat) {
              let d = new Date(data);
              return isNaN(d) ? data : d.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
            }
            return data ?? 'N/A';
          });
    
        chart.yAxis
          .axisLabel(result.yAxisLabel)
          .tickFormat(d => d == null ? 'N/A' : d);
    
        chart.tooltip.contentGenerator(function (e) {
          let formattedDate = result.xAxisDateFormat 
            ? new Date(e.data.x).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) 
            : e.data.x || '';
    
          let colorBox = `<div style="width: 15px; height: 15px; display: inline-block; background-color: ${e.color}; margin-right: 7px;"></div>`;
    
          let value = e.data.y;
          let currencySymbol = result.currencySymbol || '';
          let yValue = '';
    
          if (['Amount', 'Average', 'Average amount'].includes(e.series[0].key)) {
            yValue = `<div style="display:inline-block;margin-right:5px;"><strong>${currencySymbol}${value * 1000}</strong></div>`;
          } else if (['Percentage', 'Total'].includes(e.series[0].key)) {
            yValue = `<div style="display:inline-block;margin-right:5px;"><strong>${parseFloat(value).toFixed(2)}%</strong></div>`;
          } else {
            yValue = `<div style="display:inline-block;margin-right:5px;"><strong>${value}</strong></div>`;
          }
    
          return `
            ${formattedDate ? `<div style="margin-bottom: 10px;"><strong>${formattedDate}</strong></div>` : ''}
            <div style="display: flex; justify-content: center; align-items: center;">
              ${colorBox}
              <div style="margin-right: 5px;">${e.series[0].key}</div>
              ${yValue}
            </div>
          `;
        });
    
        d3.select(`#stack${id} svg`)
          .datum(result.data)
          .call(chart);
    
        d3.select(`#stack${id} svg`)
          .selectAll(".nv-x .tick text")
          .each(function (d) {
            let textElement = d3.select(this);
    
            if (result.xAxisLabel !== "Date") {
              textElement.text(d);
              return;
            }
    
            let dateValue = new Date(d);
            if (isNaN(dateValue)) dateValue = new Date(d * 1000);
            if (isNaN(dateValue)) {
              textElement.text(d);
              return;
            }
    
            let today = new Date();
            today.setHours(0, 0, 0, 0);
    
            let formattedDate = dateValue.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
            let isToday = dateValue.toDateString() === today.toDateString();
    
            textElement.text(formattedDate).style("font-weight", isToday ? "bold" : "normal");
    
            if (isToday) {
              textElement.append('tspan')
                .attr('x', 0)
                .attr('dy', '1.2em')
                .style('font-size', '12px')
                .style('font-weight', 'normal') // "TODAY" in normal font weight
                .text("TODAY");
            }
          });
    
        nv.utils.windowResize(chart.update);
        return chart;
      });
    }
    
    function renderLineChart(result, id) {
      let chart, legendPosition = "top";
      nv.addGraph(function () {
        chart = nv.models.lineChart()
          .options({
            duration: 300,
            useInteractiveGuideline: true
          })
          .showLabels(true).color(d3.scale.myColors().range());
        console.log(result)
        // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
        chart.xAxis
          .axisLabel(result.xAxisLabel)
          .tickFormat(function (data) {
            if (result.xAxisDateFormat) {
              let d = new Date(data);
              return d.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              });
            }
            if (data == null) {
              return 'N/A';
            }

            return data;
          })
          .staggerLabels(true);
        chart.yAxis
          .axisLabel(result.yAxisLabel)
          .tickFormat(function (d) {
            if (d == null) {
              return 'N/A';
            }

            return d;
          });
        //   chart.tooltip.contentGenerator(function(e) {
        //     // Format the date from Unix timestamp
        //     let date = new Date(e.data.x);
        //     let formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

        //     // Define color box for visual identification
        //     let colorBox = `<div style="width: 15px; height: 15px; display: inline-block; background-color: ${e.color}; margin-right: 7px;"></div>`;

        //     // Extract the amount and count series
        //     let amountSeries = e.series[0].find(s => s.key === 'amount');
        //     let countSeries = e.series[0].find(s => s.key === 'count');

        //     // Amount value formatting (currency symbol included only for the amount series)
        //     let amountValue = amountSeries 
        //         ? `<div style="display:inline-block; margin-right:5px;"><strong>${result.currencySymbol || ''}${amountSeries.value}</strong></div>`
        //         : `<div style="display:inline-block; margin-right:5px;"><strong>N/A</strong></div>`;

        //     // Count value formatting
        //     let countValue = countSeries 
        //         ? `<div style="display:inline-block; margin-right:5px;"><strong>Count: ${countSeries.value}</strong></div>`
        //         : '<div style="display:inline-block; margin-right:5px;"><strong>Count: N/A</strong></div>';

        //     // Return the complete tooltip content with structured layout
        //     return `
        //         <div style="margin-bottom: 10px; text-align: left;">
        //             <strong>${formattedDate}</strong>
        //         </div>
        //         <div style="margin-bottom: 5px; text-align: left;">
        //             ${countValue}
        //         </div>
        //         <div style="display: flex; justify-content: center; align-items: center; text-align: center;">
        //             ${colorBox}
        //             <div style="margin-right: 5px;"><strong>${e.series[0].key}</strong></div>
        //             ${amountValue}
        //         </div>
        //     `;
        // });

        d3.select('#lineChart' + id + ' svg')
          .datum(result.data)
          .call(chart);

        nv.utils.windowResize(chart.update);

        return chart;
      });

    }

    //   function renderLineChart(result, id) {
    //     let chart;
    //     nv.addGraph(function () {
    //         chart = nv.models.stackedAreaChart()
    //             .options({
    //                 duration: 300,
    //                 useInteractiveGuideline: true, // Adds hover tooltips
    //                 showControls: false, // Optional: hides controls for stacked/expanded modes
    //                 clipEdge: true, // Clips the chart to avoid overflow
    //                 style: 'expand', // Ensures line chart style with filled area
    //             })
    //             .color(d3.scale.myColors().range());

    //         // Customize the tooltip to remove "total" and format the date
    //         chart.tooltip.contentGenerator(function (e) {
    //             let timestamp = e.value.toString().length === 10 ? e.value * 1000 : e.value; // Handle 10- or 13-digit timestamps
    //             let date = new Date(timestamp);
    //             let formattedDate = date.toLocaleDateString('en-US', {
    //                 month: 'short',
    //                 day: 'numeric',
    //                 year: 'numeric',
    //             });

    //             // Build the tooltip content
    //             let content = `<div><strong>${formattedDate}</strong></div>`;
    //             e.series.forEach(function (series) {
    //                 if (series.value !== null) {
    //                     content += `
    //                         <div style="color: ${series.color};">
    //                             <strong>${series.key}:</strong> ${series.value}
    //                         </div>`;
    //                 }
    //             });

    //             return content;
    //         });

    //         // Format the X-axis ticks
    //         chart.xAxis
    //             .axisLabel(result.xAxisLabel)
    //             .tickFormat(function (data) {
    //                 if (result.xAxisDateFormat) {
    //                     let timestamp = data.toString().length === 10 ? data * 1000 : data; // Normalize timestamp
    //                     let d = new Date(timestamp);
    //                     return d.toLocaleDateString('en-US', {
    //                         month: 'short',
    //                         day: 'numeric',
    //                         year: 'numeric',
    //                     });
    //                 }
    //                 if (data == null) {
    //                     return 'N/A';
    //                 }
    //                 return data;
    //             })
    //             .staggerLabels(true);

    //         // Configure the Y-axis
    //         chart.yAxis
    //             .axisLabel(result.yAxisLabel)
    //             .tickFormat(function (d) {
    //                 if (d == null) {
    //                     return 'N/A';
    //                 }
    //                 return d;
    //             });

    //         // Render the chart with data
    //         d3.select('#lineChart' + id + ' svg')
    //             .datum(result.data)
    //             .call(chart);

    //         // Style the filled area and line
    //         d3.selectAll('#lineChart' + id + ' svg .nv-area')
    //             .style('fill-opacity', 0.3);

    //         d3.selectAll('#lineChart' + id + ' svg .nv-line')
    //             .style('stroke-width', 2)
    //             .style('stroke-opacity', 1);

    //         nv.utils.windowResize(chart.update);

    //         return chart;
    //     });
    // }
  }
}