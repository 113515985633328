import { filter } from 'lodash';
import saveProposalTemplate from './save-proposal-popup.html';
import AITemplate from './../../../components/popup-view/ai-import-template.html';
import { precisionFixed } from 'd3';


export default function editProposalPopupController(freepikService, quoteDetails, $q, quoteId, contactData, $compile, vm, id, isDoc, isTemplate, $scope, $timeout, $rootScope, $stateParams, $mdDialog, spinnerLoader, dataServices, serverUrl, FILE_SIZES) {
  'ngInject';

  let pr = this;

  let builder, builderOptions;

  pr.docId = isDoc;
  pr.proposalId = id;
  pr.parentVm = vm;
  pr.isTemplate = isTemplate;
  pr.customerId = pr.parentVm.customerId;
  pr.freepikPopup = freepikService.freepikPopup;
  spinnerLoader.start($rootScope.customSpinnerName);

  pr.updateProposal = openSaveDialog;

  function getCustomerDetails() {
    dataServices.get({
      url: serverUrl.main + 'contactApi/customers/details/' + pr.customerId + '?',
      spinner: false
    }).then((response) => {
      pr.contactData = response.data.result;
      contactData.initSocialLinks(pr);
      loadLibraries();
    });
  }

  function getProposal() {
    let url;
    console.log(pr.isTemplate, isDoc);
    if (pr.isTemplate) {
      if (pr.proposalId) {
        url = serverUrl.main + 'docBuilderTemplateApi/doc-builder-template/' + pr.proposalId;
      }
    } else {
      url = serverUrl.main + 'documentBuilderApi/proposal-document/' + pr.proposalId;
    }

    dataServices.get({
      url: url + '?'
    }).then((response) => {
      if (response && response.data) {
        pr.proposal = response.data.result;
        if (pr.customerId) {
          getCustomerDetails();
        } else {
          loadLibraries();
        }
        
      }
    });
  }

  getProposal();

  pr.isProduction = process.env.NODE_ENV === 'production' && location.host !== 'nirvana.jacktrade.co';

  // const ORIGIN_URL = location.protocol + '//' + location.hostname + '/JT_Innova_ContentBuilder/latest/public/';

  const ORIGIN_URL = (location.hostname == 'localhost' ? 'https://nirvana.jacktrade.co' : location.origin) + '/JT_Innova_ContentBuilder/latest/public/';

  function loadStyle(src) {
    return new Promise(function (resolve, reject) {
      let link = document.createElement('link');
      link.href = src.url;
      link.rel = 'stylesheet';
      // link.setAttribute('id', src.id);
      link.classList.add(src.id);

      link.onload = () => resolve(link);
      link.onerror = () => reject(new Error(`Style load error for ${src.url}`));

      document.head.append(link);
    });
  }

  function loadStyles(srcs) {
    let promises = [];
    srcs.forEach(src => promises.push(loadStyle(src)));
    return Promise.all(promises);
  }

  function addFreepikIcon() {
    $('.proposal-section #proposal-container').ready(function () {
      let images = document.getElementById('proposal-container').getElementsByTagName('img');

      for (let i = 0; i < images.length; i++) {
        images[i].addEventListener('click', function () {
          $timeout(() => {
            const imageParent = document.getElementById('divImageTool');
            console.log(imageParent);

            if (imageParent && !document.getElementById('img-change')) {
              const button = document.createElement('button');
              button.setAttribute('id', 'img-change')
              button.setAttribute('title', 'Change Image');
              button.setAttribute('data-title', 'Change Image');
              button.setAttribute('class', 'image-change');
              button.setAttribute('style', 'width:40px;height:40px;background:none;');

              const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
              svg.setAttribute("viewBox", "0 0 32 32");
              svg.setAttribute("id", "icon-ico-camera");
              svg.setAttribute("width", "20px");
              svg.setAttribute("height", "20px");

              // Create the path element and set its attributes
              const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
              path.setAttribute("class", "path1");
              path.setAttribute("fill", "#000");
              path.setAttribute("d", "M15.722 12.939c-2.643 0-4.73 2.087-4.73 4.73s2.087 4.73 4.73 4.73c2.643 0 4.73-2.087 4.73-4.73-0.139-2.643-2.226-4.73-4.73-4.73v0zM28.243 8.209h-3.757c-0.557 0-1.113-0.417-1.252-0.835l-0.974-3.061c-0.278-0.417-0.835-0.835-1.252-0.835h-10.713c-0.417 0-0.974 0.417-1.252 0.835l-0.974 2.922c-0.139 0.557-0.696 0.974-1.113 0.974h-3.757c-1.809 0-3.2 1.391-3.2 3.061v14.052c0 1.809 1.391 3.2 3.2 3.2h25.043c1.67 0 3.2-1.391 3.2-3.2v-14.052c-0.139-1.67-1.53-3.061-3.2-3.061v0zM15.722 25.461c-4.313 0-7.791-3.478-7.791-7.791s3.478-7.791 7.791-7.791c4.313 0 7.791 3.478 7.791 7.791 0 4.174-3.478 7.791-7.791 7.791v0zM27.13 13.496c-0.557 0-1.113-0.557-1.113-1.113s0.557-1.113 1.113-1.113c0.557 0 1.113 0.557 1.113 1.113s-0.557 1.113-1.113 1.113v0z");

              // Append the path to the SVG
              svg.appendChild(path);

              // Append the SVG to the button
              button.appendChild(svg);
              button.onclick = function () {
                const images = document.getElementsByClassName('elm-active');
                console.log(images);
                for (let i = 0; i < images.length; i++) {
                  images[i].addEventListener('click', handleMouseEvent);

                  const clickEvent = new MouseEvent('click');

                  // Dispatch the click event on the current image
                  images[i].dispatchEvent(clickEvent);
                  images[i].removeEventListener('click', handleMouseEvent);
                }
              };
              imageParent.appendChild(button);
            }
          }, 0);

        });
      }
    });
  }

  function loadLibraries() {
    $.ajaxSetup({
      cache: true
    });

    const proposalcss = $('.proposalcss').length;
    let styleArr = [];

    if (!proposalcss) {
      styleArr = [{
        url: ORIGIN_URL + 'assets/minimalist-blocks/content.css',
        id: 'proposalcss'
      }, {
        url: ORIGIN_URL + 'block/block.css',
        id: 'proposalcss'
      }, {
        url: ORIGIN_URL + 'contentbuilder/contentbuilder.css',
        id: 'proposalcss'
      }, {
        url: ORIGIN_URL + 'assets/scripts/glide/css/glide.core.css',
        id: 'proposalcss'
      }, {
        url: ORIGIN_URL + 'assets/scripts/glide/css/glide.theme.css',
        id: 'proposalcss'
      }]
    } else {
      $('.proposalcss').removeAttr('disabled');
    }

    loadStyles(styleArr).then(() => {
      $.getScript(ORIGIN_URL + 'assets/scripts/glide/glide.js', function () {
        // here you can use anything you defined in the loaded script
        $.getScript(ORIGIN_URL + 'contentbuilder/contentbuilder.min.js', function () {
          initiate();
        });
        $.getScript(ORIGIN_URL + 'block/block.js', function () {
          // initiate();
        });
      });
    }).catch(err => {
      spinnerLoader.stop($rootScope.customSpinnerName);
      console.log(err)
    });
  }

  function handleMouseEvent(event) {
    pr.freepikPopup(event, builder);
  }

  function initiate() {
    spinnerLoader.stop($rootScope.customSpinnerName);

    builderOptions = {
      container: '.container',
      imageQuality: .6,
      canvas: true,
      previewURL: ORIGIN_URL + 'preview-canvas.html', // a must for canvas mode
      // pageSize: '10in,10in', // Default: 100%,100vh,web (values: 1000px,1000px,web | 11in,8.5in || 12in,12in ..)

      // snippetModal: true,
      snippetModalLeft: true,
      snippetOpen: true,
      previewStyle: 'bottom:30px;top:auto;left:300px;right:auto;', // default position for Live Preview window on open

      clearPreferences: true, // reset preferences settings
      zoom: 1,

      // To enable AI Assistant
      sendCommandUrl: '/sendcommand',
      // showDisclaimer: false,
      // startAIAssistant: true, // Auto open 'AI Assistant' panel
      enableShortCommands: false,
      speechRecognitionLang: 'en-US',
      triggerWords: {
        send: ['send', 'okay', 'ok', 'execute', 'run'],
        abort: ['abort', 'cancel'],
        clear: ['clear', 'erase']
      },
      AIModalStyle: 'right:300px;', // default position for AI Assistant panel on open

      // // If using DeepGram for speech recognition, specify the speechTranscribeUrl.
      // // speechTranscribeUrl: 'ws://localhost:3002',
      // // The server implementation for ws://localhost:3002 can be found in server.js (Node.js code)
      //
      // // Enabling AI image generation
      // textToImageUrl: '/texttoimage',
      // upscaleImageUrl: '/upscaleimage',
      // // imageAutoUpscale: false,
      // // viewFileUrl: '/viewfile', // If using S3

      // If you need to change some paths:
      snippetUrl: ORIGIN_URL + 'assets/minimalist-blocks/content.js', // Snippet file
      snippetPath: ORIGIN_URL + 'assets/minimalist-blocks/', // Location of snippets' assets
      modulePath: ORIGIN_URL + 'assets/modules/',
      assetPath: ORIGIN_URL + 'assets/',
      fontAssetPath: ORIGIN_URL + 'assets/fonts/',

      // Load plugins
      plugins: [{
        name: 'preview',
        showInMainToolbar: true,
        showInElementToolbar: true
      },
      {
        name: 'wordcount',
        showInMainToolbar: true,
        showInElementToolbar: true
      },
      {
        name: 'symbols',
        showInMainToolbar: true,
        showInElementToolbar: false
      },
      ],
      pluginPath: ORIGIN_URL + 'contentbuilder/', // Location of the plugin scripts

      // Open asset/file browser (can be replaced with your own file/asset manager application)
      imageSelect: ORIGIN_URL + 'assets.html',
      videoSelect: ORIGIN_URL + 'assets.html',
      audioSelect: ORIGIN_URL + 'assets.html',
      fileSelect: ORIGIN_URL + 'assets.html',
      mediaSelect: ORIGIN_URL + 'assets.html', // for images and videos
      // You can replace it with your own asset/file manager application
      // or use: https://innovastudio.com/asset-manager

      // Or use custom:
      // onImageSelectClick: () => {  },
      // onVideoSelectClick: () => {  },
      // onAudioSelectClick: () => {  },
      // onFileSelectClick: () => {  },
      // onMediaSelectClick: () => {  },

      onImageUpload: (e) => {
        console.log(e);
        uploadFile(e, (response) => {
          if (!response.error) {
            const uploadedFileUrl = response.url;
            if (uploadedFileUrl) builder.returnUrl(uploadedFileUrl);
          }
        });
      },
      onVideoUpload: (e) => {
        uploadFile(e, (response) => {
          if (!response.error) {
            const uploadedFileUrl = response.url;
            if (uploadedFileUrl) builder.returnUrl(uploadedFileUrl);
          }
        });
      },
      onAudioUpload: (e) => {
        uploadFile(e, (response) => {
          if (!response.error) {
            const uploadedFileUrl = response.url;
            if (uploadedFileUrl) builder.returnUrl(uploadedFileUrl);
          }
        });
      },
      onMediaUpload: (e) => { // for image & video
        console.log(e);
        uploadFile(e, (response) => {
          if (!response.error) {
            const uploadedImageUrl = response.url;
            if (uploadedImageUrl) builder.returnUrl(uploadedImageUrl);
          }
        });
      },
      onFileUpload: (e) => { // for file/document
        console.log(e);
        uploadFile(e, (response) => {
          if (!response.error) {
            const uploadedImageUrl = response.url;
            if (uploadedImageUrl) builder.returnUrl(uploadedImageUrl);
          }
        });
      },

      onChange: () => {
        console.log('Content changed.');
        // addFreepikIcon();
      },

      useLightbox: true

    }; // options

    builder = new ContentBuilder(builderOptions); // Start ContentBuilder

    builder.loadSnippets(ORIGIN_URL + 'assets/minimalist-blocks/content.js');
    // Load content
    let html = pr.proposalId ? pr.proposal.body : '';

    builder.loadHTML(html);
    const container = document.querySelector('.container');
    setTimeout(() => {
      container.style.opacity = ''; // Remove opacity 0 to show the content
      // addFreepikIcon();
      // if (pr.customerId) {
      //   let snippetList = angular.element(document.getElementById("divSnippetList"));
      //   snippetList.children()[0].style.position = 'relative';
      //   pr.openUrl = contactData.openUrl;
      //   let detailsContainer = $compile(`
      //   <div layout="column" class="customer-card">
      //     <div class="spacer-top-10 padding-5" layout="row" layout-align="space-between start">
      //       <div layout="row" class="gap-10 spacer-left-5">
      //         <div class="archive-img-container">
      //           <div class="user-img-holder">
      //             <img crossorigin="anonymous" ng-if="pr.contactData.images[0].thumbnailUrl" ng-src="{{pr.contactData.images[0].thumbnailUrl}}" alt="No Image" />
      //             <md-icon ng-if="(!pr.contactData.images[0].thumbnailUrl && pr.contactData.avatar)" aria-label="ico avatar" class="avatar circle-svg no-cursor" md-svg-icon="image:icon-ico-{{pr.contactData.avatar}}">
      //             </md-icon>
      //             <span class="img-replacer" ng-if="!(pr.contactData.images[0].thumbnailUrl || pr.contactData.avatar)">{{pr.contactData | nameAbbreviation}}</span>
      //           </div>
      //         </div>
      //         <div layout="column" class="customer-contact" layout-align="start">
      //           <h2 class="customer-name no-margin">{{pr.contactData | fullName}}</h2>
      //           <div class="f4 spacer-bot-5" ng-if="pr.contactData.company">At {{pr.contactData.company}}</div>

      //           <div flex class="contact-user-list PosR" style="left: -5px;">
      //             <div class="social-icon-wrapper" layout="row" layout-wrap>
      //               <md-button ng-repeat="link in pr.socialHandles track by $index" class="no-color md-icon-buttonsocialicon-btn" ng-click="pr.openUrl(pr.contactData.social[link])">
      //                 <md-icon class="{{link}}" ng-class="{'icon-grey' : !pr.contactData.social[link]}" md-svg-icon="set-d:icon-ico-{{link}}" aria-label="{{link}}"></md-icon>
      //                 <md-tooltip md-direction="top">{{ link | camelcase }}</md-tooltip>
      //               </md-button>
      //               <!-- <md-menu style="z-index: 11111111;" md-offset="26 35" md-position-mode="target-right target" ng-repeat="link in pr.socialHandles track by $index">
                    
      //               <md-menu-content class="status-dropmenu social-dropdown" width="6">
      //               <md-menu-item>
      //               <div class="input-box">
      //               <span>
      //               <md-icon aria-label="Ico-tick" ng-if="link != 'others'" class="ico-tick spacer-right-5 no-cursor" md-svg-icon="media:icon-{{link}}"></md-icon>
      //               {{ link | camelcase }}
      //               </span>
      //               <input type="text" ng-model="pr.contactData.social[link]" placeholder="{{ link | camelcase }} Link" required>
      //               <md-button class="btn-primary" aria-label="submit" ng-click="pr.saveSocialAddress($index)">
      //               {{'SAVE' | translate}}
      //               </md-button>
      //               <div class="v-url">
      //               <a ng-click="vm.openUrl(pr.contactData.social[link])" ng-if="pr.contactData.social[link]">{{'VISIT_URL' | translate}}</a>
      //               <a ng-click="vm.openSocialMedia(link)" ng-if="!pr.contactData.social[link] && link != 'others'"> {{'SEARCH' | translate}} {{link}}</a>
      //               </div>
      //               </div>
      //               </md-menu-item>
      //               </md-menu-content>
      //               </md-menu> -->
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <a class=" spacer-right-5" ui-sref="main.customers.customersDetail({customerId: pr.customerId})" target="_blank">
      //         <md-icon class="no-margin" md-svg-icon="set-b:icon-Open-new-tab"></md-icon>
      //       </a>
      //     </div>
      //     <div class="customer-contact-info" layoyt="column" layout-align="start space-between">
      //       <div layout="row" layout-align="start center" class="contact-line" ng-repeat="website in pr.contactData.websites track by $index">
      //         <md-icon class="icon-ico-mail" md-svg-icon="set-c:ico-email" aria-label="Email"></md-icon>
      //           <a class="mr-10" href="{{website}}" target="_blank">{{website}}</a>
      //       </div>
      //       <div layout="row" layout-align="start start" class="contact-line">
      //         <div>
      //           <md-icon class="icon-ico-mail" md-svg-icon="set-c:ico-email" aria-label="Email"></md-icon>
      //         </div>
      //         <a class="mr-10" href="mailto:{{pr.contactData.email[0].id}}"> {{pr.contactData.email[0].id}}
      //           <span class="status {{pr.contactData.emailVerificationStatus | emailStatus | lowercase}}">
      //             {{pr.contactData.emailVerificationStatus | emailStatus | translate}} (Primary email)
      //           </span>
      //         </a>
      //       </div>
      //       <div layout="row" layout-align="start center" class="contact-line" ng-repeat="phone in pr.contactData.phone track by $index">
      //         <div>
      //           <md-icon class="icon-ico-phone" md-svg-icon="set-a:icon-ico-phone" aria-label="PhoneNo"></md-icon>
      //         </div>
      //         <a ng-href="tel:{{$root.aObj.countries[phone.countryCode].callingCode ? ('+' + $root.aObj.countries[phone.countryCode].callingCode + '-') : ''}}{{phone.phone | phoneFormat}}">
      //           {{$root.aObj.countries[phone.countryCode].callingCode ? ('+' + $root.aObj.countries[phone.countryCode].callingCode + '-') : ''}}{{phone.phone | phoneFormat}} ({{phone.type}})
      //         </a>
      //       </div>
      //     </div>
      //   </div>
      //   <md-divider></md-divider>`)($scope);
      //   snippetList.prepend(detailsContainer);

      // }
    }, 500);

    function uploadFile(e, callback) {

      // For demo purpose (no file upload)
      const file = e.target.files[0];

      if (file.size > FILE_SIZES.max) {
        const error = `File size exceeds ${FILE_SIZES.maxMB}.`;
        messages.simpleToast(error, 'danger');
        if (callback) {
          callback({
            error
          });
        }
        return;
      }

      // new Compressor(file, {
      //   quality: .6,
      //   success(compressedFile) {
      dataServices.upload({
        url: serverUrl.main + 'mediaApi/upload/file',
        data: {
          file: [compressedFile]
        },
        spinner: false,
        isShowError: false
      }, true)
        .then((response) => {
          let data = response.data;
          if (response.status == '406') {
            let error = 'File couldn\'t be uploaded: System has detected the file as unsafe.';
            messages.simpleToast(error, 'danger');
            if (callback) {
              callback({
                error
              });
            }
          } else if (data && data.response_code == 200) {
            if (callback) {
              callback({
                url: data.result.success[0].webUrl
              });
            }
          }
        }, (error) => {
          if (callback) {
            callback({
              error
            });
          }
        });
    }
  }

  pr.saveSocialAddress = (index) => {
    contactData.saveSocialAddress(index, vm, vm.customerId);
  }

  const initBuilder = () => {
    if (!builder) {
      builder = new ContentBuilder(builderOptions);
    }
  };


  pr.menus = [
    {
      name: 'View/Hide Controls',
      click: () => {
        if (builder) {
          builder.destroy(); // Destroy
          builder = null;
        } else {
          builder = new ContentBuilder(builderOptions);
        }
      }
    },
    {
      name: 'View HTML',
      click: () => {
        initBuilder();
        builder.viewHtml();
      }
    },
    {
      name: 'Configure Builder',
      click: () => {
        initBuilder();
        builder.viewConfig();
      }
    },
    {
      name: 'Open AI Assistant',
      click: () => {
        initBuilder();
        useGpt();
        // builder.toggleAIAssistant();
      }
    },
    {
      name: 'Preview Proposal',
      click: () => {
        initBuilder();
        let html = builder.html();
        localStorage.setItem('preview-html', html);

        window.open(ORIGIN_URL + 'preview-canvas.html', '_blank').focus();
      }
    },
    {
      name: 'Page Size Options',
      click: () => {
        initBuilder();
        builder.openPageOptions();
      }
    },
    {
      name: 'A4 (landscape) Size',
      click: () => {
        initBuilder();
        builder.setPageSize("11.7in,8.3in");
      }
    },
    {
      name: 'Web Page Default Size',
      click: () => {
        initBuilder();
        builder.setPageSize("1000px,1000px,web");
      }
    },
    {
      name: 'Print The Proposal',
      click: () => {
        initBuilder();
        builder.print();
      }
    }
  ];

  // if (quoteId) {
  //   dataServices.get({
  //     url: serverUrl.main + 'invoiceApi/quote/' + quoteId + '/invoice/regular?action=preview&'
  //   }).then((response) => {
  //     if (response && response.data) {
  //       pr.regularSummary = pr.quoteResult = response.data.result;
  //     }
  //   });
  //   let popup = {
  //     invoice: `<md-dialog>
  //     <md-dialog-content>
  //       <md-content layout="row" class="gap-10" flex layout-padding>
  //         <div layout="column" class="gap-10">
  //           <md-button class=" bordered" ng-class="{'btn-bordered': ib.type == 'regular', 'btn-primary': ib.type != 'regular'}" ng-click='ib.serviceType("regular")' ng-if='ib.parentVm.quoteEstimateDetails.noOfRegularCycle'>Regular Service</md-button>
  //           <div ng-if='ib.parentVm.quoteEstimateDetails.noOfRecurringCycle'>
  //           <md-menu md-offset="80 40" md-position-mode="target-right target">
  //             <md-button class="bordered" ng-click='$mdMenu.open($event)'  ng-class="{'btn-bordered': ib.type != 'regular', 'btn-primary': ib.type == 'regular'}" >
  //               Recurring Service
  //             </md-button>
  //             <md-menu-content width="4" class="padding-10" style="height: 300px">
  //               <md-menu-item>
  //                 <ul layout="column" class="no-style gap-10">
  //                   <li class=" padding-5 bordered cursor-point" ng-repeat='service in ib.parentVm.recurringServices' ng-click='ib.serviceType(service.id)' ng-if="!service.isMaster"  ng-class="{'btn-bordered': ib.type == service.id, 'btn-primary': ib.type != service.id}" >
  //                     <div class="fm">{{service.serviceType | serviceTypeValue}}</div>
  //                     <div>Cycle {{service.cycleNumber}} </div>
  //                   </li>
  //                 </ul>
  //               </md-menu-item>
  //             </md-menu-content>
  //           </md-menu>
  //           </div>
  //         </div>
  //         <div layout="column" class="gap-20" layout-align="start end">
  //           <div layout="column" layout-wrap class="gap-10">
  //             <img class="cursor-point bordered md-whiteframe-1" ng-src="{{item}}" ng-repeat="item in ['assets/images/invoice-thumbnail.png', 'assets/images/sow-thumbnail.png', 'assets/images/res-thumbnail.png'] track by $index" ng-disabled="!ib.type" ng-click='ib.selectedBlock = ($index + 1)' ng-class="{'btn-bordered': ib.selectedBlock == ($index + 1),}" style="width: 300px; height: 200px">
  //           </div>
  //           <md-button class="btn-primary bordered no-margin" ng-disabled="!ib.selectedBlock" ng-click='ib.insertBlock(ib.selectedBlock)'>INSERT</md-button>
  //         </div>
  //       </md-content>
  //     </md-dialog-content>
  //   </md-dialog>`,
  
  //     signature: `<md-dialog style="max-width: 500px" class="full-width">
  //     <md-dialog-content>
  //       <md-content layout="column" class="gap-10" flex layout-padding>
  //         <div layout="column" class="gap-20" layout-align="start center">
  //           <div layout="row" layout-wrap class="gap-10">
  //             <img class="full-width cursor-point bordered md-whiteframe-1" ng-src="assets/images/sign-thumbnail-{{item}}.png" ng-repeat="item in [1,2,3,4,5,6] track by $index" ng-disabled="!ib.type" ng-click='ib.selectedBlock = ($index + 1)' ng-class="{'btn-bordered': ib.selectedBlock == ($index + 1)}" style="height: 200px">
  //           </div>
  //           <md-button class="btn-primary bordered no-margin" ng-disabled="!ib.selectedBlock" ng-click='ib.insertBlock(ib.selectedBlock)'>INSERT</md-button>
  //         </div>
  //       </md-content>
  //     </md-dialog-content>
  //   </md-dialog>`,
  //   }
  //   pr.menus.unshift({
  //     name: 'Invoice Blocks',
  //     click: () => {
  //       pr.quoteEstimateDetails = quoteDetails;
  //       if(pr.quoteEstimateDetails.noOfRegularCycle || pr.quoteEstimateDetails.noOfRecurringCycle) {
  //         getQuoteEstimateDetails().then(response => {
  //           $mdDialog.show({
  //             locals: {
  //               builder: builder,
  //               vm: pr,
  //               quoteId: quoteId
  //             },
  //             controller: InvoiceBlockController,
  //             template: popup.invoice,
  //             controllerAs: 'ib',
  //             multiple: true,
  //             clickOutsideToClose: true
  //           });
  //         });
  //       }
  //     }
  //   }, {
  //     name: 'Customer & Signature Blocks',
  //     click: () => {
  //       $mdDialog.show({
  //         locals: {
  //           builder: builder,
  //           vm: pr,
  //         },
  //         controller: SignatureBlockController,
  //         template: popup.signature,
  //         controllerAs: 'ib',
  //         multiple: true,
  //         clickOutsideToClose: true
  //       });
  //     }
  //   });
  // }

  function getQuoteEstimateDetails() {
    const deferred = $q.defer();
    let recurringApi;
    if(pr.quoteEstimateDetails.noOfRecurringCycle) {
      let filterCriteria = {
        filters: [{
          field: 'isArchived',
          operator: '=',
          value: false
        }, {
          field: 'taskScheduleType',
          operator: '=',
          value: 3
        }, {
          field: "isMaster",
          operator: "!=",
          value: true
        }],
        defaultSort: [{
          field: "cycleNumber",
          order: 1
        }]
      };
      recurringApi = dataServices.get({
        url: serverUrl.main + 'taskApi/services/quotes/' + quoteId + '?stats=yes&filtercriteria=' + angular.toJson(filterCriteria) + '&',
      }).then((response) => {
        if (response && response.data) {
          pr.recurringServices = response.data.result.records;
          console.log(pr.recurringServices);
        }
      });
    }

    $q.all([recurringApi]).then(() => {
      deferred.resolve('API calls are complete.');
    });
    return deferred.promise;
  }

  function useGpt() {
    $mdDialog.show({
      controller: OpenAIController,
      templateUrl: AITemplate,
      locals: {
        vm: pr,
        builder,
        builderOptions
      },
      fullscreen: true,
      multiple: true,
      parent: angular.element(document.body),
      controllerAs: 'cd'
    });
  };

  function openSaveDialog(ev, data) {
    if (!builder) {
      messages.simpleToast(`Proposal Builder isn't initiated!`);
    }
    spinnerLoader.start($rootScope.customSpinnerName);

    builder.saveImages('', () => {
      // Image saving done. Then you can save the content
      spinnerLoader.stop($rootScope.customSpinnerName);
      $mdDialog.show({
        locals: {
          vm: pr,
          templateData: builder.html(),
        },
        controller: SaveProposalController,
        templateUrl: saveProposalTemplate,
        targetEvent: ev,
        controllerAs: 'st',
        multiple: true
      });
      console.log("save proposal");
    }, (img, base64, filename) => {
      // Upload base64 images
      dataServices.upload({
        url: serverUrl.main + 'mediaApi/upload/base64',
        data: {
          data: angular.toJson([{
            type: img.src.split(';')[0].split('/')[1],
            data: img.src
          }])
        }
      })
        .then(function (response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            img.setAttribute('src', data.result.success[0].webUrl); // Update image src
          }
        });
    });
  }

  function SignatureBlockController($mdDialog, $filter, $rootScope, builder, vm, dataServices, serverUrl,) {

    'ngInject';

    let ib = this;
    ib.parentVm = vm;

    let date = new Date();

    function initQuoteDetails() {
      ib.quoteDetails = {
        clientName: ib.parentVm.quoteResult.customerInfo.primary.firstName + " " + ib.parentVm.quoteResult.customerInfo.primary.lastName,
        salesAgentName: ib.parentVm.quoteResult.salesPersonInfo.firstName + " " + ib.parentVm.quoteResult.salesPersonInfo.lastName,
        date: date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(),
        clientPhone: ib.parentVm.quoteResult.customerInfo.primary.phone[0] ? ib.parentVm.quoteResult.customerInfo.primary.phone[0].phone : 'N/A',
        clientEmail: ib.parentVm.quoteResult.customerInfo.primary.email[0] ? ib.parentVm.quoteResult.customerInfo.primary.email[0].id : 'N/A',
        senderEmail: ib.parentVm.quoteResult.salesPersonInfo.email[0] ? ib.parentVm.quoteResult.salesPersonInfo.email[0].value : 'N/A',
        signaturePrimary: ib.parentVm.quoteResult.signature && ib.parentVm.quoteResult.signature.primary ? ib.parentVm.quoteResult.signature.primary.webUrl : 'N/A',
        signatureSecondary: ib.parentVm.quoteResult.signature && ib.parentVm.quoteResult.signature.secondary ? ib.parentVm.quoteResult.signature.secondary.webUrl : 'N/A',
        senderCompany: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].origin_company_name : 'N/A',
        clientCompany: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].destination_company_name : 'N/A',
        senderAddress: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].originAddress : 'N/A',
        clientAddress: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].destinationAddress : 'N/A',
        invoiceId: ib.parentVm.quoteResult.quoteTitle.invoiceId ? ib.parentVm.quoteResult.quoteTitle.invoiceId : 'N/A',
        terms: ib.parentVm.quoteResult.termsAndConditions
      }

    }
    initQuoteDetails();

    ib.insertBlock = (item) => {
      let block;
      switch (item) {
        case 1:
          block = `
            <div class="column full">
                I, the undersigned ${ib.quoteDetails.clientName}, do hereby confirm that this invoice relates to a commercial transaction and this document contains a fair, complete and accurate description of that transaction and the relevant goods and/or services provided as well as a true and realistic description of their value.
            </div>
            <div class="column right">
              <div class="center transition-all column half whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-2 mb-3 py-5 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125"><img src=${ib.quoteDetails.signaturePrimary} alt=""></div>
              <div class="center transition-all column half whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-2 mb-1 py-2 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125">${ib.quoteDetails.date}</div>
            </div>
			`;
          break;
        case 2:
          block = `
				<div class="column full">
					<div>
						<h1>Entire Agreement</h1>
						<div>This document and its attachments constitute the entire agreement between the Client and the Provider. It supersedes all prior discussions, agreements, and understandings, providing a comprehensive and exclusive record of the agreed-upon terms.
							<p class="my-2">IN WITNESS WHEREOF, the parties have signed this agreement as of the Effective Date.</p>
						</div>
					</div>
					<div class="row">
						<div class="px-0 my-2 column half">
							<div>${ib.quoteDetails.clientCompany}</div>
							<div class="center column half transition-all whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-5 mb-3 py-5 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125">
								<img src={ib.quoteDetails.signaturePrimary} alt="">
							</div>
							<div class="center column half transition-all whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-5 mb-3 py-2 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125">${ib.quoteDetails.date}</div>
							<div>${ib.quoteDetails.clientName}</div>
						</div>
						<div class="px-0 my-2 column half">
							<div>${ib.quoteDetails.senderCompany}</div>
							<div class="center column half transition-all whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-5 mb-3 py-5 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125"><img src=${ib.quoteDetails.signatureSecondary} alt=""></div>
							<div class="center column half transition-all whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-5 mb-3 py-2 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125">${ib.quoteDetails.date}</div>
							<div>${ib.quoteDetails.salesAgentName}</div>
						</div>
					</div>
				</div>
			`
          break;
        case 3:
          block = `
            <div class="column">
              <div>${ib.quoteDetails.senderCompany}</div>
              <div>${ib.quoteDetails.senderAddress}</div>
              <div class="mb-3">${ib.quoteDetails.clientEmail}</div>
              <div>${ib.quoteDetails.clientName}</div>
              <div class="mb-3">${ib.quoteDetails.clientAddress}</div>
              <div class="center column half transition-all whitespace-nowrap cursor-pointer no-underline border-2 border-solid mr-2 mt-5 mb-3 py-2 px-8 border-current hover:border-current font-normal leading-relaxed rounded size-14 uppercase tracking-125">${ib.quoteDetails.date}</div>
            </div>
			    `
          break;
        case 4:
          block = `
            <div class="column">
              <div class="mb-3">Prepared for:</div>
              <div>${ib.quoteDetails.clientName}</div>
              <div class="mb-3">${ib.quoteDetails.clientCompany}</div>
              
              <div class="mt-3 mb-3">Created By:</div>
              <div>${ib.quoteDetails.salesAgentName}</div>
              <div>${ib.quoteDetails.senderCompany}</div>
            </div>
			`
          break;
        case 5:
          block = `
            <div class="column">
              <div class="mb-3">Seller:</div>
              <div class="mb-3">${ib.quoteDetails.senderAddress}</div>
              <div class="mb-3">${ib.quoteDetails.senderEmail}</div>
              <div class="mb-3">Buyer:</div>
              <div class="mb-3">${ib.quoteDetails.clientAddress}</div>
              <div>${ib.quoteDetails.clientEmail}</div>
            </div>
			`
          break;
        case 6:
          block = `
            <div class="column">
              <div class="mb-3">This sales agency agreement is entered into and deemed effective as of ${ib.quoteDetails.date} between the following entities, collectively known as the "Parties":</div>
              <div class="mb-3">${ib.quoteDetails.senderCompany} (Company) and</div>
              <div>${ib.quoteDetails.senderCompany} (Sales Agency) and</div>
            </div>
			  `
          break;
      }
      // builder.addSnippet(block);
      builder.addSnippet(
        `<div class="row">
            ${block}
        </div>`
      );
      builder.applyBehavior();
      ib.cancel();
    }

    ib.cancel = function () {
      ib.parentVm.quoteResult = ib.parentVm.regularSummary;
      $mdDialog.hide();
    };
  }

  function InvoiceBlockController($mdDialog, $filter, quoteId, $rootScope, builder, vm, dataServices, serverUrl,) {

    'ngInject';

    let ib = this;
    ib.parentVm = vm;
    ib.type = 'regular';
    console.log(ib.parentVm.quoteResult);
    let date = new Date();
    function initQuoteDetails() {
      ib.quoteDetails = {
        clientName: ib.parentVm.quoteResult.customerInfo.primary.firstName + " " + ib.parentVm.quoteResult.customerInfo.primary.lastName,
        salesAgentName: ib.parentVm.quoteResult.salesPersonInfo.firstName + " " + ib.parentVm.quoteResult.salesPersonInfo.lastName,
        date: date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(),
        clientPhone: ib.parentVm.quoteResult.customerInfo.primary.phone[0] ? ib.parentVm.quoteResult.customerInfo.primary.phone[0].phone : 'N/A',
        clientEmail: ib.parentVm.quoteResult.customerInfo.primary.email[0] ? ib.parentVm.quoteResult.customerInfo.primary.email[0].id : 'N/A',
        senderEmail: ib.parentVm.quoteResult.salesPersonInfo.email[0] ? ib.parentVm.quoteResult.salesPersonInfo.email[0].value : 'N/A',
        signaturePrimary: ib.parentVm.quoteResult.signature && ib.parentVm.quoteResult.signature.primary ? ib.parentVm.quoteResult.signature.primary.webUrl : 'N/A',
        signatureSecondary: ib.parentVm.quoteResult.signature && ib.parentVm.quoteResult.signature.secondary ? ib.parentVm.quoteResult.signature.secondary.webUrl : 'N/A',
        senderCompany: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].origin_company_name : 'N/A',
        clientCompany: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].destination_company_name : 'N/A',
        senderAddress: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].originAddress : 'N/A',
        clientAddress: ib.parentVm.quoteResult.serviceDetails[0] ? ib.parentVm.quoteResult.serviceDetails[0].destinationAddress : 'N/A',
        invoiceId: ib.parentVm.quoteResult.quoteTitle.invoiceId ? ib.parentVm.quoteResult.quoteTitle.invoiceId : 'N/A',
        terms: ib.parentVm.quoteResult.termsAndConditions
      }
    }
    initQuoteDetails();

    ib.serviceType = (item) => {
      console.log(item);
      if (item && item != 'regular') {
        dataServices.get({
          url: serverUrl.main + 'invoiceApi/quote/' + quoteId + '/invoice/recurring?serviceId=' + item + '&action=preview&showPrice=true&',
          spinner: $rootScope.customSpinnerName
        }).then(function (response) {
          if (response.data && response.data.result) {
            ib.parentVm.quoteResult = response.data.result;
            ib.type = item;
            initQuoteDetails()
          }
        });
      } else {
        ib.type = item;
        ib.parentVm.quoteResult = ib.parentVm.regularSummary;
        initQuoteDetails();
      }
    }

    function getEstimates(type, cat) {
      ib.estimatesData = {
        totalCost: 0
      };
      ib.estimatesData[type] = [];
      angular.forEach(ib.parentVm.quoteResult.serviceDetails, service => {
        angular.forEach(service.serviceDetails.estimates, estimate => {
          if (estimate.category == cat) {
            ib.estimatesData[type].push(estimate);
            ib.estimatesData.totalCost += estimate.totalCost;
          }
        });
      });
    }

    function parseServiceRates(rateArray, code = false) {
      let html = '';
      angular.forEach(rateArray, function (value, index) {
        // console.log(value);
        switch (value['rate_type']) {
          case 'flat_per_task':
            html += 'Rate (Flat Per Quantity):<br>';
            // console.log("value['code']", value['code']);
            if (code == 1005) {
              html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
              html += ' for ' + value['category'] + '<br>';
            } else {
              html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
              if (value['duration']) {
                html += ', Total ' + value['duration'] + ' Days<br>'
              }
            }
            break;

          case 'flat':
            html += 'Rate (Flat Per Day):<br>';
            // console.log("value['code']", value['code']);
            if (code == 1005) {
              html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
              html += ' for ' + value['category'] + '<br>';
            } else {
              html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
              html += ' / Day';
              if (value['duration']) {
                html += ', Total ' + value['duration'] + ' Days<br>'
              }
            }
            break;

          case 'composite':
            html += 'Rate (Composite):<br>';
            html += $filter('currencyForm')(value['initial_rate'], ib.parentVm.quoteResult.currency.currencySymbol);
            html += ' / Hour for Initial ' + value['initial_duration'] + ' Hours<br>';
            html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
            html += ' / Hour for Additional ' + value['duration'] + ' Hours<br>';
            break;

          case 'hourly':
            html += 'Rate (Hourly):<br>';
            html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
            html += ' / Hour, Total ' + value['duration'] + ' Hours<br>';
            break;

          case 'line_haul':
            html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
            html += ' for Line Haul<br>';
            break;

          case 'distance':
            html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
            html += ' / Mile for Distance Travel, Total ' + value['duration'] + ' Miles<br>';
            break;

          case 'percentage':
            html += 'Rate (Percentage):<br>';
            html += value['rate'] + ' % of ' + value['category'] + '<br>';
            break;

          case 'custom':
            html += 'Rate (Hourly):<br>';
            html += $filter('currencyForm')(value['rate'], ib.parentVm.quoteResult.currency.currencySymbol);
            html += ' / Hour, Total ' + value['duration'] + ' Hours<br>';
            if (value['discount']) {
              html += value['discount'] + '% Discount';
            }
            break;
        }
      });

      return html;
    }

    function getTemplate(key) {
      let snippet = `
            <div class="column full">
              <div>
                <h1>${key == 'sow' ? 'Scope of Work' : 'Resources'}</h1>
                <div>
                  We thrive to serve our customers and stand as a strong partner along their side. Please review the following ${key == 'sow' ? 'Scope of work' : 'resources'}.
                </div>
              </div>
              `;
      if (ib.estimatesData) {
        if (ib.estimatesData[key].length) {
          snippet += `
              <table class="data-table" width="100%" border="0" cellpadding="0" cellspacing="0">
                <thead>
                  <tr style="backgroud: #e5e7eb">
                    <th>Name</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>`;
        }
        angular.forEach(ib.estimatesData[key], estimate => {
          snippet += `<tr>
              <td>
                <div>${estimate.type}</div>
                <div>${estimate.description || 'N/A'}</div>
              </td>
              <td>${parseServiceRates(estimate.rate)}</td>
              <td>${estimate.quantity}</td>
              <td>${ib.parentVm.quoteResult.currency.currencySymbol}${estimate.totalCost}</td>
            </tr>`;
        });
      } else {
        snippet += `<div>
          The selected service doesn't have any ${key == 'sow' ? 'Scope of Work assigned' : 'Resources allocated'}.
        </div>`
      }
      if (ib.estimatesData && ib.estimatesData[key].length) {
        snippet += `</table>`;
      }
      snippet += `</div>`;

      return snippet;
    }

    ib.insertBlock = (item) => {
      let block;
      switch (item) {
        case 1:
          block = `
          <div class="column full">
            <div class="row">
              <div class="column half">
                <h1>Commercial <br>Invoice</h1>
              </div>
              <div class="column half">
                <h3 class="right">Bill To</h3>
              </div>
            </div>
            <div class="row">
              <div class="column half">
                <div class="row">
                  <div>Invoice No.: </div> <div>${ib.quoteDetails.invoiceId}</div>
                </div>
                <div class="row">
                  <div>Due Date: </div> <div>${ib.quoteDetails.date}</div>
                </div>
              </div>
              <div class="column half right">
                <div>${ib.quoteDetails.clientName}</div>
                <div>${ib.quoteDetails.clientAddress}</div>
                <div>${ib.quoteDetails.clientPhone}</div>
              </div>
            </div>
          </div>`;
          break;
        case 2:
          getEstimates("sow", "Scope of Work");
          block = getTemplate('sow');
          break;
        case 3:
          getEstimates("resources", "Resources");
          block = getTemplate('resources');
          break;
      }
      // builder.addSnippet(block);
      builder.addSnippet(
        `<div class="row">
            ${block}
        </div>`
      );
      builder.applyBehavior();
      ib.cancel();
    }

    ib.cancel = function () {
      ib.parentVm.quoteResult = ib.parentVm.regularSummary;
      $mdDialog.hide();
    };
  }

  function SaveProposalController($mdDialog, messages, validatorService, dataServices, serverUrl, vm, templateData) {

    'ngInject';

    let st = this;
    // st.name = pr.template.name;
    // st.category = pr.template.category;
    // st.isTemplate = pr.isTemplate;
    st.save = validatorService.validateForm(st, saveProposal);
    console.log("save proposal");

    st.getCategories = () => {
      return st.templateCategories ? st.templateCategories : dataServices.get({
        url: serverUrl.main + (pr.isTemplate ? 'docBuilderTemplateApi/doc-builder/category?' : 'documentBuilderApi/proposal-document/category?'),
        spinner: false
      })
        .then(function (response) {
          const data = response.data;
          if (data && data.response_code == 200) {
            st.templateCategories = data.result;
          }
        });
    };

    if (pr.proposalId || (pr.isTemplate && pr.proposalId)) {
      st.name = pr.proposal.name;
      st.category = pr.proposal.category;
      $timeout(function () {
        st.getCategories();
      }, 0);
    }

    function saveProposal(fileId) {
      const dataToSend = {
        name: st.name,
        category: st.category,
        body: templateData,
        active: true
      };

      if (pr.docId) {
        dataToSend.documentId = pr.docId;
      }

      let isEdit = !pr.isClone && pr.proposalId;
      let url = 'documentBuilderApi/proposal-document' + (isEdit ? ('/' + pr.proposalId) : '');

      if (pr.isTemplate) {
        if (pr.proposalId) {
          isEdit = !pr.isClone && pr.isTemplate;
          url = 'docBuilderTemplateApi/doc-builder-template' + (isEdit ? ('/' + pr.proposalId) : '');
        } else {
          url = 'docBuilderTemplateApi/doc-builder-template';
        }
      }

      console.log(dataToSend);
      // return;

      dataServices.post({
        url: serverUrl.main + url,
        data: {
          data: angular.toJson(dataToSend)
        },
        method: isEdit ? 'PUT' : ''
      })
        .then(function (response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            st.cancel();

            messages.simpleToast('Saved Successfully.');
          }
        });
    }

    st.cancel = function () {
      if (angular.isFunction(vm.parentVm.setFilters)) {
        vm.parentVm.setFilters();
      }
      vm.cancel();
      $mdDialog.hide();
    };
  }

  function OpenAIController($mdDialog, builder, spinnerLoader, messages, OpenAIService, maxlength, errorMessage, $rootScope, vm) {

    'ngInject';

    let cd = this;
    angular.extend(cd, vm);
    cd.aiPopup = 'Proposal Builder'
    cd.parentVm = vm;
    cd.maxlength = maxlength;
    cd.errorMessages = errorMessage;
    cd.gpt = false;
    cd.step1 = true;
    cd.step2 = false;
    cd.index = 0;
    cd.gptData = [];
    cd.actionItems = [];

    cd.confirm = () => {
      if(cd.designArray && cd.designArray.length) {
        // builder.loadHTML('<div class="is-box box-canvas autolayout" data-pagesize="1000px,1000px,web"></div>');
        builder.loadHTML(`<div></div>`)
        angular.forEach(cd.designArray, block => {
          builder.addSnippet(block);
          builder.applyBehavior();
        });
      }
      // builder = new ContentBuilder(builderOptions);
      // builder.loadHTML(cd.design);
      cd.cancel();
    }

    // cd.messages = [{
    //   role: "system",
    //   content: 'Generate proposal builder template from the given topic. The generated template should have html tags, inline css and images instead of plain text. Structure of HTML should begin with <div class="row"><div class="column full"></div></div> tag as container. Each section of the generated template should be an individual block. Present the available data in the following complete JSON format {"template": ["block1", "block2"]} with correct termination'
    // }];

    cd.messages = [{
      role: "system",
      content: 'Generate proposal builder template from the given topic. The generated template should have html tags and inline css instead of plain text. Structure of HTML should begin with <div class="row"><div class="column full"></div></div> tag as container. Each section should be an individual block with proper ending tag and tags inner HTML should not be empty. Present the available data in the following complete and proper JSON format only {"template": ["block1", "block2"]}.'
    }];

    cd.nextStep = () => {
      if (cd.field == 1) {
        cd.field = 2;
      }
    }

    cd.backStep = () => {
      if (cd.field == 2) {
        cd.field = 1;
      }
    }

    cd.sendPromptToGPT = () => {
      if (!cd.gptInput) {
        messages.simpleToast("Enter data", "danger");
        return;
      }
      spinnerLoader.start($rootScope.customSpinnerName);

      let prompt = cd.gptInput;
      cd.messages.push({
        role: "user",
        content: prompt
      });

      cd.gptData.push({
        prompt
      });

      OpenAIService.createChatCompletion(cd.messages, 5000).then(response => {
        let data = response.replace(/^```json\n|\n```$/g, '');
        spinnerLoader.stop($rootScope.customSpinnerName);
        try {
          data = JSON.parse(data);
          console.log(data.template);
          cd.designArray = data.template;
          cd.design = '';
          angular.forEach(data.template, block => {
            console.log(block);
            cd.design = cd.design + block;
          });
          cd.step1 = false;
          cd.step2 = true;
          $timeout(function () {
            let input = document.getElementById("gpt-input");
            input.focus();
          });
        } catch (error) {
          console.log(error);
          messages.simpleToast("Data you entered could not be converted. Please reevaluate.", "danger");
          return;
        }
      });
    };

    cd.goToNextStep = () => {
      cd.step2 = !cd.step2;
      cd.step1 = !cd.step1;
    }

    cd.cancel = function () {
      $mdDialog.cancel();
    };
  }

  pr.cancel = () => {
    if (builder) {
      try {
        builder.destroy(); // Destroy
        builder = null;
      } catch (err) {
        console.log(err);
      }
    }
    $('.proposalcss').attr("disabled", "disabled");
    $mdDialog.cancel();
  }
}