import shareConversationTemplate from './../../components/notes/share-conversation-popup.html';
import {
  OPPORTUNITIES_LIST
} from '../../pages/customers/customers.resolve.obj';
import findIndex from 'lodash/findIndex';

export default function (app) {
  app.factory('NotesService', notesService);

  let vm, parentVm, body;
  function notesService($state, $rootScope, $sce, $stateParams, dateFactory, $mdConstant, dataServices, $timeout, serverUrl, $mdDialog, $mdSidenav, $filter, sharedDataService, messages, ListService, validatorService, timeList, TimezoneService, contactData, FileUploadService, imageService) {
    "ngInject";
    return {
      init: init,
      initNotes: initNotes,
    }

    function init(_vm, _parentVm) {
      vm = _vm;
      parentVm = _parentVm;
    }
    function initNotes() {

      vm.enableNewConversation = true;
      let customerId = vm.customerId;
      vm.showFunction = sharedDataService.get('notesExpandedState')
      vm.fiveDaysAgoTS = moment()
        .subtract(5, "day")
        .endOf("day")
        .unix();
      console.log(vm.fiveDaysAgoTS);

      FileUploadService.init(vm, 'conversation', 'right');
      vm.showImagePopUp = imageService.showImagePopUp;
      vm.timeZone = TimezoneService.getTimeZoneShortName();

      vm.separatorKeys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA];

      vm.timeList = timeList;
      vm.totalFileUpload = [];
      vm.allLabelsNotes = true;
      vm.isRequiredApiFailed = parentVm.isRequiredApiFailed = {};
      vm.selectedFun = 'All Functions';
      vm.conIconTypes = {
        'post': 'chat',
        'idea': 'bulb',
        'meeting': 'docs',
        'calendar': 'g-cal',
        'reminder': 'bell',
        'question': 'question',
        'attachment': 'attach'
      };

      vm.conversationTypes = ['post', 'idea', 'meeting', 'calendar', 'reminder', 'attachment', 'question'];
      vm.conversationTitles = ['Message', 'Idea', 'Meeting Notes', 'Calendar Invite', 'Reminder', 'Attachment', 'Question'];
      vm.conversationIcons = ['chat', 'bulb', 'docs', 'g-cal', 'bell', 'attach', 'question'];

      let user = $rootScope.usrObj;
      vm.fromEmail = [{
        images: user.images,
        avatar: user.avatar,
        email: user.userEmail,
        lastName: user.lastName,
        firstName: user.firstName,
        middleName: user.middleName
      }];

      function initSetOfDays() {
        vm.setOfDays = [{
          name: 's',
          class: 'dayOff'
        }, {
          name: 'm'
        }, {
          name: 't'
        }, {
          name: 'w'
        }, {
          name: 't'
        }, {
          name: 'f'
        }, {
          name: 's',
          class: 'dayOff'
        }];
      }
      initSetOfDays();

      const stateName = $rootScope.currentStateName;
      vm.isCustomerNotes = stateName === 'main.customers.customersConversation';

      // To show function filters in customers
      if (vm.isCustomerNotes) {
        vm.allNotes = true;
      }

      vm.conversationsCount = {
        10: 0,
        45: 0,
        20: 0,
        28: 0,
        83: 0,
        85: 0,
        total: 0
      };

      vm.modelMap = {
        'main.customers.customersConversation': {
          name: 10,
          id: vm.customerId
        },
        'main.customers.opportunitiesNotes': {
          name: 45,
          id: $stateParams.cardId
        },
        'main.quotes.notes': {
          name: 20,
          id: $stateParams.quoteId
        },
        'main.jobs.notes': {
          name: 28,
          id: $stateParams.quoteId
        },
        'main.quotes.servicesWorkorder': {
          name: 83,
          id: $stateParams.serviceId
        },
        'main.jobs.servicesWorkorder': {
          name: 85,
          id: $stateParams.serviceId
        }
      }[stateName];

      vm.functionIcons = {
        10: 'customers',
        20: 'quotes',
        28: 'jobs',
        45: 'customers',
        83: 'quotes',
        85: 'jobs'
      };

      vm.filterFunctionsArray = [45, 20, 28, 83, 85];

      vm.filterFns = {
        45: {
          functionName: 'Opportunities',
          cbac: 'customer_opportunities'
        },
        20: {
          functionName: 'Quotes',
          cbac: 'quotes'
        },
        28: {
          functionName: 'Jobs',
          cbac: 'jobs'
        },
        83: {
          functionName: 'Work Order',
          cbac: 'quote_service_work_order'
        },
        85: {
          functionName: 'Work Order',
          cbac: 'job_service_work_order'
        },
      };

      vm.filterFunctions = {
        45: {
          functionName: 'Opportunities',
          cbac: 'customer_opportunities'
        },
        20: {
          functionName: 'Quotes',
          cbac: 'quotes'
        },
        28: {
          functionName: 'Jobs',
          cbac: 'jobs'
        },
        83: {
          functionName: 'Work Order (Quotes)',
          cbac: 'quote_service_work_order'
        },
        85: {
          functionName: 'Work Order (Jobs)',
          cbac: 'job_service_work_order'
        },
      };

      vm.getFunctionsList = (con) => {
        vm.editingCon = {
          ...con
        };
        vm.editingAttachFunctionCon = vm.editingCon;

        vm.functionVal = vm.functionVal || (vm.isCustomerNotes ? 20 : vm.modelMap.name);
        vm.selectedBusinessId = vm.selectedBusinessId || (vm.isCustomerNotes ? (vm.contact.lastLocationServed || $rootScope.usrObj.businessAddress[0].addressId) : $stateParams.locationId);

        $mdSidenav('attachFunction').open().then(() => {
          $mdSidenav('attachFunction').onClose(function () {
            vm.editingCon = false;
          });
        });

        if (vm.functionVal == 45) {
          getOpportunitiesBoard();
        }

        vm.openSidenavResource("attachFunctionValue");
      };

      vm.menuRightNav = (navId) => {
        return $mdSidenav(navId).toggle();
      }

      function getOpportunitiesBoard() {
        vm.boardId = "";
        vm.cards = [];
        vm.opportunityBoard = [];

        let filterCriteria = angular.toJson({
          filters: [{
            field: "location",
            operator: "=",
            value: vm.selectedBusinessId.toString(),
          }]
        });

        dataServices
          .get({
            url: serverUrl.main +
              "opportunityApi/opportunity/boards?filterCriteria=" +
              filterCriteria +
              "&",
            spinner: false
          })
          .then(function (response) {
            if (response && response.data && response.data.result) {
              vm.opportunityBoard = response.data.result;
            }
          });
      }

      vm.openSidenavResource = (navID, sectionId = ".task-wrapper") => {
        vm.switchList(vm, parentVm, navID, vm.setFilters);
      };

      vm.getOpportunities = () => {
        vm.openSidenavResource('cards');
      };

      // vm.functionVal
      vm.selectUnselectFunction = (item) => {
        vm.postAttachFunctionData = item;
        vm.editingCon.modelName = vm.functionVal + '';
        if (vm.functionVal == 20 || vm.functionVal == 28) {
          vm.editingCon.modelId = vm.editingCon.modelId == item.quoteId ? undefined : item.quoteId;
        } else if (vm.functionVal == 45) {
          vm.editingCon.modelId = vm.editingCon.modelId == item.id ? undefined : item.id;
        } else if (vm.functionVal == 83 || vm.functionVal == 85) {
          vm.editingCon.modelId = vm.editingCon.modelId == item.id ? undefined : item.id;
        }
        if (vm.editingCon.modelId == undefined) {
          vm.editingCon.modelId = vm.customerId + "";
          vm.editingCon.modelName = '10';
        }
      };

      const isPanelOpen = sharedDataService.get('isRightPanelOpen');
      vm.isPanelOpen = isPanelOpen !== null ? isPanelOpen : true;

      vm.removeFunction = (con) => {
        vm.editingCon = {
          ...con
        };
        vm.editingCon.modelId = vm.customerId + "";
        vm.editingCon.modelName = '10';
        const conversation = dataServices.toSave(vm.editingCon, ['customerId', 'body', 'type', 'entityId', 'modelId', 'modelName', 'startDate', 'endDate', 'title', 'sharedTo', 'timeZone', 'labels']);
        saveConversationFinal(vm.editingCon, conversation);
      }

      vm.attachFunction = () => {
        const conversation = dataServices.toSave(vm.editingCon, ['customerId', 'body', 'type', 'entityId', 'modelId', 'modelName', 'startDate', 'endDate', 'title', 'sharedTo', 'timeZone', 'labels']);
        saveConversationFinal(vm.editingCon, conversation);
        $mdSidenav('attachFunction').toggle();
      };

      vm.attachFunctionData = () => {
        if (vm.functionVal == 45) {
          getOpportunitiesBoard();
        } else {
          vm.openSidenavResource("attachFunctionValue");
        }
        vm.postAttachFunctionData = undefined;
      };

      vm.postAttachFunction = () => {
        if (vm.postAttachFunctionData) {
          console.log("post");
          vm.attachFunctionPostData = vm.postAttachFunctionData;
          vm.conversation.modelName = vm.functionVal;
          console.log(vm.attachFunctionPostData)
        }

        if (vm.postAttachFunctionData && vm.functionVal == 10) {
          vm.conversation.modelId = 5;
        }

        if (vm.postAttachFunctionData && vm.functionVal == 45) {
          vm.conversation.modelId = vm.attachFunctionPostData.id;
        }

        if (vm.postAttachFunctionData && (vm.functionVal == 28 || vm.functionVal == 20)) {
          vm.conversation.modelId = vm.attachFunctionPostData.quoteId;
        }
        if (vm.postAttachFunctionData && (vm.functionVal == 83 || vm.functionVal == 85)) {
          vm.conversation.modelId = vm.attachFunctionPostData.id;
          ;
        }
        $mdSidenav('attachFunction').close();
      }

      vm.removePostAttachFunction = () => {
        vm.attachFunctionPostData = undefined;
        vm.conversation.modelName = 10;
        vm.functionVal = undefined;
      }

      vm.lists = parentVm.lists = ['attachFunctionValue', 'cards', 'templates', 'postHistory'];
      vm.listName = parentVm.listName = vm.lists[0];

      vm.defaultFc = {
        'attachFunctionValue': angular.copy({
          filters: [{
            field: "customerId",
            operator: "=",
            value: customerId,
          }],
          defaultSort: [{
            field: "lastUpdated",
            order: -1
          }],
          onlyPagination: true,
          skip: 0,
          limit: 15
        }),
        'cards': angular.copy(OPPORTUNITIES_LIST.filterCriteria),
        'templates': {
          filters: [{
            field: "isArchived",
            value: false,
            operator: "="
          }],
          skip: 0,
          limit: 15
        },
        'postHistory': {
          filters: [{
            field: "isArchived",
            value: false,
            operator: "="
          }],
          defaultSort: [{
            field: "lastUpdated",
            order: -1
          }],
          skip: 0,
          limit: 15
        }
      };

      ListService.assignedDataCopy(vm, false, [], 'attachFunctionValue', 'id');
      ListService.assignedDataCopy(vm, false, [], 'cards', 'id');
      ListService.assignedDataCopy(vm, false, [], 'templates', 'id');
      ListService.assignedDataCopy(vm, false, [], 'postHistory', 'id');

      vm.getList = getList;
      vm.setFilters = setFilters;

      function getList(type = 'new', retainLimit, switchPageType, callback) {
        let url, spinnerName = "noSpinner",
          filterCriteria = ListService.setPrimaryDataCopy(vm, type, retainLimit, switchPageType);
        switch (vm.listName) {
          case 'actionActive':
          // view model, url, data, what list name you want
          case 'attachFunctionValue':
            url = serverUrl.main + 'actionsApi/action/getFunctions?filterCriteria=' + filterCriteria + '&modelName=' + vm.functionVal + '&addressId=' + vm.selectedBusinessId + '&';
            spinnerName = 'get-function-spinner'
            break;
          case 'cards':
            url = serverUrl.main + 'opportunityApi/opportunity/cards?filterCriteria=' + filterCriteria + '&';
            break;
          case 'templates':
            console.log("switch case");
            url = serverUrl.main + 'convTemplateApi/convTemplates?filterCriteria=' + filterCriteria + '&';
            break
          case 'postHistory':
            url = serverUrl.main + 'convHistoryApi/conversations/history/' + vm.conHistory.id + '?filterCriteria=' + filterCriteria + '&';
            spinnerName = 'notes-history-sidebar-spinner';
            break
        }
        ListService.postList(vm, url, vm.enableList, spinnerName, type == 'add', callback);
      }

      function setFilters(type, retainLimit = false, switchPageType = false) {
        console.log(vm.defaultFc[vm.listName].filters);
        vm.filters[vm.listName] = angular.copy(vm.defaultFc[vm.listName].filters);

        if (vm.listName === 'cards') {
          ListService.addFilter(vm, 'boardId', vm.boardId, '=');
          ListService.addFilter(vm, 'customerId', customerId, '=');
        }
        console.log(vm.listName);
        console.log(vm.noteTemplates.category_id);
        if (vm.listName === 'templates') {
          ListService.addFilter(vm, 'function', vm.copyTemplateCon.modelName.toString(), '=');

          if (vm.status == 'status-btn-1') {
            ListService.addFilter(vm, 'bzPreferred', true, '=');
          }

          if (vm.status == 'status-btn-2') {
            ListService.addFilter(vm, 'bzPreferred', false, '=');
          }

          if (vm.noteTemplates.category_id) {
            ListService.addFilter(vm, 'categoryId', vm.noteTemplates.category_id, '=');
          }

          if (vm.removeAllFilters) {
            ListService.removeFilter(vm, 'categoryId');
            ListService.removeFilter(vm, 'bzPreferred');
            vm.noteTemplates.category_id = undefined;
            vm.removeAllFilters = false;
          }
          console.log(vm.noteTemplates.category_id);
        }

        vm.getList(type, retainLimit, switchPageType);
      }

      vm.changeConversationType = changeConversationType;
      vm.toggleRight = toggleRight;
      vm.isCloseRight = isCloseRight;
      vm.shareConversation = shareConversation;
      vm.saveConversation = saveConversation;
      vm.filterConversations = filterConversations;
      vm.resetCalendarAndReminder = resetCalendarAndReminder;
      vm.changeStartDate = dateFactory.changeStartDate;
      vm.resetTime = resetTime;

      function resetTime() {
        vm.calendarStartTime = 0;
        vm.calendarEndTime = 47;
      }

      // function changeStartDate() {
      //   if (vm.calendarEndDate < vm.calendarStartDate) {
      //     vm.calendarEndDate = vm.calendarStartDate;
      //   }
      // }

      function resetCalendarAndReminder() {
        vm.reminder = {};
        vm.calendar = {};
        vm.selectedContacts = [];
        vm.days = [];
        vm.selectedTimezone = vm.searchTextZone = null;
        vm.calendarStartDate = vm.calendarEndDate = vm.calendarStartTime = vm.calendarEndTime = vm.reminderStartDate = vm.reminderStartTime = undefined;
      }

      // Cancel card edit
      vm.cancelEdit = function (con) {
        resetCalendarAndReminder();
        if (con) {
          vm.conEdit[con.id] = vm.editingCon = false;
          FileUploadService.init(vm, 'conversation', 'right');
          // restore content on cancel
          con.body = body;
        }
      };

      function resetVal() {
        if (vm.isChannelConversation) {
          vm.conversation = {
            body: '',
            type: vm.conversationTypes[0],
          };
          vm.shareMsg = 'SHARE';
          vm.cancelEdit(vm.editingCon);
        } else {
          vm.conversation = {
            customerId,
            body: '',
            type: vm.conversationTypes[0],
            modelId: vm.modelMap.id + '',
            modelName: vm.modelMap.name
          };
          vm.attachFunctionPostData = undefined;
          vm.conversation.labels = [];
          vm.selectedPostLabelsCopy = undefined;
          vm.shareMsg = 'POST';
          vm.cancelEdit(vm.editingCon);
        }

      }

      // init variables and get contact details
      resetVal();
      console.log(vm.conversation);
      if (!vm.isChannelConversation) {
        contactData.assignedData(vm, customerId, function () {
          parentVm.screenData = vm.contact.firstName + ' ' + vm.contact.lastName + ' - ' + customerId + ' - ' + ((vm.contact.isArchived) ? 'Archived' : 'Active');
        });
      }

      function isCloseRight() {
        return $mdSidenav('right').close();
      }

      /* Side menu Toggler*/
      function toggleRight(navId) {
        $mdSidenav(navId).toggle();
      }

      //////
      // Labels code starts here
      vm.selectedLabels = {};
      vm.selectedLabelsCopy = {};
      vm.labels = [];

      vm.getBgColor = function (index) {
        const colors = ['#FFDAB9', '#ADD8E6', '#98FB98'];
        return colors[index % colors.length]
      };

      vm.openAssignLabels = (con) => {
        if (con) {
          vm.editingCon = con;
          vm.editingLabelsCon = vm.editingCon;
          vm.selectedLabelsCopy = {};
          angular.forEach(con.labelInfo, (label, i) => {
            vm.selectedLabelsCopy[label.id] = label;
          });
        } else if (!con) {
          vm.conversationPost = {};
          vm.editingLabelsCon = undefined;
          vm.conversationPost.labels = [];
          vm.selectedLabelsCopy = {};
          if (vm.selectedPostLabelsCopy) {
            vm.selectedLabelsCopy = angular.copy(vm.selectedPostLabelsCopy);
          }
          console.log(vm.selectedPostLabelsCopy);
          console.log(vm.selectedLabelsCopy);
        }

        $mdSidenav('assignLabels').open().then(() => {
          $mdSidenav('assignLabels').onClose(function () {
            vm.editingCon = false;
          });
        });

        if (!vm.labels.length) {
          getAssignedLabels();
        }
      }

      // vm.labelsIndex = 0;
      // vm.toggleLabels = function() {
      //   console.log(vm.conversation.labels);
      //   vm.selectedPostLabel = vm.selectedPostLabelsCopy[vm.conversation.labels[vm.labelsIndex]];
      //   console.log(vm.selectedPostLabel);
      //   if(vm.labelsIndex == vm.conversation.labels.length - 1) {
      //     vm.labelsIndex = 0;
      //   } else {
      //     vm.labelsIndex++;
      //   }

      // };

      vm.closeAssignLabels = () => {
        $mdSidenav('assignLabels').close();
      };

      vm.getAssignedLabels = getAssignedLabels;

      function getAssignedLabels() {
        vm.isLoadingLabels = true;
        dataServices.get({
          url: serverUrl.main + 'convLabelApi/convLabels?',
          method: 'GET',
          spinnerName: 'notes-labels-sidebar-spinner'
        }).then(response => {
          vm.isLoadingLabels = false;
          if (response && response.data) {
            vm.labels = response.data.result;
          }
        }, error => {
          vm.isLoadingLabels = false;
        });
      }

      vm.updateSelectedLabels = (item) => {
        if (vm.selectedLabelsCopy[item.id]) {
          delete vm.selectedLabelsCopy[item.id];
        } else {
          if (Object.keys(vm.selectedLabelsCopy).length >= 3) {
            messages.simpleToast('YOU_CAN_SELECT_MAX_THREE_LABELS.');
            return;
          }
          vm.selectedLabelsCopy[item.id] = item;
        }
      }

      vm.saveLabels = saveLabels;

      function saveLabels() {
        console.log("Save labels")
        const conversation = dataServices.toSave(vm.editingCon, ['customerId', 'body', 'type', 'entityId', 'modelId', 'modelName', 'startDate', 'endDate', 'title', 'sharedTo', 'timeZone', 'labels']);
        saveConversationFinal(vm.editingCon, conversation);
        vm.closeAssignLabels();
      }

      vm.savePostLabels = () => {
        vm.selectedPostLabelsCopy = angular.copy(vm.selectedLabelsCopy);
        vm.conversation.labels = Object.keys(vm.selectedLabelsCopy);
        if (vm.conversation.labels.length != 0) {
          vm.selectedPostLabel = vm.selectedPostLabelsCopy[vm.conversation.labels[0]];
          // vm.labelsIndex++;
        }
        vm.closeAssignLabels();
      }

      vm.cancelLabels = cancelLabels;

      function cancelLabels() {
        vm.closeAssignLabels();
      }

      // Labels ended

      //create labels
      vm.manageNotesLabels = manageNotesLabels;

      function manageNotesLabels() {
        $mdSidenav('notesLabels').toggle();
        getLabels();
      }
      vm.closenotesLabelsSidebar = () => {
        $mdSidenav('notesLabels').close();
      };

      function getLabels() {
        dataServices.get({
          url: serverUrl.main + 'convLabelApi/convLabels?',
          method: 'GET',
          spinnerName: 'notes-labels-sidebar-spinner'
        }).then(response => {
          if (response && response.data) {
            console.log(response);
            prepareLabels(response.data.result);
          }
        });
      }

      function prepareLabels(labels) {
        vm.labels = labels;
        vm.noOfLabels = labels.length;
        angular.forEach(labels, function (value) {
          if (!vm.notesLabels[value.id]) {
            vm.notesLabels[value.id] = value;
            // vm.colorClass[value.color] = false;
          }
          vm.notesLabels[value.id].edit = false;
        });
      }

      vm.saveUpdatedLabels = (label = null) => {
        let data = label ? updateData() : dataServices.toSave(vm.label, ['color', 'name', 'status']);
        data.status = 2;

        function updateData() {
          return {
            'color': label.color,
            'name': label.name,
            'status': label.status
          }
        }

        dataServices.post({
          url: serverUrl.main + 'convLabelApi/convLabel' + (label ? '/' + label.id : ''),
          method: (label ? 'PUT' : 'POST'),
          spinnerName: 'notes-labels-sidebar-spinner',
          data: {
            data: angular.toJson(data)
          }
        }).then(response => {
          if (response && response.data) {
            console.log(response.data);
            getLabels();
          }
        });
        vm.resetSelectedLabel();
        vm.createLabelForm.$setPristine();
        vm.createLabelForm.$setUntouched();
      };

      vm.resetSelectedLabel = () => {
        vm.label = {
          name: '',
          color: '',
          status: 2
        };
      };

      vm.deleteLabel = (label) => {
        dataServices.delete({
          url: serverUrl.main + 'convLabelApi/convLabels/' + label.id,
          spinnerName: 'notes-labels-sidebar-spinner'
        }).then(response => {
          if (response && response.data) {
            delete vm.notesLabels[label.id];
            getLabels();
            messages.simpleToast("Deleted Successfully");
          }
        })
      };
      vm.colorClass = {
        '#741b47': true,
        '#351c75': true,
        '#0b5394': true,
        '#1155cc': true,
        '#134f5c': true,
        '#38761d': true,
        '#bf9000': true,
        '#b45f06': true,
        '#990000': true,
        '#434343': true,
        '#5b0f00': true,
      };
      vm.focus = focus;
      vm.labelCopy = {};
      vm.notesLabels = {};
      vm.labelCopy = [];

      function focus(label, index) {
        vm.labelCopy[label.id] = angular.copy(label);
        label.edit = true;

        $timeout(function () {
          $('#label' + index).focus().select();
        }, 0);
      };

      vm.cancelEditLabel = function (id) {
        vm.notesLabels[id] = angular.copy(vm.labelCopy[id]);
        vm.notesLabels[id].edit = false;
      };

      //create label code end

      vm.shareConversationTo = function (con) {

        vm.emailDetail = {
          to: vm.isChannelConversation ? [{
            name: con.customerInfo.firstName + ' ' + con.customerInfo.lastName,
            email: con.customerInfo.email[0].id,
            images: con.customerInfo.images,
            avatar: con.customerInfo.avatar,
            type: 'contacts'
          }] : [{
            name: vm.contact.firstName + ' ' + vm.contact.lastName,
            email: vm.contact.email[0].id,
            images: vm.contact.images,
            avatar: vm.contact.avatar,
            type: 'contacts'
          }],
          cc: [],
          emailBody: con.body
        };

        $mdDialog.show({
          locals: {
            mv: vm,
            emailDetail: vm.emailDetail,
            con: con
          },
          controller: ShareConversationController,
          templateUrl: shareConversationTemplate,
          parent: angular.element(document.body),
          controllerAs: 'vm'
        });
      };

      function ShareConversationController($scope, $rootScope, $state, $mdDialog, $mdSidenav, $timeout, FileUploadService, maxlength, mv, emailDetail, con) {

        'ngInject';

        let vm = this;

        vm.maxlength = maxlength;
        vm.entities = mv.entities;

        vm.emailDetail = emailDetail;
        vm.selectedEntity = con.entityId;

        // To be used in next release
        FileUploadService.init(vm, 'data');

        if (angular.isArray(con.attachments) && con.attachments.length) {
          vm.totalFileUpload = angular.copy(con.attachments);
          vm.emailDetail.attachment = [];
          angular.forEach(vm.totalFileUpload, function (file) {
            vm.emailDetail.attachment.push(file.id)
          });
        }

        vm.cancel = cancel;
        vm.uploadFileAndSend = FileUploadService.uploadFilesAndSave(vm, send);
        vm.send = send;

        function send() {
          if (vm.selectedEntity) {
            vm.emailDetail.entityId = vm.selectedEntity;
          }

          if (vm.data.attachments) {
            vm.emailDetail.attachment = vm.emailDetail.attachment || [];
            vm.emailDetail.attachment = vm.emailDetail.attachment.concat(vm.data.attachments);
          }

          dataServices.post({
            url: serverUrl.main + 'conversationApi/conversations/' + con.customerId + '/share',
            data: {
              data: angular.toJson(vm.emailDetail)
            }
          })
            .then(function (response) {
              let data = response.data;
              if (data && data.response_code == 200) {
                messages.simpleToast('Conversation shared successfully.');
                $mdDialog.cancel();
              }
            });
        }



        // contact chips email to field starts
      }
      // Likes started
      vm.conversationLikeInfo = (likeCon) => {
        if (isNaN(likeCon.likesCount)) {
          likeCon.likesCount = 0;
        }
        let likedConversation;
        vm.getLikes(likeCon.id).then(() => {
          if (vm.getLikesDetails.length <= 0) {
            likedConversation = false;
          } else {
            vm.getLikesDetails.forEach(user => {
              console.log(user.id);
              if (user.id == vm.currentUserId) {
                likedConversation = true;
                return;
              }
            });
          }
          if (!likedConversation) {
            vm.likeConversation(likeCon);

          } else {
            vm.removeLike(likeCon);
          }
        });
      }

      vm.likeConversation = function postLike(con) {
        vm.editingLikeCon = con;
        dataServices.post({
          url: serverUrl.main + 'conversationApi/conversation/like/' + vm.editingLikeCon.id,
          method: 'POST'
        }).then(response => {
          if (response && response.data.result) {
            messages.simpleToast("Liked a post");
            con.likesCount++;
          }
        })
      }

      vm.getLikes = (conId) => {
        return dataServices.get({
          url: serverUrl.main + 'conversationApi/conversation/like/' + conId + '?',
          method: 'GET'
        }).then(response => {
          if (response && response.data.result) {
            vm.getLikesDetails = response.data.result;
          }
        })
      }

      vm.removeLike = function removeLike(con) {
        vm.editingLikeCon = con;
        dataServices.delete({
          url: serverUrl.main + 'conversationApi/conversation/like/' + vm.editingLikeCon.id
        }).then(response => {
          if (response && response.data.result) {
            messages.simpleToast("Like Removed");
            con.likesCount--;
            if (vm.retractLikeCon.id == con.id) {
              vm.getLikes(vm.retractLikeCon.id);
            }
          }
        })
      }

      // Likes History
      vm.openLikesHistory = (likesCon) => {
        $mdSidenav('conversationLikesHistory').toggle();
        vm.getLikes(likesCon.id);

        vm.retractLikeCon = likesCon;
        console.log(vm.retractLikeCon);
      }

      vm.openPostHistory = (historyCon) => {
        vm.conHistory = historyCon;
        ListService.openSidenav(vm, parentVm, 'postHistory', '.scroll-auto.custom-scroll', false);
        vm.setFilters('new');
      }

      vm.closeSidenav = (id) => {
        $mdSidenav(id).close();
      }

      vm.closeLikesHistory = () => {
        $mdSidenav('conversationLikesHistory').close();
      }

      vm.extractText = function (html) {
        var div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
      };

      vm.truncateText = function (text, limit) {
        var words = text.split(' ');
        if (words.length > limit) {
          return words.slice(0, limit).join(' ') + '...';
        }
        return text;
      };

      vm.getWordCount = function (text) {
        return text ? text.split(/\s+/).length : 0;
      };

      vm.toggleExpand = function (history) {
        history.expanded = !history.expanded;
      };

      vm.saveDisplayState = () => {
        sharedDataService.set("notesExpandedState", vm.showFunction);
      }
      // Add templates
      vm.noteTemplates = {};
      vm.addTemplate = (con) => {
        vm.status = 'status-btn-0';
        vm.selectedTemplateId = undefined;
        if (con) {
          vm.copyTemplateCon = con;
          vm.templateFunction = vm.copyTemplateCon.modelName == 10 ? 'Customers' : vm.filterFunctions[vm.copyTemplateCon.modelName].functionName;
          console.log(vm.copyTemplateCon);
        } else {
          vm.copyTemplateCon = {};
          vm.copyTemplateCon.modelName = vm.conversation.modelName;
          vm.copyTemplateCon.body = vm.conversation.body;
          vm.templateFunction = vm.conversation.modelName == 10 ? 'Customers' : vm.filterFunctions[vm.conversation.modelName].functionName;
          vm.copyTemplateCon.modelId = vm.conversation.modelId;
          console.log(vm.copyTemplateCon);
        }
        vm.noteTemplates.category_id = undefined;
        vm.templatesFilterCriteria = {};
        vm.templatesFilterCriteria.filters = [{
          field: "isArchived",
          value: false,
          operator: "="
        },
        {
          field: "function",
          value: vm.copyTemplateCon.modelName.toString(),
          operator: "="
        }
        ];
        $mdSidenav('assignTemplates').toggle();
        // vm.getNotesTemplates();
        vm.listName = 'templates';
        vm.openSidenavResource('templates');
        console.log(vm.defaultFc[vm.listName]);
        // vm.setFilters();
      }

      // vm.setField = (field) => {
      //   if(field == 'bzPreferred') {
      //     vm.filter.templates.bzPreferred = true;
      //     vm.filter.templates.personal = undefined;
      //   }

      //   if(field == 'personal') {
      //     vm.filter.templates.personal = true;
      //     vm.filter.templates.bzPreferred = undefined;
      //   }
      // }

      // function setTemplateFilters() {
      //   console.log(vm.filter.templates.bzPreferred);
      //   if (vm.filter.templates.bzPreferred || vm.filter.templates.personal) {
      //     ListService.addFilter(vm, 'bzPreferred', vm.filter.templates.bzPreferred ? true : false, '=');
      //     ListService.addFilter(vm, 'isSystem', false, '=');
      //   }

      //   if(vm.filter.templates.category_id) {
      //     ListService.addFilter(vm, 'categoryId', vm.filter.templates.category_id, '=');
      //   }
      // }

      // vm.getNotesTemplates = () => {
      //   // vm.isListLoading.addTemplate = true;
      //   let filterCriteria = angular.toJson(vm.templatesFilterCriteria);
      //   dataServices.get({
      //     url: serverUrl.main + 'convTemplateApi/convTemplates?filterCriteria=' + filterCriteria + '&',
      //     spinnerName: 'notes-templates-sidebar-spinner'
      //   }).then(response => {
      //     if (response.data.response_code == 200) {
      //       vm.templates = response.data.result;
      //       // vm.isListLoading.addTemplate = false;
      //     }
      //   });
      // }

      // vm.getNoteTemplatesByCategory = (category_id) => {
      //   let setFilter = false;
      //   vm.templatesFilterCriteria.filters.forEach(x => {
      //     if (x.field == "categoryId") {
      //       x.value = category_id;
      //       setFilter = true;
      //       return;
      //     }
      //   });

      //   if (!setFilter) {
      //     vm.templatesFilterCriteria.filters.push({
      //       field: "categoryId",
      //       value: category_id,
      //       operator: "="
      //     });
      //   }

      //   vm.getNotesTemplates();
      // }

      // vm.getAllNoteTemplatesByBusinessCategory = () => {
      //   vm.templatesFilterCriteria.filters = [
      //     {
      //       field: "isArchived",
      //       value: false,
      //       operator: "="
      //     },
      //     {
      //       field: "function",
      //       value: vm.copyTemplateCon.modelName.toString(),
      //       operator: "="
      //     }
      //   ];
      //   vm.getNotesTemplates();
      // }

      // vm.getNoteTemplatesByBusinessCategory = (bzPreferred) => {
      //   let setFilter = false;
      //   vm.templatesFilterCriteria.filters.forEach(x => {
      //     if (x.field == "bzPreferred") {
      //       x.value = bzPreferred;
      //       setFilter = true;
      //       return;
      //     }
      //   });

      //   if (!setFilter) {
      //     vm.templatesFilterCriteria.filters.push({
      //       field: "bzPreferred",
      //       value: bzPreferred,
      //       operator: "="
      //     });
      //   }

      //   console.log(vm.templatesFilterCriteria);
      //   vm.getNotesTemplates();
      // }

      vm.getNoteCategories = () => {
        return dataServices.get({
          url: serverUrl.main + 'convTemplateCategoryApi/conversation/category?stats=true',
          spinnerName: 'notes-category-spinner'
        }).then(response => {
          if (response.data.response_code == 200) {
            vm.notesTemplateCategories = response.data.result;
            console.log(vm.notesTemplateCategories);
          }
        });
      }

      vm.copyPostContent = function (content) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = content;
        const plainText = tempElement.textContent || tempElement.innerText;

        navigator.clipboard.writeText(plainText).then(() => {
          messages.simpleToast('Post content copied to clipboard!');
        }).catch(err => {
          messages.simpleToast('Failed to copy content');
        });
      };


      vm.createTemplate = () => {
        $state.go('main.customers.addNotesTemplates');
      }

      vm.copyTemplateBody = (template) => {
        console.log(vm.copyTemplateCon.modelName == 10 ? 'customer' : vm.filterFunctions[vm.copyTemplateCon.modelName].functionName.toLowerCase());
        dataServices.get({
          url: serverUrl.main + 'convTemplateApi/convTemplates/parse/' + template.id + '?model=' + (vm.copyTemplateCon.modelName == 10 ? 'customer' : (vm.copyTemplateCon.modelName == 83 || vm.copyTemplateCon.modelName == 85) ? 'work_orders' : vm.filterFunctions[vm.copyTemplateCon.modelName].functionName.toLowerCase()) + '&modelId=' + vm.copyTemplateCon.modelId + '&'
        }).then(response => {
          if (response && response.data) {
            vm.copyTemplateCon.body += response.data.result.body;
            if (!vm.copyTemplateCon.id) {
              vm.conversation.body += response.data.result.body;
            }
          }
        });
        closeAssignTemplates();
      }

      vm.closeAssignTemplates = closeAssignTemplates;

      function closeAssignTemplates() {

        $mdSidenav('assignTemplates').close();
      }
      // Add templates ended

      // set or unset days on user click or on updating post.
      // vm.days -> modal that contains data to be sent to BE
      // vm.setOfDays -> Variable used to show set days, holidays and working days
      vm.setOrUnsetDay = function (index, flag) {
        if (vm.days.indexOf(index) == -1 || flag) {
          if (!flag) {
            vm.days.push(index);
          }
          vm.setOfDays[index].class = (index === 0 || index == 6) ? 'dayOffWorking' : 'selected';
        } else {
          vm.days.splice(vm.days.indexOf(index), 1);
          vm.setOfDays[index].class = (index === 0 || index == 6) ? 'dayOff' : '';
        }
      };

      function postConversationDeleteOrUpdateHandler(result, con) {

        let connavId = getnavId(con),
          resnavId = getnavId(result);

        // Set skip of current and prev nav values of the conversation to 0
        vm.skip[resnavId] = vm.skip[connavId] = 0;
        clickedNav = resnavId;
        // Make nav visible in UI
        vm.navMapped[resnavId] = true;

        // Fetch all conversations with present category and skip = 0(categories which set to 0 above or conversations of particular category not loaded yet)
        let len = navItems.length;

        for (let i = 0; i < len; i++) {
          // console.log(vm.navMapped[navItems[i]] && vm.skip[navItems[i]] == 0, vm.navMapped[navItems[i]], vm.skip[navItems[i]] == 0);
          if (vm.navMapped[navItems[i]] && vm.skip[navItems[i]] == 0) {
            let len = vm.conversations[navItems[i]].length;
            vm.conversations[navItems[i]] = [];
            vm.filterConversations(navItems[i], clickedNav != navItems[i], len);
          }
        }
      }

      // possible nav items
      let navItems = ['Future', 'Now', 'One week ago', 'Two weeks ago', 'Three weeks ago', 'One month ago', 'Two months ago', 'Three months ago', 'Six months ago', 'Nine months ago', 'One year ago', 'Two years ago', 'Past', 'Archived'];

      function getEpocheRanges() {
        let oneDay = 86400, //24 * 60 * 60,
          oneWeek = 7 * oneDay,
          oneMonth = 30 * oneDay,
          oneYear = 365 * oneDay;

        let d = new Date();
        // d.setHours(23, 59, 59, 1000);
        console.log(Math.trunc(d.getTime() / 1000));
        let epoche = Math.trunc(d.getTime() / 1000);

        return {
          'Future': [epoche],
          'Now': [epoche, epoche - oneWeek],
          'One week ago': [epoche - oneWeek, epoche - (oneWeek * 2)],
          'Two weeks ago': [epoche - (oneWeek * 2), epoche - (oneWeek * 3)],
          'Three weeks ago': [epoche - (oneWeek * 3), epoche - oneMonth],
          'One month ago': [epoche - oneMonth, epoche - (oneMonth * 2)],
          'Two months ago': [epoche - (oneMonth * 2), epoche - (oneMonth * 3)],
          'Three months ago': [epoche - (oneMonth * 3), epoche - (oneMonth * 6)],
          'Six months ago': [epoche - (oneMonth * 6), epoche - (oneMonth * 9)],
          'Nine months ago': [epoche - (oneMonth * 9), epoche - oneYear],
          'One year ago': [epoche - oneYear, epoche - (oneYear * 2)],
          'Two years ago': [epoche - (oneYear * 2), epoche - (oneYear * 3)],
          'Past': [epoche - (oneYear * 3)]
        };
      }

      function getnavId(con) {
        let epocheRanges = getEpocheRanges(),
          navId;
        console.log(con.startDate, epocheRanges.Future[0]);
        if (con.isArchived) {
          navId = 'Archived';
        } else if (con.startDate > epocheRanges.Future[0]) {
          navId = 'Future';
        } else if (con.startDate < epocheRanges.Past[0]) {
          navId = 'Past';
        } else {
          let len = navItems.length;
          for (let i = 1; i < len - 1; i++) {
            if (con.startDate <= epocheRanges[navItems[i]][0] && con.startDate > epocheRanges[navItems[i]][1]) {
              navId = navItems[i];
              break;
            }
          }
        }

        return navId;
      }

      function saveConversationFinal(con, conversation, isSidebarSpinner) {
        if (con && vm.selectedLabelsCopy && angular.isObject(vm.selectedLabelsCopy)) {
          conversation.labels = Object.keys(vm.selectedLabelsCopy);
          console.log(conversation.labels);
        }

        console.log(conversation);
        // return
        // filterConversations();
        // resetVal();
        // return;

        dataServices.post({
          url: serverUrl.main + 'conversationApi/conversations' + (con ? ('/' + con.id) : ''),
          data: {
            data: angular.toJson(conversation)
          },
          method: con ? 'PUT' : '',
          spinnerName: isSidebarSpinner ? 'calendar-sidebar-spinner' : $rootScope.customSpinnerName
        })
          .then(function (response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              let result = data.result;

              let navId = getnavId(result);

              if (con) {
                // Updating conversation - update finished, so set flag and modal variables to false and update conversation UI.
                vm.conEdit[con.id] = vm.editingCon = false;
                vm.conversations[navId][con.index] = result;

                // increase/decrease count after updating note
                vm.conversationsCount[con.modelName]--;
                vm.conversationsCount[result.modelName]++;

                postConversationDeleteOrUpdateHandler(result, con);
              } else {
                // New conversation added.
                // Show new conversation's nav
                vm.navMapped[navId] = true;
                // increase count after adding new note
                vm.conversationsCount[result.modelName]++;
                vm.conversationsCount.total++;
                vm.timeLapseEmptyState = true;
                result.labelInfo = Object.values(vm.selectedLabelsCopy);
                vm.conversations[result.type == 'calendar' || result.type == 'reminder' ? navId : 'Now'].unshift(result);
                // postConversationDeleteOrUpdateHandler(result, conversation);
                vm.scrollAndFetch(result.type == 'calendar' || result.type == 'reminder' ? navId : 'Now');
                // filterConversations("Now", true);
                // let len = vm.conversations[navId].length;
                // If there are conversations already there for particular category
                // if (len) {
                //   // if no of conversations are equal to limit, enable pagination and pop out last conversation to accomodate new conversation added
                //   if (len % vm.limit === 0) {
                //     vm.pagination[navId] = true;
                //     vm.conversations[navId].pop();
                //   }
                //   vm.conversations[navId].unshift(result);
                //   scrollTo(navId);
                // } else {
                //   // Fetch conversations for newly added conversation category as there were no conversations in this category loaded before
                //   // noScroll true
                //   vm.scrollAndFetch(navId);
                // }
                // Hide new conversation UI
                // vm.enableNewConversation = false;
              }
              vm.selectedLabelsCopy = {};
              resetVal();
              messages.simpleToast(con ? 'Updated conversation' : 'Posted conversation in ' + ((result.type == 'calendar' || result.type == 'reminder') ? navId : 'Now'));
              vm.isCloseRight();
            }
          });
      }

      function saveConversation(con, isSidebarSpinner) {
        let conversation = angular.copy(vm.conversation);
        console.log(con, conversation);
        if (con) {
          if (con.body) {
            conversation.body = con.body;
            conversation.modelId = con.modelId;
            conversation.modelName = con.modelName;
          } else {
            messages.simpleToast('Enter Description!', 'danger');
            return;
          }
        }

        conversation.entityId = vm.selectedEntity;

        if (conversation.type == 'calendar') {
          vm.calendarForm.$setSubmitted();
          if (vm.calendarForm.$invalid) {
            return;
          }

          conversation.startDate = vm.timezoneInfo.startTime;
          conversation.endDate = vm.timezoneInfo.endTime;

          // if (conversation.startDate > conversation.endDate) {
          //   messages.simpleToast('Start date should be less than end date!', 'danger');
          //   return;
          // }

          if (vm.calendar.isRepeatDay && vm.days.length) {
            conversation.repeatDays = vm.days;
          }
          angular.merge(conversation, vm.calendar);

          let selectedContacts = angular.copy(vm.selectedContacts);
          if (!selectedContacts.length) {
            messages.simpleToast('Send To Field can not be blank.');
            return;
          }
          conversation.sharedTo = selectedContacts.map(function (contact) {
            delete contact.name;
            delete contact._lowername;
            delete contact._loweremail;
            delete contact.images;
            delete contact.avatar;
            // delete contact.$$hashKey;
            return contact;
          });

          // save timezone for next edit
          conversation.timeZone = vm.selectedTimezone ? dataServices.toSave(vm.selectedTimezone, ['gmt', 'offset', 'region', 'timezoneId', 'timezone']) : $rootScope.usrObj.timeZone;
        } else if (conversation.type == 'reminder') {
          vm.reminderForm.$setSubmitted();
          if (vm.reminderForm.$invalid) {
            return;
          }
          conversation.startDate = vm.timezoneInfo.reminderStartTime;
          angular.merge(conversation, vm.reminder);

          // save timezone for next edit
          conversation.timeZone = vm.selectedTimezone ? dataServices.toSave(vm.selectedTimezone, ['gmt', 'offset', 'region', 'timezoneId', 'timezone']) : $rootScope.usrObj.timeZone;
        } else if (conversation.type == 'attachment') {
          let files = vm.totalFileUpload.filter(function (file) {
            return !file.hasOwnProperty('id');
          });

          if (files.length) {
            dataServices.upload({
              url: serverUrl.main + 'mediaApi/upload/file',
              data: {
                file: files
              }
            })
              .then(function (response) {
                let data = response.data;
                if (data && data.response_code == 200) {
                  conversation.attachments = data.result.success.map(function (file) {
                    return file.fileId;
                  });
                  console.log(conversation);
                  FileUploadService.init(vm, 'conversation', 'right');
                  console.log(conversation);
                  saveConversationFinal(con, conversation);
                }
              });
          } else {
            conversation.attachments = [];
            saveConversationFinal(con, conversation);
          }
        }

        if (conversation.type != 'attachment') {
          console.log(con);
          console.log(conversation);
          saveConversationFinal(con, conversation, isSidebarSpinner)
        }
      }

      function shareConversation(con, isEdit) {

        if (!con && !vm.conversation.body || con && !con.body) {
          messages.simpleToast('Enter Description!', 'danger');
          return;
        }
        if (vm.conversation.body) {
          const bodyText = vm.conversation.body;
          const invalidDotPattern = /(?<!\s)\.(?!\s)/.test(bodyText);
          if (invalidDotPattern) {
              const urlPattern = bodyText.includes("http://") || bodyText.includes("https://");
              if (!urlPattern) {
                  messages.simpleToast("Invalid URL detected. Please enter a complete URL");
                  return;
              }
          }
      }
        if (vm.conversation.body) {
          const possibleLink = /\S\.\S/.test(vm.conversation.body);

          if (possibleLink) {
            const urlPattern = vm.conversation.body.includes("http://") ||
              vm.conversation.body.includes("https://");

            if (!urlPattern) {
              messages.simpleToast("Invalid URL detected. Please enter a complete URL");
              return;
            }
          }
        }
        const conversationType = con ? con.type : vm.conversation.type;

        if (conversationType == 'calendar' || conversationType == 'reminder') {
          vm.isCloseRight();
          vm.toggleRight('right');

          if (!isEdit) {
            prepareTimezoneInfo();

            vm.calendarStartDate = vm.calendarEndDate = vm.reminderStartDate = new Date();
            // START : To select next hour.
            let incrementHour = 0;
            if (new Date().getMinutes() >= 30) {
              incrementHour = 1;
            }
            vm.calendarStartTime = vm.reminderStartTime = ((new Date().getHours() + incrementHour) * 2) + (incrementHour == 0 ? 1 : 0);
            vm.calendarEndTime = ((new Date().getHours() + incrementHour + 1) * 2) + (incrementHour == 0 ? 1 : 0);
            // END : To select next hour.

            prepareTimezoneDates(conversationType == 'calendar');
          }
        } else if (conversationType == 'attachment') {
          if (isEdit) {
            vm.isCloseRight();
            vm.toggleRight('right');
          } else {
            saveConversation(con);
          }
        } else {
          saveConversation(con);
        }

      }

      // Bind date and time to UI on editing conversation
      function setDateTime(conDate, date, time) {
        if (conDate) {
          let d = new Date(conDate * 1000);
          d.setMinutes(d.getMinutes() + vm.timezoneInfo.timezoneOffset);

          vm[date] = d;
          vm[time] = d.getHours() * 2 + (d.getMinutes() ? 1 : 0);
        }
      }

      vm.toggleNewConversation = function (ev) {
        if (vm.editingCon && !vm.enableNewConversation) {
          // Already a conversation is being edited
          messages.mdconfirm(ev, 'You have a post being edited. Do you want to discard it?')
            .then(function (flag) {
              if (flag) {
                messages.simpleToast('Discarded the conversation being edited and switched to new one.');
                vm.cancelEdit(vm.editingCon);
                vm.enableNewConversation = true;
              }
            });
        } else {
          vm.enableNewConversation = !vm.enableNewConversation;
        }
      };

      vm.conEdit = {};
  vm.editConversation = function(ev, con, index) {
    if (!vm.editingCon) {
      console.log("called")
      body = con.body;
      con.index = index;
      vm.editingCon = con;

      // Show save and cancel icons
      vm.conEdit[con.id] = true;
      if (con.type == 'calendar' || con.type == 'reminder' || con.type == 'attachment') {
        vm.conversation.type = con.type;

        if (con.type == 'calendar') {
          vm.shareMsg = 'SCHEDULE';

          // Bind auto suggest contacts
          vm.selectedContacts = mapContacts(con.sharedTo);
          vm.calendar = {
            'title': con.title,
            'allDay': con.allDay,
            'isRepeatDay': con.isRepeatDay
          };

          prepareTimezoneInfo(con);

          // Bind start, end date and time
          setDateTime(con.startDate, 'calendarStartDate', 'calendarStartTime');
          setDateTime(con.endDate, 'calendarEndDate', 'calendarEndTime');

          prepareTimezoneDates(con.type == 'calendar');

          // Set or unset days based on user selection
          if (con.isRepeatDay && angular.isArray(con.repeatDays)) {
            vm.days = con.repeatDays;
            angular.forEach(con.repeatDays, function(day) {
              vm.setOrUnsetDay(day, true);
            });
          } else {
            vm.days = [];
            initSetOfDays();
          }
        } else if (con.type == 'reminder') {
          vm.shareMsg = 'SCHEDULE';

          vm.reminder = {
            'title': con.title
          };

          prepareTimezoneInfo(con);

          // Bind start date and time
          setDateTime(con.startDate, 'reminderStartDate', 'reminderStartTime');

          prepareTimezoneDates();
        } else if (con.type == 'attachment') {
          vm.totalFileUpload = [];
          if (con.attachments) {
            angular.forEach(con.attachments, function(file) {
              vm.totalFileUpload.push({
                name: file.fileOriginalName,
                id: file.id,
                url: file.thumbnailUrl || file.webUrl,
                webUrl: file.webUrl
              });
            });
          }
        }
      }
    } else {
      // Already a conversation is being edited
      messages.mdconfirm(ev, 'You have a post being edited. Do you want to discard it?')
        .then(function(flag) {
          if (flag) {
            messages.simpleToast('Discarded the conversation being edited and editing current one.');
            vm.cancelEdit(vm.editingCon);
            vm.editConversation(ev, con, index);
          }
        });
    }
  };
      vm.toggleLabels = (label) => {
        if (!vm.selLabel) {
          vm.setFilterLabels(label.id);
        }
      }
      // Archive a conversation
      vm.deleteConversation = function (con, index) {
        dataServices.patch({
          url: serverUrl.main + 'conversationApi/conversations/' + con.id + '/' + (con.isArchived ? 'un' : '') + 'archive',
          spinner: false
        })
          .then(function (response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              let result = data.result;
              postConversationDeleteOrUpdateHandler(result, con);
              messages.simpleToast((con.isArchived ? 'Una' : 'A') + 'rchived Successfully');
            }
          });
      };

      vm.toTrusted = function (html_code) {
        return $sce.trustAsHtml(html_code);
      };

      vm.shareMsg = 'POST';

      function changeConversationType(index) {
        vm.shareMsg = 'POST';
        vm.isCloseRight();
        if (index == 5) {
          vm.toggleRight('right');
        } else if (index == 3 || index == 4) {
          vm.shareMsg = 'SCHEDULE';
        }
        vm.conversation.type = vm.conversationTypes[index];
      }

      vm.openUrl = contactData.openUrl;
      vm.openSocialMedia = contactData.openSocialMedia;

      vm.saveSocialAddress = (index) => {
        contactData.saveSocialAddress(index, vm, customerId);
      };

      let lastNav,
        loader = true,
        clickedNav;

      vm.loadingFlagForClick = false;
      vm.limit = 15;
      vm.conversations = {};
      vm.navMapped = {};
      vm.pagination = {};
      vm.skip = {};

      // Initiate nav Items for further processing
      const initNavPagination = () => {
        angular.forEach(navItems, function (nav) {
          vm.conversations[nav] = [];
          vm.navMapped[nav] = false;
          vm.pagination[nav] = true;
          vm.skip[nav] = 0;
        });
      }
      initNavPagination();

      let mdContentElem = $('md-content');

      function scrollTo(navId) {
        $timeout(function () {
          if ($('#' + vm.getId(navId))) {
            mdContentElem.animate({
              scrollTop: mdContentElem.scrollTop() + $('#' + vm.getId(navId)).offset().top - $('md-toolbar').height()
            }, 'slow');
          }
        }, 0);
      }

      function getFCforNav(navId) {
        console.log("get fc")
        let fc = [{
          field: "isArchived",
          operator: "=",
          value: navId == 'Archived'
        }];

        if (navId != 'Archived') {
          let epocheRanges = getEpocheRanges();
          if (navId == 'Future' || navId == 'Past') {
            fc.push({
              field: 'startDate',
              value: epocheRanges[navId][0],
              operator: navId == 'Future' ? '>' : '<',
              type: 'date'
            });
          } else {
            fc.push({
              field: 'startDate',
              value: [epocheRanges[navId][1], epocheRanges[navId][0]],
              operator: 'dtrange'
            });
          }
        }

        if (!vm.allNotes && !vm.isCustomerNotes) {
          fc.push({
            field: 'modelId',
            value: vm.modelMap.id + '',
            operator: '='
          });
        }

        if (vm.allNotes && vm.selectedFunctionFilter) {
          fc.push({
            field: 'modelName',
            value: vm.selectedFunctionFilter,
            operator: '='
          });
        }

        if (vm.selectedLabelFilter && vm.selectedLabelFilter != 0) {
          fc.push({
            field: 'labels',
            value: vm.selectedLabelFilter,
            operator: '='
          });
        }

        return fc;
      }

      function filterConversations(navId, noScroll, limit) {
        console.log("called")
        if (navId) {
          vm.loadingFlagForClick = vm.isLoading = true;
          lastNav = navId;
          console.log(lastNav, navId);
          let limitToSend = limit && limit > vm.limit ? limit : vm.limit;

          let filters = getFCforNav(navId), filterCriteria;
          filterCriteria = {
            sort: [{ field: 'lastUpdated', order: -1 }],
            skip: vm.skip[navId],
            limit: limitToSend,
            filters: vm.isChannelConversation ? filters.concat(vm.filtersChannel || []) : filters
          };

          if (!vm.isChannelConversation) {
            filterCriteria = angular.toJson(filterCriteria);
          }

          const urlPattern = vm.isChannelConversation ? (serverUrl.main + 'conversationChannelApi/conversation/channel/segment/' + vm.segmentId + '?filterCriteria=' + angular.toJson(filterCriteria) + '&') : (serverUrl.main + 'conversationApi/conversations/' + customerId + '?stats=true&filterCriteria=' + filterCriteria + '&');
          dataServices.get({
            url: urlPattern,
            spinner: loader,
            spinnerName: $rootScope.customSpinnerName
          }).then((response) => {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.conversationsCount = {
                ...vm.conversationsCount,
                ...data.result.data
              };
              vm.timeLapseEmptyState = data.result.data;
              if (!vm.isChannelConversation) {
                if (vm.newPost) {
                  vm.conversations[navId].unshift(data.result.records.shift());
                  // vm.conversations[navId] = data.result.records;
                  // location.reload();
                  vm.newPost = false;
                } else {
                  vm.conversations[navId] = vm.conversations[navId].concat(data.result.records);
                }
              } else {
                vm.assignDropDownData(filters, filterCriteria);
                const fiveDaysAgo = moment().subtract(5, 'days').startOf('day').valueOf() / 1000;
                if (data.result.records) {
                  vm.conversations[navId] = vm.conversations[navId].concat(data.result.records.map(function (con) {
                    con.isNew = fiveDaysAgo < (con.createdOn > con.postUpdated ? con.createdOn : con.postUpdated);
                    return con;
                  }));
                }
              }

              vm.navMapped[navId] = vm.conversations[navId].length != 0;
              // if(vm.conversations[navId].length == 0) {
              //   vm.count++;
              // }
              // vm.timelapseEmptyState = vm.count != 0;
              // console.log(vm.count);

              // pagination calculations
              vm.pagination[navId] = data.result.records.length == limitToSend;
              vm.skip[navId] = vm.conversations[navId].length;
              console.log(vm.skip, navId)
            } else {
              vm.pagination[navId] = false;
            }
            loader = vm.isLoading = vm.isFilterLoading = false;
            // scrolling to nav on click;
            vm.loadingFlagForClick = clickedNav != navId;
            if (vm.conversations[navId].length && !vm.loadingFlagForClick && !noScroll) {
              scrollTo(navId);
            }

            // Fetch records if there is no scrollbar
            let div = document.getElementById('conversation-content-container');

            if (div) {
              let adjustedScrollHeight = div.scrollHeight - div.clientHeight;
              if (div.scrollTop >= adjustedScrollHeight - 10) {
                fetchNextNavOnScroll();
              }
            }
            // if (!vs) {
            //   fetchNextNavOnScroll()
            // }
          }, (error) => {
            vm.isFilterLoading = false;
          });
        }
      }

      const processNavItems = () => {
        // Default nav Items to show
        angular.forEach(vm.nav, function (nav) {
          vm.navMapped[nav.id] = true;
        });

        // If Now nav item is there, fetch conversations belonging to Now and do not scroll
        if (vm.navMapped.Now) {
          clickedNav = 'Now';
          vm.filterConversations('Now', true);
        } else {
          // If Now nav item is not there, then fetch conversations belonging to all nav items and do not scroll
          let stopIteration = false;
          angular.forEach(vm.navMapped, function (value, key) {
            if (stopIteration) return;
            if (value) {
              clickedNav = key;
              vm.filterConversations(key, true);
              stopIteration = true;
            }
          });
        }
      };
      vm.processNavItems = processNavItems;

      // vm.clearNotesFilters = () => {
      //   vm.selectedFunctionFilter = undefined;
      //   vm.selectedLabelFilter = undefined;
      //   vm.allLabelsNotes = true;
      //   getNavItems();
      // }

      const getNavItems = () => {
        const fc = [];
        vm.filterApplied = false;

        if (!vm.allNotes && !vm.isCustomerNotes) {
          fc.push({
            field: 'modelId',
            value: vm.modelMap.id + '',
            operator: '='
          });
          vm.filterApplied = true;
        }

        if (vm.allNotes && vm.selectedFunctionFilter) {
          fc.push({
            field: 'modelName',
            value: vm.selectedFunctionFilter,
            operator: '='
          });
          vm.filterApplied = true;
        }

        if (vm.selectedLabelFilter && vm.selectedLabelFilter != 0) {
          fc.push({
            field: 'labels',
            value: vm.selectedLabelFilter,
            operator: '='
          });
          vm.allLabelsNotes = false;
          vm.filterApplied = true;
        }
        vm.isFilterLoading = true;

        dataServices.get({
          url: serverUrl.main + 'conversationApi/conversations/nav/' + customerId + (fc.length ? ('?filterCriteria=' + angular.toJson({
            filters: fc
          }) + '&') : '?'),
          spinner: false
        }).then((response) => {
          let data = response.data;
          vm.isLoading = false;
          if (data && data.response_code == 200) {
            vm.nav = data.result;
            vm.timeLapseEmptyState = data.result.data;
            initNavPagination();
            processNavItems();
            if (!vm.nav.length) {
              vm.isFilterLoading = false;
            }
          }
        }, (error) => {
          vm.isFilterLoading = false;
        });
      };

      vm.viewAllOrFunctionNotes = () => {
        getNavItems();
      };

      vm.setFilterFunctions = (modelName = 0) => {
        vm.selectedFunctionFilter = parseInt(modelName);
        getNavItems();
      };

      vm.handleLabelSelection = function (label) {
        if (vm.selLabel && vm.selLabel.id === label.id) {
          vm.selLabel = null;
          vm.selectedLabelFilter = null;
          label.id = undefined;
        } else {
          vm.selLabel = label;
          vm.selectedLabelFilter = label.id;
        }
        vm.setFilterLabels(label.id);
      };

      vm.setFilterLabels = (label) => {
        vm.selectedLabelFilter = label;
        vm.allLabelsNotes = false;
        getNavItems();
      };


      // Fetch conversations of categories one by one serially on click or posting a new conversation
      vm.scrollAndFetch = function (navId) {
        clickedNav = navId;
        if (!vm.conversations[navId].length) {
          console.log(navId);
          // If navId is Future, fetch it's conversations only as it's always on top
          if (navId == 'Future') {
            vm.filterConversations(navId);
          } else {
            // If navId is not Future, fetch conversations of categories one by one serially
            // index -> index of navItem next to lastNav(by default now)
            // let index = navItems.indexOf(lastNav) + 1,
            let i, len = navItems.length;
            for (i = 1; navItems[i - 1] != navId && i < len; i++) {
              if (vm.navMapped[navItems[i]] && (vm.skip[navItems[i]] == 0)) {
                // if (vm.skip[navItems[i]] == vm.conversations[navId].length) {
                //   vm.skip[navItems[i]] = 0;
                // }
                vm.filterConversations(navItems[i]);
              }
            }
          }
        } else {
          scrollTo(navId);
        }
      };

      vm.getId = function (navId) {
        return navId.replace(/ /g, '-');
      };

      function fetchNextNavOnScroll() {
        let lastNavIndex;
        angular.forEach(vm.nav, function (x, index) {
          console.log(x.id, lastNav)
          if (x.id == lastNav) {
            lastNavIndex = index;
          }
        });

        if (lastNavIndex == -1) {
          return;
        }

        if (!vm.nav || vm.nav.length === 0) {
          console.error('vm.nav is empty or not populated:', vm.nav);
          return;
        }

        if (!lastNav) {
          console.error('lastNav is not defined:', lastNav);
          return;
        }

        console.log(vm.nav, lastNavIndex, vm.skip, lastNav, navItems)
        let index = (vm.skip[lastNav] >= vm.nav[lastNavIndex].count) ? navItems.indexOf(lastNav) + 1 : navItems.indexOf(lastNav),
          len = navItems.length,
          i;

        console.log(index)

        for (i = index; i < len && !vm.loadingFlagForClick; i++) {
          if (vm.navMapped[navItems[i]]) {
            clickedNav = navItems[i];
            vm.filterConversations(navItems[i], true);
            break;
          }
        }
      }

      // fetch next nav by scroll
      $('md-content').scroll(function () {
        let scrollTop = $('#conversation-content-container').scrollTop();
        let outerHeight = $('#conversation-content-container').outerHeight(true), scrollHeight = $('#conversation-content-container').prop('scrollHeight');
        if (scrollTop + outerHeight >= scrollHeight * 0.95) {
          fetchNextNavOnScroll();
        }
        // if (Math.ceil(scrollTop + $('md-content').height()) === $('md-content').prop('scrollHeight') || Math.floor(scrollTop + $('md-content').height()) === $('md-content').prop('scrollHeight') || Math.trunc(scrollTop + $('md-content').height()) === $('md-content').prop('scrollHeight')) {
        //   fetchNextNavOnScroll();
        // }
      });

      // replies or comments logic starts
      vm.isOpened = {};
      vm.isExpanded = [];
      vm.replyEditor = {};
      vm.commentEdit = {};
      vm.comments = {};
      vm.paginationComments = {};
      vm.skipComments = {};
      vm.limitComments = 5;

      vm.toggleReplyEditor = function (con) {
        if (vm.replyEditor[con.id]) {
          delete vm.replyEditor[con.id]
        } else {
          vm.replyEditor[con.id] = {
            body: ''
          }
        }
      };

      // vm.toggleComments = function(con) {
      //   if (con.postReplyCount && !vm.comments[con.id]) {
      //     vm.fetchComments(con);
      //   }
      //   vm.isOpened[con.id] = !vm.isOpened[con.id];
      // };

      vm.toggleComments = function (con) {
        console.log(!vm.skipComments[con.id])
        if (!vm.skipComments[con.id]) {
          vm.comments[con.id] = [];
        }
        console.log(con.postReplyCount && vm.comments[con.id]);
        if (con.postReplyCount && vm.comments[con.id]) {
          console.log("calling");
          vm.fetchComments(con);
        }
        vm.isOpened[con.id] = !vm.isOpened[con.id];
      };

      vm.toggleAllComments = function (con) {
        if (con.postReplyCount && !vm.isExpanded[con.id]) {
          vm.fetchAllComments(con);
        }
        vm.isExpanded[con.id] = !vm.isExpanded[con.id];
      }

      vm.saveReply = function (con, comment) {
        if (vm.replyEditor[vm.selectedCon.id].body) {
          const bodyText = vm.replyEditor[vm.selectedCon.id].body;
          const invalidDotPattern = /(?<!\s)\.(?!\s)/.test(bodyText);
          if (invalidDotPattern) {
              const urlPattern = bodyText.includes("http://") || bodyText.includes("https://");
              if (!urlPattern) {
                  messages.simpleToast("Invalid URL detected. Please enter a complete URL");
                  return;
              }
          }
       }
      
        vm.isLoading = true;

        dataServices.post({
          url: serverUrl.main + 'conversationReplyApi/conversations/reply/' + con.id + (comment ? ('/' + comment.id) : ''),
          data: {
            data: angular.toJson({
              customerId,
              body: comment ? comment.body : vm.replyEditor[con.id].body
            })
          },
          method: comment ? 'PUT' : '',
          spinner: false
        })
          .then(function (response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.fetchComments(con, 'new', true);
              con.postReplyCount++;
              console.log(con.postReplyCount)
              vm.closeEditSide();
              if (comment) {
                vm.commentEdit[comment.id] = false;
              } else {
                vm.toggleReplyEditor(con);
              }
            } else {
              vm.isLoading = false;
            }
          }, function (err) {
            vm.isLoading = false;
          });
      };

      vm.deleteComment = function (con, comment) {
        vm.isLoading = true;

        dataServices.delete({
          url: serverUrl.main + 'conversationReplyApi/conversations/reply/' + con.id + '/' + comment.id,
          spinner: false
        })
          .then(function (response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              con.postReplyCount--;
              vm.fetchComments(con, 'new', true);
            } else {
              vm.isLoading = false;
            }
          }, function (err) {
            vm.isLoading = false;
          });
      };

      // vm.fetchComments = function(con, type, isLoadingStarted) {

      //   if (!isLoadingStarted) {
      //     vm.isLoading = true;
      //   }

      //   let limitToSend = vm.limitComments;
      //   vm.comments[con.id] = vm.comments[con.id] || [];
      //   vm.skipComments[con.id] = vm.skipComments[con.id] || 0;

      //   if (type === 'new') {
      //     let len = vm.comments[con.id].length;
      //     limitToSend = len > vm.limitComments ? len : vm.limitComments;
      //     vm.skipComments[con.id] = 0;
      //   }

      //   let filterCriteria = angular.toJson({
      //     'sort': [{
      //       'field': 'lastUpdated',
      //       'order': -1
      //     }],
      //     'skip': vm.skipComments[con.id],
      //     'limit': limitToSend
      //   });

      //   dataServices.get({
      //     url: serverUrl.main + 'conversationReplyApi/conversations/reply/' + con.id + '?filterCriteria=' + filterCriteria + '&',
      //     spinner: false
      //   }).then(function(response) {
      //     let data = response.data;
      //     vm.isLoading = false;
      //     if (data && data.response_code == 200) {
      //       vm.comments[con.id] = type === 'new' ? data.result : vm.comments[con.id].concat(data.result);
      //       // pagination calculations
      //       vm.paginationComments[con.id] = data.result.length == limitToSend;
      //       vm.skipComments[con.id] += vm.comments[con.id].length;
      //     } else {
      //       vm.paginationComments[con.id] = false;
      //     }
      //   }, function(err) {
      //     vm.isLoading = false;
      //   });
      // };

      vm.fetchComments = function (con, type, isLoadingStarted) {

        if (!isLoadingStarted) {
          vm.isLoading = true;
        }

        let limitToSend = vm.limitComments;
        vm.comments[con.id] = vm.comments[con.id] || [];
        vm.skipComments[con.id] = vm.skipComments[con.id] || 0;

        if (type === 'new') {
          let len = vm.comments[con.id].length;
          limitToSend = len > vm.limitComments ? len : vm.limitComments;
          vm.skipComments[con.id] = 0;
        }

        let filterCriteria = angular.toJson({
          'sort': [{
            'field': 'lastUpdated',
            'order': -1
          }],
          'skip': vm.skipComments[con.id],
          'limit': limitToSend
        });

        dataServices.get({
          url: serverUrl.main + 'conversationReplyApi/conversations/reply/' + con.id + '?filterCriteria=' + filterCriteria + '&',
          spinner: false
        }).then(function (response) {
          let data = response.data;
          vm.isLoading = false;
          if (data && data.response_code == 200) {
            vm.comments[con.id] = type === 'new' ? data.result : vm.comments[con.id].concat(data.result);
            // pagination calculations
            vm.paginationComments[con.id] = data.result.length == limitToSend;
            vm.skipComments[con.id] += vm.comments[con.id].length;
          } else {
            vm.paginationComments[con.id] = false;
          }
        }, function (err) {
          vm.isLoading = false;
        });
      };

      vm.fetchAllComments = function (con, type, isLoadingStarted) {
        if (!isLoadingStarted) {
          vm.isLoading = true;
        }

        vm.comments[con.id] = [];
        vm.skipComments[con.id] = 0;

        // if (type === 'new') {
        //   vm.skipComments[con.id] = 0;
        // }

        let filterCriteria = angular.toJson({
          'sort': [{
            'field': 'lastUpdated',
            'order': -1
          }]
          // 'skip': vm.skipComments[con.id],
        });

        dataServices.get({
          url: serverUrl.main + 'conversationReplyApi/conversations/reply/' + con.id + '?filterCriteria=' + filterCriteria + '&',
          spinner: false
        }).then(function (response) {
          let data = response.data;
          vm.isLoading = false;
          if (data && data.response_code == 200) {
            vm.comments[con.id] = data.result;

            // pagination calculations
            vm.paginationComments[con.id] = true;
            // vm.skipComments[con.id] += vm.allComments[con.id].length;
          } else {
            vm.paginationComments[con.id] = false;
          }
        }, function (err) {
          vm.isLoading = false;
        });
      };
      // replies or comments logic ends

      //////

      // contact chips for calendar/conversation sidebar starts
      vm.selectedItem = null;
      vm.searchTextContacts = null;
      vm.searchContacts = searchContacts;
      vm.selectedContacts = [];
      vm.transformChip = transformChip;

      /**
       * Return the proper object when the transformChip is called.
       */
      function transformChip(chip) {
        // If it is an object, it's already a known chip
        if (angular.isObject(chip)) {
          return chip;
        }

        // Otherwise, create a new one
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(chip) ? {
          email: chip,
          type: 'external'
        } : null;
      }

      /**
       * Create filter function for a query string
       */
      function filterContacts(query) {
        let lowercaseQuery = query.toLowerCase();

        return function filterFn(contact) {
          return (contact._lowername.indexOf(lowercaseQuery) === 0) ||
            (contact._loweremail.indexOf(lowercaseQuery) === 0);
        };
      }

      /**
       * Search for contacts.
       */
      function searchContacts(query) {
        return query ? vm.contacts.filter(filterContacts(query)) : [];
      }

      function mapContacts(array) {
        if (!angular.isArray(array)) {
          return [];
        }
        return array.filter(function (contact) {
          if (contact.firstName && contact.lastName) {
            contact.name = contact.firstName + ' ' + contact.lastName;
            contact._lowername = contact.name.toLowerCase();
            contact._loweremail = contact.email.toLowerCase();
          }
          return contact;
        });
      }

      function loadContacts() {
        dataServices.get({
          url: serverUrl.main + 'conversationApi/conversations/contactsAndResources?',
          spinnerName: $rootScope.customSpinnerName
        })
          .then(function (response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.contacts = mapContacts(data.result);
            } else {
              vm.contacts = [];
            }
          });
      }
      loadContacts();
      // contact chips for calendar/conversation sidebar ends

      let imgExtensions = {
        'jpeg': true,
        'jpg': true,
        'png': true,
        'gif': true
      };

      vm.isImage = function (file, key) {
        let extension = file[key].slice(file[key].lastIndexOf('.') + 1);
        if (!extension) {
          return;
        } else {
          extension = extension.toLowerCase();
        }

        if (file.type && imgExtensions[extension]) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            file.src = reader.result;
          };
        }

        return Boolean(imgExtensions[extension]);
      };

      vm.goToResources = function (con) {
        $state.go('main.resources.resourcesDetails', {
          resourceId: con.resourceId
        });
      };

      function isCloseRight() {
        return $mdSidenav('right').close();
      }

      /* Side menu Toggler*/
      function toggleRight(navId) {
        $mdSidenav(navId).toggle();
      }

      function conversationScroll() {
        $timeout(function () {
          let containerTop = $('#all-conversation');

          $('.conversations').scroll(function () {
            var windowOffset = containerTop.offset().top - $(window).scrollTop() - $('.main-header').height();
            var windowTop = Math.abs(windowOffset);
            var typeConversation = $('.conversation-type');
            var fixedTitle = $('.title-wrap');

            if (windowOffset <= 0) {
              typeConversation.css({
                'position': 'fixed',
                'top': $('.main-header').height() + 'px'
              });
            } else {
              typeConversation.css({
                'position': 'static'
              });
            }
          });
        }, 0);
      }
      // if(vm.isChannelConversation) {
      //   conversationScroll();
      // }

      // Used for UI
      // $scope.activeType = 0;
      // angular.element(document.querySelector('#conver-wrap')).bind('scroll', function ehrscroll() {
      //   var currentScroll = $("#fixed-title").offset().top;
      //   var i = 0;
      //   var firstChild = -1;
      //   while (document.getElementById('conver-list' + i)) {
      //     var grp = $('#conver-list' + i);
      //     var grpoffset = grp.offset().top;
      //     if (currentScroll >= (grpoffset - 12)) {
      //       firstChild = i;
      //       $scope.activeType = firstChild;
      //       $scope.$apply();
      //     }
      //     i++;
      //   }
      //   $("#fixed-title").html($scope.cHeadings[firstChild]);
      // });
      //
      // var w = angular.element($window);
      // w.bind('resize', function(size) {
      //   windowHeight = $(window).height();
      //   headFoot = $('.main-header').height() + $('.footer').height();
      // });

      // Returns timestamp by date and time
      function getTimestamp(date, time) {
        let d = angular.copy(date);
        if (angular.isDefined(time)) {
          time = parseInt(time);
          d.setHours(time / 2);
          d.setMinutes(time % 2 ? 30 : 0);
          d.setSeconds(0);
        } else {
          d.setHours(0);
          d.setMinutes(0);
        }
        return d;
      }

      vm.timeListKeys = Object.keys(vm.timeList);
      vm.timezoneInfo = {};
      let localTimezone = new Date().toString().split('GMT')[1];
      vm.timezoneInfo.localTimeZone = vm.timezoneInfo.destTimeZone = localTimezone.substr(0, 3) + ':' + localTimezone.substr(3);

      vm.prepareLocalStartTimeData = function () {
        let startDate = getTimestamp(vm.calendarStartDate, vm.calendarStartTime);
        vm.timezoneInfo.startTime = Math.trunc(startDate.setMinutes(startDate.getMinutes() - vm.timezoneInfo.timezoneOffset) / 1000);
      };

      vm.prepareLocalEndTimeData = function () {
        let endDate = getTimestamp(vm.calendarEndDate, vm.calendarEndTime);
        vm.timezoneInfo.endTime = Math.trunc(endDate.setMinutes(endDate.getMinutes() - vm.timezoneInfo.timezoneOffset) / 1000);
      };

      vm.prepareReminderLocalStartTimeData = function () {
        let startDate = getTimestamp(vm.reminderStartDate, vm.reminderStartTime);
        vm.timezoneInfo.reminderStartTime = Math.trunc(startDate.setMinutes(startDate.getMinutes() - vm.timezoneInfo.timezoneOffset) / 1000);
      };

      function prepareTimezoneDates(isCalendar) {
        if (isCalendar) {
          vm.prepareLocalStartTimeData();
          vm.prepareLocalEndTimeData();
        } else {
          vm.prepareReminderLocalStartTimeData();
        }
      }

      vm.setDefaultTimezoneInfo = function () {
        vm.timezoneInfo.destTimeZone = vm.timezoneInfo.localTimeZone;
        vm.timezoneInfo.timezoneOffset = 0;
      };

      function prepareTimezoneInfo(con) {
        if (con && con.timeZone) {
          vm.selectedTimezone = con.timeZone;
          vm.timezoneInfo.destTimeZone = con.timeZone.gmt + ' (' + con.timeZone.region + ')';
          vm.timezoneInfo.timezoneOffset = new Date().getTimezoneOffset() + con.timeZone.offset / 60;
        } else if (!vm.selectedTimezone) {
          vm.setDefaultTimezoneInfo();
        }
      }

      function isCalendarOrReminder(isCalendar) {
        let conversationType = vm.editingCon ? vm.editingCon.type : vm.conversation.type;
        return (conversationType === 'reminder' && !isCalendar) || (conversationType === 'calendar' && isCalendar);
      }

      TimezoneService.loadTimezones(vm);
      vm.searchTimezonesReminder = vm.searchTimezonesCalendar = function (query, isCalendar) {
        return isCalendarOrReminder(isCalendar) ? vm.searchTimezones(query) : vm.timezones;
      };

      vm.selectTimezone = function (loc, isCalendar) {
        if (loc && isCalendarOrReminder(isCalendar)) {
          vm.timezoneInfo.destTimeZone = loc.gmt + ' (' + loc.region + ')';
          vm.timezoneInfo.timezoneOffset = new Date().getTimezoneOffset() + loc.offset / 60;
          prepareTimezoneDates(isCalendar);
        }
      };

      vm.searchTextChanged = function (isCalendar) {
        if (vm.searchTextZone === '' && isCalendarOrReminder(isCalendar)) {
          vm.setDefaultTimezone(isCalendar);
        }
      };

      vm.setDefaultTimezone = function (isCalendar) {
        vm.selectedTimezone = vm.searchTextZone = null;
        vm.setDefaultTimezoneInfo();
        prepareTimezoneDates(isCalendar);
      };
      vm.openEditSide = function (con) {
        if (isNaN(con.postReplyCount)) {
          con.postReplyCount = 0;
        }
        console.log("CAlled", con)
        vm.isSidebarOpen = true;
        vm.selectedCon = con;
        vm.replyEditor[con.id] = {
          body: ''
        }
        $mdSidenav('editSidenav').open();
      };

      // Close Sidebar
      vm.closeEditSide = function () {
        vm.isSidebarOpen = false;
        vm.selectedCon = null;
        $mdSidenav('editSidenav').close();
      };

    }
  }
}