import imageViewTemplate from './../../components/associate-documents/imageViewTemplate.html';
import saveDiagramTemplate from './../../components/diagram/popups/save-diagram-popup.html';
import SaveTemplateController from './../../components/diagram/popups/save-template.controller';
import drawingCategoriesTemplate from './../../components/diagram/popups/diagram-categories.html';
import proposalCategoriesTemplate from './../../components/proposal/popups/proposal-categories.html';
import DrawingCategoriesController from './../../components/diagram/popups/diagram-categories.controller';
import ProposalCategoriesController from './../../components/proposal/popups/proposal-categories.controller';
import saveProposalTemplate from './../../components/proposal/popups/save-proposal-popup.html';
import proposalTemplateCategoriesTemplate from './../../components/proposal/manage-proposal-templates/popup/proposal-template-categories.html';
import ProposalTemplateCategoriesController from '../../components/proposal/manage-proposal-templates/popup/proposal-template-categories.controller';
// import confirmDialogTemplate from '../../components/popup-view/confirm-dialog.html';
import CloneProposalTemplate from './../../components/proposal/manage-proposal-templates/popup/clone-proposal-template.html';
import editProposalPopupController from "../../components/proposal/popups/edit-proposal-popup.controller";
import editProposalPopupTemplate from "../../components/proposal/popups/edit-proposal-popup.html";
import { template } from 'lodash';

export default function(app) {
  app.factory('AttachDocumentService', AttachDocumentService);

  function AttachDocumentService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage, $rootScope, $mdSidenav, sharedDataService, dateFactory) {

    "ngInject";

    let scope, vm, imageExtension = ["JPG", "JPE", "JPEG", "BMP", "GIF", "PNG"];

    return {
      init: init,
      initDataForDetail: initDataForDetail,
      initDataForUpdate: initDataForUpdate,
      initForUpdate: initForUpdate,
      updateDocument: updateDocument,
      close: close,
      isFileImage: isFileImage,
      imageViewPopup: imageViewPopup,
      deleteDocument: deleteDocument,
      openTemplates: openTemplates,
      openCategories: openCategories,
      openProposalCategories: openProposalCategories,
      goToCategories: goToCategories,
      editProposalTemplate: editProposalTemplate,
      cloneProposalTemplate: cloneProposalTemplate,
      showPreview: showPreview
    }

    // Intialize common variable and for document.
    function init(_vm, _scope) {
      vm = _vm;
      scope = _scope;
      vm.documentCategory = $rootScope.rObj.records['Associated documents'];
    }

    function initForUpdate() {
      vm.goToProposalTemplates = function() {
        $state.go('main.' + $rootScope.selectedModule + '.addProposalTemplates', {
          docId: vm.id,
          customerId: vm.customerId,
          quoteId: vm.quoteId
        });
      };

      vm.goToAddProposal = function() {
        $state.go('main.' + $rootScope.selectedModule + '.addProposal', {
          docId: vm.id,
          quoteId: vm.quoteId
        });
      };

      vm.goToAddProposalTemplate = function() {
        $state.go('main.' + $rootScope.selectedModule + '.addUserProposalTemplate', {
          isTemplate: true
        });
      };

      vm.editDiagram = function(drawingId) {
        $state.go('main.' + $rootScope.selectedModule + '.updateDiagram', {
          docId: vm.id,
          drawingId: drawingId
        });
      };

      vm.editProposal = function(event, vm, scope, id, quoteId) {
        // $state.go('main.' + $rootScope.selectedModule + '.updateProposal', {
        //   docId: vm.id,
        //   proposalId: proposalId
        // });
        editProposalTemplate(event, vm, scope, id, undefined, vm.id, quoteId);
      };

      vm.copyDiagram = function(drawingId) {
        $state.go('main.' + $rootScope.selectedModule + '.addCopyDiagram', {
          docId: vm.id,
          drawingId: drawingId,
          isClone: true
        });
      };

      vm.copyProposal = function(proposalId) {
        $state.go('main.' + $rootScope.selectedModule + '.addCopyProposal', {
          docId: vm.id,
          proposalId: proposalId,
          isClone: true
        });
      };

      vm.copyProposalTemplate = function(proposalId, adminTemplate = false) {
        console.log(vm.customerId);
        $state.go('main.' + $rootScope.selectedModule + '.addProposalTemplate', {
          docId: vm.id,
          proposalId: proposalId,
          isClone: true,
          copyTemplate: true,
          customerId: vm.customerId,
          adminTemplate: adminTemplate,
          quoteId: vm.quoteId
        });
      };

      vm.goToTemplates = function() {
        sharedDataService.set('docUpdateParams', $state.params);
        // return;
        $state.go('main.' + $rootScope.selectedModule + '.addTemplatesList', {
          docId: vm.id,
          fromState: $rootScope.currentStateName.replaceAll('.', '-')
        });
      };

      vm.deleteDrawing = (ev, id) => {
        messages.mdconfirm(ev, "Do you want to delete this drawing?")
          .then(function(flag) {
            if (flag) {
              dataServices.delete({
                url: serverUrl.main + 'drawingsApi/drawings/' + id
              }).then(function(response) {
                if (response && response.data && response.data.result) {
                  messages.simpleToast('Drawing deleted successfully!');
                  // After deleting the document we are fetching the list of documents for sync the live data.
                  getDrawings();
                }
              });
            }
          });
      };

      vm.deleteProposal = (ev, id) => {
        messages.mdconfirm(ev, "Do you want to delete this proposal?")
          .then(function(flag) {
            if (flag) {
              dataServices.delete({
                url: serverUrl.main + 'documentBuilderApi/proposal-document/' + id
              }).then(function(response) {
                if (response && response.data && response.data.result) {
                  messages.simpleToast('Proposal deleted successfully!');
                  // After deleting the document we are fetching the list of documents for sync the live data.
                  getProposals();
                }
              });
            }
          });
      };

      vm.saveAsTemplate = (ev, drawing) => {
        $mdDialog.show({
          locals: {
            templateData: drawing
          },
          controller: SaveTemplateController,
          templateUrl: saveDiagramTemplate,
          parent: angular.element(document.body),
          targetEvent: ev,
          controllerAs: 'st'
        });
      };

      vm.saveAsTemplateProposal = (ev, proposal) => {
        $mdDialog.show({
          locals: {
            templateData: proposal,
            vm: vm
          },
          controller: SaveProposalTemplateController,
          templateUrl: saveProposalTemplate,
          parent: angular.element(document.body),
          targetEvent: ev,
          controllerAs: 'st'
        });
      }

      function SaveProposalTemplateController($mdDialog, $state, messages, Upload, validatorService, dataServices, serverUrl, vm, templateData) {

        'ngInject';
    
        let st = this;
    
        // st.name = vm.template.name;
        // st.category = vm.template.category;
        // st.isTemplate = vm.isTemplate;
        st.save = validatorService.validateForm(st, saveProposal);
    
        st.getCategories = () => {
          return st.templateCategories ? st.templateCategories : dataServices.get({
              url: serverUrl.main + 'docBuilderTemplateApi/doc-builder/category?',
              spinner: false
            })
            .then(function(response) {
              const data = response.data;
              if (data && data.response_code == 200) {
                st.templateCategories = data.result;
              }
            });
        };
    
        if (vm.proposalId || vm.templateId) {
          st.name = vm.proposal.name;
          st.category = vm.proposal.category;
          $timeout(function() {
            st.getCategories();
          }, 0);
        }
    
        function saveProposal(fileId) {
          const dataToSend = {
            name: st.name,
            category: st.category,
            body: templateData.body,
            active: true
          };

          let url = 'docBuilderTemplateApi/doc-builder-template';
    
          console.log(dataToSend);
          // return;
    
          dataServices.post({
              url: serverUrl.main + url,
              data: {
                data: angular.toJson(dataToSend)
              },
            })
            .then(function(response) {
              let data = response.data;
              if (data && data.response_code == 200) {
                st.cancel();
                
                messages.simpleToast('Saved Successfully.');
              }
            });
        }
    
        st.cancel = function() {
          $mdDialog.hide();
        };
      }

      vm.toggleTemplate = (id) => {
        if (vm.diagramSelected && vm.diagramSelected === id) {
          vm.diagramSelected = undefined;
        } else {
          vm.diagramSelected = id;
        }
      };
    }

    // checking if file is image file
    function isFileImage(imageFileName) {
      let ext = imageFileName.split('.').pop().toUpperCase();
      return imageExtension.includes(ext);
    }

    function getDrawings() {
      const filterCriteria = angular.toJson({
        filters: [{
          field: "documentId",
          operator: "=",
          value: vm.id
        }]
      });

      dataServices.get({
          url: serverUrl.main + 'drawingsApi/drawings?filterCriteria=' + filterCriteria + '&',
          spinner: false
        })
        .then(function(response) {
          vm.isLoadedDrawings = true;
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.drawings = data.result;
          }
        }, function() {
          vm.isLoadedDrawings = true;
        });
    }

    function getProposals() {
      const filterCriteria = angular.toJson({
        filters: [{
          field: "documentId",
          operator: "=",
          value: vm.id
        }]
      });

      dataServices.get({
          url: serverUrl.main + 'documentBuilderApi/proposal-document?filterCriteria=' + filterCriteria + '&',
          spinner: false
        })
        .then(function(response) {
          vm.isLoadedProposals = true;
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.proposals = data.result;
          }
        }, function() {
          vm.isLoadedProposals = true;
        });
    }

    function initData(documentDetail, initDropdownMenu) {
      if (documentDetail && documentDetail.data.result) {
        vm.document = documentDetail.data.result;

        if (angular.isFunction(initDropdownMenu)) {
          initDropdownMenu();
        }

        vm.totalFileUpload = angular.copy(documentDetail.data.result.attachments);

        for (let i = 0; i < vm.totalFileUpload.length; i++) {
          let image = vm.totalFileUpload[i];
          if (vm.isFileImage(image.fileOriginalName)) {
            vm.images.push(image);
          } else {
            vm.files.push(image);
          }
        }
      }

      getDrawings(vm);
      getProposals(vm);
    }

    function initDataForDetail(documentDetail, initDropdownMenu) {
      if (documentDetail && documentDetail.data.result) {
        vm.document = documentDetail.data.result;

        if (angular.isFunction(initDropdownMenu)) {
          initDropdownMenu();
        }

        vm.totalFileUpload = angular.copy(documentDetail.data.result.attachments);

        for (let i = 0; i < vm.totalFileUpload.length; i++) {
          let image = vm.totalFileUpload[i];
          if (vm.isFileImage(image.fileOriginalName)) {
            vm.images.push(image);
          } else {
            vm.files.push(image);
          }
        }
        getDrawings(vm);
        getProposals(vm);
      }
    }

    function initDataForUpdate(documentDetail) {
      if (documentDetail && documentDetail.data.result) {
        vm.document = dataServices.toSave(documentDetail.data.result, ['name', 'category', 'docExpiryDate', 'description', 'modelId', 'modelName', 'tags']);
        if (vm.document.docExpiryDate) {
          vm.docExpiryDate = new Date(vm.document.docExpiryDate * 1000);
        }
        vm.totalFileUpload = angular.copy(documentDetail.data.result.attachments);
        getDrawings(vm);
        getProposals(vm);
      }
    }

    // showing images on popup or showing fullscreen images.
    function imageViewPopup(ev, image) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: ImageViewController,
        controllerAs: 'cd',
        templateUrl: imageViewTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        locals: {
          parentVm: vm,
          image: image
        }
      });
    }

    function ImageViewController($scope, $mdDialog, $rootScope, $timeout, parentVm, image) {
      "ngInject";
      let cd = this;
      cd.image = image;
      cd.cancel = cancel;

      function cancel() {
        $mdDialog.cancel();
      }
    }

    // create an document or update the document
    function updateDocument() {
      // if(!vm.document.attachments.length){
      //   messages.simpleToast('Please attach at lease one attachment.');
      //   return;
      // }
      if (vm.docExpiryDate) {
        vm.document.docExpiryDate = dateFactory.getEpoche(vm.docExpiryDate);
      }

      dataServices.post({
        url: serverUrl.main + 'assocDocApi/assoc/doc' + (vm.id ? '/' + vm.id : ''),
        method: (vm.id ? 'PUT' : 'POST'),
        data: {
          data: angular.toJson(vm.document)
        }
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          messages.simpleToast('Document ' + (vm.id ? 'updated' : 'added') + ' successfully.');
          scope.$parent.vm.backScreen();
        }
      });
    }

    function close() {
      $mdSidenav('fileUploadSidebar').close();
    }

    // delete documents by user constent
    function deleteDocument(ev, id) {
      messages.mdconfirm(ev, "Do you want to delete this document?")
        .then(function(flag) {
          if (flag) {
            dataServices.delete({
              url: serverUrl.main + 'assocDocApi/assoc/doc/' + id
            }).then(function(response) {
              if (response && response.data && response.data.result) {
                messages.simpleToast('Document deleted successfully!');
                // After deleting the document we are fetching the list of documents for sync the live data.
                vm.setFilters('new', true);
              }
            });
          }
        });
    }

    function openTemplates() {
      $state.go('main.' + $rootScope.selectedModule + '.addManageTemplates');
    }

    function openCategories(ev) {
      $mdDialog.show({
        controller: DrawingCategoriesController,
        templateUrl: drawingCategoriesTemplate,
        locals: {
          vm: vm
        },
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'dc'
      });
    }

    function openProposalCategories(ev) {
      $mdDialog.show({
        controller: ProposalCategoriesController,
        templateUrl: proposalCategoriesTemplate,
        locals: {
          vm: vm
        },
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'dc'
      });
    }

    function goToCategories(callback) {
      $mdDialog.show({
        controller: ProposalTemplateCategoriesController,
        templateUrl: proposalTemplateCategoriesTemplate,
        locals: {
          vm: vm,
          callback: callback
        },
        parent: angular.element(document.body),
        controllerAs: 'dc'
      });
    }

    function editProposalTemplate(event, vm, scope, id, isTemplate, isDoc, isQuote) {
      // vm.docId = isDoc;
      // vm.proposalId = id;
      // vm.parentVm = vm;
      // vm.isTemplate = isTemplate;
      // vm.customerId = vm.parentVm.customerId;
      // vm.freepikPopup = freepikService.freepikPopup;
      // vm.quoteId = isQuote;
      // ProposalService.init(vm, scope);
      
      // $state.go('main.' + $rootScope.selectedModule + '.editProposalTemplate', {proposalId: id, isTemplate: true});
      let body = document.getElementsByTagName('body');
      body[0].classList.add("scroll-hide");
      $mdDialog.show({
        controller: editProposalPopupController,
        templateUrl: editProposalPopupTemplate,
        targetEvent: event,
        controllerAs: 'pr',
        fullScreen: true,
        multiple: true,
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {
          vm: vm,
          ev: event,
          id: id,
          isTemplate: isTemplate,
          isDoc: isDoc,
          customerId: vm.customerId,
          quoteId: isQuote,
          quoteDetails: isQuote ? scope.$parent.vm.userDetail : undefined
        },
      }).then(() => {
        body[0].classList.remove("scroll-hide");
      }, () => {
        body[0].classList.remove("scroll-hide");
      });
    }

    function cloneProposalTemplate(template, isSaved) {
      $mdDialog.show({
        locals: {
          vm: vm,
          template: template,
          isSaved: isSaved
        },
        controller: cloneDesignController,
        templateUrl: CloneProposalTemplate,
        parent: angular.element(document.body),
        controllerAs: 'cd'
      });
    }

    function cloneDesignController($scope, $mdDialog, vm, template, isSaved, dataServices) {

      'ngInject';
  
      let cd = this;
      $scope.vm = vm;
  
      cd.question = 'Do you want to clone ' + template.name + '?'
      cd.description = 'Your template will be cloned to ‘Saved’ tab and marked inactive.';
  
      cd.confirm = confirm;
  
      function confirm() {
        cd.template = {
          name: cd.template.name,
          category: cd.template.category
        }
        dataServices.post({
          url: serverUrl.main + 'docBuilderTemplateApi/doc-builder-template/template/clone/' + (isSaved ? 'saved/' : '') + template.id,
          data: {
            data: angular.toJson(cd.template)
          }
        })
        .then(function(response) {
          let data = response.data;
          if (data && (data.response_code == 200)) {
            cd.cancel();
            // vm.activeTabIndex = 0;
            $timeout(function() {
              messages.simpleToast('Cloned Successfully.');
              vm.switchTab('savedProposal');
            }, 100);
          }
        });
      }

      cd.getCategories = () => {
        return cd.categories ? cd.categories : dataServices.get({
            url: serverUrl.main + 'docBuilderTemplateApi/doc-builder/category?',
            spinner: false
          })
          .then(function(response) {
            const data = response.data;
            if (data && data.response_code == 200) {
              cd.categories = data.result;
            }
          });
      };
  
      cd.cancel = function() {
        $mdDialog.cancel();
      };
    }

    function showPreview(ev, design) {
      $state.go('main.' + $rootScope.selectedModule + '.addViewProposalTemplate', {
        proposalId: design.id
      });
    };
  }
}