import rightQuickMenuTemplate from './right-quick-menu.html';
import addContactPopupTemplate from './add-contact-popup.html';
import AddContactPopupController from './add-contact-popup.controller';

export default function(app) {
  app.directive("rightQuickMenu", rightQuickMenu)
    .controller("RightQuickMenuController", RightQuickMenuController);

  function rightQuickMenu() {
    return {
      restrict: 'E',
      templateUrl: rightQuickMenuTemplate,
      controller: 'RightQuickMenuController',
      controllerAs: 'vm',
      // replace: true
    };
  }

  function RightQuickMenuController($scope, $rootScope, $mdSidenav, $mdDialog, $state, sharedDataService, ActionService, FileUploadService, TimezoneService, ListService, dataServices, serverUrl, actionAttachFunctions) {

    "ngInject";

    let vm = this;

    const LIMIT = 25;

    vm.searchText = {};
    vm.textToSearch = {};

    // search functions
    vm.toggleSearch = ListService.toggleSearch;
    vm.searchOnEnter = ListService.searchOnEnter;
    vm.getSearchKeys = ListService.getSearchKeys(vm);

    vm.isPanel = vm.isFrame = true;
    vm.isFromApp = sharedDataService.get('isFromApp');
    vm.isOwnerOrAdmin = $rootScope.usrObj.isOwner || $rootScope.usrObj.isAdmin;
    vm.cbac = {
      addContact: 'customer_list',
      opportunities: 'customer_opportunity_boards',
      addQuote: 'quotes',
      action: 'team_action_items'
    };

    const toggleBodyPanelClass = () => {
      if (vm.isPanelOpen) {
        $('body').addClass('quick-panel-opened');
      } else {
        $('body').removeClass('quick-panel-opened');
      }
    };

    const isPanelOpen = sharedDataService.get('isRightPanelOpen');
    vm.isPanelOpen = isPanelOpen !== null ? isPanelOpen : true;
    toggleBodyPanelClass();

    vm.togglePanel = () => {
      vm.isPanelOpen = !vm.isPanelOpen;
      sharedDataService.set('isRightPanelOpen', vm.isPanelOpen);
      toggleBodyPanelClass();
    };

    vm.goToAddOpportunity = () => {
      if (vm.board) {
        $state.go('main.customers.addCard', {
          'boardId': vm.board.id,
          'locationId': vm.board.location
        });
        vm.toggleSidenav('pick-board-panel');
      }
    };

    vm.toggleSidenav = (id) => {
      $mdSidenav(id).toggle();
    };

    vm.openAddContactPopup = (ev, isQuote) => {
      $mdDialog.show({
        locals: {
          isQuote
        },
        targetEvent: ev,
        controller: AddContactPopupController,
        templateUrl: addContactPopupTemplate,
        parent: angular.element(document.body),
        controllerAs: 'vm',
        fullscreen: true,
        multiple: true
      });
    };

    vm.getBoards = getBoards;

    function getBoards() {
      let filterCriteria = angular.toJson({
        filters: [{
          field: 'isArchived',
          operator: '=',
          value: false
        }],
        sort: [{
          field: 'name',
          order: 1
        }]
      });

      dataServices.get({
          url: serverUrl.main + 'opportunityApi/opportunity/boards?location=true&filterCriteria=' + filterCriteria + '&',
          spinnerName: 'navigation-sidebar-spinner'
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.boards = data.result;
          }
        }).catch((error) => {
          vm.apiFailed = true;
        });
    }

    // action item code
    vm.objLength = ActionService.objLength;
    vm.selectResource = ActionService.selectResource;
    vm.getResourceList = ActionService.getResourceList;
    vm.saveAttachedResources = ActionService.saveAttachedResources;

    vm.openSidenavResource = ActionService.openSidenavResource;
    vm.showDatePickerPopup = ActionService.showDatePickerPopup;
    vm.uploadFilesAndSaveAction = ActionService.uploadFilesAndSaveAction;

    vm.attachFunction = ActionService.attachFunction;
    vm.getFunctionsList = ActionService.getFunctionsList;
    vm.enableEditScreen = ActionService.enableEditScreen;
    vm.attachFunctionData = ActionService.attachFunctionData;
    vm.selectUnselectFunction = ActionService.selectUnselectFunction;
    vm.clearSelectedFunctions = ActionService.clearSelectedFunctions;
    vm.getOpportunitiesBoard = ActionService.getOpportunitiesBoard;

    vm.filterFunctions_arr = actionAttachFunctions;

    // Clear filters by name recieved in filterName.
    function removeFilter(vm, filterName, listName) {
      angular.forEach(vm.filters[listName], function(value, key) {
        if (value.field == filterName) {
          vm.filters[listName].splice(key, 1);
        }
      });
    }

    // Add particular filter
    function addFilter(vm, filterName, filterValue, operator, listName) {
      if (filterName == 'status' && angular.isString(filterValue)) {
        filterValue = parseInt(filterValue);
      }

      // If filter exists already, remove it before adding it
      removeFilter(vm, filterName, listName);

      let filter = {
        field: filterName,
        value: filterValue,
        operator: operator
      }

      vm.filters[listName].push(filter);
    }

    vm.defaultFc = {
      fresources: {
        filters: [{
          field: 'isArchived',
          operator: '=',
          value: false
        }, {
          field: 'status',
          value: 2,
          operator: "="
        }]
      },
      tags: angular.copy({
        filters: [{
          field: 'isArchived',
          operator: '=',
          value: false
        }, {
          field: 'category',
          operator: '=',
          value: 1
        }, {
          field: 'mainCategory',
          operator: '!=',
          value: 'System Tags'
        }],
        defaultSort: [{
          field: 'name',
          order: 1
        }],
        skip: 0,
        limit: LIMIT
      }),
      attachFunctionValue: angular.copy({
        filters: [],
        defaultSort: [{
          field: "lastUpdated",
          order: -1
        }],
        onlyPagination: true,
        skip: 0,
        limit: LIMIT
      }),
      fcards: angular.copy({
        filters: [{
          field: 'isArchived',
          operator: '=',
          value: false
        }]
      })
    };

    vm.filters = {
      fresources: angular.copy(vm.defaultFc.fresources.filters),
      fcards: angular.copy(vm.defaultFc.fcards.filters)
    };

    vm.filter = {
      fresources: {},
      fcards: {}
    };

    // Suggestion and search history api keys required for search functionality in right sidenavs
    vm.suggestionApi = {
      fresources: 'resourceApi/resources',
      fcards: 'opportunityApi/opportunity'
    };

    vm.searchHistoryApiKey = {
      fresources: 6,
      fcards: 3
    };

    function setResourcesFilters() {
      if (vm.filter.fresources.orgId && vm.filter.fresources.orgId != 'All') {
        addFilter(vm, 'orgId', vm.filter.fresources.orgId, '=', 'fresources');
      } else {
        removeFilter(vm, 'orgId', 'fresources');
      }

      if (vm.filter.fresources.selectedLocation) {
        addFilter(vm, 'businessAddress', parseInt(vm.filter.fresources.selectedLocation), '=', 'fresources');
      }
    }

    vm.infiniteResources = {
      numLoaded_: 0,
      toLoad_: 0,
      items: [],
      isLoading: false,
      isListLoaded: false,
      disablePagination: false,

      init: function() {
        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        this.fetchMoreItems_(1);
      },

      // Required.
      getItemAtIndex: function(index) {
        if (index > this.numLoaded_) {
          this.fetchMoreItems_(index);
          return null;
        }

        return this.items[index];
      },

      // Required.
      // For infinite scroll behavior, we always return a slightly higher
      // number than the previously loaded items.
      getLength: function() {
        return this.numLoaded_ + (this.disablePagination ? 0 : LIMIT);
      },

      search: function() {
        console.log(vm.searchText);
        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        if (vm.searchText.fresources) {
          ListService.toggleSearch(true);
        }
        this.disablePagination = false;
        this.fetchMoreItems_(1);
      },

      filter: function(isClear) {
        vm.filters.fresources = angular.copy(vm.defaultFc.fresources.filters);

        if (isClear) {
          vm.filter.fresources = {};
        }

        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        this.disablePagination = false;
        this.fetchMoreItems_(1);
      },

      fetchMoreItems_: function(index) {
        // For demo purposes, we simulate loading more items with a timed
        // promise. In real code, this function would likely contain an
        // $http request.
        if (this.toLoad_ < index && !this.isLoading && !this.disablePagination) {
          this.toLoad_ += LIMIT;

          vm.filters.fresources = angular.copy(vm.defaultFc.fresources.filters);
          setResourcesFilters();

          const filterCriteria = {
            filters: vm.filters.fresources,
            project: ['images', 'avatar', 'first_name', 'middle_name', 'last_name', 'assignedQuotes',
              'totalQuotes', 'org_details.organizations.primary', 'org_details.organizations.secondary'
            ],
            sort: [{
              field: "firstName",
              order: 1
            }, {
              field: "_id",
              order: 1
            }],
            skip: this.items.length,
            limit: LIMIT
          };

          this.isLoading = true;
          this.isListLoaded = false;
          dataServices.get({
              url: serverUrl.main + 'resourceApi/resources?filterCriteria=' + angular.toJson(filterCriteria) + '&' + (vm.searchText.fresources ? ('search=' + vm.searchText.fresources + '&') : ''),
              spinner: false
            })
            .then(response => {
              this.isLoading = false;
              this.isListLoaded = true;

              let data = response.data;
              if (data && data.response_code == 200) {
                this.items = this.items.concat(data.result);
                this.disablePagination = data.result.length < LIMIT;
                this.numLoaded_ = this.disablePagination ? this.items.length : this.toLoad_;
              }
            }, error => {
              this.isLoading = false;
              this.isListLoaded = true;
            });
        }
      }
    };

    vm.infiniteAttachFunctions = {
      numLoaded_: 0,
      toLoad_: 0,
      items: [],
      isLoading: false,
      isListLoaded: false,
      disablePagination: false,

      init: function() {
        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        this.disablePagination = false;
        this.fetchMoreItems_(1);
      },

      // Required.
      getItemAtIndex: function(index) {
        if (index > this.numLoaded_) {
          this.fetchMoreItems_(index);
          return null;
        }

        return this.items[index];
      },

      // Required.
      // For infinite scroll behavior, we always return a slightly higher
      // number than the previously loaded items.
      getLength: function() {
        return this.numLoaded_ + (this.disablePagination ? 0 : LIMIT);
      },

      search: function() {
        console.log(vm.searchText);
        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        if (vm.searchText.attachFunctionValue) {
          ListService.toggleSearch(true);
        }
        this.disablePagination = false;
        this.fetchMoreItems_(1);
      },

      fetchMoreItems_: function(index) {
        // For demo purposes, we simulate loading more items with a timed
        // promise. In real code, this function would likely contain an
        // $http request.
        if (this.toLoad_ < index && !this.isLoading && !this.disablePagination) {
          this.toLoad_ += LIMIT;

          const filterCriteria = {
            filters: [],
            sort: [{
              field: "lastUpdated",
              order: -1
            }],
            skip: this.items.length,
            limit: LIMIT
          };

          this.isLoading = true;
          this.isListLoaded = false;
          dataServices.get({
              url: serverUrl.main + 'actionsApi/action/getFunctions?filterCriteria=' + angular.toJson(filterCriteria) + '&modelName=' + vm.functionVal + (vm.selectedBusinessId && vm.selectedBusinessId != 'All' ? ('&addressId=' + vm.selectedBusinessId) : '') + '&' + (vm.searchText.attachFunctionValue ? ('search=' + vm.searchText.attachFunctionValue + '&') : ''),
              spinner: false
            })
            .then(response => {
              this.isLoading = false;
              this.isListLoaded = true;

              let data = response.data;
              if (data && data.response_code == 200) {
                this.items = this.items.concat(data.result);
                this.disablePagination = data.result.length < LIMIT;
                this.numLoaded_ = this.disablePagination ? this.items.length : this.toLoad_;
              }
            }, error => {
              this.isLoading = false;
              this.isListLoaded = true;
            });
        }
      }
    };

    function setCardsFilters() {
      if (vm.boardId && vm.boardId != 'All') {
        addFilter(vm, 'boardId', vm.boardId, '=', 'fcards');
      }
    }

    vm.infiniteCards = {
      numLoaded_: 0,
      toLoad_: 0,
      items: [],
      isListLoaded: false,
      disablePagination: false,

      init: function() {
        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        this.disablePagination = false;
        this.fetchMoreItems_(1);
      },

      // Required.
      getItemAtIndex: function(index) {
        if (index > this.numLoaded_) {
          this.fetchMoreItems_(index);
          return null;
        }

        return this.items[index];
      },

      // Required.
      // For infinite scroll behavior, we always return a slightly higher
      // number than the previously loaded items.
      getLength: function() {
        return this.numLoaded_ + (this.disablePagination ? 0 : LIMIT);
      },

      search: function() {
        console.log(vm.searchText);
        this.numLoaded_ = LIMIT;
        this.toLoad_ = 0;
        this.items = [];
        if (vm.searchText.fcards) {
          ListService.toggleSearch(true);
        }
        this.disablePagination = false;
        this.fetchMoreItems_(1);
      },

      fetchMoreItems_: function(index) {
        // For demo purposes, we simulate loading more items with a timed
        // promise. In real code, this function would likely contain an
        // $http request.
        if (this.toLoad_ < index) {
          this.toLoad_ += LIMIT;

          vm.filters.fcards = angular.copy(vm.defaultFc.fcards.filters);
          setCardsFilters();

          const filterCriteria = {
            filters: vm.filters.fcards,
            skip: this.items.length,
            limit: LIMIT,
            // sort: angular.copy(vm.defaultFc.fcards.defaultSort)
          };

          this.isListLoaded = false;
          dataServices.get({
              url: serverUrl.main + 'opportunityApi/opportunity/cards?filterCriteria=' + angular.toJson(filterCriteria) + '&' + (vm.searchText.fcards ? ('search=' + vm.searchText.fcards + '&') : ''),
              spinner: false
            })
            .then(response => {
              this.isListLoaded = true;

              let data = response.data;
              if (data && data.response_code == 200) {
                this.items = this.items.concat(data.result);
                this.disablePagination = data.result.length < LIMIT;
                this.numLoaded_ = this.disablePagination ? this.items.length : this.toLoad_;
              }
            }, error => {
              this.isListLoaded = true;
            });
        }
      }
    };

    // Action Item Add
    vm.addActionItem = (ev) => {
      vm.isAddingAction = true;

      vm.selectedBusinessId = $rootScope.usrObj.primaryBzLocation;
      TimezoneService.setLocalTimezone(vm, vm.selectedBusinessId);

      vm.actionConstant = {
        modelName: 40,
        modelId: $rootScope.usrObj.userId,
        scopeParentVm: {},
        isSidenavOnly: true
      };

      ActionService.init(vm);
      FileUploadService.init(vm, 'actionEdit', 'fileUploadSidebar-fullScreen');

      vm.actionEdit = {
        priority: 0,
        isSystem: false,
        addressId: vm.selectedBusinessId,
        modelId: vm.actionConstant.modelId,
        modelName: vm.actionConstant.modelName,
        tags: [],
        resources: []
      };

      vm.allResource = [];

      ActionService.editAction(vm, vm.actionEdit, false, false);
      ActionService.openFullScreen(ev, vm, vm.actionConstant.modelId);
    };
  }
}