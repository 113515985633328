export const SERVICE_LIST = {
  url: 'taskApi/services/quotes/:quoteId?stats=yes&',
  method: 'GET',
  name: 'services',
  data: {
    type: 'list'
  },
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'taskScheduleType',
      operator: '!=',
      value: 3
    }, {
      field: "prepCycle",
      operator: "exists",
      value: false
    }],
    // defaultSort: [{
    //   field: "createdAt",
    //   order: -1
    // }]
  },
  params: ['quoteId'],
  pagination: 'SERVICE_LIST',
  requiredFor: ['main.quotes.services']
};

export const SERVICE_DETAIL = {
  url: 'taskApi/services/:serviceId/quotes/:quoteId',
  method: 'GET',
  name: 'serviceData',
  data: {
    type: "detail"
  },
  params: ['quoteId', 'serviceId'],
  requiredFor: ['main.quotes.servicesDetail', 'main.quotes.editServices'],
  forcedBackFor: {
    'main.quotes.servicesDetail': ['main.services'],
    'main.quotes.editServices': ['main.services']
  }
};

export const LIST_SERVICE_RATES = {
  url: 'businessApi/listServiceRates',
  name: 'serviceRates',
  data: {
    flow: 'serviceRatesListDetail'
  },
  requiredFor: ['main.quotes.editServicesEstimates'],
  forcedBackFor: {
    'main.quotes.editServicesEstimates': ['main.services']
  }
};

export const ESTIMATE_DETAIL = {
  url: 'taskLineApi/services/:serviceId/quotes/:quoteId/estimates',
  name: 'estimateData',
  method: 'GET',
  params: ['quoteId', 'serviceId'],
  requiredFor: ['main.quotes.servicesEstimateDetail', 'main.quotes.editServicesEstimates'],
  forcedBackFor: {
    'main.quotes.editServicesEstimates': ['main.services'],
    'main.quotes.servicesEstimateDetail': ['main.services']
  }
};

export const PROMOTION_DETAIL = {
  url: 'quoteApi/quotes/:quoteId/services/:serviceId',
  method: 'GET',
  name: 'serviceData',
  data: {
    type: "detail"
  },
  params: ['quoteId', 'serviceId']
};
export const PRODUCT_DETAIL = {
  url: 'quoteInventoryApi/products/reg/:quoteId/:locationId/:productId',
  method: 'GET',
  name: 'PRODUCT_DETAIL',
  params: ['productId', 'quoteId', 'locationId'],
  requiredFor: ['main.quotes.addProductDetail', 'main.quotes.addProductDetailCart']
};

export const PACKAGE_PRODUCT_DETAIL = {
  url: 'quoteInventoryApi/quotes/products/:quoteId/:locationId/:productId?prodType=["product"]&',
  method: 'GET',
  name: 'PACKAGE_PRODUCT_DETAIL',
  params: ['productId', 'quoteId', 'locationId', 'packageId', 'serviceId', 'catalogId'],
  requiredFor: ['main.quotes.addPackageProductCustomize']
};

export const PACKAGE_PRODUCT_SELECTED_SKU_DETAIL = {
  url: 'quoteInventoryApi/quotes/products/package/sku/:lineItemId/:catalogId/:productId?prodType=["product"]&',
  method: 'GET',
  name: 'PACKAGE_PRODUCT_SELECTED_SKU_DETAIL',
  params: ['productId', 'quoteId', 'locationId', 'packageId', 'serviceId', 'catalogId', 'lineItemId'],
  requiredFor: ['main.quotes.addPackageProductCustomize']
};

export const BUNDLE_DETAIL = {
  url: 'quoteInventoryApi/products/bndl/:quoteId/:locationId/:bundleId',
  method: 'GET',
  name: 'BUNDLE_DETAIL',
  params: ['quoteId', 'locationId', 'catalogId', 'childCatalogId', 'bundleId', 'serviceId'],
  requiredFor: ['main.quotes.addBundleDetail', 'main.quotes.addBundleProductDetailCart']
};

export const BUNDLE_DETAIL_CART = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products/:lineItemId?prodType=["product"]&',
  method: 'GET',
  name: 'BUNDLE_DETAIL_CART',
  params: ['quoteId', 'locationId', 'bundleId', 'serviceId', 'lineItemId'],
  requiredFor: ['main.quotes.addBundleDetail', 'main.quotes.addBundleProductDetailCart']
};

export const PACKAGE_DETAIL = {
  url: 'quoteInventoryApi/products/package/:quoteId/:locationId/:packageId?prodType=["product"]&',
  method: 'GET',
  name: 'PACKAGE_DETAIL',
  params: ['quoteId', 'locationId', 'packageId', 'serviceId'],
  requiredFor: ['main.quotes.addPackageDetail', 'main.quotes.addPackageDetailCart']
};

export const PACKAGE_DETAIL_CART = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products/:lineItemId?prodType=["product"]&',
  method: 'GET',
  name: 'PACKAGE_DETAIL_CART',
  params: ['quoteId', 'locationId', 'packageId', 'serviceId', 'lineItemId'],
  requiredFor: ['main.quotes.addPackageDetail', 'main.quotes.addPackageDetailCart']
};

export const PRODUCT_DETAIL_CART = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products/:lineItemId',
  method: 'GET',
  name: 'PRODUCT_DETAIL_CART',
  params: ['productId', 'quoteId', 'serviceId', 'lineItemId'],
  requiredFor: ['main.quotes.addProductDetail', 'main.quotes.addProductDetailCart']
};

export const REGULAR_LIST = {
  url: 'quoteInventoryApi/catalogs/products/list/reg?quoteId=:quoteId&location=:locationId&prodType=["product"]&',
  method: 'GET',
  name: 'REGULAR_LIST',
  params: ['quoteId', 'locationId'],
  requiredFor: ['main.quotes.servicesProductsAdd']
};

export const suppliersList = {
  url: 'supplierApi/suppliers?includePo=true&',
  method: 'GET',
  name: 'suppliersList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  }
};

export const policiesList = {
  url: 'recurringPlanApi/policies',
  name: 'policiesList',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'type',
      operator: '=',
      value: 9
    }]
  },
  paramTypes: ['int']
};

export const RECURRING_PLANS = {
  url: 'recurringPlanApi/recurringPlans?scenario=list&',
  name: 'RECURRING_PLANS',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'recEnable',
      operator: '=',
      value: true
    }]
  }
};

export const fetchAllPromotion = {
  url: 'promotionApi/promotions',
  method: 'GET',
  name: 'promotions'
};

export const CART_LIST = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products?prodType=["product"]&',
  method: 'GET',
  name: 'CART_LIST',
  params: ['quoteId', 'serviceId']
};

export const CART_TOTAL = {
  url: 'quoteInventoryApi/quote/products/cart/price/:quoteId/:serviceId?prodType=["product"]&',
  method: 'GET',
  name: 'CART_TOTAL',
  params: ['quoteId', 'serviceId']
};

export const CART_LIST_MATERIAL = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products?prodType=["material"]&',
  method: 'GET',
  name: 'CART_LIST',
  params: ['quoteId', 'serviceId']
};

export const CART_TOTAL_MATERIAL = {
  url: 'quoteInventoryApi/quote/products/cart/price/:quoteId/:serviceId?prodType=["material"]&',
  method: 'GET',
  name: 'CART_TOTAL',
  params: ['quoteId', 'serviceId']
};

export const REGULAR_LIST_MATERIAL = {
  url: 'quoteInventoryApi/catalogs/products/list/reg?prodType=["material"]&quoteId=:quoteId&location=:locationId&',
  method: 'GET',
  name: 'REGULAR_LIST',
  params: ['quoteId', 'locationId'],
  requiredFor: ['main.quotes.servicesProductsAdd']
};
export const PACKAGE_DETAIL_MATERIAL = {
  url: 'quoteInventoryApi/products/package/:quoteId/:locationId/:packageId?prodType=["material"]&',
  method: 'GET',
  name: 'PACKAGE_DETAIL',
  params: ['quoteId', 'locationId', 'packageId', 'serviceId'],
  requiredFor: ['main.quotes.addPackageDetail', 'main.quotes.addPackageDetailCart']
};
export const PACKAGE_DETAIL_CART_MATERIAL = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products/:lineItemId?prodType=["material"]&',
  method: 'GET',
  name: 'PACKAGE_DETAIL_CART',
  params: ['quoteId', 'locationId', 'packageId', 'serviceId', 'lineItemId'],
  requiredFor: ['main.quotes.addPackageDetail', 'main.quotes.addPackageDetailCart']
};

export const PACKAGE_PRODUCT_DETAIL_MATERIAL = {
  url: 'quoteInventoryApi/quotes/products/:quoteId/:locationId/:productId?prodType=["material"]&',
  method: 'GET',
  name: 'PACKAGE_PRODUCT_DETAIL',
  params: ['productId', 'quoteId', 'locationId', 'packageId', 'serviceId'],
  requiredFor: ['main.quotes.addPackageProductCustomize']
};
export const PRODUCT_DETAIL_MATERIAL = {
  url: 'quoteInventoryApi/products/reg/:quoteId/:locationId/:productId?prodType=["material"]&',
  method: 'GET',
  name: 'PRODUCT_DETAIL',
  params: ['productId', 'quoteId', 'locationId'],
  requiredFor: ['main.quotes.addProductDetail', 'main.quotes.addProductDetailCart']
};
export const PRODUCT_DETAIL_CART_MATERIAL = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products/:lineItemId?prodType=["material"]&',
  method: 'GET',
  name: 'PRODUCT_DETAIL_CART',
  params: ['productId', 'quoteId', 'serviceId', 'lineItemId'],
  requiredFor: ['main.quotes.addProductDetail', 'main.quotes.addProductDetailCart']
};

export const BUNDLE_DETAIL_MATERIAL = {
  url: 'quoteInventoryApi/products/bndl/:quoteId/:locationId/:bundleId?prodType=["material"]&',
  method: 'GET',
  name: 'BUNDLE_DETAIL',
  params: ['quoteId', 'locationId', 'catalogId', 'childCatalogId', 'bundleId', 'serviceId'],
  requiredFor: ['main.quotes.addBundleDetail', 'main.quotes.addBundleProductDetailCart']
};

export const BUNDLE_DETAIL_CART_MATERIAL = {
  url: 'quoteInventoryApi/quotes/:quoteId/services/:serviceId/products/:lineItemId?prodType=["material"]&',
  method: 'GET',
  name: 'BUNDLE_DETAIL_CART',
  params: ['quoteId', 'locationId', 'bundleId', 'serviceId', 'lineItemId'],
  requiredFor: ['main.quotes.addBundleDetail', 'main.quotes.addBundleProductDetailCart']
};

export const SAVING_SUMMARY = {
  url: 'quotePromotionApi/quote/promotions/summary/:quoteId/:serviceId',
  method: 'GET',
  name: 'SAVING_SUMMARY',
  params: ['quoteId', 'serviceId']
};

export const JOBS_PRODUCT_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/products?prodType=["product"]&',
  method: 'GET',
  name: 'JOBS_PRODUCT_VIEW',
  params: ['quoteId', 'serviceId'],
  // filterCriteria: {
  //   filters: [{
  //     field: "lineItemId",
  //     operator: "=",
  //     value: "5d22d9f5e374c5001a456313"
  //   }, {
  //     field: "productId",
  //     operator: "=",
  //     value: "5cfa090ce374c5001a54f4a2"
  //   }, {
  //     field: "itemId",
  //     operator: "=",
  //     value: "5cfa092de374c5001910c9d2"
  //   }]
  // }
};
export const JOBS_PRODUCT_CART_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/product',
  method: 'GET',
  name: 'JOBS_PRODUCT_CART_VIEW',
  filterCriteria: {
    filters: [{
        field: "productId",
        operator: "="
      },
      {
        field: "itemId",
        operator: "="
      },
      {
        field: "lineItemId",
        operator: "="
      }
    ]
  },
  filterValue: ['productId', 'itemId', 'lineItemId'],
  params: ['quoteId', 'serviceId']
};

export const JOBS_BUNDLE_PRODUCT_CART_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/product',
  method: 'GET',
  name: 'JOBS_PRODUCT_CART_VIEW',
  filterCriteria: {
    filters: [{
        field: "slug",
        operator: "="
      },
      {
        field: "productId",
        operator: "="
      },
      {
        field: "itemId",
        operator: "="
      },
      {
        field: "lineItemId",
        operator: "="
      },
      {
        field: "catType",
        operator: "=",
        value: ["product"]
      },
      {
        field: "productType",
        operator: "=",
        value: "bndl"
      }
    ]
  },
  filterValue: ['slugId', 'productId', 'itemId', 'lineItemId'],
  params: ['quoteId', 'serviceId', 'itemId']
};

export const JOBS_BUNDLE_MATERIAL_CART_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/product',
  method: 'GET',
  name: 'JOBS_PRODUCT_CART_VIEW',
  filterCriteria: {
    filters: [{
        field: "slug",
        operator: "="
      },
      {
        field: "productId",
        operator: "="
      },
      {
        field: "itemId",
        operator: "="
      },
      {
        field: "lineItemId",
        operator: "="
      },
      {
        field: "catType",
        operator: "=",
        value: ["product"]
      },
      {
        field: "productType",
        operator: "=",
        value: "bndl"
      }
    ]
  },
  filterValue: ['slugId', 'productId', 'itemId', 'lineItemId'],
  params: ['quoteId', 'serviceId', 'itemId']
};

export const JOBS_MATERIAL_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/products?prodType=["material"]&',
  method: 'GET',
  name: 'JOBS_PRODUCT_VIEW',
  params: ['quoteId', 'serviceId']
};

export const STOCK_VIEW_DATA_PRODUCT = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/service/:serviceId/stock/review?stats=yes&prodType=["product"]&',
  method: 'GET',
  name: 'STOCK_VIEW_DATA',
  params: ['quoteId', 'serviceId'],
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }]
  }
};

export const STOCK_VIEW_DATA_MATERIAL = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/service/:serviceId/stock/review?stats=yes&prodType=["material"]&',
  method: 'GET',
  name: 'STOCK_VIEW_DATA',
  params: ['quoteId', 'serviceId'],
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }]
  }
};

export const DELIVERY_USAGE_PRODUCT = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/delivers?prodType=["product"]&',
  method: 'GET',
  name: 'DELIVERY_USAGE',
  params: ['quoteId', 'serviceId'],
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "action",
      operator: "=",
      value: "delivery"
    }]
  }
};

export const DELIVERY_USAGE_MATERIAL = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/delivers?stats=yes&prodType=["material"]&',
  method: 'GET',
  name: 'DELIVERY_USAGE',
  params: ['quoteId', 'serviceId'],
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "action",
      operator: "=",
      value: "delivery"
    }]
  }
};

export const FULFILLMENT_TABS_COUNT_PRODUCT = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/ordershortinfo?prodType=["product"]&',
  method: 'GET',
  name: 'FULFILLMENT_TABS_COUNT',
  params: ['quoteId', 'serviceId'],
};

export const FULFILLMENT_TABS_COUNT_MATERIAL = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/ordershortinfo?prodType=["material"]&',
  method: 'GET',
  name: 'FULFILLMENT_TABS_COUNT',
  params: ['quoteId', 'serviceId'],
};

export const JOBS_PACKAGE_PRODUCT_CART_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/product',
  method: 'GET',
  name: 'JOBS_PACKAGE_PRODUCT_CART_VIEW',
  filterCriteria: {
    filters: [{
        field: "slug",
        operator: "="
      },
      {
        field: "productId",
        operator: "="
      },
      {
        field: "itemId",
        operator: "="
      },
      {
        field: "lineItemId",
        operator: "="
      },
      {
        field: "catalogId",
        operator: "="
      },
      {
        field: "catType",
        operator: "=",
        value: ["product"]
      },
      {
        field: "productType",
        operator: "=",
        value: "package"
      }
    ]
  },
  filterValue: ['slugId', 'productId', 'itemId', 'lineItemId', 'catalogId'],
  params: ['quoteId', 'serviceId', 'itemId', 'slugId', 'productId', 'lineItemId', 'catalogId']
};

export const JOBS_PACKAGE_MATERIAL_CART_VIEW = {
  url: 'jobsInventoryApi/fulfillment/job/:quoteId/services/:serviceId/product',
  method: 'GET',
  name: 'JOBS_PACKAGE_PRODUCT_CART_VIEW',
  filterCriteria: {
    filters: [{
        field: "slug",
        operator: "="
      },
      {
        field: "productId",
        operator: "="
      },
      {
        field: "itemId",
        operator: "="
      },
      {
        field: "lineItemId",
        operator: "="
      },
      {
        field: "catalogId",
        operator: "="
      },
      {
        field: "catType",
        operator: "=",
        value: ["product"]
      },
      {
        field: "productType",
        operator: "=",
        value: "package"
      }
    ]
  },
  filterValue: ['slugId', 'productId', 'itemId', 'lineItemId', 'catalogId'],
  params: ['quoteId', 'serviceId', 'itemId', 'slugId', 'productId', 'lineItemId']
};

export const ScopeOfWorks = {
  url: 'businessApi/business/scopeOfWork/:locationId/:regionId',
  method: 'GET',
  name: 'sows',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    defaultSort: [{
      field: "name",
      order: 1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  params: ['locationId', 'regionId']
};

