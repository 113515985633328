import NotesTemplate from './notes-view.html';

export default function(app) {
  app.directive('notes', notes); 

  function notes() {
    return {
      restrict: 'E',
      templateUrl: NotesTemplate,
    };
  }
}
