import {
  FREEPIK_API_KEY
} from './../../env.js';

import freepikTemplate from './../../components/popup-view/freepik-popup.html';

export default function(app) {
  app.factory('freepikService', freepikService);

  const configuration = {
    'Accept': 'application/json', 'x-freepik-api-key': FREEPIK_API_KEY, 'Content-Type': 'application/json'
  };

  function freepikService($mdDialog, messages) {

    "ngInject";

    return {
      freepikPopup: freepikPopup
    }

    function freepikPopup(ev, builder, vm) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: freepikController,
        controllerAs: "fc",
        templateUrl: freepikTemplate,
        targetEvent: ev,
        parent: angular.element(document.body),
        multiple: true,
        locals: {
          vm: vm,
          ev: ev,
        },
      });
    }
     
    function freepikController($scope, $mdSidenav, ev, messages) {
      'ngInject';
  
      let fc = this;
      fc.status = [];
  
      const imageTypes = {
        '/': 'jpeg',
        'i': 'png',
        'R': 'gif', 
        'U': 'webp', 
      };
      fc.imageStyling = {
        'photo': 'Photo',
        'digital-art': 'Digital-art',
        '3d': '3d',
        'painting': 'Painting',
        'low-poly': 'Low-poly',
        'pixel-art': 'Pixel-art',
        'anime': 'Anime',
        'cyberpunk': 'Cyberpunk',
        'comic': 'Comic',
        'vintage': 'Vintage',
        'cartoon': 'Cartoon',
        'vector': 'Vector',
        'studio-shot': 'Sudio-shot',
        'dark': 'Dark',
        'sketch': 'Sketch',
        'mockup': 'Mockup',
        '2000s-pone': '2000ps-pone',
        '70s-vibe': '70s-vibe',
        'watercolor': 'Watercolor',
        'art-nouveau': 'Art-nouveau',
        'origami': 'Origami',
        'surreal': 'Surreal',
        'fantasy': 'Fantasy',
        'traditional-japan': 'Traditional-Japan'
      };
      fc.imageSizes = {
        'square':	'Square',
        'portrait':	'Portrait',
        'social_post':	'Social post',
        'social_story':	'Social story',
        'standard':	'Standard',
        'rectangular':	'Rectangular',
        'widescreen':	'Widescreen'
      };
      fc.imageColor = {
        "b&w": "Black and White",
        "pastel": "Pastel",
        "sepia": "Sepia",
        "dramatic": "Dramatic",
        "vibrant": "Vibrant",
        "orange&teal": "Orange & Teal",
        "film-filter": "Film-Filter",
        "split": "Split",
        "electric": "Electric",
        "pastel-pink": "Pastel Pink",
        "gold-glow": "Gold Glow",
        "autumn": "Autumn",
        "muted-green": "Muted Green",
        "deep-teal": "Deep Teal",
        "duotone": "Duotone",
        "terracotta&teal": "Terracotta & Teal",
        "red&blue": "Red & Blue",
        "cold-neon": "Cold-neon",
        "burgundy&blue": "Burgundy & Blue"
      }
      fc.imageLighting = {
        "studio": "Studio",
        "warm": "Warm",
        "cinematic": "Cinematic",
        "volumetric": "Volumetric",
        "golden-hour": "Golden-hour",
        "long-exposure": "Long-exposure",
        "cold": "Cold",
        "iridescent": "Iridescent",
        "dramatic": "Dramatic",
        "hardlight": "Hardlight",
        "redscale": "Redscale",
        "indoor-light": "Indoor-light"
      };
      fc.imageFraming = {
        "portrait": "Portrait",
        "macro": "Macro",
        "panoramic": "Panoramic",
        "aerial-view": "Aerial-view",
        "close-up": "Close-up",
        "cinematic": "Cinematic",
        "high-angle": "High-angle",
        "low-angle": "Low-angle",
        "symmetry": "Symmetry",
        "fish-eye": "Fish-eye",
        "first-person": "First-person"
      }
      fc.imageOrder = {
        "relevance": "Relevance",
        "recent": "Recent",
        "random": "Random"
      }
      fc.imageOrientation = {
        "landscape": "Landscape",
        "portrait": "Portrait",
        "panoramic": "Panoramic",
        "square": "Square"
      }
  
      fc.contentType = {
        "photo": "Photo",
        "psd": "PSD",
        "vector": "Vector"
      }
  
      fc.people = {
        "include": "Include People",
        "exclude": "Exclude People"
      }
  
      fc.peopleBased = {
        'number': {
          '1': 'One',
          '2': 'Two',
          '3': 'Three',
          'more_than_three': "More than 3"
        },
        'age': {
          'infant': 'Infant',
          'child': 'Child',
          'teen': 'Teen',
          'young-adult': 'Young Adult',
          'adult': 'Adult',
          'senior': 'Senior',
          'elder': 'Elder'
        },
        'gender': {
          'male': 'Male',
          'female': 'Female'
        },
        'ethnicity': {
          'south-asian': 'South Asian',
          'middle-eastern': 'Middle Eastern',
          'east-asian': 'East Asian',
          'black': 'Black',
          'hispanic': 'Hispanic',
          'indian': 'Indian',
          'white': 'White',
          'multiracial': 'Multiracial',
        }
      }
  
      fc.userInput = {
        'limit': 8,
        'imageOrder': 'relevance',
        'imageOrientation': 'landscape',
        'contentType': 'photo',
        'people': 'include'
      };
      fc.selectedType = 'new';
  
      // let fc.orientationParams, fc.contentTypeParams, fc.peopleParams;
  
      fc.addFilters = (filterType, filter) => {
        const params = new URLSearchParams();
        params.append(`filters[${filterType}][${filter}]`, 1);
        
        switch (filterType) {
          case 'orientation':
            fc.orientationParams = params;
            break;
          case 'content_type':
            fc.contentTypeParams = params;
            break;
          case 'people':
            fc.peopleParams = params;
            break;
  
          default:
            break;
        }
      };

      fc.addFilters('orientation', 'landscape');
      fc.addFilters('content_type', 'photo');
      fc.addFilters('people', 'include');
      
  
      fc.getImages = () => {
        let url;
        let options = {
          method: !fc.generateImage ? 'GET' : 'POST',
          headers: configuration
        };
        console.log(options);
  
        if(!fc.generateImage) {
          let filters = '';
          if (fc.orientationParams) {
            filters += fc.orientationParams.toString().replace(/"/g, '') + '&';
          }
          
          if (fc.contentTypeParams) {
              filters += fc.contentTypeParams.toString().replace(/"/g, '') + '&';
          }
          
          if (fc.peopleParams) {
              filters += fc.peopleParams.toString().replace(/"/g, '') + '&';
          }
          url = 'https://api.freepik.com/v1/resources?' + (fc.userInput.imageOrder ? 'order=' + fc.userInput.imageOrder + '&' : '') + (fc.userInput.prompt ? 'term=' + fc.userInput.prompt + '&' : '') + (fc.userInput.limit ? 'limit=' + fc.userInput.limit + '&' : '') + (fc.userInput.page ? 'page=' + fc.userInput.page + '&' : '') + (filters ? filters : '');
          console.log(url);
        } else {
          if(fc.form.$invalid) {
            return;
          }
          url = 'https://api.freepik.com/v1/ai/text-to-image';
          options = {
            method: 'POST',
            headers: configuration,
            body: {
                prompt: fc.userInput.prompt,
                image: {
                  size: fc.userInput.imageSize ? fc.userInput.imageSize : 'square'
                },
                num_images: fc.userInput.noOfImages ? fc.userInput.noOfImages : 1
            }
          };
  
          if(fc.userInput.imageStyle || fc.userInput.imageColor || fc.userInput.imageLighting || fc.userInput.imageFraming) {
            options.body.styling = {};
            if(fc.userInput.imageStyle) {
              options.body.styling.style = fc.userInput.imageStyle;
            }
            if(fc.userInput.imageColor) {
              options.body.styling.color = fc.userInput.imageColor;
            }
            if(fc.userInput.imageLighting) {
              options.body.styling.lightning = fc.userInput.imageLighting;
            }
            if(fc.userInput.imageFraming) {
              options.body.styling.framing = fc.userInput.imageFraming;
            }
          }
          options.body = JSON.stringify(options.body);
        }
        // return;
        fetch(url, options)
        .then(response => response.json())
        .then(response => {
          console.log(response);
          if(response && response.data) {
            fc.allImages = response.data;
  
            if(fc.generateImage) {
              angular.forEach(fc.allImages, (x) => {
                let mimeType = x.base64.charAt(0);
                x.imageType = imageTypes[mimeType] || 'jpeg';
                if (!x.imageType) {
                  console.error(x.base64);
                }
              });
            }
            
            console.log(fc.allImages);
          } else {
            messages.simpleToast(response.message);
          }
        })
        .catch(err => {
          messages.simpleToast(err.message);
        });
      }
  
      fc.generateImages = () => {
        fc.generateImage = !fc.generateImage;
        if(fc.generateImage) {
          fc.allImagesCopy = fc.allImages;
          fc.allImages = [];
        } else {
          fc.allImages = fc.allImagesCopy;
        }
      }
  
      fc.clearFilters = () => {
        fc.userInput = {};
        if(!fc.generateImage) {
          console.log(fc.userInput);
          fc.orientationParams = undefined;
          fc.contentTypeParams = undefined;
          fc.peopleParams = undefined;
          fc.getImages();
        }
      }
  
      // fc.getImages();
  
      fc.toggleSidenav = (menuId) => {
        $mdSidenav(menuId).toggle();
      }
  
      fc.selectImages = (image) => {
        console.log(ev);
        ev.target.src = image.image.source.url;
        fc.cancel();
      }
  
      $scope.onSuccess = function(e) {
        console.log('copied');
        messages.simpleToast('Image url copied');
      };
    
      $scope.onError = function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
      }
  
      fc.cancel = function() {
        $mdDialog.cancel();
      };
  
      console.log(fc.status);
    }
  }
}